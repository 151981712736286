@media (max-width: 1024px) {
  .main-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
  }
}

/* 
const s = document.querySelector(".sidebar-toggle");
s && s.addEventListener("click", (function(t) {
    t.preventDefault(),
    e(".overlay-dark-sidebar").toggleClass("show"),
    document.querySelector(".sidebar").classList.toggle("collapsed"),
    document.querySelector(".contents").classList.toggle("expanded"), */
