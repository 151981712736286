@import url(./assets/css/plugin.min.css);
@import url(./assets/style.css);
@import url(./components/styles/responsive.css);

body {
  --bs-body-line-height: 2;
  line-height: var(--bs-body-line-height);
}
/* Login */
.Container {
  background-color: rgb(255, 255, 255);
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_card {
  width: 40vw;
  display: flex;
}
.card-body {
  padding: rem;
  padding: 2rem;
}

.form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.Heading-div {
  text-align: center;
  margin-bottom: 11;
  margin-top: 3rem;
}
.Heading {
  font-weight: bold;
  letter-spacing: 2px;
}

.input-div {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
  margin-left: 1.6rem;
  margin-right: 1.6rem;
}
.forgot-div {
  display: flex;
  flex-direction: row-reverse;
  margin-top: -0.7rem;
  margin-bottom: 0.9rem;
}
.submit-btn {
  border-radius: 1rem;
  padding: 0.4rem;
  width: 100%;
}
.submit-div {
  margin-left: 4rem;
  margin-right: 4rem;
  margin-top: 0.8rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  color: whitesmoke;
}
.signup-div {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 2rem;
}
.signup {
  margin-left: 0.5rem;
}
.form-size-1 {
  --fr-spacer-y: 0.45rem;
  --fr-spacer-x: 0.75rem;
}
.form-size-2 {
  --fr-spacer-y: 0.55rem;
  --fr-spacer-x: 1rem;
}
.form-size-3 {
  --fr-spacer-y: 1rem;
  --fr-spacer-x: 1rem;
}
.form-size-4 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}
.form-size-5 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}
.form-size-6 {
  --fr-spacer-y: 0.75rem;
  --fr-spacer-x: 1rem;
}

.form_group.radius-0,
.rounded-1 {
  --bs-border-radius: 0px;
  --bs-btn-border-radius: 0px;
}
.form_group.radius-1,
.rounded-2 {
  --bs-border-radius: 0.375rem;
  --bs-btn-border-radius: 0.375rem;
}
.form_group.radius-2,
.rounded-3 {
  --bs-border-radius: 5px;
  --bs-btn-border-radius: 5px;
}
.form_group.radius-3,
.rounded-4 {
  --bs-border-radius: 7px;
  --bs-btn-border-radius: 7px;
}
.form_group.radius-4,
.rounded-5 {
  --bs-border-radius: 10px;
  --bs-btn-border-radius: 10px;
}
.form_group.radius-5,
.rounded-6 {
  --bs-border-radius: 100px;
  --bs-btn-border-radius: 100px;
}
.form_group.radius-6,
.rounded-7 {
  --bs-border-radius: 0px;
  --bs-btn-border-radius: 0px;
}
body .form_group {
  --text-color: var(--bs-body-color);
  --fr-transition: all 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  --fr-border-radius: var(--bs-border-radius);
  --fr-border-color: #cdd2da;
  --fr-box-shadow: none;
  --fr-border-focus-color: #86b7fe;
  --fr-danger-border-color: #dc3545;
  --fr-border-width: 1px;
  --fr-border-style: solid;
  --fr-color: var(--text-color);
  --fr-line-height: var(--bs-body-line-height);
  --fr-font-size: var(--bs-body-font-size);
  /* cursor: pointer; */
}
/* body .form_group .form-label{cursor: pointer;} */
body .form_group .form-control::placeholder,
body .form_group .form-control {
  font-size: var(--fr-font-size);
}
body .form_group textarea.form-control {
  min-height: 134px;
}
body .form_group textarea.form-control.min-h-auto {
  min-height: auto;
}
body .form_group.form_style_underline .form-control {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
}
body .form_group .form-control.is-invalid:focus,
.form_group .was-validated .form-control:invalid:focus {
  border-color: var(--fr-danger-border-color);
  box-shadow: none;
}
body .form_group .form-control.is-invalid,
.form_group .was-validated .form-control:invalid {
  border-color: var(--fr-danger-border-color);
  padding-right: calc(1.5em + var(--fr-spacer-x));
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
body .form_group .form-control:focus,
.form_group .form-select:focus {
  box-shadow: var(--fr-box-shadow);
  border-color: var(--fr-border-color);
}
body .form_group .form-control {
  display: block;
  width: 100%;
  padding: var(--fr-spacer-y) var(--fr-spacer-x) !important;
  font-size: var(--fr-font-size);
  font-weight: 400;
  line-height: var(--fr-line-height);
  color: var(--fr-color);
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);
  appearance: none;
  border-radius: var(--fr-border-radius);
  transition: var(--fr-transition);
}
body .form_group .form-control:focus,
.form_group .form-select:focus {
  color: var(--fr-color);
  background-color: var(--bs-body-bg);
  border-color: var(--fr-border-focus-color);
  outline: 0;
  box-shadow: none;
}
body .form_group .form-label {
  font-size: var(--fr-font-size);
  font-weight: 400;
}
body .form_group .form-control {
  height: auto !important;
}
body .form-validate {
  --fr-border-color: var(--fr-danger-border-color);
}
body .form-validate .invalid-feedback {
  color: var(--fr-danger-border-color);
  --bs-font-size: 0.8rem;
  font-size: var(--bs-font-size);
}
.form_group .form-select {
  display: block;
  width: 100%;
  padding: var(--fr-spacer-y) var(--fr-spacer-x);
  padding-right: 2rem;
  -moz-padding-start: auto;
  font-size: var(--fr-font-size);
  font-weight: 400;
  line-height: var(--fr-line-height);
  color: #212529;
  background-color: #fff;
  /* background-image: url(./../img/icon/arrowDown.svg); */
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--fr-border-width) var(--fr-border-style) var(--fr-border-color);
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input_group_icon .input-group {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;
}
.input_group_icon .input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-body-bg);
  border: 1px solid var(--fr-border-color);
  border-radius: var(--fr-border-radius);
  user-select: none;
}
.input_group_icon .input-group-text:first-child {
  border-right-color: transparent;
}
.input_group_icon .input-group-text:last-child {
  border-left-color: transparent;
}
.input_group_icon .input-group:focus-within .input-group-text {
  --fr-border-color: var(--fr-border-focus-color);
}
.input_group_icon .form-control:not(:first-child),
.input_group_icon .form-control:not(:first-child):focus {
  border-left-color: transparent;
  padding-left: 5px;
}
.input_group_icon .form-control:not(:last-child),
.input_group_icon .form-control:not(:last-child):focus {
  border-right-color: transparent;
}

.black-text{
  color: black;
}