.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--color-dark);
}
.color-primary {
  color: var(--color-primary);
}
.bg-primary {
  background: var(--bg-primary) !important;
}
.outline-primary {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.btn-outline-primary {
  border: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.btn-outline-primary:hover {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.active-color-primary label input:checked + span {
  background: var(--color-primary);
  border-color: var(--color-primary);
}
.badge-primary {
  color: var(--color-white);
  background: var(--color-primary);
}
.alert-primary {
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgba), 0.1);
  border: 1px solid rgba(var(--color-primary-rgba), 0.1);
}
.btn-primary {
  color: var(--color-white);
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.circle-primary {
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgba), 0.1);
}
.border-primary {
  border: 1px solid var(--color-primary);
}
.bg-opacity-primary-20 {
  background: rgba(var(--color-primary-rgba), 0.2);
}
.bg-opacity-primary {
  background: rgba(var(--color-primary-rgba), 0.15);
}
.order-bg-opacity-primary {
  background: rgba(var(--color-primary-rgba), 0.1);
}
.order-bg-opacity-primary svg {
  fill: var(--color-primary);
}
.color-svgDF-primary svg {
  color: var(--color-primary);
}
.form-switch-primary .form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.form-switch-primary .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-secondary {
  color: var(--color-secondary);
}
.bg-secondary {
  background: var(--bg-secondary) !important;
}
.outline-secondary {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
}
.btn-outline-secondary {
  border: 1px solid var(--color-secondary);
  color: var(--color-secondary);
}
.btn-outline-secondary:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
.active-color-secondary label input:checked + span {
  background: var(--color-secondary);
  border-color: var(--color-secondary);
}
.badge-secondary {
  color: var(--color-white);
  background: var(--color-secondary);
}
.alert-secondary {
  color: var(--color-secondary);
  background-color: rgba(var(--color-secondary-rgba), 0.1);
  border: 1px solid rgba(var(--color-secondary-rgba), 0.1);
}
.btn-secondary {
  color: var(--color-white);
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
.circle-secondary {
  color: var(--color-secondary);
  background: rgba(var(--color-secondary-rgba), 0.1);
}
.border-secondary {
  border: 1px solid var(--color-secondary);
}
.bg-opacity-secondary-20 {
  background: rgba(var(--color-secondary-rgba), 0.2);
}
.bg-opacity-secondary {
  background: rgba(var(--color-secondary-rgba), 0.15);
}
.order-bg-opacity-secondary {
  background: rgba(var(--color-secondary-rgba), 0.1);
}
.order-bg-opacity-secondary svg {
  fill: var(--color-secondary);
}
.color-svgDF-secondary svg {
  color: var(--color-secondary);
}
.form-switch-secondary .form-check-input:checked {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}
.form-switch-secondary .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-success {
  color: var(--color-success);
}
.bg-success {
  background: var(--bg-success) !important;
}
.outline-success {
  border: 1px solid var(--color-success);
  color: var(--color-success);
}
.btn-outline-success {
  border: 1px solid var(--color-success);
  color: var(--color-success);
}
.btn-outline-success:hover {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.active-color-success label input:checked + span {
  background: var(--color-success);
  border-color: var(--color-success);
}
.badge-success {
  color: var(--color-white);
  background: var(--color-success);
}
.alert-success {
  color: var(--color-success);
  background-color: rgba(var(--color-success-rgba), 0.1);
  border: 1px solid rgba(var(--color-success-rgba), 0.1);
}
.btn-success {
  color: var(--color-white);
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.circle-success {
  color: var(--color-success);
  background: rgba(var(--color-success-rgba), 0.1);
}
.border-success {
  border: 1px solid var(--color-success);
}
.bg-opacity-success-20 {
  background: rgba(var(--color-success-rgba), 0.2);
}
.bg-opacity-success {
  background: rgba(var(--color-success-rgba), 0.15);
}
.order-bg-opacity-success {
  background: rgba(var(--color-success-rgba), 0.1);
}
.order-bg-opacity-success svg {
  fill: var(--color-success);
}
.color-svgDF-success svg {
  color: var(--color-success);
}
.form-switch-success .form-check-input:checked {
  background-color: var(--color-success);
  border-color: var(--color-success);
}
.form-switch-success .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-info {
  color: var(--color-info);
}
.bg-info {
  background: var(--bg-info) !important;
}
.outline-info {
  border: 1px solid var(--color-info);
  color: var(--color-info);
}
.btn-outline-info {
  border: 1px solid var(--color-info);
  color: var(--color-info);
}
.btn-outline-info:hover {
  background-color: var(--color-info);
  border-color: var(--color-info);
}
.active-color-info label input:checked + span {
  background: var(--color-info);
  border-color: var(--color-info);
}
.badge-info {
  color: var(--color-white);
  background: var(--color-info);
}
.alert-info {
  color: var(--color-info);
  background-color: rgba(var(--color-info-rgba), 0.1);
  border: 1px solid rgba(var(--color-info-rgba), 0.1);
}
.btn-info {
  color: var(--color-white);
  background-color: var(--color-info);
  border-color: var(--color-info);
}
.circle-info {
  color: var(--color-info);
  background: rgba(var(--color-info-rgba), 0.1);
}
.border-info {
  border: 1px solid var(--color-info);
}
.bg-opacity-info-20 {
  background: rgba(var(--color-info-rgba), 0.2);
}
.bg-opacity-info {
  background: rgba(var(--color-info-rgba), 0.15);
}
.order-bg-opacity-info {
  background: rgba(var(--color-info-rgba), 0.1);
}
.order-bg-opacity-info svg {
  fill: var(--color-info);
}
.color-svgDF-info svg {
  color: var(--color-info);
}
.form-switch-info .form-check-input:checked {
  background-color: var(--color-info);
  border-color: var(--color-info);
}
.form-switch-info .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-infos {
  color: var(--color-infos);
}
.bg-infos {
  background: var(--bg-infos) !important;
}
.outline-infos {
  border: 1px solid var(--color-infos);
  color: var(--color-infos);
}
.btn-outline-infos {
  border: 1px solid var(--color-infos);
  color: var(--color-infos);
}
.btn-outline-infos:hover {
  background-color: var(--color-infos);
  border-color: var(--color-infos);
}
.active-color-infos label input:checked + span {
  background: var(--color-infos);
  border-color: var(--color-infos);
}
.badge-infos {
  color: var(--color-white);
  background: var(--color-infos);
}
.alert-infos {
  color: var(--color-infos);
  background-color: rgba(var(--color-infos-rgba), 0.1);
  border: 1px solid rgba(var(--color-infos-rgba), 0.1);
}
.btn-infos {
  color: var(--color-white);
  background-color: var(--color-infos);
  border-color: var(--color-infos);
}
.circle-infos {
  color: var(--color-infos);
  background: rgba(var(--color-infos-rgba), 0.1);
}
.border-infos {
  border: 1px solid var(--color-infos);
}
.bg-opacity-infos-20 {
  background: rgba(var(--color-infos-rgba), 0.2);
}
.bg-opacity-infos {
  background: rgba(var(--color-infos-rgba), 0.15);
}
.order-bg-opacity-infos {
  background: rgba(var(--color-infos-rgba), 0.1);
}
.order-bg-opacity-infos svg {
  fill: var(--color-infos);
}
.color-svgDF-infos svg {
  color: var(--color-infos);
}
.form-switch-infos .form-check-input:checked {
  background-color: var(--color-infos);
  border-color: var(--color-infos);
}
.form-switch-infos .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-warning {
  color: var(--color-warning);
}
.bg-warning {
  background: var(--bg-warning) !important;
}
.outline-warning {
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
}
.btn-outline-warning {
  border: 1px solid var(--color-warning);
  color: var(--color-warning);
}
.btn-outline-warning:hover {
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}
.active-color-warning label input:checked + span {
  background: var(--color-warning);
  border-color: var(--color-warning);
}
.badge-warning {
  color: var(--color-white);
  background: var(--color-warning);
}
.alert-warning {
  color: var(--color-warning);
  background-color: rgba(var(--color-warning-rgba), 0.1);
  border: 1px solid rgba(var(--color-warning-rgba), 0.1);
}
.btn-warning {
  color: var(--color-white);
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}
.circle-warning {
  color: var(--color-warning);
  background: rgba(var(--color-warning-rgba), 0.1);
}
.border-warning {
  border: 1px solid var(--color-warning);
}
.bg-opacity-warning-20 {
  background: rgba(var(--color-warning-rgba), 0.2);
}
.bg-opacity-warning {
  background: rgba(var(--color-warning-rgba), 0.15);
}
.order-bg-opacity-warning {
  background: rgba(var(--color-warning-rgba), 0.1);
}
.order-bg-opacity-warning svg {
  fill: var(--color-warning);
}
.color-svgDF-warning svg {
  color: var(--color-warning);
}
.form-switch-warning .form-check-input:checked {
  background-color: var(--color-warning);
  border-color: var(--color-warning);
}
.form-switch-warning .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-warnings {
  color: var(--color-warnings);
}
.bg-warnings {
  background: var(--bg-warnings) !important;
}
.outline-warnings {
  border: 1px solid var(--color-warnings);
  color: var(--color-warnings);
}
.btn-outline-warnings {
  border: 1px solid var(--color-warnings);
  color: var(--color-warnings);
}
.btn-outline-warnings:hover {
  background-color: var(--color-warnings);
  border-color: var(--color-warnings);
}
.active-color-warnings label input:checked + span {
  background: var(--color-warnings);
  border-color: var(--color-warnings);
}
.badge-warnings {
  color: var(--color-white);
  background: var(--color-warnings);
}
.alert-warnings {
  color: var(--color-warnings);
  background-color: rgba(var(--color-warnings-rgba), 0.1);
  border: 1px solid rgba(var(--color-warnings-rgba), 0.1);
}
.btn-warnings {
  color: var(--color-white);
  background-color: var(--color-warnings);
  border-color: var(--color-warnings);
}
.circle-warnings {
  color: var(--color-warnings);
  background: rgba(var(--color-warnings-rgba), 0.1);
}
.border-warnings {
  border: 1px solid var(--color-warnings);
}
.bg-opacity-warnings-20 {
  background: rgba(var(--color-warnings-rgba), 0.2);
}
.bg-opacity-warnings {
  background: rgba(var(--color-warnings-rgba), 0.15);
}
.order-bg-opacity-warnings {
  background: rgba(var(--color-warnings-rgba), 0.1);
}
.order-bg-opacity-warnings svg {
  fill: var(--color-warnings);
}
.color-svgDF-warnings svg {
  color: var(--color-warnings);
}
.form-switch-warnings .form-check-input:checked {
  background-color: var(--color-warnings);
  border-color: var(--color-warnings);
}
.form-switch-warnings .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-danger {
  color: var(--color-danger);
}
.bg-danger {
  background: var(--bg-danger) !important;
}
.outline-danger {
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
}
.btn-outline-danger {
  border: 1px solid var(--color-danger);
  color: var(--color-danger);
}
.btn-outline-danger:hover {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}
.active-color-danger label input:checked + span {
  background: var(--color-danger);
  border-color: var(--color-danger);
}
.badge-danger {
  color: var(--color-white);
  background: var(--color-danger);
}
.alert-danger {
  color: var(--color-danger);
  background-color: rgba(var(--color-danger-rgba), 0.1);
  border: 1px solid rgba(var(--color-danger-rgba), 0.1);
}
.btn-danger {
  color: var(--color-white);
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}
.circle-danger {
  color: var(--color-danger);
  background: rgba(var(--color-danger-rgba), 0.1);
}
.border-danger {
  border: 1px solid var(--color-danger);
}
.bg-opacity-danger-20 {
  background: rgba(var(--color-danger-rgba), 0.2);
}
.bg-opacity-danger {
  background: rgba(var(--color-danger-rgba), 0.15);
}
.order-bg-opacity-danger {
  background: rgba(var(--color-danger-rgba), 0.1);
}
.order-bg-opacity-danger svg {
  fill: var(--color-danger);
}
.color-svgDF-danger svg {
  color: var(--color-danger);
}
.form-switch-danger .form-check-input:checked {
  background-color: var(--color-danger);
  border-color: var(--color-danger);
}
.form-switch-danger .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-dangers {
  color: var(--color-dangers);
}
.bg-dangers {
  background: var(--bg-dangers) !important;
}
.outline-dangers {
  border: 1px solid var(--color-dangers);
  color: var(--color-dangers);
}
.btn-outline-dangers {
  border: 1px solid var(--color-dangers);
  color: var(--color-dangers);
}
.btn-outline-dangers:hover {
  background-color: var(--color-dangers);
  border-color: var(--color-dangers);
}
.active-color-dangers label input:checked + span {
  background: var(--color-dangers);
  border-color: var(--color-dangers);
}
.badge-dangers {
  color: var(--color-white);
  background: var(--color-dangers);
}
.alert-dangers {
  color: var(--color-dangers);
  background-color: rgba(var(--color-dangers-rgba), 0.1);
  border: 1px solid rgba(var(--color-dangers-rgba), 0.1);
}
.btn-dangers {
  color: var(--color-white);
  background-color: var(--color-dangers);
  border-color: var(--color-dangers);
}
.circle-dangers {
  color: var(--color-dangers);
  background: rgba(var(--color-dangers-rgba), 0.1);
}
.border-dangers {
  border: 1px solid var(--color-dangers);
}
.bg-opacity-dangers-20 {
  background: rgba(var(--color-dangers-rgba), 0.2);
}
.bg-opacity-dangers {
  background: rgba(var(--color-dangers-rgba), 0.15);
}
.order-bg-opacity-dangers {
  background: rgba(var(--color-dangers-rgba), 0.1);
}
.order-bg-opacity-dangers svg {
  fill: var(--color-dangers);
}
.color-svgDF-dangers svg {
  color: var(--color-dangers);
}
.form-switch-dangers .form-check-input:checked {
  background-color: var(--color-dangers);
  border-color: var(--color-dangers);
}
.form-switch-dangers .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-purple {
  color: var(--color-purple);
}
.bg-purple {
  background: var(--bg-purple) !important;
}
.outline-purple {
  border: 1px solid var(--color-purple);
  color: var(--color-purple);
}
.btn-outline-purple {
  border: 1px solid var(--color-purple);
  color: var(--color-purple);
}
.btn-outline-purple:hover {
  background-color: var(--color-purple);
  border-color: var(--color-purple);
}
.active-color-purple label input:checked + span {
  background: var(--color-purple);
  border-color: var(--color-purple);
}
.badge-purple {
  color: var(--color-white);
  background: var(--color-purple);
}
.alert-purple {
  color: var(--color-purple);
  background-color: rgba(var(--color-purple-rgba), 0.1);
  border: 1px solid rgba(var(--color-purple-rgba), 0.1);
}
.btn-purple {
  color: var(--color-white);
  background-color: var(--color-purple);
  border-color: var(--color-purple);
}
.circle-purple {
  color: var(--color-purple);
  background: rgba(var(--color-purple-rgba), 0.1);
}
.border-purple {
  border: 1px solid var(--color-purple);
}
.bg-opacity-purple-20 {
  background: rgba(var(--color-purple-rgba), 0.2);
}
.bg-opacity-purple {
  background: rgba(var(--color-purple-rgba), 0.15);
}
.order-bg-opacity-purple {
  background: rgba(var(--color-purple-rgba), 0.1);
}
.order-bg-opacity-purple svg {
  fill: var(--color-purple);
}
.color-svgDF-purple svg {
  color: var(--color-purple);
}
.form-switch-purple .form-check-input:checked {
  background-color: var(--color-purple);
  border-color: var(--color-purple);
}
.form-switch-purple .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-dark {
  color: var(--color-dark);
}
.bg-dark {
  background: var(--bg-dark) !important;
}
.outline-dark {
  border: 1px solid var(--color-dark);
  color: var(--color-dark);
}
.btn-outline-dark {
  border: 1px solid var(--color-dark);
  color: var(--color-dark);
}
.btn-outline-dark:hover {
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}
.active-color-dark label input:checked + span {
  background: var(--color-dark);
  border-color: var(--color-dark);
}
.badge-dark {
  color: var(--color-white);
  background: var(--color-dark);
}
.alert-dark {
  color: var(--color-dark);
  background-color: rgba(var(--color-dark-rgba), 0.1);
  border: 1px solid rgba(var(--color-dark-rgba), 0.1);
}
.btn-dark {
  color: var(--color-white);
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}
.circle-dark {
  color: var(--color-dark);
  background: rgba(var(--color-dark-rgba), 0.1);
}
.border-dark {
  border: 1px solid var(--color-dark);
}
.bg-opacity-dark-20 {
  background: rgba(var(--color-dark-rgba), 0.2);
}
.bg-opacity-dark {
  background: rgba(var(--color-dark-rgba), 0.15);
}
.order-bg-opacity-dark {
  background: rgba(var(--color-dark-rgba), 0.1);
}
.order-bg-opacity-dark svg {
  fill: var(--color-dark);
}
.color-svgDF-dark svg {
  color: var(--color-dark);
}
.form-switch-dark .form-check-input:checked {
  background-color: var(--color-dark);
  border-color: var(--color-dark);
}
.form-switch-dark .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-light {
  color: var(--color-light);
}
.bg-light,
.btn.btn-transparent-light:hover {
  background: var(--bg-light) !important;
}
.outline-light {
  border: 1px solid var(--color-light);
  color: var(--color-light);
}
.btn-outline-light {
  border: 1px solid var(--color-light);
  color: var(--color-light);
}
.btn-outline-light:hover {
  background-color: var(--color-light);
  border-color: var(--color-light);
}
.active-color-light label input:checked + span {
  background: var(--color-light);
  border-color: var(--color-light);
}
.badge-light {
  color: var(--color-white);
  background: var(--color-light);
}
.alert-light {
  color: var(--color-light);
  background-color: rgba(var(--color-light-rgba), 0.1);
  border: 1px solid rgba(var(--color-light-rgba), 0.1);
}
.btn-light {
  color: var(--color-white);
  background-color: var(--color-light);
  border-color: var(--color-light);
}
.circle-light {
  color: var(--color-light);
  background: rgba(var(--color-light-rgba), 0.1);
}
.border-light {
  border: 1px solid var(--color-light);
}
.bg-opacity-light-20 {
  background: rgba(var(--color-light-rgba), 0.2);
}
.bg-opacity-light {
  background: rgba(var(--color-light-rgba), 0.15);
}
.order-bg-opacity-light {
  background: rgba(var(--color-light-rgba), 0.1);
}
.order-bg-opacity-light svg {
  fill: var(--color-light);
}
.color-svgDF-light svg {
  color: var(--color-light);
}
.form-switch-light .form-check-input:checked {
  background-color: var(--color-light);
  border-color: var(--color-light);
}
.form-switch-light .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-lighten {
  color: var(--color-lighten);
}
.bg-lighten {
  background: var(--bg-lighten) !important;
}
.outline-lighten {
  border: 1px solid var(--color-lighten);
  color: var(--color-lighten);
}
.btn-outline-lighten {
  border: 1px solid var(--color-lighten);
  color: var(--color-lighten);
}
.btn-outline-lighten:hover {
  background-color: var(--color-lighten);
  border-color: var(--color-lighten);
}
.active-color-lighten label input:checked + span {
  background: var(--color-lighten);
  border-color: var(--color-lighten);
}
.badge-lighten {
  color: var(--color-white);
  background: var(--color-lighten);
}
.alert-lighten {
  color: var(--color-lighten);
  background-color: rgba(var(--color-lighten-rgba), 0.1);
  border: 1px solid rgba(var(--color-lighten-rgba), 0.1);
}
.btn-lighten {
  color: var(--color-white);
  background-color: var(--color-lighten);
  border-color: var(--color-lighten);
}
.circle-lighten {
  color: var(--color-lighten);
  background: rgba(var(--color-lighten-rgba), 0.1);
}
.border-lighten {
  border: 1px solid var(--color-lighten);
}
.bg-opacity-lighten-20 {
  background: rgba(var(--color-lighten-rgba), 0.2);
}
.bg-opacity-lighten {
  background: rgba(var(--color-lighten-rgba), 0.15);
}
.order-bg-opacity-lighten {
  background: rgba(var(--color-lighten-rgba), 0.1);
}
.order-bg-opacity-lighten svg {
  fill: var(--color-lighten);
}
.color-svgDF-lighten svg {
  color: var(--color-lighten);
}
.form-switch-lighten .form-check-input:checked {
  background-color: var(--color-lighten);
  border-color: var(--color-lighten);
}
.form-switch-lighten .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-light-gray {
  color: var(--color-light-gray);
}
.bg-light-gray {
  background: var(--bg-light-gray) !important;
}
.outline-light-gray {
  border: 1px solid var(--color-light-gray);
  color: var(--color-light-gray);
}
.btn-outline-light-gray {
  border: 1px solid var(--color-light-gray);
  color: var(--color-light-gray);
}
.btn-outline-light-gray:hover {
  background-color: var(--color-light-gray);
  border-color: var(--color-light-gray);
}
.active-color-light-gray label input:checked + span {
  background: var(--color-light-gray);
  border-color: var(--color-light-gray);
}
.badge-light-gray {
  color: var(--color-white);
  background: var(--color-light-gray);
}
.alert-light-gray {
  color: var(--color-light-gray);
  background-color: rgba(var(--color-light-gray-rgba), 0.1);
  border: 1px solid rgba(var(--color-light-gray-rgba), 0.1);
}
.btn-light-gray {
  color: var(--color-white);
  background-color: var(--color-light-gray);
  border-color: var(--color-light-gray);
}
.circle-light-gray {
  color: var(--color-light-gray);
  background: rgba(var(--color-light-gray-rgba), 0.1);
}
.border-light-gray {
  border: 1px solid var(--color-light-gray);
}
.bg-opacity-light-gray-20 {
  background: rgba(var(--color-light-gray-rgba), 0.2);
}
.bg-opacity-light-gray {
  background: rgba(var(--color-light-gray-rgba), 0.15);
}
.order-bg-opacity-light-gray {
  background: rgba(var(--color-light-gray-rgba), 0.1);
}
.order-bg-opacity-light-gray svg {
  fill: var(--color-light-gray);
}
.color-svgDF-light-gray svg {
  color: var(--color-light-gray);
}
.form-switch-light-gray .form-check-input:checked {
  background-color: var(--color-light-gray);
  border-color: var(--color-light-gray);
}
.form-switch-light-gray .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-text,
.modal-content.modal-colored .modal-footer .btn {
  color: var(--color-text);
}
.bg-text {
  background: var(--bg-text) !important;
}
.outline-text {
  border: 1px solid var(--color-text);
  color: var(--color-text);
}
.btn-outline-text {
  border: 1px solid var(--color-text);
  color: var(--color-text);
}
.btn-outline-text:hover {
  background-color: var(--color-text);
  border-color: var(--color-text);
}
.active-color-text label input:checked + span {
  background: var(--color-text);
  border-color: var(--color-text);
}
.badge-text {
  color: var(--color-white);
  background: var(--color-text);
}
.alert-text {
  color: var(--color-text);
  background-color: rgba(var(--color-text-rgba), 0.1);
  border: 1px solid rgba(var(--color-text-rgba), 0.1);
}
.btn-text {
  color: var(--color-white);
  background-color: var(--color-text);
  border-color: var(--color-text);
}
.circle-text {
  color: var(--color-text);
  background: rgba(var(--color-text-rgba), 0.1);
}
.border-text {
  border: 1px solid var(--color-text);
}
.bg-opacity-text-20 {
  background: rgba(var(--color-text-rgba), 0.2);
}
.bg-opacity-text {
  background: rgba(var(--color-text-rgba), 0.15);
}
.order-bg-opacity-text {
  background: rgba(var(--color-text-rgba), 0.1);
}
.order-bg-opacity-text svg {
  fill: var(--color-text);
}
.color-svgDF-text svg {
  color: var(--color-text);
}
.form-switch-text .form-check-input:checked {
  background-color: var(--color-text);
  border-color: var(--color-text);
}
.form-switch-text .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-gray {
  color: var(--color-gray);
}
.bg-gray {
  background: var(--bg-gray) !important;
}
.outline-gray {
  border: 1px solid var(--color-gray);
  color: var(--color-gray);
}
.btn-outline-gray {
  border: 1px solid var(--color-gray);
  color: var(--color-gray);
}
.btn-outline-gray:hover {
  background-color: var(--color-gray);
  border-color: var(--color-gray);
}
.active-color-gray label input:checked + span {
  background: var(--color-gray);
  border-color: var(--color-gray);
}
.badge-gray {
  color: var(--color-white);
  background: var(--color-gray);
}
.alert-gray {
  color: var(--color-gray);
  background-color: rgba(var(--color-gray-rgba), 0.1);
  border: 1px solid rgba(var(--color-gray-rgba), 0.1);
}
.btn-gray {
  color: var(--color-white);
  background-color: var(--color-gray);
  border-color: var(--color-gray);
}
.circle-gray {
  color: var(--color-gray);
  background: rgba(var(--color-gray-rgba), 0.1);
}
.border-gray {
  border: 1px solid var(--color-gray);
}
.bg-opacity-gray-20 {
  background: rgba(var(--color-gray-rgba), 0.2);
}
.bg-opacity-gray {
  background: rgba(var(--color-gray-rgba), 0.15);
}
.order-bg-opacity-gray {
  background: rgba(var(--color-gray-rgba), 0.1);
}
.order-bg-opacity-gray svg {
  fill: var(--color-gray);
}
.color-svgDF-gray svg {
  color: var(--color-gray);
}
.form-switch-gray .form-check-input:checked {
  background-color: var(--color-gray);
  border-color: var(--color-gray);
}
.form-switch-gray .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-third {
  color: var(--color-third);
}
.bg-third {
  background: var(--bg-third) !important;
}
.outline-third {
  border: 1px solid var(--color-third);
  color: var(--color-third);
}
.btn-outline-third {
  border: 1px solid var(--color-third);
  color: var(--color-third);
}
.btn-outline-third:hover {
  background-color: var(--color-third);
  border-color: var(--color-third);
}
.active-color-third label input:checked + span {
  background: var(--color-third);
  border-color: var(--color-third);
}
.badge-third {
  color: var(--color-white);
  background: var(--color-third);
}
.alert-third {
  color: var(--color-third);
  background-color: rgba(var(--color-third-rgba), 0.1);
  border: 1px solid rgba(var(--color-third-rgba), 0.1);
}
.btn-third {
  color: var(--color-white);
  background-color: var(--color-third);
  border-color: var(--color-third);
}
.circle-third {
  color: var(--color-third);
  background: rgba(var(--color-third-rgba), 0.1);
}
.border-third {
  border: 1px solid var(--color-third);
}
.bg-opacity-third-20 {
  background: rgba(var(--color-third-rgba), 0.2);
}
.bg-opacity-third {
  background: rgba(var(--color-third-rgba), 0.15);
}
.order-bg-opacity-third {
  background: rgba(var(--color-third-rgba), 0.1);
}
.order-bg-opacity-third svg {
  fill: var(--color-third);
}
.color-svgDF-third svg {
  color: var(--color-third);
}
.form-switch-third .form-check-input:checked {
  background-color: var(--color-third);
  border-color: var(--color-third);
}
.form-switch-third .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-white {
  color: var(--color-white);
}
.bg-white {
  background: var(--bg-white) !important;
}
.outline-white {
  border: 1px solid var(--color-white);
  color: var(--color-white);
}
.btn-outline-white {
  border: 1px solid var(--color-white);
  color: var(--color-white);
}
.btn-outline-white:hover {
  background-color: var(--color-white);
  border-color: var(--color-white);
}
.active-color-white label input:checked + span {
  background: var(--color-white);
  border-color: var(--color-white);
}
.badge-white {
  color: var(--color-white);
  background: var(--color-white);
}
.alert-white {
  color: var(--color-white);
  background-color: rgba(var(--color-white-rgba), 0.1);
  border: 1px solid rgba(var(--color-white-rgba), 0.1);
}
.btn-white {
  color: var(--color-white);
  background-color: var(--color-white);
  border-color: var(--color-white);
}
.circle-white {
  color: var(--color-white);
  background: rgba(var(--color-white-rgba), 0.1);
}
.border-white {
  border: 1px solid var(--color-white);
}
.bg-opacity-white-20 {
  background: rgba(var(--color-white-rgba), 0.2);
}
.bg-opacity-white {
  background: rgba(var(--color-white-rgba), 0.15);
}
.order-bg-opacity-white {
  background: rgba(var(--color-white-rgba), 0.1);
}
.order-bg-opacity-white svg {
  fill: var(--color-white);
}
.color-svgDF-white svg {
  color: var(--color-white);
}
.form-switch-white .form-check-input:checked {
  background-color: var(--color-white);
  border-color: var(--color-white);
}
.form-switch-white .form-check-input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.color-footer-dark {
  color: var(--color-footer-dark);
}
.bg-footer-dark {
  background: var(--bg-footer-dark);
}
.border-footer-dark {
  border-color: var(--color-footer-dark);
}
.border-normal {
  border: 1px solid var(--color-normal);
}
.border-deep {
  border: 1px solid var(--color-deep);
}
.border-color {
  border: 1px solid var(--color-color);
}
.bg-normal {
  background: var(--bg-normal);
}
.bg-lighter {
  background: var(--bg-lighter);
}
.bg-deep {
  background: var(--bg-deep);
}
.color-facebook {
  color: var(--color-facebook);
}
.bg-facebook {
  background: var(--color-facebook);
}
.border-facebook {
  border: 1px solid var(--color-facebook);
}
.order-bg-opacity-facebook {
  background: rgba(var(--color-facebook-rgba), 0.1);
}
.order-bg-opacity-facebook svg {
  fill: var(--color-facebook);
}
.order-bg-opacity-facebook i {
  color: var(--color-facebook);
}
.color-twitter {
  color: var(--color-twitter);
}
.bg-twitter {
  background: var(--color-twitter);
}
.border-twitter {
  border: 1px solid var(--color-twitter);
}
.order-bg-opacity-twitter {
  background: rgba(var(--color-twitter-rgba), 0.1);
}
.order-bg-opacity-twitter svg {
  fill: var(--color-twitter);
}
.order-bg-opacity-twitter i {
  color: var(--color-twitter);
}
.color-pinterest {
  color: var(--color-pinterest);
}
.bg-pinterest {
  background: var(--color-pinterest);
}
.border-pinterest {
  border: 1px solid var(--color-pinterest);
}
.order-bg-opacity-pinterest {
  background: rgba(var(--color-pinterest-rgba), 0.1);
}
.order-bg-opacity-pinterest svg {
  fill: var(--color-pinterest);
}
.order-bg-opacity-pinterest i {
  color: var(--color-pinterest);
}
.color-gplus {
  color: var(--color-gplus);
}
.bg-gplus {
  background: var(--color-gplus);
}
.border-gplus {
  border: 1px solid var(--color-gplus);
}
.order-bg-opacity-gplus {
  background: rgba(var(--color-gplus-rgba), 0.1);
}
.order-bg-opacity-gplus svg {
  fill: var(--color-gplus);
}
.order-bg-opacity-gplus i {
  color: var(--color-gplus);
}
.color-linkedin {
  color: var(--color-linkedin);
}
.bg-linkedin {
  background: var(--color-linkedin);
}
.border-linkedin {
  border: 1px solid var(--color-linkedin);
}
.order-bg-opacity-linkedin {
  background: rgba(var(--color-linkedin-rgba), 0.1);
}
.order-bg-opacity-linkedin svg {
  fill: var(--color-linkedin);
}
.order-bg-opacity-linkedin i {
  color: var(--color-linkedin);
}
.color-tumblr {
  color: var(--color-tumblr);
}
.bg-tumblr {
  background: var(--color-tumblr);
}
.border-tumblr {
  border: 1px solid var(--color-tumblr);
}
.order-bg-opacity-tumblr {
  background: rgba(var(--color-tumblr-rgba), 0.1);
}
.order-bg-opacity-tumblr svg {
  fill: var(--color-tumblr);
}
.order-bg-opacity-tumblr i {
  color: var(--color-tumblr);
}
.color-vk {
  color: var(--color-vk);
}
.bg-vk {
  background: var(--color-vk);
}
.border-vk {
  border: 1px solid var(--color-vk);
}
.order-bg-opacity-vk {
  background: rgba(var(--color-vk-rgba), 0.1);
}
.order-bg-opacity-vk svg {
  fill: var(--color-vk);
}
.order-bg-opacity-vk i {
  color: var(--color-vk);
}
.color-youtube {
  color: var(--color-youtube);
}
.bg-youtube {
  background: var(--color-youtube);
}
.border-youtube {
  border: 1px solid var(--color-youtube);
}
.order-bg-opacity-youtube {
  background: rgba(var(--color-youtube-rgba), 0.1);
}
.order-bg-opacity-youtube svg {
  fill: var(--color-youtube);
}
.order-bg-opacity-youtube i {
  color: var(--color-youtube);
}
.color-ruby {
  color: var(--color-ruby);
}
.bg-ruby {
  background: var(--color-ruby);
}
.border-ruby {
  border: 1px solid var(--color-ruby);
}
.order-bg-opacity-ruby {
  background: rgba(var(--color-ruby-rgba), 0.1);
}
.order-bg-opacity-ruby svg {
  fill: var(--color-ruby);
}
.order-bg-opacity-ruby i {
  color: var(--color-ruby);
}
.color-instagram {
  color: var(--color-instagram);
}
.bg-instagram {
  background: var(--color-instagram);
}
.border-instagram {
  border: 1px solid var(--color-instagram);
}
.order-bg-opacity-instagram {
  background: rgba(var(--color-instagram-rgba), 0.1);
}
.order-bg-opacity-instagram svg {
  fill: var(--color-instagram);
}
.order-bg-opacity-instagram i {
  color: var(--color-instagram);
}
.btn-shadow-primary {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-secondary {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-success {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-info {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-infos {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-warning {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-warnings {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-danger {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-dangers {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-purple {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-dark {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-light {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-lighten {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-light-gray {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-text {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-gray {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-third {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.btn-shadow-white {
  -webkit-box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
  box-shadow: 0 8px 10px var(--color-primary-rgba-shadow) !important;
}
.outline-lg-primary {
  border: 2px solid #8231d3;
  background: 0 0;
  color: #8231d3;
}
.outline-lg-secondary {
  border: 2px solid #5840ff;
  background: 0 0;
  color: #5840ff;
}
.outline-lg-success {
  border: 2px solid #01b81a;
  background: 0 0;
  color: #01b81a;
}
.outline-lg-info {
  border: 2px solid #0af;
  background: 0 0;
  color: #0af;
}
.outline-lg-infos {
  border: 2px solid #00e4ec;
  background: 0 0;
  color: #00e4ec;
}
.outline-lg-warning {
  border: 2px solid #fa8b0c;
  background: 0 0;
  color: #fa8b0c;
}
.outline-lg-warnings {
  border: 2px solid #fb0;
  background: 0 0;
  color: #fb0;
}
.outline-lg-danger {
  border: 2px solid #ff0f0f;
  background: 0 0;
  color: #ff0f0f;
}
.outline-lg-dangers {
  border: 2px solid #ff0f0f;
  background: 0 0;
  color: #ff0f0f;
}
.outline-lg-purple {
  border: 2px solid #a722f6;
  background: 0 0;
  color: #a722f6;
}
.outline-lg-dark {
  border: 2px solid #0a0a0a;
  background: 0 0;
  color: #0a0a0a;
}
.outline-lg-light {
  border: 2px solid #8c90a4;
  background: 0 0;
  color: #8c90a4;
}
.outline-lg-lighten {
  border: 2px solid #a0a0a0;
  background: 0 0;
  color: #a0a0a0;
}
.outline-lg-light-gray {
  border: 2px solid #8c90a4;
  background: 0 0;
  color: #8c90a4;
}
.outline-lg-text {
  border: 2px solid #666d92;
  background: 0 0;
  color: #666d92;
}
.outline-lg-gray {
  border: 2px solid #404040;
  background: 0 0;
  color: #404040;
}
.outline-lg-third {
  border: 2px solid #8231d3;
  background: 0 0;
  color: #8231d3;
}
.outline-lg-white {
  border: 2px solid #fff;
  background: 0 0;
  color: #fff;
}
.btn-transparent-primary {
  background: rgba(130, 49, 211, 0.15);
  color: #8231d3;
}
.btn-transparent-secondary {
  background: rgba(88, 64, 255, 0.15);
  color: #5840ff;
}
.btn-transparent-success {
  background: rgba(1, 184, 26, 0.15);
  color: #01b81a;
}
.btn-transparent-info {
  background: rgba(0, 170, 255, 0.15);
  color: #0af;
}
.btn-transparent-infos {
  background: rgba(0, 228, 236, 0.15);
  color: #00e4ec;
}
.btn-transparent-warning {
  background: rgba(250, 139, 12, 0.15);
  color: #fa8b0c;
}
.btn-transparent-warnings {
  background: rgba(255, 187, 0, 0.15);
  color: #fb0;
}
.btn-transparent-danger {
  background: rgba(255, 15, 15, 0.15);
  color: #ff0f0f;
}
.btn-transparent-dangers {
  background: rgba(255, 15, 15, 0.15);
  color: #ff0f0f;
}
.btn-transparent-purple {
  background: rgba(167, 34, 246, 0.15);
  color: #a722f6;
}
.btn-transparent-dark {
  background: rgba(10, 10, 10, 0.15);
  color: #0a0a0a;
}
.btn-transparent-light {
  background: rgba(140, 144, 164, 0.15);
  color: #8c90a4;
}
.btn-transparent-lighten {
  background: rgba(160, 160, 160, 0.15);
  color: #a0a0a0;
}
.btn-transparent-light-gray {
  background: rgba(140, 144, 164, 0.15);
  color: #8c90a4;
}
.btn-transparent-text {
  background: rgba(102, 109, 146, 0.15);
  color: #666d92;
}
.btn-transparent-gray {
  background: rgba(64, 64, 64, 0.15);
  color: #404040;
}
.btn-transparent-third {
  background: rgba(130, 49, 211, 0.15);
  color: #8231d3;
}
.btn-transparent-white {
  background: rgba(255, 255, 255, 0.15);
  color: #fff;
}
.checkbox-primary .form-check-label::before {
  background: #8231d3;
}
.checkbox-primary .form-check-input:checked ~ .form-check-label::before {
  background: #8231d3;
  border: 1px solid #8231d3;
}
.checkbox-primary .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-secondary .form-check-label::before {
  background: #5840ff;
}
.checkbox-secondary .form-check-input:checked ~ .form-check-label::before {
  background: #5840ff;
  border: 1px solid #5840ff;
}
.checkbox-secondary .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-success .form-check-label::before {
  background: #01b81a;
}
.checkbox-success .form-check-input:checked ~ .form-check-label::before {
  background: #01b81a;
  border: 1px solid #01b81a;
}
.checkbox-success .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-info .form-check-label::before {
  background: #0af;
}
.checkbox-info .form-check-input:checked ~ .form-check-label::before {
  background: #0af;
  border: 1px solid #0af;
}
.checkbox-info .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-infos .form-check-label::before {
  background: #00e4ec;
}
.checkbox-infos .form-check-input:checked ~ .form-check-label::before {
  background: #00e4ec;
  border: 1px solid #00e4ec;
}
.checkbox-infos .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-warning .form-check-label::before {
  background: #fa8b0c;
}
.checkbox-warning .form-check-input:checked ~ .form-check-label::before {
  background: #fa8b0c;
  border: 1px solid #fa8b0c;
}
.checkbox-warning .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-warnings .form-check-label::before {
  background: #fb0;
}
.checkbox-warnings .form-check-input:checked ~ .form-check-label::before {
  background: #fb0;
  border: 1px solid #fb0;
}
.checkbox-warnings .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-danger .form-check-label::before {
  background: #ff0f0f;
}
.checkbox-danger .form-check-input:checked ~ .form-check-label::before {
  background: #ff0f0f;
  border: 1px solid #ff0f0f;
}
.checkbox-danger .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-dangers .form-check-label::before {
  background: #ff0f0f;
}
.checkbox-dangers .form-check-input:checked ~ .form-check-label::before {
  background: #ff0f0f;
  border: 1px solid #ff0f0f;
}
.checkbox-dangers .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-purple .form-check-label::before {
  background: #a722f6;
}
.checkbox-purple .form-check-input:checked ~ .form-check-label::before {
  background: #a722f6;
  border: 1px solid #a722f6;
}
.checkbox-purple .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-dark .form-check-label::before {
  background: #0a0a0a;
}
.checkbox-dark .form-check-input:checked ~ .form-check-label::before {
  background: #0a0a0a;
  border: 1px solid #0a0a0a;
}
.checkbox-dark .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-light .form-check-label::before {
  background: #8c90a4;
}
.checkbox-light .form-check-input:checked ~ .form-check-label::before {
  background: #8c90a4;
  border: 1px solid #8c90a4;
}
.checkbox-light .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-lighten .form-check-label::before {
  background: #a0a0a0;
}
.checkbox-lighten .form-check-input:checked ~ .form-check-label::before {
  background: #a0a0a0;
  border: 1px solid #a0a0a0;
}
.checkbox-lighten .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-light-gray .form-check-label::before {
  background: #8c90a4;
}
.checkbox-light-gray .form-check-input:checked ~ .form-check-label::before {
  background: #8c90a4;
  border: 1px solid #8c90a4;
}
.checkbox-light-gray .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-text .form-check-label::before {
  background: #666d92;
}
.checkbox-text .form-check-input:checked ~ .form-check-label::before {
  background: #666d92;
  border: 1px solid #666d92;
}
.checkbox-text .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-gray .form-check-label::before {
  background: #404040;
}
.checkbox-gray .form-check-input:checked ~ .form-check-label::before {
  background: #404040;
  border: 1px solid #404040;
}
.checkbox-gray .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-third .form-check-label::before {
  background: #8231d3;
}
.checkbox-third .form-check-input:checked ~ .form-check-label::before {
  background: #8231d3;
  border: 1px solid #8231d3;
}
.checkbox-third .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-white .form-check-label::before {
  background: #fff;
}
.checkbox-white .form-check-input:checked ~ .form-check-label::before {
  background: #fff;
  border: 1px solid #fff;
}
.checkbox-white .form-check-input:checked ~ .form-check-label::after {
  color: #8c90a4;
}
.checkbox-outline-primary .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-primary
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #8231d3;
  background: #8231d3;
}
.checkbox-outline-primary .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-secondary .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-secondary
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #5840ff;
  background: #5840ff;
}
.checkbox-outline-secondary
  .form-check-input:checked
  ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-success .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-success
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #01b81a;
  background: #01b81a;
}
.checkbox-outline-success .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-info .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-info .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #0af;
  background: #0af;
}
.checkbox-outline-info .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-infos .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-infos .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #00e4ec;
  background: #00e4ec;
}
.checkbox-outline-infos .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-warning .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-warning
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #fa8b0c;
  background: #fa8b0c;
}
.checkbox-outline-warning .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-warnings .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-warnings
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #fb0;
  background: #fb0;
}
.checkbox-outline-warnings
  .form-check-input:checked
  ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-danger .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-danger .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #ff0f0f;
  background: #ff0f0f;
}
.checkbox-outline-danger .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-dangers .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-dangers
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #ff0f0f;
  background: #ff0f0f;
}
.checkbox-outline-dangers .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-purple .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-purple .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #a722f6;
  background: #a722f6;
}
.checkbox-outline-purple .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-dark .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-dark .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #0a0a0a;
  background: #0a0a0a;
}
.checkbox-outline-dark .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-light .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-light .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #8c90a4;
  background: #8c90a4;
}
.checkbox-outline-light .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-lighten .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-lighten
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #a0a0a0;
  background: #a0a0a0;
}
.checkbox-outline-lighten .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-light-gray .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-light-gray
  .form-check-input:checked
  ~ .form-check-label::before {
  border: 1px solid #8c90a4;
  background: #8c90a4;
}
.checkbox-outline-light-gray
  .form-check-input:checked
  ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-text .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-text .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #666d92;
  background: #666d92;
}
.checkbox-outline-text .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-gray .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-gray .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #404040;
  background: #404040;
}
.checkbox-outline-gray .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-third .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-third .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #8231d3;
  background: #8231d3;
}
.checkbox-outline-third .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.checkbox-outline-white .form-check-label:before {
  border: 1px solid var(--border-light);
}
.checkbox-outline-white .form-check-input:checked ~ .form-check-label::before {
  border: 1px solid #fff;
  background: #fff;
}
.checkbox-outline-white .form-check-input:checked ~ .form-check-label::after {
  color: var(--color-white);
}
.btn-primary:hover {
  color: var(--color-white);
  background-color: var(--bg-primary-hover) !important;
  border-color: var(--bg-primary-hover);
}
.btn-primary-hover {
  color: #fff;
  background-color: var(--bg-primary-hover) !important;
  border-color: var(--bg-primary-hover);
}
.btn-primary-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-secondary:hover {
  color: var(--color-white);
  background-color: var(--bg-secondary-hover) !important;
  border-color: var(--bg-secondary-hover);
}
.btn-secondary-hover {
  color: #fff;
  background-color: var(--bg-secondary-hover) !important;
  border-color: var(--bg-secondary-hover);
}
.btn-secondary-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-success:hover {
  color: var(--color-white);
  background-color: var(--bg-success-hover) !important;
  border-color: var(--bg-success-hover);
}
.btn-success-hover {
  color: #fff;
  background-color: var(--bg-success-hover) !important;
  border-color: var(--bg-success-hover);
}
.btn-success-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-info:hover {
  color: var(--color-white);
  background-color: var(--bg-info-hover) !important;
  border-color: var(--bg-info-hover);
}
.btn-info-hover {
  color: #fff;
  background-color: var(--bg-info-hover) !important;
  border-color: var(--bg-info-hover);
}
.btn-info-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-warning:hover {
  color: var(--color-white);
  background-color: var(--bg-warning-hover) !important;
  border-color: var(--bg-warning-hover);
}
.btn-warning-hover {
  color: #fff;
  background-color: var(--bg-warning-hover) !important;
  border-color: var(--bg-warning-hover);
}
.btn-warning-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-danger:hover {
  color: var(--color-white);
  background-color: var(--bg-danger-hover) !important;
  border-color: var(--bg-danger-hover);
}
.btn-danger-hover {
  color: #fff;
  background-color: var(--bg-danger-hover) !important;
  border-color: var(--bg-danger-hover);
}
.btn-danger-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-dark:hover {
  color: var(--color-white);
  background-color: var(--bg-dark-hover) !important;
  border-color: var(--bg-dark-hover);
}
.btn-dark-hover {
  color: #fff;
  background-color: var(--bg-dark-hover) !important;
  border-color: var(--bg-dark-hover);
}
.btn-dark-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.btn-gray:hover {
  color: var(--color-white);
  background-color: var(--bg-gray-hover) !important;
  border-color: var(--bg-gray-hover);
}
.btn-gray-hover {
  color: #fff;
  background-color: var(--bg-gray-hover) !important;
  border-color: var(--bg-gray-hover);
}
.btn-gray-hover:hover {
  color: #fff;
  opacity: 0.7;
}
.vertical-tabs::-webkit-scrollbar-track {
  background-color: rgba(var(--color-dark-rgba), 0.05);
}
.vertical-tabs::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.vertical-tabs::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.search-body::-webkit-scrollbar-track {
  background-color: rgba(var(--color-dark-rgba), 0.05);
}
.search-body::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.search-body::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.chat-box::-webkit-scrollbar-track {
  background-color: rgba(var(--color-dark-rgba), 0.05);
}
.chat-box::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.chat-box::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.scroll-timeline::-webkit-scrollbar-track {
  background-color: rgba(var(--color-dark-rgba), 0.05);
}
.scroll-timeline::-webkit-scrollbar {
  width: 3px;
  background-color: transparent;
}
.scroll-timeline::-webkit-scrollbar-thumb {
  background-color: var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.account-profile #remove_pro_pic,
.account-profile-cards__button button,
.add-card-btn,
.ap-nameAddress__subTitle,
.ap-po-details__icon-area .svg-icon,
.ap-post-attach__btn,
.application-faqs .button-group button,
.banner-feature--14 button,
.banner-feature--15 button,
.bookmark__button .btn,
.coming-social ul li a,
.comment-respond button,
.content-center,
.custom-checkbox input:indeterminate ~ label::after,
.date-picker__next,
.date-picker__prev,
.db-social-parent__item a,
.feature-cards figcaption button,
.ffp__icon,
.fileM-Modal-overlay .modal-footer button,
.form-control.ih-large,
.form-control.ih-medium,
.form-control.ih-small,
.gc--gallery2 .gc__link,
.gc--gallery2 .gc__link li a,
.icon-list-social__link,
.kanban-modal__list button,
.kanban-modal__research button,
.knowledgebase-adv__collapse .changelog__accordingArrow,
.knowledgebase-help .card-body,
.knowledgebase-help .card-body button,
.layout-button button,
.like-icon,
.media-badge,
.media-ui--completed .progress-icon,
.order-button-group button,
.order-profile-icon,
.orderDatatable-status span,
.orderDatatable_actions li a,
.payment-invoice__btn button,
.pricing__badge,
.pricing__tag,
.product-cart .actions button,
.quantity .bttn,
.revenue-chart-box__Icon,
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice,
.selling-badge,
.testimonial-slider-global .slider-arrow,
.timeline-box .timeline-single__badge,
.timeline-box--3.basic-timeline .timeline > li .timeline-single__buble,
.timeline-box--3.basic-timeline .timeline > li .timeline-single__buble--svg,
.ui-datepicker-header a.ui-corner-all,
.user-member .action-btn a,
.user-skils-parent__item a,
.userDatatable-content-status,
.users-list__button button,
.wig-overlay__content,
.wig-overlay__iconWrapper,
.wizard10 .payment-success-modal .modal-icon,
.wizard9 .checkout-progress2 .step span:first-child {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.inline-content-center {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.align-center {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.gradient1 {
  background: #5840ff;
  background: -webkit-linear-gradient(to right, #5840ff, #00e4ec);
  background: -o-linear-gradient(to right, #5840ff, #00e4ec);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#5840ff),
    to(#00e4ec)
  );
  background: -webkit-linear-gradient(left, #5840ff, #00e4ec);
  background: -o-linear-gradient(left, #5840ff, #00e4ec);
  background: linear-gradient(to right, #5840ff, #00e4ec);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#5840FF,endColorstr=#00E4EC);
}
.gradient2 {
  background: #ff69a5;
  background: -webkit-linear-gradient(to right, #ff69a5, #fa8b0c);
  background: -o-linear-gradient(to right, #ff69a5, #fa8b0c);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff69a5),
    to(#fa8b0c)
  );
  background: -webkit-linear-gradient(left, #ff69a5, #fa8b0c);
  background: -o-linear-gradient(left, #ff69a5, #fa8b0c);
  background: linear-gradient(to right, #ff69a5, #fa8b0c);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ff69a5,endColorstr=#fa8b0c);
}
.gradient3 {
  background: #2cb7ff;
  background: -webkit-linear-gradient(to right, #2cb7ff, #5f63f2);
  background: -o-linear-gradient(to right, #2cb7ff, #5f63f2);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cb7ff),
    to(#5f63f2)
  );
  background: -webkit-linear-gradient(left, #2cb7ff, #5f63f2);
  background: -o-linear-gradient(left, #2cb7ff, #5f63f2);
  background: linear-gradient(to right, #2cb7ff, #5f63f2);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#2cb7ff,endColorstr=#5f63f2);
}
.gradient4 {
  background: #009646;
  background: -webkit-linear-gradient(to right, #009646, #62de04);
  background: -o-linear-gradient(to right, #009646, #62de04);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#009646),
    to(#62de04)
  );
  background: -webkit-linear-gradient(left, #009646, #62de04);
  background: -o-linear-gradient(left, #009646, #62de04);
  background: linear-gradient(to right, #009646, #62de04);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#009646,endColorstr=#62de04);
}
.gradientInstra {
  background: #ffc107;
  background: -webkit-linear-gradient(to right, #ffc107, #9c27b0);
  background: -o-linear-gradient(to right, #ffc107, #9c27b0);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ffc107),
    to(#9c27b0)
  );
  background: -webkit-linear-gradient(left, #ffc107, #9c27b0);
  background: -o-linear-gradient(left, #ffc107, #9c27b0);
  background: linear-gradient(to right, #ffc107, #9c27b0);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#ffc107,endColorstr=#9c27b0);
}
.b-light,
.btn-outline-lighten,
.new-member-modal button.b-light,
.new-member-modal button.btn-outline-lighten {
  border: 1px solid var(--border-light);
}
.b-deep {
  border: 1px solid var(--border-deep);
}
.b-normal-b {
  border-bottom: 1px solid var(--border-color);
}
.shadow1 {
  -webkit-box-shadow: 0 5px 10px var(--shadow3);
  box-shadow: 0 5px 10px var(--shadow3);
}
.shadow2 {
  -webkit-box-shadow: 0 3px 5px var(--shadow2);
  box-shadow: 0 3px 5px var(--shadow2);
}
.display-1,
.display-2,
.display-3,
.display-4 {
  color: var(--color-dark);
}
.text-light {
  color: var(--color-light) !important;
}
.bg-none {
  background-color: transparent !important;
}
.radius {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.radius-xl {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.radius-lg {
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.radius-md {
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.radius-sm {
  -webkit-border-radius: 0.2rem;
  border-radius: 0.2rem;
}
.radius-xs {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.flex-1 {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.media-ui--completed .progress-icon,
.wh-18 {
  width: 18px;
  height: 18px;
}
.wh-20 {
  width: 20px;
  height: 20px;
}
.wh-24 {
  width: 24px;
  height: 24px;
}
.wh-25 {
  width: 25px;
  height: 25px;
}
.wh-26 {
  width: 26px;
  height: 26px;
}
.wh-30 {
  width: 30px;
  height: 30px;
}
.wh-32 {
  width: 32px;
  height: 32px;
}
.wh-36 {
  width: 36px;
  height: 36px;
}
.product-cart .actions button,
.wh-38 {
  width: 38px;
  height: 38px;
}
.like-icon,
.wh-34 {
  width: 34px;
  height: 34px;
}
.icon-list-social__link,
.wh-40 {
  width: 40px;
  height: 40px;
}
.wh-42 {
  width: 42px;
  height: 42px;
}
.wh-44 {
  width: 44px;
  height: 44px;
}
.wh-46 {
  width: 46px;
  height: 46px;
}
.wh-48 {
  width: 48px;
  height: 48px;
}
.wh-50 {
  width: 50px;
  height: 50px;
}
.wh-60 {
  width: 60px;
  height: 60px;
}
.wh-70 {
  width: 70px;
  height: 70px;
}
.wh-80 {
  width: 80px;
  height: 80px;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.wh-100 {
  width: 100px;
  height: 100px;
}
.w-100px {
  width: 100px;
}
.wh-120 {
  width: 120px;
  height: 120px;
}
.wh-150 {
  width: 150px;
  height: 150px;
}
.svg-w-10 img,
.svg-w-10 svg {
  width: 10px;
}
.svg-w-12 img,
.svg-w-12 svg {
  width: 12px;
}
.svg-w-18 img,
.svg-w-18 svg {
  width: 18px;
}
.w-unset {
  width: unset;
}
.h-unset {
  height: unset;
}
.fs-10 {
  font-size: 10px;
}
.fs-12 {
  font-size: 12px;
}
.fs-13 {
  font-size: 13px;
}
.fs-14 {
  font-size: 14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: calc(16.2px + 0.15vw);
}
.fs-20 {
  font-size: calc(16.4px + 0.3vw);
}
.fs-22 {
  font-size: calc(16.6px + 0.45vw);
}
.fs-24 {
  font-size: calc(16.8px + 0.6vw);
}
.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-700 {
  font-weight: 700;
}
.cbg-light {
  background-color: var(--bg-lighter);
}
.transparent {
  background-color: transparent;
}
.global-shadow {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
}
.lh-normal {
  line-height: normal;
}
.d-grid {
  display: grid;
}
.media-badge {
  padding: 3px 6px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 1.6;
  font-weight: 700;
  display: inline-block;
}
.text-normal {
  font-size: 15px;
  line-height: 1.7333333333;
  font-weight: 400;
}
.text-small {
  font-size: 14px;
  line-height: 1.7142857143;
  font-weight: 400;
}
.text-big {
  font-size: 16px;
  line-height: 1.6875;
  font-weight: 400;
}
.overflow-y-scroll {
  overflow-y: scroll;
}
.overflow-x-auto {
  overflow-x: auto;
}
.clear-both {
  clear: both;
}
.mh-v-50 {
  min-height: 50vh;
}
.gradient-color-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.form-control.box-shadow-none:focus,
input.box-shadow-none {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.space-nowrap {
  white-space: nowrap;
}
.cursor-true {
  cursor: pointer;
}
.h-100vh {
  height: 100vh;
}
.btn-outline-lighten {
  color: var(--color-gray);
}
.btn-outline-lighten__height {
  height: 36px;
}
a:hover h1,
a:hover h2,
a:hover h3,
a:hover h4,
a:hover h5,
a:hover h6 {
  color: var(--color-primary);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
html {
  font-size: 16px;
  direction: ltr;
}
body {
  position: relative;
  min-height: 100vh;
  font-size: 16px;
  overflow-x: hidden;
  direction: ltr;
  background-color: var(--body);
}
a {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
button:active,
button:focus,
select:active,
select:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
}
:is(button, .btn).btn-danger:focus,
:is(button, .btn).btn-info:focus,
:is(button, .btn).btn-primary:focus,
:is(button, .btn).btn-secondary:focus,
:is(button, .btn).btn-success:focus,
:is(button, .btn).btn-warning:focus {
  color: var(--color-white) !important;
}
:is(button, .btn).btn-outline-danger:hover,
:is(button, .btn).btn-outline-info:hover,
:is(button, .btn).btn-outline-primary:hover,
:is(button, .btn).btn-outline-secondary:hover,
:is(button, .btn).btn-outline-success:hover,
:is(button, .btn).btn-outline-warning:hover {
  color: var(--color-white);
  opacity: 0.7;
}
.text-initial {
  text-transform: initial !important;
}
.form-control,
input,
textarea {
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.form-control:focus,
input:focus,
textarea:focus {
  outline: 0;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-primary-rgba), 0.1);
  box-shadow: 0 5px 20px rgba(var(--color-primary-rgba), 0.1);
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.outline-0:focus {
  outline: 0;
}
.contents {
  padding: 74px 15px 0px 295px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  background-color: var(--contents-bg);
}
.contents.expanded {
  padding-left: 93px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.contents.expanded + .footer-wrapper {
  padding-left: 93px;
}
.contents.expanded:focus {
  outline: 0;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-primary-rgba), 0.1);
  box-shadow: 0 5px 20px rgba(var(--color-primary-rgba), 0.1);
}
.main-content {
  /* min-height: 100vh; changes */
  position: relative;
  background-color: var(--main-content);
}
.dm-message {
  position: fixed;
  top: 0;
  left: 50%;
  padding: 25px;
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 40px rgba(146, 153, 184, 0.15);
  box-shadow: 0 5px 40px rgba(146, 153, 184, 0.15);
  z-index: 99999;
  font-size: 14px;
  -webkit-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  transform: translateY(-20px);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  opacity: 0;
  visibility: hidden;
  color: var(--color-dark);
}
.dm-message.show {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(20px);
  -ms-transform: translateY(20px);
  transform: translateY(20px);
}
.chart-desc span {
  display: block;
  font-size: 14px;
  color: var(--color-lighten);
}
.chart-desc strong {
  font-size: calc(16.6px + 0.45vw);
  font-weight: 600;
  color: var(--color-dark);
}
.chart-desc sub {
  font-size: 14px;
  font-weight: 600;
  bottom: 0;
}
.border-line-chart {
  border-bottom: 1px solid #c6d0dc;
}
.overlay-dark,
.overlay-dark-l2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(26, 27, 41, 0.5);
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9988;
  opacity: 0;
  visibility: hidden;
}
.overlay-dark-l2.show,
.overlay-dark.show {
  opacity: 1;
  visibility: visible;
}
.overlay-dark-l2 {
  z-index: 9998;
}
.customizer-overlay,
.overlay-dark-sidebar {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  opacity: 0;
  visibility: visible;
  z-index: -1;
  background-color: var(--color-dark);
}
.customizer-overlay {
  background: rgba(16, 18, 33, 0.1882352941);
}
.customizer-overlay.show {
  opacity: 1;
  visibility: visible;
  z-index: 9998;
}
.mfp-bg {
  z-index: 99999;
}
.mfp-wrap {
  z-index: 999999;
}
.trumbowyg-modal-box label input {
  line-height: 19px;
}
body.top-menu .navbar-left .top-menu {
  margin-left: 30px;
}
body.top-menu .navbar-left .customizer-trigger + .top-menu {
  margin-left: 10px;
}
.top-menu .search-form {
  display: none;
}
.top-menu .navbar-right__menu .nav-search {
  display: block !important;
}
.top-menu .sidebar-toggle {
  display: none;
}
.top-menu .sidebar {
  display: none;
}
.top-menu .contents {
  padding-left: 15px;
}
.top-menu .footer-wrapper {
  padding-left: 10px;
}
.side-menu .top-menu {
  display: none;
}
.regions-svg .svdg {
  width: auto;
  height: 200px;
}
.datePicker-active .ui-datepicker {
  z-index: 9999 !important;
}
.ui-datepicker {
  z-index: 11 !important;
}
.chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: var(--color-white);
  -webkit-box-shadow: 0 8px 10px rgba(146, 153, 184, 0.0823529412);
  box-shadow: 0 8px 10px rgba(146, 153, 184, 0.0823529412);
  padding: 10px 12px !important;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
  min-width: 80px;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 5%);
  -ms-transform: translate(-50%, 5%);
  transform: translate(-50%, 5%);
  z-index: 222;
  top: 0;
  left: 0;
}
.chartjs-tooltip:before {
  position: absolute;
  content: "";
  border-top: 5px solid var(--color-white);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  bottom: -5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  background: inherit;
  margin-right: 10px;
}
.tooltip-title {
  font-size: 14px;
  font-weight: 500 !important;
  font-family: Jost, sans-serif;
  text-transform: capitalize;
  margin-bottom: 4px;
  color: var(--color-gray);
}
.tooltip-value {
  color: #63b963;
  font-size: calc(16.6px + 0.45vw);
  font-weight: 600 !important;
  font-family: Jost, sans-serif;
}
.tooltip-value sup {
  font-size: 12px;
}
.chartjs-tooltip table tbody td {
  font-size: 14px;
  font-weight: 400;
  padding-bottom: 3px;
  white-space: nowrap;
  color: var(--color-gray);
}
.chartjs-tooltip table tbody td .data-label {
  color: var(--color-gray);
}
.laIcon-list-box .la {
  font-family: line awesome free, line awesome brands;
}
.user-group .media-body {
  margin: -10px 0 !important;
}
.user-group .media-body .media-badge {
  margin: 10px 0 !important;
}
.daterangepicker {
  top: 150px !important;
  border: 0;
  -webkit-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
  -webkit-box-shadow: 0 10px 30px rgba(143, 142, 159, 0.2);
  box-shadow: 0 10px 30px rgba(143, 142, 159, 0.2);
}
.daterangepicker:after,
.daterangepicker:before {
  display: none;
}
.daterangepicker .ranges {
  display: block;
  width: auto;
  padding: 20px;
}
.daterangepicker .ranges ul {
  width: 100%;
}
.daterangepicker .ranges ul li {
  font-family: Jost, sans-serif;
  font-size: 13px;
  font-weight: 600;
  width: 100%;
  padding: 10px 18px;
  border: 1px solid var(--border-light);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.daterangepicker .ranges ul li:not(:last-child) {
  margin-bottom: 10px;
}
.daterangepicker .ranges ul li.active {
  background-color: var(--color-primary);
}
.daterangepicker .calendar-table table {
  border-collapse: separate !important;
  border-spacing: 5px 8px !important;
}
.daterangepicker .calendar-table thead th.prev span {
  margin-left: 3px;
}
.daterangepicker .calendar-table thead th.next span {
  margin-right: 3px;
}
.daterangepicker .calendar-table thead th.next,
.daterangepicker .calendar-table thead th.prev {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.daterangepicker .calendar-table thead th.next:hover,
.daterangepicker .calendar-table thead th.prev:hover {
  background: #f7f7fe;
}
.daterangepicker .calendar-table thead th.next:hover span,
.daterangepicker .calendar-table thead th.prev:hover span {
  border-color: var(--color-primary) !important;
}
.daterangepicker.show-calendar {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  margin-top: 0;
}
.daterangepicker.show-calendar .calendar-title {
  display: none;
}
.daterangepicker.show-calendar .calendar-table table {
  border-collapse: separate !important;
  border-spacing: 0 2px !important;
}
.daterangepicker.show-calendar .calendar-table td {
  width: 36px;
  height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 32px;
  border: 0;
  position: relative;
  z-index: 33;
}
.daterangepicker.show-calendar .calendar-table thead th {
  font-size: 14px;
  color: var(--color-dark);
  font-weight: 500;
  padding: 4px 0;
}
.daterangepicker.show-calendar .calendar-table thead tr:last-child th {
  font-size: 12px;
  color: var(--body-color);
}
.daterangepicker.show-calendar .calendar-table tbody td.today {
  position: relative;
  background-color: var(--color-white);
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.daterangepicker.show-calendar .calendar-table tbody td.today:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
}
.daterangepicker.show-calendar .calendar-table tbody td.today.off:after {
  display: none;
}
.daterangepicker.show-calendar .calendar-table tbody td.active {
  color: var(--color-white) !important;
  border: 0 !important;
  background-color: var(--color-white) !important;
  position: relative;
}
.daterangepicker.show-calendar .calendar-table tbody td.active.off {
  background-color: var(--color-white) !important;
  color: #adb4d2 !important;
}
.daterangepicker.show-calendar .calendar-table tbody td.active.in-range:after {
  display: none;
}
.daterangepicker.show-calendar .calendar-table tbody td.available {
  font-size: 13px;
  font-weight: 500;
  color: var(--color-dark);
}
.daterangepicker.show-calendar .calendar-table tbody td.available.off {
  color: var(--body-color);
}
.daterangepicker.show-calendar .calendar-table tbody td.available.in-range {
  background: #f7f7fe;
  position: relative;
  z-index: 2;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.in-range.active {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.in-range.today {
  -webkit-border-radius: 50% !important;
  border-radius: 50% !important;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.start-date {
  color: var(--color-white) !important;
  border: 0 !important;
  background-color: var(--color-white) !important;
  position: relative;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date:after,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date:before,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.start-date:after,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.start-date:before {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  -webkit-border-radius: 50%;
  border-radius: 50%;
  z-index: -1;
  background-color: var(--color-primary);
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date:before,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.start-date:before {
  left: 12px;
  background: #f7f7fe;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date.in-range::before,
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.start-date.in-range::before {
  opacity: 0;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date:after {
  display: block;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date:before {
  left: auto;
  right: 12px;
  background: #f7f7fe;
}
.daterangepicker.show-calendar
  .calendar-table
  tbody
  td.available.active.end-date.in-range:before {
  opacity: 1;
}
.daterangepicker.show-calendar .drp-buttons {
  padding: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-color: var(--border-light);
}
.daterangepicker.show-calendar .drp-buttons .drp-selected {
  font-size: 13px;
  color: var(--body-color);
  font-family: Jost, sans-serif;
}
.daterangepicker.show-calendar .drp-buttons .cancelBtn {
  color: var(--color-gray-x);
  border: 1px solid var(--border-light);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.daterangepicker.show-calendar .drp-buttons .btn {
  font-size: 13px;
  padding: 0 14.5px;
  font-weight: 600;
}
.daterangepicker.show-calendar.ltr .drp-calendar.left {
  border: 0;
}
.daterangepicker.single .calendar-table {
  padding-right: 0 !important;
}
.daterangepicker.single .calendar-table thead th {
  font-size: 12px;
  height: 32px;
}
.daterangepicker.single .calendar-table thead th.available span {
  border-width: 0 1px 1px 0;
  border-color: var(--color-dark);
}
.daterangepicker.single .calendar-table thead .month {
  font-size: 15px;
  font-weight: 500;
}
.daterangepicker.single .calendar-table tbody td {
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 1px 2px rgba(143, 142, 159, 0.25);
  box-shadow: 0 1px 2px rgba(143, 142, 159, 0.25);
  margin-bottom: 8px;
  color: var(--color-dark);
}
.daterangepicker.single .calendar-table tbody td.off {
  color: #bdbccc;
}
.daterangepicker.single .calendar-table tbody td.active,
.daterangepicker.single .calendar-table tbody td.today {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.daterangepicker.single .calander-title {
  display: block;
  padding: 22px 15px 15px;
  font-size: 14px;
  text-align: center;
  border-bottom: 1px solid var(--border-light);
}
.daterangepicker.single .drp-calendar {
  max-width: 305px;
}
.daterangepicker.single .drp-calendar.left {
  padding: 0 20px 20px;
}
.daterangepicker .ranges li.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.social-box {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  color: var(--color-white);
  font-size: calc(16.6px + 0.45vw);
}
.customizer-trigger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 999;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-gray);
  height: 34px;
  padding: 0 15px;
  background-color: var(--bg-lighter);
  -webkit-border-radius: 50rem;
  border-radius: 50rem;
  margin-left: 10px;
}
.customizer-trigger svg path {
  fill: var(--color-gray);
}
.customizer-trigger i {
  margin-right: 8px;
  font-size: calc(16.2px + 0.15vw);
  fill: var(--color-gray);
}
.customizer-trigger:hover {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  color: var(--color-primary);
}
.customizer-trigger:hover svg path {
  fill: var(--color-primary);
}
.customizer-trigger.show {
  right: 200px;
}
.customizer-trigger img,
.customizer-trigger svg {
  width: 20px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 8px;
}
.customizer-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 350px;
  -webkit-transform: translateX(350px);
  -ms-transform: translateX(350px);
  transform: translateX(350px);
  height: 100vh;
  overflow-y: auto;
  background-color: var(--color-white);
  -webkit-box-shadow: 0 0 30px rgba(146, 153, 184, 0.062745098);
  box-shadow: 0 0 30px rgba(146, 153, 184, 0.062745098);
  z-index: 9999;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.customizer-wrapper.show {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.customizer {
  height: 100%;
}
.customizer__head {
  position: relative;
  padding: 18px 24px;
  border-bottom: 1px solid #f0f0f0;
  text-align: left;
}
.customizer__head .customizer-close {
  position: absolute;
  right: 15px;
  top: 15px;
}
.customizer__head .customizer-close i,
.customizer__head .customizer-close svg {
  color: #ff4d4f;
}
.customizer__head .customizer__sub-title {
  font-size: 14px;
}
.customizer__title {
  font-weight: 600;
  font-size: calc(16.2px + 0.15vw);
  margin-bottom: 2px;
  color: var(--color-dark);
}
.customizer__body {
  padding: 25px;
}
.customizer__single:not(:last-child) {
  margin-bottom: 35px;
}
.customizer__single h4 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 10px;
  color: var(--color-dark);
}
.customizer-list {
  margin: -10px;
}
.customizer-list__item {
  position: relative;
  display: inline-block;
  min-height: 60px;
  -webkit-background-size: cover;
  background-size: cover;
  margin: 10px;
}
.customizer-list__item a.active i,
.customizer-list__item a.active svg {
  display: block;
}
.customizer-list__item.top i,
.customizer-list__item.top svg {
  top: 35px;
}
.customizer-list__item:hover span {
  color: #5f63f2;
}
.customizer-list__item a {
  position: relative;
  display: block;
}
.customizer-list__item a i,
.customizer-list__item a svg {
  display: none;
  font-size: 16px;
  margin-top: 0;
  position: absolute;
  top: 15px;
  right: 15px;
  color: var(--color-success);
}
.customizer-list__item img {
  width: 100%;
}
.customizer-list__item span {
  display: inline-block;
  margin-top: 15px;
  color: var(--color-dark);
}
.header-top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9988;
  background: var(--main-color);
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
}
.navbar {
  padding: 0;
  padding-right: 30px;
  background-color: var(--header-bg);
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.navbar-left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-left .sidebar-toggle {
  margin-right: 8px;
}
.navbar-left .sidebar-toggle img,
.navbar-left .sidebar-toggle svg {
  width: 18px;
  pointer-events: none;
}
.navbar-left .sidebar-toggle img g,
.navbar-left .sidebar-toggle svg g {
  fill: var(--color-dark);
}
.navbar-left .sidebar-toggle img {
  width: 18px;
  height: 18px;
}
.navbar-left .sidebar-toggle i {
  font-size: calc(16.3px + 0.225vw);
  color: var(--color-gray);
}
.navbar-left .navbar-brand {
  margin-right: 40px;
}
.navbar-left .navbar-brand img,
.navbar-left .navbar-brand svg {
  max-width: 160px;
  min-width: 160px;
}
.navbar-left .navbar-brand img text span,
.navbar-left .navbar-brand svg text span {
  fill: var(--color-dark);
}
.navbar-left .navbar-brand .light {
  display: none;
}
.navbar-left .navbar-brand .svg.dark {
  display: block;
}
.navbar-left .navbar-brand .svg.dark g {
  fill: var(--color-dark);
}
.navbar-left form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-left form span {
  width: 15px;
  color: var(--color-gray-x);
}
.navbar-left form input {
  height: 25px;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 10px;
}
.navbar-left form input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.navbar-left form input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.navbar-left form input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.navbar-left form input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.navbar-left form img,
.navbar-left form svg {
  width: 18px;
  height: 18px;
  color: var(--color-gray);
}
.navbar-left .customizer-trigger svg path {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.navbar-left .logo-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 72px;
  padding-left: 30px;
  background-color: var(--logo-wrapper-bg);
  min-width: 280px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.navbar-right__menu {
  list-style: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 17px;
}
.navbar-right__menu > li {
  padding: 20px 12.5px;
}
.navbar-right__menu > li.nav-author {
  padding-right: 0;
}
.navbar-right__menu .nav-settings .nav-item-toggle img {
  width: 20px;
}
.navbar-right__menu .nav-settings .nav-item-toggle i {
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-right__menu .nav-flag-select img {
  width: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.navbar-right__menu .nav-flag-select .dropdown-wrapper {
  padding: 5px 0;
}
.navbar-right__menu .nav-flag-select .dropdown-wrapper a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 7px 20px;
  color: var(--body-color);
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  font-size: 14px;
}
.navbar-right__menu .nav-flag-select .dropdown-wrapper a:hover {
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgba), 0.1);
}
.navbar-right__menu .nav-flag-select .dropdown-wrapper a img {
  margin-right: 10px;
}
.navbar-right__menu .nav-search {
  position: relative;
}
.navbar-right__menu .nav-author img {
  width: 32px;
  height: 32px;
  -o-object-fit: cover;
  object-fit: cover;
}
.navbar-right__menu .nav-item-toggle {
  position: relative;
  color: var(--color-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.navbar-right__menu .nav-item-toggle span,
.navbar-right__menu .nav-item-toggle svg {
  width: 20px;
  height: 20px;
}
.navbar-right__menu .nav-item-toggle.icon-active:before {
  position: absolute;
  content: "";
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  right: -2px;
  top: -2px;
  border: 2px solid var(--color-white);
}
.navbar-right__menu .nav-message .nav-item-toggle:before {
  background: #01b81a;
}
.navbar-right__menu .nav-notification .nav-item-toggle:before {
  background: var(--color-warning);
}
.navbar-right__menu .dropdown-wrapper {
  -webkit-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.navbar-right__menu .dropdown-wrapper__title {
  margin: 10px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: var(--bg-lighter);
  padding: 15px;
  font-size: 14px;
  text-align: center;
}
.navbar-right__menu .dropdown-wrapper__more {
  padding: 15px;
  text-align: center;
  display: block;
  -webkit-box-shadow: 0 -15px 20px rgba(var(--light-gray-rgba), 0.08);
  box-shadow: 0 -15px 20px rgba(var(--light-gray-rgba), 0.08);
  color: var(--color-lighten);
  font-size: 13px;
  font-weight: 500;
}
.navbar-right__menu .dropdown-wrapper__more:hover {
  color: var(--color-primary);
}
.navbar-right__menu .nav-message .dropdown-wrapper ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 320px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.navbar-right__menu .nav-message .dropdown-wrapper ul::-webkit-scrollbar {
  width: 11px;
}
.navbar-right__menu .nav-message .dropdown-wrapper ul::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.navbar-right__menu .nav-message .dropdown-wrapper ul::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.navbar-right__menu .nav-message .dropdown-wrapper ul li {
  padding: 15px 25px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.navbar-right__menu .nav-message .dropdown-wrapper ul li:hover {
  -webkit-box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
}
.navbar-right__menu .nav-message .dropdown-wrapper .user-avater {
  margin-right: 15px;
  position: relative;
}
.navbar-right__menu .nav-message .dropdown-wrapper .user-avater:before {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 2px solid var(--color-white);
  right: 0;
  bottom: 0;
}
.navbar-right__menu
  .nav-message
  .dropdown-wrapper
  .author-online
  .user-avater:before {
  background: #01b81a;
}
.navbar-right__menu
  .nav-message
  .dropdown-wrapper
  .author-offline
  .user-avater:before {
  background: var(--color-warning);
}
.navbar-right__menu .nav-message .dropdown-wrapper .user-message {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.navbar-right__menu .nav-message .dropdown-wrapper .user-avater img {
  width: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.navbar-right__menu .nav-message .dropdown-wrapper .user-message p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  margin: 0;
}
.navbar-right__menu .nav-message .dropdown-wrapper .subject {
  color: var(--color-dark);
}
.navbar-right__menu .nav-message .dropdown-wrapper span.time-posted {
  font-size: 12px;
}
.navbar-right__menu
  .nav-message
  .dropdown-wrapper
  .has-new-message
  .time-posted {
  color: #01b81a;
}
.navbar-right__menu .nav-notification ul {
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 320px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.navbar-right__menu .nav-notification ul::-webkit-scrollbar {
  width: 11px;
}
.navbar-right__menu .nav-notification ul::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.navbar-right__menu .nav-notification ul::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.navbar-right__menu .nav-notification__single {
  padding: 15px 25px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.navbar-right__menu .nav-notification__single:hover {
  -webkit-box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 15px 50px rgba(var(--light-gray-rgba), 0.2);
}
.navbar-right__menu .nav-notification__type {
  width: 30px;
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 15px;
}
.navbar-right__menu .nav-notification__type span {
  font-size: 15px;
}
.navbar-right__menu .nav-notification__type img,
.navbar-right__menu .nav-notification__type svg {
  width: 15px;
}
.navbar-right__menu .nav-notification__type--primary {
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgba), 0.1);
}
.navbar-right__menu .nav-notification__type--secondary {
  color: var(--color-secondary);
  background: rgba(var(--color-secondary-rgba), 0.1);
}
.navbar-right__menu .nav-notification__type--success {
  color: #01b81a;
  background: rgba(var(--color-success-rgba), 0.1);
}
.navbar-right__menu .nav-notification__type--info {
  color: var(--color-info);
  background: rgba(var(--color-info-rgba), 0.1);
}
.navbar-right__menu .nav-notification__type--danger {
  color: var(--color-danger);
  background: rgba(var(--color-danger-rgba), 0.1);
}
.navbar-right__menu .nav-notification__details {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.navbar-right__menu .nav-notification__details p {
  margin-bottom: 5px;
  font-size: 14px;
}
.navbar-right__menu .nav-notification__details p:last-child {
  margin: 0;
}
.navbar-right__menu .nav-notification__details .time-posted {
  font-size: 12px;
}
.navbar-right__menu .nav-support .dropdown-wrapper {
  padding: 30px;
}
.navbar-right__menu .nav-support .list-group span {
  color: var(--color-light);
  font-size: 14px;
  display: block;
  margin-bottom: 5px;
}
.navbar-right__menu .nav-support .list-group ul {
  margin: 0 0 15px;
  padding: 0 0 0 15px;
  list-style: none;
}
.navbar-right__menu .nav-support .list-group ul:last-child {
  margin-bottom: 0;
}
.navbar-right__menu .nav-support .list-group ul li {
  margin-bottom: 10px;
}
.navbar-right__menu .nav-support .list-group ul li a {
  color: var(--color-dark);
  font-size: 14px;
  font-weight: 500;
}
.navbar-right__menu .nav-author .nav-item__title {
  margin-left: 10px;
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-transform: capitalize;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: auto;
  height: auto;
}
.navbar-right__menu .nav-author .nav-item__title .nav-item__arrow {
  color: var(--color-light);
  font-size: 12px;
  margin-left: 5px;
}
.navbar-right__menu .nav-author .dropdown-wrapper {
  min-width: 300px;
}
.navbar-right__menu .nav-author__info {
  background: var(--bg-lighter);
  -webkit-border-radius: 8px;
  border-radius: 8px;
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px 25px;
}
.navbar-right__menu .nav-author__info .author-img {
  margin-right: 15px;
}
.navbar-right__menu .nav-author__info .author-img img {
  max-width: 40px;
}
.navbar-right__menu .nav-author__info h6 {
  font-weight: 500;
  font-size: 14px;
}
.navbar-right__menu .nav-author__info span {
  font-size: 13px;
}
.navbar-right__menu .nav-author__signout {
  font-size: 13px;
  background: var(--bg-lighter);
  padding: 18px 15px;
  color: #868eae;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
}
.navbar-right__menu .nav-author__signout img,
.navbar-right__menu .nav-author__signout svg {
  width: 15px;
}
.navbar-right__menu .nav-author__signout i,
.navbar-right__menu .nav-author__signout span {
  font-size: 15px;
  margin-right: 6px;
}
.navbar-right__menu .nav-author__options ul {
  list-style: none;
  margin: 0;
  padding: 0 0 15px;
}
.navbar-right__menu .nav-author__options ul li {
  padding: 0;
}
.navbar-right__menu .nav-author__options ul li a {
  font-size: 14px;
  display: block;
  padding: 5px 25px;
  color: var(--color-light);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.navbar-right__menu .nav-author__options ul li a i,
.navbar-right__menu .nav-author__options ul li a span,
.navbar-right__menu .nav-author__options ul li a svg {
  margin-right: 12px;
}
.navbar-right__menu .nav-author__options ul li a i {
  font-size: 18px;
}
.navbar-right__menu .nav-author__options ul li a img,
.navbar-right__menu .nav-author__options ul li a svg {
  width: 16px;
}
.navbar-right__menu .nav-author__options ul li a:hover {
  background: rgba(var(--color-primary-rgba), 0.05);
  padding-left: 35px;
  color: var(--color-primary);
}
.navbar-right__menu .search-toggle.active i.uil-times {
  display: block;
}
.navbar-right__menu .search-toggle.active i.uil-search {
  display: none;
}
.navbar-right__menu .search-toggle i,
.navbar-right__menu .search-toggle img,
.navbar-right__menu .search-toggle svg {
  font-size: 20px;
  color: var(--color-light);
}
.navbar-right__menu .search-toggle i.uil-times {
  display: none;
}
.navbar-right__menu .search-toggle i.uil-search {
  display: block;
}
.search-form-topMenu {
  display: none;
  position: absolute;
  padding: 0 15px;
  min-width: 320px;
  top: 4px;
  right: 40px;
  height: 50px;
  background-color: var(--color-white);
  border: 1px solid var(--border-light);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.search-form-topMenu.show {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-form-topMenu .search-icon {
  width: 18px;
  position: absolute;
  left: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.search-form-topMenu input {
  border: 0;
  padding-left: 30px;
}
.navbar-right__mobileAction a {
  padding: 15px 0;
  display: inline-block;
}
.navbar-right__mobileAction a img,
.navbar-right__mobileAction a svg {
  width: 20px;
  color: var(--color-light);
}
.navbar-right__mobileAction a + a {
  margin-left: 15px;
}
.navbar-right__mobileAction .btn-search.search-active .feather-search {
  display: none;
}
.navbar-right__mobileAction .btn-search.search-active .feather-x {
  display: inline-block;
}
.navbar-right__mobileAction .btn-search .feather-x {
  display: none;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.mobile-author-actions,
.mobile-search {
  position: fixed;
  width: 100%;
  height: 60px;
  top: 60px;
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transform: translateY(-80px);
  -ms-transform: translateY(-80px);
  transform: translateY(-80px);
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  -webkit-box-shadow: 0 2px 30px rgba(146, 153, 184, 0.063);
  box-shadow: 0 2px 30px rgba(146, 153, 184, 0.063);
  background-color: var(--color-white);
  visibility: hidden;
  opacity: 0;
}
.mobile-author-actions.show,
.mobile-search.show {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.mobile-author-actions.show .search-form,
.mobile-search.show .search-form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mobile-author-actions .search-form,
.mobile-search .search-form {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
}
.mobile-author-actions .search-form .form-control,
.mobile-search .search-form .form-control {
  border: 0;
}
.mobile-author-actions .search-form img,
.mobile-author-actions .search-form svg,
.mobile-search .search-form img,
.mobile-search .search-form svg {
  margin-left: 15px;
  width: 20px;
}
.mobile-author-actions {
  padding: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mobile-author-actions .navbar-right__menu {
  width: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: 0;
}
.list-settings {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 10px;
}
.list-settings li {
  width: 50%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  padding: 20px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.list-settings li:hover {
  background: var(--color-white);
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.15);
}
.list-settings li p {
  font-size: 14px;
  margin: 0;
}
.list-settings li a {
  color: var(--color-dark);
}
.list-settings li h6 {
  font-weight: 500;
}
.hexadash-top-menu ul li {
  display: inline-block;
  position: relative;
  padding-right: 14px;
}
.hexadash-top-menu ul li:not(:last-child) {
  margin-right: 34px;
}
.hexadash-top-menu ul li.has-subMenu > a {
  position: relative;
}
.hexadash-top-menu ul li.has-subMenu > a:before {
  font-size: 12px;
  position: absolute;
  right: -16px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 600;
  font-family: line awesome free;
  content: "\f107";
  line-height: 1;
  color: var(--color-light);
}
.hexadash-top-menu ul li.has-subMenu-left > a {
  position: relative;
}
.hexadash-top-menu ul li.has-subMenu-left > a:before {
  font-size: 12px;
  position: absolute;
  right: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-weight: 600;
  font-family: line awesome free;
  content: "\f105";
  line-height: 1;
  color: var(--color-light);
}
.hexadash-top-menu ul li:hover > .subMenu {
  top: 65px;
  opacity: 1;
  visibility: visible;
}
.hexadash-top-menu ul li a {
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  padding: 1.657rem 0;
  color: var(--color-gray);
  position: relative;
}
.hexadash-top-menu ul li a .nav-icon,
.hexadash-top-menu ul li a i,
.hexadash-top-menu ul li a img,
.hexadash-top-menu ul li a svg {
  margin-right: 14px;
  width: 16px;
  font-size: 18px;
}
.hexadash-top-menu ul li a .menuItem {
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: auto;
  font-size: 10px;
  font-weight: 700;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  min-width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
}
.hexadash-top-menu ul li > ul li {
  display: block;
  position: relative;
  padding-right: 0;
  margin-right: 0 !important;
}
.hexadash-top-menu ul li > ul li.active a {
  background-color: rgba(var(--color-primary-rgba), 0.06);
  padding-left: 40px;
  color: var(--color-primary);
}
.hexadash-top-menu ul li > ul li a {
  font-weight: 400;
  padding: 0 30px;
  line-height: 3;
  color: var(--color-light);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.hexadash-top-menu ul li > ul li a.active,
.hexadash-top-menu ul li > ul li a:hover {
  background-color: rgba(var(--color-primary-rgba), 0.06);
  padding-left: 40px;
  color: var(--color-primary);
}
.hexadash-top-menu ul li > ul li:hover .subMenu {
  top: 0;
  left: 250px;
}
.hexadash-top-menu .subMenu {
  width: 250px;
  background: var(--color-white);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  position: absolute;
  left: 0;
  top: 80px;
  padding: 12px 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 98;
  -webkit-box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
}
.hexadash-top-menu .subMenu .subMenu {
  width: 250px;
  background: var(--color-white);
  position: absolute;
  left: 250px;
  top: 0;
  padding: 12px 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  z-index: 98;
  -webkit-box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
}
.hexadash-top-menu > ul > li:hover .megaMenu-wrapper {
  opacity: 1;
  visibility: visible;
  z-index: 99;
}
.hexadash-top-menu > ul > li.mega-item {
  position: static;
}
.hexadash-top-menu > ul > li a.active {
  color: var(--color-primary);
}
.hexadash-top-menu > ul > li a.active:before {
  color: var(--color-primary) !important;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  text-align: left;
  left: 0;
  top: 100%;
  overflow: hidden;
  z-index: -1;
  padding: 16px 0;
  -webkit-box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
  box-shadow: 0 15px 40px 0 rgba(82, 63, 105, 0.15);
  -webkit-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  background-color: var(--color-white);
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small {
  width: 500px;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small > li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small ul li > a {
  padding: 0 45px;
  position: relative;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small ul li > a:after {
  width: 5px;
  height: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #c6d0dc;
  content: "";
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small ul li > a.active,
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-small ul li > a:hover {
  padding-left: 45px;
  color: var(--color-primary);
}
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-small
  ul
  li
  > a.active:after,
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-small
  ul
  li
  > a:hover:after {
  background-color: var(--color-primary);
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-wide {
  width: 1000px;
  padding: 5px 0 18px;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-wide > li {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-wide > li:hover:after {
  opacity: 1;
  visibility: visible;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-wide > li .mega-title {
  display: inline-block;
  margin: 20px 0 14px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-left: 45px;
  color: var(--color-dark);
}
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-wide
  > li
  .mega-title:after {
  position: absolute;
  height: 5px;
  width: 5px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #c6d0dc;
  content: "";
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-dropdown {
  width: 540px;
  padding: 5px 0 18px;
  overflow: initial;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-dropdown > li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-dropdown .subMenu {
  left: 250px;
  top: 0;
}
.hexadash-top-menu > ul > li .megaMenu-wrapper:not(.megaMenu-dropdown) ul li {
  position: relative;
}
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper:not(.megaMenu-dropdown)
  ul
  li:hover
  > a {
  padding-left: 45px;
}
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper:not(.megaMenu-dropdown)
  ul
  li
  > a {
  line-height: 3;
  color: var(--color-light);
  font-weight: 400;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper:not(.megaMenu-dropdown)
  ul
  li:after {
  width: 6px;
  height: 1px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  left: 30px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  background-color: var(--color-light);
}
.header-top .navbar-right__menu .nav-notification .nav-item-toggle img,
.header-top .navbar-right__menu .nav-notification .nav-item-toggle svg {
  -webkit-animation: ring 2s ease infinite;
  animation: ring 2s ease infinite;
}
.header-top .navbar-right__menu .nav-notification .nav-item-toggle:after,
.header-top .navbar-right__menu .nav-notification .nav-item-toggle:before {
  z-index: 1;
}
.dropdown {
  display: inline-block;
}
.dropdown a,
.dropdown i,
.dropdown span,
.dropdown svg {
  font-size: 14px;
}
.dropdown img,
.dropdown svg {
  width: 16px;
}
.dropdown button {
  padding: 0 21.36px;
  height: 36px;
}
.dropdown button .icon-right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  right: -42px;
  top: 0;
  width: 36px;
  height: 36px;
  z-index: 9;
  border: 1px solid var(--border-light);
  border-left: 0;
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.dropdown button .icon-right i,
.dropdown button .icon-right span,
.dropdown button .icon-right svg {
  margin-right: 0;
  color: var(--color-gray);
}
.dropdown .btn-group button {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  padding: 0 16px;
}
.dropdown .btn-group button:first-child {
  -webkit-border-radius: 5px 0 0 5px !important;
  border-radius: 5px 0 0 5px !important;
}
.dropdown .dropdown-toggle-split {
  padding: 0 9.5px !important;
  -webkit-border-radius: 0 5px 5px 0 !important;
  border-radius: 0 5px 5px 0 !important;
}
.dropdown .dropdown-toggle-split i,
.dropdown .dropdown-toggle-split img,
.dropdown .dropdown-toggle-split span,
.dropdown .dropdown-toggle-split svg {
  margin: 0;
}
.dropdown-list {
  margin: -4px -2px;
}
.dropdown-list .dropdown {
  margin: 4px 2px;
}
.dropdown-custom {
  position: relative;
}
.dropdown-custom .dropdown-wrapper {
  position: absolute;
  right: 0;
  top: 52px;
  min-width: 360px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  background: var(--color-white);
  -webkit-box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}
.dropdown-custom .dropdown-wrapper:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 35px;
  top: -35px;
  left: 0;
}
.dropdown-custom .dropdown-wrapper--large {
  min-width: 700px;
}
.dropdown-custom .dropdown-wrapper--small {
  min-width: 130px;
}
.dropdown-custom:hover .dropdown-wrapper {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.dropdown-default,
.dropdown-menu {
  position: absolute;
  background: var(--color-white);
  -webkit-box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  padding: 15px 0;
  z-index: 2222;
}
.dropdown-default:not(.dropdown-menu--dynamic),
.dropdown-menu:not(.dropdown-menu--dynamic) {
  -webkit-transform: none !important;
  -ms-transform: none !important;
  transform: none !important;
  top: 40px !important;
  left: auto !important;
  right: 0 !important;
}
.dropdown-default .dropdown-item,
.dropdown-menu .dropdown-item {
  font-size: 14px;
  display: block;
  line-height: 1.1;
  padding: 13px 30px;
  font-weight: 500;
  color: var(--color-light);
}
.dropdown-default .dropdown-item:hover,
.dropdown-menu .dropdown-item:hover {
  background: rgba(var(--color-primary-rgba), 0.06);
  color: var(--color-primary);
}
.dropdown-default .dropdown-item.disabled,
.dropdown-menu .dropdown-item.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.dropdown-hover {
  position: relative;
}
.dropdown-hover:hover .dropdown-default {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(5px);
  -ms-transform: translateY(5px);
  transform: translateY(5px);
}
.dropdown-hover:hover.dropdown-btn .dropdown-default {
  -webkit-transform: translateY(15px);
  -ms-transform: translateY(15px);
  transform: translateY(15px);
  top: 40px !important;
}
.dropdown-hover .dropdown-default {
  left: 0 !important;
  right: auto !important;
  top: 30px !important;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.dropdown-default.dropdown-bottomLeft:not(.dropdown-menu--dynamic) {
  left: auto !important;
  right: 0 !important;
}
.dropdown-default.dropdown-bottomRight {
  right: auto !important;
  left: 0 !important;
}
.dropdown-default.dropdown-bottomCenter {
  left: 50% !important;
  -webkit-transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}
.dropdown-default.dropdown-topLeft {
  top: 0;
  -webkit-transform: translateY(-110%) !important;
  -ms-transform: translateY(-110%) !important;
  transform: translateY(-110%) !important;
}
.dropdown-default.dropdown-topCenter {
  top: 0;
  -webkit-transform: translate(-50%, -110%) !important;
  -ms-transform: translate(-50%, -110%) !important;
  transform: translate(-50%, -110%) !important;
  left: 50% !important;
}
.dropdown-default.dropdown-topRight {
  top: 0;
  -webkit-transform: translateY(-110%) !important;
  -ms-transform: translateY(-110%) !important;
  transform: translateY(-110%) !important;
  left: auto !important;
  right: 0 !important;
}
.dropdown-hover:hover .dropdown-default.dropdown-bottomCenter {
  -webkit-transform: translate(-50%, 0) !important;
  -ms-transform: translate(-50%, 0) !important;
  transform: translate(-50%, 0) !important;
}
.dropdown-hover:hover .dropdown-default.dropdown-topLeft {
  -webkit-transform: translateY(-130%) !important;
  -ms-transform: translateY(-130%) !important;
  transform: translateY(-130%) !important;
}
.dropdown-hover:hover .dropdown-default.dropdown-topCenter {
  -webkit-transform: translate(-50%, -130%) !important;
  -ms-transform: translate(-50%, -130%) !important;
  transform: translate(-50%, -130%) !important;
}
.dropdown-hover:hover .dropdown-default.dropdown-topRight {
  -webkit-transform: translateY(-130%) !important;
  -ms-transform: translateY(-130%) !important;
  transform: translateY(-130%) !important;
}
.dropdown.dropdown-disable {
  background-color: var(--bg-lighter);
}
.dropdown.dropdown-disable button {
  color: var(--text-color) !important;
  cursor: not-allowed !important;
}
.sidebar-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 999;
  height: 100vh;
}
.sidebar {
  position: static;
  height: 100%;
  overflow-y: auto;
  -webkit-box-shadow: 0 0 30px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 0 30px rgba(var(--light-gray-rgba), 0.1);
  width: 280px;
  background: var(--color-white);
  padding: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  scrollbar-width: thin;
}
.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
}
.sidebar::-webkit-scrollbar {
  width: 8px;
  cursor: pointer;
}
.sidebar:-webkit-scrollbar-track {
  background: var(--scrollbarBG);
  -webkit-border-radius: 100px;
  border-radius: 100px;
  cursor: pointer;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: transparent;
  -webkit-border-radius: 100px;
  border-radius: 100px;
  cursor: pointer;
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
}
.sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #adadad;
}
.sidebar .menu-text {
  -webkit-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
}
.sidebar .menuItem {
  display: inline-block;
}
.sidebar.collapsed {
  width: 76px;
  padding: 0;
}
.sidebar.collapsed .menu-text {
  display: none;
}
.sidebar.collapsed .menuItem {
  display: none !important;
}
.sidebar.collapsed .sidebar__menu-group {
  margin-bottom: 0;
}
.sidebar.collapsed .sidebar__menu-group > span {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li {
  position: static;
}
.sidebar.collapsed .sidebar__menu-group li.menu-title {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li a {
  width: 42px;
  height: 42px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 15px 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
}
.sidebar.collapsed .sidebar__menu-group li a .nav-icon {
  margin-right: 0;
}
.sidebar.collapsed .sidebar__menu-group li a .toggle-icon {
  display: none;
}
.sidebar.collapsed .sidebar__menu-group li a:hover {
  background-color: rgba(var(--color-primary-rgba), 0.05);
  color: var(--color-primary);
}
.sidebar.collapsed .sidebar__menu-group .has-child ul {
  width: 240px;
  background: var(--color-white);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  right: -225px;
  position: absolute;
  top: 0;
  padding: 10px 20px;
  -webkit-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transform: scale(0.8);
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  max-height: 350px;
  overflow: auto;
  z-index: 1000;
}
.sidebar.collapsed .sidebar__menu-group .has-child ul li a {
  width: 100%;
  height: auto;
  padding: 8px 0;
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.sidebar.collapsed .sidebar__menu-group .has-child ul li a:hover {
  background-color: transparent;
  color: var(--color-primary);
}
.sidebar.collapsed .sidebar__menu-group .has-child:hover ul {
  display: block !important;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}
.sidebar__menu-group {
  margin-bottom: 25px;
}
.sidebar__menu-group ul.sidebar_nav {
  margin: 87px 0 0;
  padding: 0;
  list-style: none;
}
.sidebar__menu-group ul.sidebar_nav li.menu-title span {
  text-transform: uppercase;
  display: block;
  color: var(--color-light);
  font-size: 12px;
  font-weight: 500;
  padding: 0 20px;
  margin-bottom: 10px;
}
.sidebar__menu-group ul.sidebar_nav li.active {
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.sidebar__menu-group ul.sidebar_nav li.active > a {
  color: var(--color-primary);
}
.sidebar__menu-group ul.sidebar_nav li.active > a .nav-icon {
  color: currentColor;
}
.sidebar__menu-group ul.sidebar_nav li > a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10.5px 20px;
  color: var(--color-gray);
  font-size: 14px;
  position: relative;
  font-weight: 500;
  line-height: 1.3571428571;
  text-transform: capitalize;
}
.sidebar__menu-group ul.sidebar_nav li > a .nav-icon {
  color: var(--color-lighten);
  display: inline-block;
  margin-right: 20px;
  width: 16px;
  font-size: calc(16.2px + 0.15vw);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.sidebar__menu-group ul.sidebar_nav li > a .toggle-icon {
  font-size: 12px;
  font-family: line awesome free;
  font-weight: 900;
  margin-left: auto;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.sidebar__menu-group ul.sidebar_nav li > a .toggle-icon:before {
  content: "\f105";
}
.sidebar__menu-group ul.sidebar_nav li > a .menuItem {
  position: absolute;
  right: 52px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: auto;
  font-size: 10px;
  font-weight: 700;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  min-width: 18px;
  height: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
}
.sidebar__menu-group ul.sidebar_nav li > a.active,
.sidebar__menu-group ul.sidebar_nav li > a:hover {
  color: var(--color-primary);
}
.sidebar__menu-group ul.sidebar_nav li > a.active .nav-icon,
.sidebar__menu-group ul.sidebar_nav li > a.active .toggle-icon,
.sidebar__menu-group ul.sidebar_nav li > a:hover .nav-icon,
.sidebar__menu-group ul.sidebar_nav li > a:hover .toggle-icon {
  color: var(--color-primary);
}
.sidebar__menu-group ul.sidebar_nav li.has-child ul {
  overflow: hidden;
}
.sidebar__menu-group ul.sidebar_nav li.has-child ul li {
  -webkit-border-radius: 0 50rem 50rem 0;
  border-radius: 0 50rem 50rem 0;
}
.sidebar__menu-group ul.sidebar_nav li.has-child ul li a {
  margin-left: 36px;
}
.sidebar__menu-group ul.sidebar_nav li.has-child.open > a .toggle-icon:before {
  content: "\f107";
}
.sidebar__menu-group ul.sidebar_nav li ul {
  padding: 1px 36px 12px 0;
}
.sidebar__menu-group ul.sidebar_nav li ul li > a.active,
.sidebar__menu-group ul.sidebar_nav li ul li > a:hover {
  background-color: transparent;
  color: var(--color-primary);
}
.sidebar__menu-group ul.sidebar_nav li ul li a {
  color: var(--color-dark);
}
.sidebar__menu-group ul.sidebar_nav li ul li a span {
  right: 16px;
}
.sidebar-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: transparent;
}
.sidebar-toggle:hover {
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.sidebar-toggle:hover i,
.sidebar-toggle:hover svg {
  color: var(--color-primary);
}
.sidebar-toggle:hover svg path {
  fill: var(--color-primary) !important;
  color: var(--color-primary) !important;
}
.sidebar-toggle:hover svg #Path_1,
.sidebar-toggle:hover svg #Path_2,
.sidebar-toggle:hover svg #Path_3 {
  fill: var(--color-primary);
  color: var(--color-primary);
}
.layout-dark .sidebar {
  background-color: var(--bg-dark);
}
.layout-dark .sidebar .sidebar__menu-group ul li.menu-title span {
  color: rgba(var(--color-white-rgba), 0.38);
}
.layout-dark .sidebar .sidebar__menu-group ul li a {
  color: rgba(var(--color-white-rgba), 0.6);
}
.layout-dark .sidebar .sidebar__menu-group ul li a:hover {
  color: var(--color-white);
}
.layout-dark .sidebar .sidebar__menu-group ul li a .nav-icon,
.layout-dark .sidebar .sidebar__menu-group ul li a .toggle-icon {
  color: rgba(var(--color-white-rgba), 0.6);
}
.layout-dark .sidebar .sidebar__menu-group ul li ul li > a:hover {
  color: var(--color-white);
}
.badge-circle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
  padding: 0 6.64px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 11px;
  line-height: 1.3;
  font-weight: 500;
}
.badge-circle.badge-warning {
  color: var(--color-white);
}
.badge-sm {
  height: 14px;
  padding: 0 5px;
  font-size: 8px;
}
.badge {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 20px;
  padding: 0 6.64px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
}
.badge.badge-lg {
  height: 20px;
  padding: 0 8.23px;
  font-size: 12px;
}
.badge.badge-md {
  height: 15px;
  padding: 0 6.64px;
  font-size: 11px;
}
.badge.badge-sm {
  height: 14px;
  padding: 0 5.12px;
  font-size: 8px;
}
.badge.badge-circle {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.badge.badge-round {
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.badge.badge-outlined {
  background-color: var(--color-white);
}
.badge.badge-outlined.badge-primary {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.badge.badge-outlined.badge-secondary {
  color: var(--color-secondary);
  border: 1px solid var(--color-secondary);
}
.badge.badge-outlined.badge-info {
  color: var(--color-info);
  border: 1px solid var(--color-info);
}
.badge.badge-outlined.badge-warning {
  color: var(--color-warning);
  border: 1px solid var(--color-warning);
}
.badge.badge-outlined.badge-danger {
  color: var(--color-danger);
  border: 1px solid var(--color-danger);
}
.badge.badge-outlined.badge-light {
  color: var(--color-light);
  border: 1px solid var(--color-light);
  background-color: transparent;
}
.badge-dot-wrap {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-white);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  height: 8px;
  width: 8px;
  top: -2.5px;
  right: -2.5px;
}
.badge-dot {
  display: block;
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.badge-dot.dot-danger {
  background-color: var(--color-danger);
}
.badge-dot.dot-default {
  background-color: var(--color-lighten);
}
.badge-dot.dot-info {
  background-color: var(--color-info);
}
.badge-dot.dot-primary {
  background-color: var(--color-primary);
}
.badge-dot.dot-success {
  background-color: var(--color-success);
}
.badge-dot.dot-secondary {
  background-color: var(--color-secondary);
}
.badge-dot.dot-warning {
  background-color: var(--color-warning);
}
.badge-dot.dot-light {
  background-color: var(--bg-lighter);
}
.badge-red {
  position: relative;
}
.badge-red.dm-badge-text {
  font-size: 12px;
}
.badge-red.dm-badge-text .badge-dot-wrap {
  right: -8px;
}
.badge-red img,
.badge-red svg {
  width: 15px;
}
.badge-red .badge-dot-wrap {
  width: 10px;
  height: 10px;
  top: -2px;
  right: 0;
}
.badge-red .badge-dot {
  position: absolute;
  width: 8px;
  height: 8px;
  top: 1px;
  right: 1px;
}
.dynamic-badge-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.dynamic-badge-block .dm-button-group {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid var(--border-light);
}
.dynamic-badge-block .dm-button-group .btn-icon {
  padding: 0 0.922rem;
  height: 32px;
  border-width: 0;
  border-right-width: 1px;
}
.dynamic-badge-block .dm-button-group .btn-icon img,
.dynamic-badge-block .dm-button-group .btn-icon svg {
  width: 20px;
  height: 20px;
  color: var(--color-dark);
}
.dynamic-badge-block .dm-button-group .btn-dec {
  border-right: 0;
}
.dynamic-badge-block .dm-button-group .btn-dec {
  border-left: 1px solid var(--border-color);
}
.dynamic-badge-block .form-switch {
  margin: 8px 0 0 16px;
}
.status-list-inline .badge-dot {
  margin-right: 10px;
}
.dm-badge-text {
  font-size: 10px;
  color: var(--color-light);
}
.dm-badge-text:not(:last-child) {
  margin-bottom: 6px;
}
.dm-badge-text .badge-dot {
  display: inline-block;
  margin-right: 10px;
}
.badge.badge-transparent.badge-danger {
  background-color: rgba(var(--color-danger-rgba), 0.1);
  color: var(--color-dark);
}
.badge.badge-transparent.badge-default {
  background-color: rgba(var(--light-gray-rgba), 0.1);
  color: var(--color-lighten);
}
.badge.badge-transparent.badge-info {
  background-color: rgba(var(--color-info-rgba), 0.1);
  color: var(--color-info);
}
.badge.badge-transparent.badge-primary {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  color: var(--color-primary);
}
.badge.badge-transparent.badge-success {
  background-color: rgba(1, 184, 26, 0.1);
  color: var(--color-success);
}
.badge.badge-transparent.badge-warning {
  background-color: rgba(var(--color-warning-rgba), 0.1);
  color: var(--color-warning);
}
.badge.badge-transparent.badge-light {
  background: rgba(var(--light-gray-rgba), 0.1);
  color: var(--color-light);
}
.badge.badge-danger {
  background-color: var(--color-danger);
  color: var(--color-white);
}
.badge.badge-default {
  background-color: var(--color-lighten);
  color: var(--color-white);
}
.badge.badge-info {
  background-color: var(--color-info);
  color: var(--color-white);
}
.badge.badge-primary {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.badge.badge-success {
  background-color: var(--color-success);
  color: var(--color-white);
}
.badge.badge-warning {
  background-color: var(--color-warning);
  color: var(--color-white);
}
.badge.badge-light {
  background: var(--color-light);
  color: var(--color-white);
}
.btn {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.btn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn:active:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.btn img,
.btn svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.btn i,
.btn span {
  font-size: 15px;
  display: inline-block;
  margin-right: 8px;
}
.btn.btn-white {
  background: var(--color-white);
  -webkit-box-shadow: 0 3px 5px rgba(var(--light-gray-rgba), 0.05);
  box-shadow: 0 3px 5px rgba(var(--light-gray-rgba), 0.05);
  color: var(--color-dark);
}
.btn.btn-white:hover {
  border-color: transparent;
  background-color: rgba(var(--color-white-rgba), 0.6);
}
.btn.btn-white.btn-shadow-white {
  -webkit-box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.15);
}
.btn.btn-white:focus {
  border-color: transparent;
  -webkit-box-shadow: 0 3px 5px rgba(var(--light-gray-rgba), 0);
  box-shadow: 0 3px 5px rgba(var(--light-gray-rgba), 0);
}
.btn.btn-white:focus:active {
  background-color: transparent;
  -webkit-box-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
  border-color: transparent;
}
.btn.btn-light {
  background: var(--bg-lighter);
  color: var(--color-dark);
  border-color: transparent;
}
.btn.btn-light:hover {
  border-color: transparent;
  background-color: #e2e6ea;
}
.btn.btn-light:active {
  color: var(--color-dark);
  background-color: #e2e6ea;
  border-color: transparent;
}
.btn.btn-light:active:focus {
  color: var(--color-dark);
  background-color: #e2e6ea;
  border-color: transparent;
}
.btn.btn-light:focus {
  color: var(--color-dark);
  background-color: #e2e6ea;
  border-color: transparent;
}
.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show > .btn-light.dropdown-toggle {
  border-color: transparent;
  background-color: #e2e6ea;
  color: var(--color-dark);
}
.btn.btn-outline-secondary:hover {
  color: var(--color-white);
}
.btn.btn-outline-warning:hover {
  color: var(--color-white);
}
.btn.btn-outline-lighten:hover {
  color: var(--color-gray);
  background-color: transparent;
  border-color: var(--color-gray);
}
.btn.btn-transparent-danger,
.btn.btn-transparent-dark,
.btn.btn-transparent-info,
.btn.btn-transparent-light,
.btn.btn-transparent-primary,
.btn.btn-transparent-secondary,
.btn.btn-transparent-success,
.btn.btn-transparent-warning {
  border-color: transparent;
}
.btn.btn-transparent-danger:hover,
.btn.btn-transparent-dark:hover,
.btn.btn-transparent-info:hover,
.btn.btn-transparent-light:hover,
.btn.btn-transparent-primary:hover,
.btn.btn-transparent-secondary:hover,
.btn.btn-transparent-success:hover,
.btn.btn-transparent-warning:hover {
  border-color: transparent;
}
.btn.btn-transparent-primary:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.btn.btn-transparent-secondary:hover {
  color: var(--color-white) !important;
  background-color: var(--color-secondary);
}
.btn.btn-transparent-success:hover {
  color: var(--color-white);
  background-color: var(--color-success);
}
.btn.btn-transparent-info:hover {
  color: var(--color-white);
  background-color: var(--color-info);
}
.btn.btn-transparent-warning:hover {
  color: var(--color-white) !important;
  background-color: var(--color-warning);
}
.btn.btn-transparent-danger:hover {
  color: var(--color-white);
  background-color: var(--color-danger);
}
.btn.btn-transparent-dark:hover {
  color: var(--color-white);
  background-color: var(--color-dark);
}
.btn.btn-transparent-light:hover {
  color: var(--color-white);
}
.btn.btn-link {
  color: var(--color-white);
  background-color: var(--color-info);
}
.btn.btn-rounded {
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.btn.btn-circle {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.btn.btn-icon {
  height: 42px;
  padding: 0 0.845rem;
}
.btn.btn-icon img,
.btn.btn-icon svg {
  margin: 0;
}
.btn.btn-outline-light {
  border-color: var(--border-light);
}
.btn.btn-outline-light:hover {
  background-color: #e2e6ea;
}
.btn.btn-outline-dashed {
  border-style: dashed;
  border: 1px dashed var(--border-light);
  color: var(--color-gray);
}
.btn-xs {
  font-size: 13px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 5.5px 15px;
  line-height: 1.4615384615;
  font-weight: 500;
}
.btn-sm {
  font-size: 14px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 1px 20px;
  line-height: 2.4;
  font-weight: 600;
}
.btn-lg {
  font-size: 14px;
  font-weight: 600;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 14px 25px;
  line-height: 1.4285714286;
}
.btn-dropdown i {
  margin-left: 10px;
}
.dm-button-group {
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.dm-button-group .btn {
  padding: 5px 0.7rem;
  z-index: 0 !important;
}
.dm-button-group .btn:active,
.dm-button-group .btn:focus {
  color: var(--color-dark);
  outline: 0;
  -webkit-box-shadow: 0 0 0 rgba(var(--color-dark-rgba), 0) !important;
  box-shadow: 0 0 0 rgba(var(--color-dark-rgba), 0) !important;
}
.dm-button-group .btn:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.dm-button-group .btn:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.dm-button-group .btn.btn-primary:active,
.dm-button-group .btn.btn-primary:focus {
  border-color: var(--color-primary);
}
.dm-button-group .btn.btn-outline-light.active {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.dm-button-group .btn.btn-outline-light.active:hover {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}
.dm-button-group .btn.btn-outline-light:hover {
  background-color: var(--bg-lighter) !important;
}
.dm-button-group .btn.btn-solid {
  color: var(--color-lighten);
}
.dm-button-group.btn-group-solid {
  background-color: var(--bg-lighter);
}
.dm-button-group.btn-group-solid .btn-light {
  color: var(--color-lighten) !important;
}
.dm-button-group.btn-group-withIcon {
  border: 0;
}
.dm-button-group.btn-group-withIcon .btn {
  padding: 5px 0.7rem;
}
.dm-button-group.btn-group-withIcon .btn.active {
  background-color: var(--color-primary);
}
.dm-button-group.btn-group-withIcon .btn:hover {
  background-color: var(--color-primary);
}
.dm-button-group.btn-group-withIcon .btn img,
.dm-button-group.btn-group-withIcon .btn svg {
  margin-right: 0;
}
.btn-group > .btn {
  z-index: 1;
}
.button-inline-list .btn.btn-outline-light {
  color: var(--color-gray);
}
.button-inline-list .btn.btn-outline-light:hover {
  color: var(--color-gray);
}
.button-inline-list .btn.btn-outline-light.active {
  color: var(--color-white);
}
.btn.btn-ghost {
  background: 0 0;
  border: 1px solid #bfb2f0;
  color: var(--color-white);
}
.btn.btn-ghost:hover {
  background: var(--color-white);
  color: var(--color-dark);
  border-color: transparent;
}
.btn.btn-block {
  width: 100%;
  display: block;
}
.button-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: -5px;
}
.button-group button {
  margin: 5px;
}
.btn:disabled {
  cursor: not-allowed;
}
.dm-nav-controller .nav .nav-link {
  position: relative;
  -webkit-box-flex: initial;
  -ms-flex: initial;
  flex: initial;
  line-height: 2.65;
  padding: 0 16.44px;
  border-width: 1px;
  -webkit-border-radius: 0;
  border-radius: 0;
  min-height: 42px;
  color: var(--color-light);
}
.dm-nav-controller .nav .nav-link.active,
.dm-nav-controller .nav .nav-link:focus {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  color: var(--color-primary);
}
.dm-nav-controller .nav .nav-link.active:hover,
.dm-nav-controller .nav .nav-link:focus:hover {
  background-color: transparent !important;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  color: var(--color-primary);
}
.dm-nav-controller .nav .nav-link.active:focus,
.dm-nav-controller .nav .nav-link:focus:focus {
  outline: 0;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.dm-nav-controller .nav .nav-link.active:not(:first-child):before {
  background-color: var(--color-primary);
}
.dm-nav-controller .nav .nav-link:last-child {
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.dm-nav-controller .nav .nav-link:first-child {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
  border-left-width: 1px;
}
.dm-nav-controller .nav .nav-link:not(:first-child):before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 0.05px;
  height: 100%;
  padding: 1px 0;
  background-color: var(--border-light);
  content: "";
}
.dm-nav-controller .btn-group > .btn.active,
.dm-nav-controller .btn-group > .btn.focus {
  z-index: 1 !important;
}
.dm-nav-controller .nav-controller-content {
  margin-top: 35px;
}
.nav-controller-content .nav-link {
  border: 0;
}
.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 44px;
}
.alert.alert-icon-area {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.5313rem 1.25rem;
}
.alert.alert-icon-area .alert-icon img,
.alert.alert-icon-area .alert-icon svg {
  margin-top: 1px;
}
.alert.alert-icon-big {
  padding: 1.125rem 1.25rem;
}
.alert.alert-big {
  padding: 0.8125rem 20px;
}
.alert:last-child {
  margin-bottom: 0;
}
.alert .alert-icon {
  margin-right: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.alert .alert-icon img,
.alert .alert-icon svg {
  width: 15px;
  margin-top: 0;
}
.alert .alert-heading {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 8px;
}
.alert p {
  font-size: 14px;
  margin-bottom: -1px;
}
.alert.alert-dismissible .btn-close {
  padding: 10px 20px;
  background: 0 0;
  width: auto;
}
.alert.alert-dismissible .btn-close i,
.alert.alert-dismissible .btn-close span {
  font-size: 12px;
  font-weight: 500;
  display: block;
  line-height: 1.9;
}
.alert.alert-dismissible .btn-close img,
.alert.alert-dismissible .btn-close svg {
  width: 12px;
}
.avatar {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-white);
  margin: 0 10px;
}
.avatar.avatar-light {
  background: var(--border-light);
}
.avatar.avatar-light.avatar-transparent {
  background: rgba(var(--light-gray-rgba), 0.15);
}
.avatar.avatar-warning {
  background-color: var(--color-warning);
}
.avatar.avatar-warning.avatar-transparent {
  color: var(--color-warning);
  background: rgba(var(--color-warning-rgba), 0.15);
}
.avatar.avatar-primary {
  background-color: var(--color-primary);
}
.avatar.avatar-primary.avatar-transparent {
  background: rgba(var(--color-primary-rgba), 0.15);
  color: var(--color-primary);
}
.avatar.avatar-success {
  background-color: var(--color-success);
}
.avatar.avatar-success.avatar-transparent {
  background: rgba(1, 184, 26, 0.15);
  color: var(--color-success);
}
.avatar.avatar-info {
  background-color: var(--color-info);
  color: var(--color-info);
}
.avatar.avatar-info.avatar-transparent {
  background: rgba(var(--color-info-rgba), 0.15);
}
.avatar .avatar-text {
  text-transform: uppercase;
}
.avatar .avatar-img {
  width: 100%;
}
.avatar.avatar-lg {
  width: 64px;
  height: 64px;
}
.avatar.avatar-lg i,
.avatar.avatar-lg svg {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}
.avatar.avatar-lg .avatar-letter {
  font-size: calc(16.8px + 0.6vw);
}
.avatar.avatar-lg .avatar-text {
  font-size: 14px;
}
.avatar.avatar-md {
  width: 40px;
  height: 40px;
}
.avatar.avatar-md i,
.avatar.avatar-md svg {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.avatar.avatar-md .avatar-letter {
  font-size: calc(16.6px + 0.45vw);
}
.avatar.avatar-md .avatar-text {
  font-size: 12px;
}
.avatar.avatar-sm {
  width: 32px;
  height: 32px;
}
.avatar.avatar-sm i,
.avatar.avatar-sm svg {
  -webkit-transform: scale(0.85);
  -ms-transform: scale(0.85);
  transform: scale(0.85);
}
.avatar.avatar-sm .avatar-letter {
  font-size: calc(16.4px + 0.3vw);
}
.avatar.avatar-sm .avatar-text {
  font-size: 10px;
}
.avatar.avatar-xs {
  width: 24px;
  height: 24px;
}
.avatar.avatar-xs i,
.avatar.avatar-xs svg {
  -webkit-transform: scale(0.6);
  -ms-transform: scale(0.6);
  transform: scale(0.6);
}
.avatar.avatar-xs .avatar-letter {
  font-size: calc(16.2px + 0.15vw);
}
.avatar.avatar-xs .avatar-text {
  font-size: 8px;
}
.avatar.avatar-circle {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.avatar.avatar-square {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.avatar .avatar-badge-wrap {
  position: absolute;
  top: -10px;
  right: -10px;
  background: var(--color-white);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 1.3px 3px;
  line-height: 1;
}
.avatar .avatar-badge-wrap .badge {
  padding: 1.5px 4.52px;
}
.avatar .avatar-badge-wrap.avatar-badge-icon {
  background: 0 0;
}
.avatar .avatar-badge-wrap .badge-icon {
  width: 16px;
  height: 16px;
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-primary {
  color: var(--color-primary);
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-secondary {
  color: var(--color-secondary);
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-info {
  color: var(--color-info);
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-success {
  color: var(--color-success);
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-warning {
  color: var(--color-warning);
}
.avatar .avatar-badge-wrap .badge-icon.badge-icon-danger {
  color: var(--color-danger);
}
.breadcrumb-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 33px;
  margin-top: 33px;
}
.breadcrumb-main .breadcrumb-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -4px;
}
.breadcrumb-main .breadcrumb-action .action-btn {
  margin: 7.5px 4px;
}
.breadcrumb-main .breadcrumb-action .action-btn .btn {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  font-weight: 500;
  height: 34px;
  padding: 0 7.5px;
  border-color: var(--color-white);
}
.breadcrumb-main .breadcrumb-action .action-btn .btn i,
.breadcrumb-main .breadcrumb-action .action-btn .btn img,
.breadcrumb-main .breadcrumb-action .action-btn .btn svg {
  color: var(--color-primary);
}
.breadcrumb-main .breadcrumb-action .action-btn .btn:active,
.breadcrumb-main .breadcrumb-action .action-btn .btn:focus {
  background: var(--color-white);
  -webkit-box-shadow: 0 3px 5px rgba(146, 153, 184, 0.05) !important;
  box-shadow: 0 3px 5px rgba(146, 153, 184, 0.05) !important;
  border-color: transparent;
}
.breadcrumb-main .breadcrumb-action .action-btn .btn-add {
  padding: 0 9.125px;
}
.breadcrumb-main .breadcrumb-action .action-btn .btn-add:active,
.breadcrumb-main .breadcrumb-action .action-btn .btn-add:focus {
  background: var(--color-primary);
}
.breadcrumb-main .breadcrumb-action .action-btn .btn-add i,
.breadcrumb-main .breadcrumb-action .action-btn .btn-add img,
.breadcrumb-main .breadcrumb-action .action-btn .btn-add svg {
  color: var(--color-white);
}
.breadcrumb-main .breadcrumb-action .input-container.icon-left input {
  padding-left: 32px;
}
.breadcrumb-main
  .breadcrumb-action
  .input-container.icon-left
  input::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
}
.breadcrumb-main
  .breadcrumb-action
  .input-container.icon-left
  input::-moz-placeholder {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
}
.breadcrumb-main
  .breadcrumb-action
  .input-container.icon-left
  input::-ms-input-placeholder {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
}
.breadcrumb-main
  .breadcrumb-action
  .input-container.icon-left
  input::placeholder {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
}
.breadcrumb-main .breadcrumb-action .input-container .icon-left {
  left: 12px;
}
.breadcrumb-main .breadcrumb-action .input-container .icon-left i,
.breadcrumb-main .breadcrumb-action .input-container .icon-left svg {
  color: var(--color-primary);
}
.breadcrumb-main .breadcrumb-action .input-container .icon-right {
  right: 8px;
}
.breadcrumb-main .breadcrumb-action .input-container input {
  padding: 10px 1rem;
  font-weight: 500;
  font-size: 12px;
  color: var(--color-gray);
}
.breadcrumb-main .breadcrumb-action .form-control {
  border: 0;
  height: 34px;
}
.dm-breadcrumb__item a,
.dm-breadcrumb__item span {
  font-size: 14px;
  color: var(--color-gray);
}
.dm-breadcrumb__item a:hover,
.dm-breadcrumb__item span:hover {
  color: var(--color-info);
}
.dm-breadcrumb__item:first-child a {
  color: var(--color-primary);
}
.dm-breadcrumb__item:first-child a span {
  color: var(--color-primary);
}
.dm-breadcrumb__item:first-child a:hover span {
  color: var(--color-info);
}
.dm-breadcrumb__item:last-child span {
  color: var(--color-light);
}
.dm-breadcrumb__item span.la {
  font-size: 14px;
}
.dm-breadcrumb__item span.la-slash {
  -webkit-transform: rotate(-20deg);
  -ms-transform: rotate(-20deg);
  transform: rotate(-20deg);
}
.dm-breadcrumb__item .dropdown > a:after {
  font-size: 10px;
  font-family: line awesome free;
  font-weight: 700;
  content: "\f107";
}
.breadcrumb {
  margin-right: 5px;
}
.breadcrumb-item {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.breadcrumb-item a {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-gray);
}
.breadcrumb-item a:hover {
  opacity: 0.7;
}
.breadcrumb-item a i,
.breadcrumb-item a span {
  color: var(--color-light);
  margin-right: 8px;
  font-size: 16px;
}
.breadcrumb-item.active {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--color-light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-transform: capitalize;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: "";
  width: 4px;
  height: 4px;
  float: left;
  padding-right: 0;
  background-color: var(--color-lighten);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 7px;
}
.dm-switch-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dm-switch-wrap .form-switch {
  margin-right: 10px;
}
.form-check-input {
  clear: left;
}
.form-switch .form-check-input {
  width: 1.8rem;
  height: 0.9rem;
  -webkit-border-radius: 2rem;
  border-radius: 2rem;
}
.form-switch.form-switch-sm .form-check-input {
  width: 2.188rem;
  height: 1.25rem;
  -webkit-border-radius: 2rem;
  border-radius: 2rem;
}
.form-switch.form-switch-md .form-check-input {
  height: 1.5rem;
  width: 2.75rem;
  -webkit-border-radius: 3rem;
  border-radius: 3rem;
}
.form-switch.form-switch-lg .form-check-input {
  height: 2rem;
  width: 3.75rem;
  -webkit-border-radius: 4rem;
  border-radius: 4rem;
}
.form-switch.form-switch-xl .form-check-input {
  height: 2.5rem;
  width: 4.75rem;
  -webkit-border-radius: 5rem;
  border-radius: 5rem;
}
.form-check-input:checked {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  background-color: var(--bg-white);
  border: 1px solid var(--border-color);
}
.card .card-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  text-transform: capitalize;
  padding-top: 0;
  padding-bottom: 0;
  min-height: 66px;
  background-color: var(--bg-white);
  border-bottom: 1px solid var(--border-color);
}
.card .card-header h1,
.card .card-header h2,
.card .card-header h3,
.card .card-header h4,
.card .card-header h5,
.card .card-header h6 {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
}
.card .card-header h1 span,
.card .card-header h2 span,
.card .card-header h3 span,
.card .card-header h4 span,
.card .card-header h5 span,
.card .card-header h6 span {
  font-size: 11px;
  margin-left: 15px;
  color: var(--color-light);
}
.card .card-header .card-tab {
  margin: -10px 0;
}
.card .card-header .card-tab .btn {
  padding: 0 0.626rem;
  -webkit-box-shadow: 0 0 0 transparent !important;
  box-shadow: 0 0 0 transparent !important;
}
.card .card-header .card-tab .btn:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}
.card .card-header .card-tab .btn:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}
.card .card-header .card-tab .btn.btn-white.active.border {
  border-color: var(--color-primary) !important;
}
.card .card-header .card-tab .btn.btn-white.active.border-light {
  border-color: var(--color-primary) !important;
}
.card .card-header .card-tab .btn.btn-white.active:focus {
  color: var(--color-white) !important;
}
.card .card-header .card-tab .btn.btn-white.active:hover {
  background-color: var(--color-primary);
}
.card .card-header .card-tab .btn.btn-white:hover {
  background-color: var(--bg-lighter);
}
.card .card-header .card-tab .btn.btn-white.border {
  border-color: var(--border-light) !important;
}
.card .card-header .card-tab .btn.btn-white.border-light {
  border-color: var(--border-color) !important;
}
.card .card-header .card-tab .btn.active {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  color: var(--color-white);
  background-color: var(--color-primary);
}
.card .card-header .card-tab .btn.active:hover {
  background-color: var(--color-primary);
}
.card .card-header .card-extra {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 3px 0;
}
.card .card-header .card-extra .btn-link {
  font-size: 14px;
}
.card .card-header .dropdown {
  padding: 10px 0;
}
.card .card-header .dropdown i,
.card .card-header .dropdown span,
.card .card-header .dropdown svg {
  font-size: calc(16.2px + 0.15vw);
  color: var(--color-light);
  width: 18px;
  height: 18px;
}
.card .card-header .dropdown img {
  width: 18px;
}
.card .card-header .card-tab-links {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.card .card-header .card-tab-links a {
  display: block;
  font-weight: 500;
  color: var(--color-light);
  font-size: 13px;
  line-height: 1.6923076923;
  position: relative;
}
.card .card-header .card-tab-links li {
  margin: 15px 0;
}
.card .card-header .card-tab-links li a {
  padding: 4px 12px;
  background-color: transparent;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.card .card-header .card-tab-links li a.active {
  font-weight: 500;
  background-color: rgba(var(--color-primary-rgba), 0.1);
  color: var(--color-primary);
}
.card .card-header .card-tab + .dropdown,
.card .card-header .card-tab-links + .dropdown {
  margin-left: 13px;
}
.card .card-header .see-all {
  margin-top: 12px !important;
}
.card .card-body .dropdown i,
.card .card-body .dropdown svg {
  color: var(--color-lighten);
}
.card.card-default {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  border: 0;
}
.card.card-default .card-header {
  border: 1px solid var(--border-color);
}
.card .card-single p {
  font-size: 14px;
  margin-bottom: 8px;
}
.card.card-md .card-header {
  padding: 18px 25px;
}
.card.card-sm .card-header {
  padding: 12px 15px;
}
.card .card-header {
  padding: 23px 25px;
}
.card.card-bordered {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid var(--border-light);
}
.card.card-bordered .card-header {
  border: 0;
  border-bottom: 1px solid var(--border-light);
}
.card.bg-white .card-body,
.card.bg-white .card-header {
  background-color: var(--color-white);
}
.card.bg-normal .card-body,
.card.bg-normal .card-header {
  background-color: var(--bg-normal);
}
.card.cashflowChart4 .card-header {
  min-height: auto;
  margin-top: 10px;
}
.card.cashflowChart4 .card-header .card-tab-links li {
  margin: 5px 0;
}
.card .chartjs-tooltip .chartjs-tooltip-key {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.card .chartjs-tooltip .data-label {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}
.card .chartjs-tooltip .chart-data {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.card .chartjs-tooltip .chart-data:after {
  content: "k";
}
.card-grid-table tr:last-child td {
  border-bottom: 0;
}
.card-grid-table tr:last-child td:first-child {
  -webkit-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}
.card-grid-table tr:last-child td:last-child {
  -webkit-border-radius: 0 0 4px 0;
  border-radius: 0 0 4px 0;
}
.card-grid-table tr td {
  padding: 22px;
  text-align: center;
  border-top: 0;
  border-bottom: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
}
.card-grid-table tr td:first-child {
  border-left: 0;
}
.card-grid-table tr td p {
  font-size: 14px;
}
.card-overview-progress {
  min-height: 548px;
}
.card-overview-progress .card-header {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(var(--color-secondary)),
    to(var(--color-warning))
  );
  background: -webkit-linear-gradient(
    left,
    var(--color-secondary),
    var(--color-warning)
  );
  background: -o-linear-gradient(
    left,
    var(--color-secondary),
    var(--color-warning)
  );
  background: linear-gradient(
    to right,
    var(--color-secondary),
    var(--color-warning)
  );
  min-height: 215px;
  padding-top: 15px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.card-overview-progress .card-header h6 {
  color: var(--color-white);
}
.card-overview-progress .card-header .dropdown-toggle {
  font-size: 12px;
  font-weight: 500;
}
.card-overview-progress .card-header .dropdown-toggle:active,
.card-overview-progress .card-header .dropdown-toggle:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
}
.card-overview-progress .card-header .btn {
  color: var(--color-white);
  background: rgba(var(--color-white-rgba), 0.1);
}
.card-overview-progress .card-header .btn .la {
  display: inline-block;
  margin-left: 6px;
  margin-right: 0;
  font-size: 10px;
  color: var(--color-white);
}
.card-overview-progress .card-body {
  margin-top: -107px;
}
.card-overview-progress .card-progress {
  background: var(--color-white);
  -webkit-box-shadow: 0 10px 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 10px 30px rgba(var(--light-gray-rgba), 0.15);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 25px;
}
.card-overview-progress .card-progress:not(:last-child) {
  margin-bottom: 25px;
}
.card-overview-progress .card-progress__summery {
  margin-bottom: 28px;
}
.card-overview-progress .card-progress__summery span,
.card-overview-progress .card-progress__summery strong {
  display: block;
}
.card-overview-progress .card-progress__summery strong {
  font-size: calc(16.8px + 0.6vw);
  font-weight: 600;
}
.card-overview-progress .card-progress__summery span {
  font-size: 14px;
  color: var(--color-lighten);
}
.card-overview-progress .card-progress__summery div + div strong {
  text-align: right;
}
.card-overview-progress .card-progress__bar .progress {
  height: 6px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.card-overview-progress .card-progress__bar .progress-excerpt {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 10px;
}
.card-overview-progress .card-progress__bar .progress-excerpt p {
  margin: 0;
}
.card-overview-progress .card-progress__bar .progress-excerpt .progress-total {
  display: inline-block;
  font-weight: 500;
  color: var(--color-dark);
  font-size: 14px;
}
.card-overview-progress .card-progress__bar .progress-downword,
.card-overview-progress .card-progress__bar .progress-upword {
  font-size: 13px;
  color: var(--color-lighten);
  margin: 16px 0 0;
}
.card-overview-progress .card-progress__bar .progress-downword strong,
.card-overview-progress .card-progress__bar .progress-upword strong {
  font-weight: 500;
  font-size: 14px;
  margin-right: 6px;
}
.card-overview-progress .card-progress__bar .progress-upword strong {
  color: var(--color-success);
}
.card-overview-progress .card-progress__bar .progress-downword strong {
  color: var(--color-danger);
}
.top-menu .card-overview-progress {
  min-height: 596px;
}
.feature-cards {
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0;
}
.feature-cards figure {
  margin-bottom: 0;
}
.feature-cards__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.feature-cards__top span {
  font-size: 12px;
  font-weight: 500;
  color: var(--color-light);
}
.feature-cards img {
  width: 100%;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.feature-cards figcaption {
  padding: 25px;
}
.feature-cards figcaption h4 {
  margin: 0 0 10px;
}
.feature-cards figcaption p {
  line-height: 1.79;
  margin-bottom: 15px;
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 400;
}
.feature-cards figcaption button {
  background: rgba(var(--color-primary-rgba), 0.082);
  color: var(--color-primary);
  font-size: 15px;
  font-weight: 600;
  height: 48px;
}
.feature-cards figcaption button:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
}
.feature-cards figcaption .feedbackSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #adb4d2;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px 0;
}
.feature-cards figcaption .feedbackSection .author {
  color: var(--color-gray);
  margin-right: 10px;
}
.feature-cards figcaption .feedbackSection .author img {
  width: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
}
.feature-cards figcaption .feedbackSection .author span {
  font-size: 14px;
  font-weight: 500;
}
.feature-cards figcaption .feedbackSection .feedback span {
  font-size: 13px;
  color: #adb4d2;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards figcaption .feedbackSection .feedback span img,
.feature-cards figcaption .feedbackSection .feedback span svg {
  margin: 0 5px;
  color: #868eae;
  width: 15px;
}
.feature-card--1 figcaption {
  padding: 21px 25px 25px;
}
.feature-card--1 figcaption h5 {
  margin: 18px 0 15px;
}
.feature-card--1 figcaption p {
  margin-bottom: 17px;
}
.feature-card--1 figcaption .author,
.feature-card--1 figcaption .feedback {
  margin: 5px 0;
}
.feature-card--1 figcaption .feedback {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feature-card--1 figcaption .feedback .like {
  margin-right: 5px;
}
.feature-card--1 figcaption .feedbackSection .author img {
  width: 35px;
  height: 35px;
}
.feature-cards2 {
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
  min-height: 253px;
}
.feature-cards2 > img {
  height: 253px;
}
.feature-cards2 figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px;
  background: rgba(39, 43, 65, 0.314);
  color: var(--color-white);
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.feature-cards2 figcaption .feedbackSection {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-white);
}
.feature-cards2 figcaption .feedbackSection .author {
  color: var(--color-white);
}
.feature-cards2 figcaption .feedbackSection .author img {
  width: 35px;
  height: 35px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
}
.feature-cards2 figcaption .feedbackSection .author span {
  font-weight: 500;
  font-size: 13px;
}
.feature-cards2 figcaption h2 {
  color: var(--color-white);
  margin: 0 0 19px;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
}
.feature-cards2 figcaption p {
  line-height: 25px;
  font-size: 13px;
}
.feature-cards3 {
  background: var(--color-white);
  padding: 25px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0;
}
.feature-cards3 img,
.feature-cards3 svg {
  width: 60px;
}
.feature-cards3 figcaption h2 {
  margin: 18px 0 10px;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}
.feature-cards3 figcaption p {
  line-height: 1.79;
  color: var(--color-gray);
  font-size: 14px;
}
.feature-cards3 figcaption a {
  color: var(--color-primary);
  font-size: 15px;
  font-weight: 600;
}
.feature-cards3 figcaption a:hover {
  opacity: 0.7;
}
.feature-cards4 {
  background: var(--color-white);
  padding: 25px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  margin: 0;
  min-height: 324px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.feature-cards4 img,
.feature-cards4 svg {
  height: 107px;
  width: 100%;
}
.feature-cards4 figcaption h2 {
  margin: 30px 0 15px;
  font-size: calc(16.4px + 0.3vw);
  font-weight: 500;
}
.feature-cards4 figcaption p {
  line-height: 1.79;
  color: var(--color-gray);
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 0;
}
.card__more-action img,
.card__more-action svg {
  width: 24px;
  height: 24px;
  color: #adb4d2;
}
.feature-cards5 {
  background-color: var(--color-white);
}
.feature-cards5 .card__more-action {
  margin-top: -17px;
}
.feature-cards6 {
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 0;
}
.feature-cards6 .card-short .card-short__title {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 500;
  margin-bottom: 18px;
  padding: 25px 25px 0;
}
.feature-cards6 .card-short .card-short__title img {
  max-width: 50px;
  margin-right: 20px;
}
.feature-cards6 .card-short .card-short__content {
  padding: 0 25px;
}
.feature-cards6 .card-short .card-short__content p {
  color: var(--color-gray-x);
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
}
.feature-cards6 .card-short .card-short__bottom {
  border-top: 1px solid var(--border-color);
  padding: 20px 25px;
}
.feature-cards6 .card-short .card-short__bottom.installed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards6 .card-short .card-short__bottom.installed .content-installed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.feature-cards6 .card-short .card-short-actions .content-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards6 .card-short .card-short-actions .content-action > button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 42px;
  border-radius: 42px;
  margin-right: 16px;
  min-width: 24px;
  height: 34px;
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
}
.feature-cards6 .card-short .card-short-actions .content-action > button span {
  margin: 0;
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
}
.feature-cards6 .card-short .card-short-actions .content-action .more img,
.feature-cards6 .card-short .card-short-actions .content-action .more svg {
  color: #adb4d2;
  width: 24px;
  height: 24px;
}
.feature-cards6
  .card-short
  .card-short-actions
  .content-not-installed.content-action {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.feature-cards6
  .card-short
  .card-short-actions
  .content-not-installed.content-action
  > button {
  -webkit-box-shadow: rgba(95, 99, 242, 0.125) 0 10px 15px;
  box-shadow: rgba(95, 99, 242, 0.125) 0 10px 15px;
}
.feature-cards6 .card-short .card-short-checkbox {
  margin-right: 10px;
}
.feature-cards6 .card-short .card-short-checkbox .checkbox-text {
  color: var(--color-success);
  text-transform: capitalize;
}
.feature-cards6
  .card-short
  .card-short-checkbox
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after {
  border: 1px solid var(--color-success);
  background-color: var(--color-success);
}
.feature-cards7 {
  margin-bottom: 0;
}
.feature-cards7 .banner-card {
  padding: 20px 25px 25px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.feature-cards7 .banner-card .banner-card__action {
  margin-top: -23px;
}
.feature-cards7 .banner-card.banner-card-border {
  border: 1px solid var(--border-color);
}
.feature-cards7
  .banner-card.banner-card-border
  .banner-card__top
  .banner-card__title {
  color: var(--color-dark);
}
.feature-cards7 .banner-card.banner-card-border .banner-card__body p {
  color: var(--color-gray);
}
.feature-cards7
  .banner-card.banner-card-border
  .banner-card__bottom
  .card-author
  .author-name {
  color: var(--color-gray);
}
.feature-cards7
  .banner-card.banner-card-border
  .banner-card__bottom
  .card-meta
  li
  span {
  color: #adb4d2;
}
.feature-cards7 .banner-card .banner-card__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards7 .banner-card .banner-card__top .banner-card__title {
  font-size: 16px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-white);
  margin-bottom: 17px;
}
.feature-cards7 .banner-card .banner-card__top .banner-card__title img,
.feature-cards7 .banner-card .banner-card__top .banner-card__title svg {
  margin-right: 10px;
  color: var(--color-lighten);
  width: 16px;
  height: 16px;
}
.feature-cards7 .banner-card .banner-card__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px 0;
}
.feature-cards7 .banner-card .banner-card__bottom .card-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0;
}
.feature-cards7 .banner-card .banner-card__bottom .card-author img {
  max-width: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.feature-cards7 .banner-card .banner-card__bottom .card-meta {
  margin: 5px 0;
}
.feature-cards7 .banner-card .banner-card__bottom .card-author .author-name {
  margin-left: 15px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.565);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.feature-cards7 .banner-card .banner-card__bottom .card-meta ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards7 .banner-card .banner-card__bottom .card-meta ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.feature-cards7 .banner-card .banner-card__bottom .card-meta ul li img,
.feature-cards7 .banner-card .banner-card__bottom .card-meta ul li svg {
  color: #adb4d2;
  margin-right: 6px;
  width: 16px;
}
.feature-cards7
  .banner-card
  .banner-card__bottom
  .card-meta
  ul
  li:not(:last-child) {
  margin-right: 10px;
}
.feature-cards7 .banner-card .banner-card__bottom .card-meta ul li span {
  font-size: 13px;
  color: var(--color-white);
}
.feature-cards7 .banner-card .banner-card__body p {
  margin-bottom: 20px;
  line-height: 1.786;
  color: rgba(255, 255, 255, 0.565);
  font-size: 14px;
  line-height: 1.7142857143;
  font-weight: 400;
}
.feature-cards7 .banner-card-image {
  background-image: url(img/feature-card-image7.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.slick-slider__single {
  float: left;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 150px;
  background-color: var(--color-gray-x);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.slick-slider__single p {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 500;
  color: var(--color-white);
}
.slick-slider .slick-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1;
}
.slick-slider .slick-dots li {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0 3px;
}
.slick-slider .slick-dots li.slick-active button {
  width: 25px;
  background-color: var(--color-white);
}
.slick-slider .slick-dots button {
  border: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  width: 15px;
  height: 3px;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
  padding: 0;
  text-indent: -9999px;
  background: rgba(var(--color-white-rgba), 0.7);
}
.carousel-position .carouse-position-content {
  margin-top: 16px;
}
.slick-slider.slick-dots-top .slick-dots {
  bottom: auto;
  top: 0;
  margin: 25px 0 0;
}
.slick-slider.slick-dots-bottom .slick-dots {
  bottom: 0;
  margin: 0 0 25px;
}
.slick-slider.slick-dots-left .slick-dots,
.slick-slider.slick-dots-right .slick-dots {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.slick-slider.slick-dots-left .slick-dots button,
.slick-slider.slick-dots-right .slick-dots button {
  width: 3px;
  height: 15px;
}
.slick-slider.slick-dots-left .slick-dots li,
.slick-slider.slick-dots-right .slick-dots li {
  display: block;
  padding: 3px 0;
}
.slick-slider.slick-dots-left .slick-dots li.slick-active button,
.slick-slider.slick-dots-right .slick-dots li.slick-active button {
  width: 3px;
  height: 25px;
}
.slick-slider.slick-dots-left .slick-dots {
  left: 0;
  margin: 0 0 0 25px;
}
.slick-slider.slick-dots-right .slick-dots {
  left: auto;
  right: 0;
  margin: 0 25px 0 0;
}
.custom-checkbox input {
  display: none;
}
.custom-checkbox label {
  display: inline-block !important;
  font-size: 14px;
}
.custom-checkbox input[type="checkbox"] + label {
  min-width: 18px;
  min-height: 18px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 400;
  color: var(--color-light);
}
.custom-checkbox input[type="checkbox"] + label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  content: "";
  background-color: var(--color-white);
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border-light);
}
.custom-checkbox input[type="checkbox"] + label:before {
  position: absolute;
  font-size: 10px;
  left: 4px;
  top: 3px;
  font-weight: 900;
  font-family: "font awesome 5 free";
  content: "\f00c";
  display: block;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 2;
  color: var(--color-primary);
}
.custom-checkbox input[type="checkbox"]:checked + label:after {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.custom-checkbox input[type="checkbox"]:checked + label:before {
  opacity: 1;
  color: var(--color-white);
}
.custom-checkbox.custom-checkbox--success
  input[type="checkbox"]:checked
  + label:after {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
}
.custom-checkbox input:indeterminate ~ label::after {
  background: var(--color-primary);
  font-weight: 900;
  font-family: "font awesome 5 free";
  content: "\f068";
  color: var(--color-white);
  font-size: 10px;
  line-height: 2;
  border: 1px solid var(--color-primary);
}
.custom-checkbox.custom-checkbox--success input:indeterminate ~ label::after {
  background: var(--color-success);
  border: 1px solid var(--color-success);
}
.custom-checkbox.disabled {
  opacity: 0.4;
}
.custom-checkbox.disabled label {
  cursor: not-allowed;
}
.custom-checkbox.disabled input[type="checkbox"]:checked + label:after {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
}
.custom-checkbox.disabled input[type="checkbox"]:checked + label:before {
  color: var(--color-light);
  font-size: 8px;
  left: 5px;
  top: 5px;
}
.custom-checkbox.checkbox-theme-2 input[type="checkbox"] + label:after {
  background-color: var(--color-white);
  border: 1px solid var(--border-light);
}
.custom-checkbox.checkbox-theme-2 input[type="checkbox"] + label::before {
  top: 4px;
  content: "";
  width: 10px;
  height: 10px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: var(--color-primary);
}
.checkbox-controll__actions {
  margin-top: 35px;
}
.checkbox-controll__actions .btn-checkToggle {
  min-width: 95px;
  margin-right: 10px;
}
.checkbox-controll__actions .btn-activeToggle {
  min-width: 70px;
}
.checkbox-group-header {
  border-bottom: 1px solid var(--border-light);
  padding-bottom: 5px;
  margin-bottom: 30px;
}
.checkbox-group__single:not(:last-child) {
  margin-right: 10px;
}
.dm-collapse {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.dm-collapse .dm-collapse-item {
  border-bottom: 1px solid var(--border-color);
}
.dm-collapse .dm-collapse-item:not(:last-child) {
  -webkit-border-bottom-right-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dm-collapse .dm-collapse-item:not(:last-child) .dm-collapse-item__header {
  -webkit-border-radius: 5px 0 0 5px;
  border-radius: 5px 0 0 5px;
}
.dm-collapse .dm-collapse-item:not(:first-child) {
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
}
.dm-collapse .dm-collapse-item:not(:first-child) .dm-collapse-item__header {
  -webkit-border-radius: 0 5px 5px 0;
  border-radius: 0 5px 5px 0;
}
.dm-collapse .dm-collapse-item:last-child {
  border-bottom: none;
}
.dm-collapse .dm-collapse-item__header {
  margin-bottom: -1px;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--bg-normal);
}
.dm-collapse .dm-collapse-item__header a[aria-expanded="true"] i:before,
.dm-collapse .dm-collapse-item__header a[aria-expanded="true"] span:before {
  content: "\f068";
}
.dm-collapse .dm-collapse-item__header .item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 20px;
}
.dm-collapse .dm-collapse-item__header .item-link i,
.dm-collapse .dm-collapse-item__header .item-link img,
.dm-collapse .dm-collapse-item__header .item-link span,
.dm-collapse .dm-collapse-item__header .item-link svg {
  font-size: 12px;
  margin-right: 18px;
  color: var(--color-light);
}
.dm-collapse .dm-collapse-item__header h1,
.dm-collapse .dm-collapse-item__header h2,
.dm-collapse .dm-collapse-item__header h3,
.dm-collapse .dm-collapse-item__header h4,
.dm-collapse .dm-collapse-item__header h5,
.dm-collapse .dm-collapse-item__header h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
}
.dm-collapse .dm-collapse-item__body .collapse-body-text {
  padding: 15px 20px;
}
.dm-collapse .dm-collapse-item__body .collapse-body-text p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--color-light);
}
.dm-collapse .dm-collapse-item .callapse-nested-block {
  padding: 20px;
}
.dm-collapse .dm-collapse-item .callapse-nested-block .dm-collapse-item {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.dm-collapse.dm-collapse-borderless {
  border: none;
}
.dm-collapse.dm-collapse-borderless .dm-collapse-item {
  border: none;
}
.dm-collapse.dm-collapse-borderless .dm-collapse-item__header {
  border: none;
}
.dm-collapse.dm-collapse-custom {
  border: none;
}
.dm-collapse.dm-collapse-custom .dm-collapse-item {
  -webkit-border-radius: 0;
  border-radius: 0;
  border: none;
}
.dm-collapse.dm-collapse-custom .dm-collapse-item:not(:last-child) {
  margin-bottom: 20px;
}
.dm-collapse.dm-collapse-custom .dm-collapse-item__header {
  border-bottom: 0;
  -webkit-border-radius: 3px !important;
  border-radius: 3px !important;
  border: none;
}
.dm-comment-box__author {
  margin-right: 15px;
}
.dm-comment-box__author figure {
  margin-bottom: 0;
}
.dm-comment-box__author figure img {
  max-width: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.dm-comment-box__content .cci__author-info {
  display: block;
  font-size: 12px;
  color: var(--color-dark);
}
.dm-comment-box__content .cci__comment-text {
  font-size: 14px;
  margin: 4px 0 16px;
  color: var(--color-light);
}
.dm-comment-box__content .cci__comment-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-comment-box__content .cci__comment-actions a {
  font-size: 15px;
  color: var(--color-lighten);
}
.dm-comment-box__content .cci__comment-actions a.btn-reply {
  font-size: 12px;
}
.dm-comment-box__content .cci__comment-actions a .line-count {
  display: inline-block;
  margin: 0 10px 0 5px;
  font-size: 12px;
}
.dm-comment-box__content .cci__comment-actions a svg {
  width: 12px;
}
.comment-list__title {
  padding-bottom: 10px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-light);
}
.comment-list__title h1,
.comment-list__title h2,
.comment-list__title h3,
.comment-list__title h4,
.comment-list__title h5,
.comment-list__title h6 {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray);
}
.comment-list__ul .comment-list__ul {
  padding-left: 45px;
}
.reply-editor__author {
  margin-right: 15px;
}
.reply-editor__author img {
  max-width: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.reply-editor__form textarea {
  font-size: 14px;
  min-height: 170px;
  resize: none;
}
.notification-modal-box__btn {
  font-size: 14px;
  padding: 0 15px;
  line-height: 34px;
  font-weight: 400;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.notification-wrapper {
  position: fixed;
  z-index: 9999;
}
.notification-wrapper.top-left {
  top: 20px;
  left: 20px;
}
.notification-wrapper.top-right {
  top: 20px;
  right: 20px;
}
.notification-wrapper.bottom-left {
  bottom: 20px;
  left: 20px;
}
.notification-wrapper.bottom-right {
  bottom: 20px;
  right: 20px;
}
.dm-notification-box {
  position: relative;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 10px;
  -webkit-box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
  max-width: 400px;
  padding: 15px;
  background-color: var(--color-white);
}
.dm-notification-box:not(:last-child) {
  margin-bottom: 20px;
}
.dm-notification-box.notification-info .dm-notification-box__icon i,
.dm-notification-box.notification-info .dm-notification-box__icon img,
.dm-notification-box.notification-info .dm-notification-box__icon svg {
  color: var(--color-info);
}
.dm-notification-box.notification-success .dm-notification-box__icon i,
.dm-notification-box.notification-success .dm-notification-box__icon svg {
  color: var(--color-success);
}
.dm-notification-box.notification-danger .dm-notification-box__icon i,
.dm-notification-box.notification-danger .dm-notification-box__icon svg {
  color: var(--color-danger);
}
.dm-notification-box.notification-warning .dm-notification-box__icon i,
.dm-notification-box.notification-warning .dm-notification-box__icon svg {
  color: var(--color-warning);
}
.dm-notification-box__icon {
  margin-right: 15px;
}
.dm-notification-box__icon i {
  font-size: 24px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.dm-notification-box__text h1,
.dm-notification-box__text h2,
.dm-notification-box__text h3,
.dm-notification-box__text h4,
.dm-notification-box__text h5,
.dm-notification-box__text h6 {
  margin-bottom: 15px;
}
.dm-notification-box__close {
  position: absolute;
  top: 10px;
  right: 15px;
}
.dm-notification-box__close i,
.dm-notification-box__close img,
.dm-notification-box__close svg {
  max-width: 20px;
  color: var(--color-dark);
  font-size: calc(16.2px + 0.15vw);
}
.notification-placement-wrap {
  margin: -10px;
}
.notification-placement-wrap .dm-select {
  min-width: 140px;
  margin: 10px;
}
.dm-empty__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 15px;
  color: var(--color-dark);
}
.dm-empty__text p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  margin-bottom: 0;
}
.dm-empty__text p.disabled {
  color: var(--color-lighten);
}
.dm-empty__image img {
  max-width: 130px;
}
.dm-empty__action {
  margin-top: 17px;
}
.social-overview {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -22px 0;
}
.social-overview li {
  width: 33.33%;
  text-align: center;
  padding: 22px 0;
}
.social-overview li .social-box {
  margin-bottom: 14px;
}
.social-overview li .social-box.gradientInstragram {
  background: #9c27b0;
  background: -webkit-linear-gradient(
    top,
    #9c27b0 0,
    #9c27b0 35%,
    #f44336 67%,
    #ffc107 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#9c27b0),
    color-stop(35%, #9c27b0),
    color-stop(67%, #f44336),
    to(#ffc107)
  );
  background: -o-linear-gradient(
    top,
    #9c27b0 0,
    #9c27b0 35%,
    #f44336 67%,
    #ffc107 100%
  );
  background: linear-gradient(
    to bottom,
    #9c27b0 0,
    #9c27b0 35%,
    #f44336 67%,
    #ffc107 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#9c27b0",endColorstr="#ffc107",GradientType=0 );
}
.social-overview li h1,
.social-overview li h2,
.social-overview li h3,
.social-overview li h4,
.social-overview li h5,
.social-overview li h6 {
  margin-bottom: 5px;
}
.social-overview li p {
  font-size: 14px;
  color: var(--color-lighten);
  margin-bottom: 0;
}
.card-overview .br-1 {
  border-right: 1px solid var(--border-color);
}
.card-overview__left .col-xl-6 {
  padding-right: 12.5px;
}
.card-overview__left .col-xl-6.br-1 {
  padding-right: 25px;
  padding-left: 12.5px;
}
.card-overview__right {
  margin-right: 3px;
}
.card-overview__right .col-xl-6 {
  padding-left: 25px;
}
.card-overview__right .col-xl-6.co-last {
  padding-right: 12.5px;
  padding-left: 12.5px;
}
.overview-content {
  background-color: var(--color-white);
}
.overview-single {
  padding: 30px 0 25px;
}
.overview-single .overview-content p {
  font-size: 14px;
  margin-top: 2px;
  color: var(--color-lighten);
}
.overview-single .overview-content small {
  font-size: 13px;
  margin-left: 6px;
  color: var(--color-lighten);
}
.overview-single .overview-content img,
.overview-single .overview-content svg {
  width: 15px;
  margin-right: 6px;
}
.overview-single .overview-content strong {
  font-weight: 500;
}
.ap-po-timeChart .parentContainer {
  position: relative;
  bottom: -6px;
}
.card-chart-bar {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-bottom: 25px;
}
.legend-static li {
  font-size: 13px;
}
.legend-static li:not(:last-child) {
  margin-right: 15px;
}
.legend-static li span {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 7px;
}
.legend-static li.custom-label {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card-bar-top p {
  margin-bottom: 6px;
  color: var(--color-lighten);
}
.card-bar-top .card-bar-info sub {
  font-size: 14px;
  font-weight: 600;
  margin: 0 0 0 6px;
}
.card-bar-top .card-bar-info sub img,
.card-bar-top .card-bar-info sub svg {
  width: 14px;
}
.perfomence-chart .legend-static {
  margin-top: 20px;
}
.performance-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.performance-stats div {
  padding: 20px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.performance-stats div span {
  color: var(--color-lighten);
  font-size: 14px;
  margin-bottom: 13px;
}
.performance-stats div strong {
  font-size: calc(16.8px + 0.6vw);
  font-weight: 600;
  color: var(--color-dark);
}
.performance-stats div strong sub {
  bottom: 0;
  font-size: 14px;
  font-weight: 500;
}
.performance-stats div:hover {
  -webkit-box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0.15);
  z-index: 1;
}
.performance-stats div:hover span {
  color: var(--color-primary);
}
.performance-stats span,
.performance-stats strong {
  display: block;
}
.performance-stats a.active .performance-stats__down,
.performance-stats a.active .performance-stats__up {
  background: var(--bg-lighter);
}
.performance-stats a.active .performance-stats__down:hover,
.performance-stats a.active .performance-stats__up:hover {
  -webkit-box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0) !important;
  box-shadow: 0 15px 30px rgba(var(--light-gray-rgba), 0) !important;
}
.performance-stats__up {
  background: var(--color-white);
}
.performance-stats__up strong sub {
  color: var(--color-success);
}
.performance-stats__down strong sub {
  color: var(--color-danger);
}
.demo3 .device-pieChart-wrap .pie-chart-legend {
  top: 53%;
}
.device-pieChart-wrap .pie-chart-legend {
  position: absolute;
  top: 51%;
  left: 50%;
  text-align: center;
  width: 200px;
  margin-bottom: 0;
  display: inline-block;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.device-pieChart-wrap .pie-chart-legend p {
  margin-bottom: 0;
  font-size: 15px;
  color: var(--color-gray);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  line-height: 28px;
}
.device-pieChart-wrap .pie-chart-legend span {
  font-size: calc(17.4px + 1.05vw);
  display: block;
  font-weight: 600;
  color: var(--color-dark);
}
.linechart-overview-wrap .border-line-chart {
  max-width: 220px;
}
.top-menu .linechart-overview-wrap .mb-3 {
  margin-bottom: 28px !important;
}
.session-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 18px 0;
}
.session-wrap.session-wrap--2 {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 15px;
  min-height: 76px;
  background: #f8f9f8;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 0;
}
.session-wrap.session-wrap--2 .session-single strong {
  font-weight: 600;
}
.session-wrap.session-wrap--2 .session-single .chart-label {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray);
  margin-bottom: 0;
}
.session-wrap.session-wrap--top {
  margin: 18px 0 0;
}
.session-wrap.session-wrap--top .session-single .chart-label {
  margin-bottom: 2px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3333333333;
  color: var(--color-dark);
}
.session-wrap.session-wrap--top--2 {
  margin: 25px 0 10px;
}
.session-wrap.session-wrap--top--4 {
  margin: 8px 0 -4px;
}
.session-wrap.session-wrap--top--5 {
  margin: 18px 0 0;
}
.session-wrap .session-single {
  text-align: center;
  padding: 5px 15px;
}
.session-wrap .session-single .chart-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-dark);
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
}
.session-wrap .session-single .chart-label.new {
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 400;
}
.session-wrap .session-single strong {
  font-weight: 500;
  font-size: calc(16.2px + 0.15vw);
  color: var(--color-dark);
}
.session-wrap .session-single sub {
  bottom: 0;
  font-size: 13px;
  color: var(--color-lighten);
}
.label-dot {
  width: 8px;
  height: 8px;
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 9px;
}
.label-dot.dot-primary {
  background-color: var(--color-primary);
}
.label-dot.dot-secondary {
  background-color: var(--color-secondary);
}
.label-dot.dot-success {
  background-color: var(--color-success);
}
.label-dot.dot-info {
  background-color: var(--color-info);
}
.label-dot.dot-warning {
  background-color: var(--color-warning);
}
.revenue-labels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -25px 20px;
}
.revenue-labels div {
  padding: 25px;
}
.revenue-labels strong {
  font-size: calc(16.8px + 0.6vw);
  display: block;
  font-weight: 600;
  color: var(--color-dark);
}
.revenue-labels span {
  font-size: 14px;
  color: var(--color-lighten);
  display: block;
  position: relative;
  padding-left: 17px;
}
.revenue-labels span:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #c6d0dc;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.revenue-chart-legend-list {
  margin-top: 25px;
}
.revenue-chart-legend:not(:last-child) {
  margin-bottom: 12px;
}
.revenue-chart-legend__label {
  min-width: 80px;
}
.revenue-chart-legend__label span {
  font-size: 14px;
  color: var(--color-gray);
}
.revenue-chart-legend__data span {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 500;
  color: var(--color-dark);
}
.revenue-chart-legend__percentage span {
  font-size: 13px;
  color: var(--color-lighten);
}
.revenue-pieChart-wrap {
  margin-top: 5px;
}
.crm .revenue-labels strong {
  padding-bottom: 8px;
}
.crm .cashflow-display__amount {
  padding-top: 8px;
}
.crm .revenue-labels .current span:before {
  background-color: #c6d0dc;
}
.crm .dropdown img,
.crm .dropdown svg {
  width: 18px;
}
.crm .selling-table-wrap .table--default tr th:first-child {
  padding-left: 15px;
}
.crm .selling-table-wrap .table--default tr th:last-child {
  padding-right: 15px;
}
.crm .selling-table-wrap .table--default tr td:first-child {
  padding-left: 0;
}
.crm .selling-table-wrap .table--default tr td:last-child {
  padding-right: 15px;
}
.crm .selling-table-wrap table thead tr {
  background: var(--bg-normal);
}
.t-thead-bg .selling-table-wrap .table--default tr th:first-child {
  padding-left: 15px;
}
.t-thead-bg .selling-table-wrap .table--default tr th:last-child {
  padding-right: 15px;
}
.t-thead-bg .selling-table-wrap .table--default tr td:first-child {
  padding-left: 0;
}
.t-thead-bg .selling-table-wrap .table--default tr td:last-child {
  padding-right: 15px;
  padding-left: 15px;
}
.t-thead-bg .selling-table-wrap table thead tr {
  background: var(--bg-normal);
}
.revenue-chart-box-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 20px;
}
.overview-content3 {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
}
.overview-content3 .revenue-chart-box__Icon img,
.overview-content3 .revenue-chart-box__Icon svg {
  width: 32px;
}
.overview-content3 .revenue-chart-box__Icon i {
  font-size: calc(17.4px + 1.05vw);
}
.revenue-chart-box__Icon {
  width: 60px;
  height: 60px;
  min-width: 60px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-right: 8px;
}
.revenue-chart-box__Icon img,
.revenue-chart-box__Icon svg {
  width: 25px;
}
.revenue-chart-box__Icon i {
  font-size: calc(17.4px + 1.05vw);
}
.revenue-chart-box__Icon.order-bg-opacity-primary i,
.revenue-chart-box__Icon.order-bg-opacity-primary svg {
  color: var(--color-primary);
}
.revenue-chart-box__Icon.order-bg-opacity-secondary i,
.revenue-chart-box__Icon.order-bg-opacity-secondary svg {
  color: var(--color-secondary);
}
.revenue-chart-box__Icon.order-bg-opacity-success i,
.revenue-chart-box__Icon.order-bg-opacity-success svg {
  color: var(--color-success);
}
.revenue-chart-box__Icon.order-bg-opacity-warning i,
.revenue-chart-box__Icon.order-bg-opacity-warning svg {
  color: var(--color-warning);
}
.revenue-chart-box__data {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 500;
  color: var(--color-dark);
}
.revenue-chart-box__label {
  font-size: 14px;
  line-height: 1.7142857143;
  font-weight: 400;
  color: var(--color-gray-x);
  margin-top: 8px;
}
.cashflow-chart .legend-static {
  margin-top: 12px;
}
.forcast-cardbox {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  background-color: var(--color-white);
}
.forcast-cardbox .forcast-title {
  font-weight: 500;
  padding: 25px 25px 30px;
}
.forcast-cardbox .forcast-details {
  padding: 0 25px;
  margin-bottom: 24px;
}
.forcast-cardbox .forcast-details .forcast-value {
  font-size: calc(17.4px + 1.05vw);
  margin-bottom: 6px;
}
.forcast-cardbox .forcast-details .forcast-status .percentage {
  font-size: 14px;
  font-weight: 500;
}
.forcast-cardbox .forcast-details .forcast-status .percentage img,
.forcast-cardbox .forcast-details .forcast-status .percentage svg {
  width: 14px;
}
.forcast-cardbox .forcast-details .forcast-status .forcast-text {
  font-size: 13px;
  margin-left: 4px;
  color: var(--color-light);
}
.ratio-box .ratio-title {
  font-weight: 500;
  margin-bottom: 30px;
}
.ratio-box .ratio-info {
  margin-bottom: 12px;
}
.ratio-box .ratio-info .ratio-point {
  font-size: calc(18px + 1.5vw);
}
.ratio-box .ratio-info .ratio-percentage {
  font-size: 14px;
  font-weight: 500;
}
.ratio-box .progress {
  height: 6px;
}
.ratio-box .progress-text {
  display: inline-block;
  font-size: 14px;
  margin-top: 12px;
}
.ratio-box .progress-text .dark {
  font-weight: 500;
}
.ratio-box .progress-text .progress-target {
  color: var(--color-light);
  font-size: 13px;
  margin-left: 4px;
}
.top-menu .ratio-box {
  min-height: 245px;
}
.inEx-wrap .inEx-box {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
  padding: 25px 0;
  border-right: 1px solid var(--border-color);
}
.inEx-wrap .inEx-chart {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
  padding: 25px;
}
.inEx-wrap .inEx-chart .legend-static {
  margin-top: 12px;
}
.inEx-wrap .inEx-chart .legend-static li:not(:last-child) {
  margin-right: 22px;
}
.inEx__single:not(:last-child) {
  margin-bottom: 30px;
}
.inEx__single .inEx-subtitle {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 8px;
  color: var(--color-light);
}
.inEx__info .inEx__percentage {
  font-size: 14px;
  font-weight: 500;
  margin: 0 8px -2px 10px;
}
.inEx__info .inEx__percentage img,
.inEx__info .inEx__percentage svg {
  width: 14px;
  margin-right: 4px;
}
.inEx__info .inEx-tail {
  font-size: 13px;
  color: var(--color-light);
}
.cashflow-display {
  margin: -20px -20px 10px;
}
.cashflow-display__single {
  margin: 20px;
}
.cashflow-display__title {
  font-size: 14px;
  color: var(--color-light);
}
.cashflow-display__amount {
  margin-top: 6px;
}
.cashflow-display2 {
  padding: 17px 0 5px 6px;
}
.regions-svg {
  width: 100%;
  height: 100%;
  min-height: 160px;
}
.regions-svg .jvectormap-container {
  width: 100%;
  height: 100%;
  text-align: center;
  min-height: 160px;
}
.crm .selling-table-wrap .table--default tbody tr:last-child {
  border-bottom: none;
}
.crm .table-selling-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.crm .table-selling-content img {
  width: 36px;
  height: 36px;
  margin-right: 12px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.crm .table-selling-content .title {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 600;
  color: var(--color-dark);
}
.crm .table-selling-content span {
  font-size: 13px;
  line-height: 1.5384615385;
  font-weight: 400;
  color: #9297af;
}
.deals-table-wrap {
  padding-bottom: 9px;
}
.deals-table-wrap .table--default td {
  padding: 8px 25px;
  font-size: 14px;
  font-weight: 600;
  color: var(--color-dark);
  border-top: none;
  white-space: nowrap;
}
.deals-table-wrap .table--default tbody tr:first-child td {
  padding-top: 23px;
}
.deals-table-wrap .table--default tbody tr:last-child td {
  padding-bottom: 17px;
}
.sales .revenue-labels div {
  padding: 17px 0 0 25px;
}
.top-circle {
  position: absolute;
  width: 15px;
  height: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  left: 0;
  bottom: 87px;
  overflow: hidden;
  background-color: var(--color-success);
  top: 88%;
  left: 12%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.selling-badge {
  height: 24px;
  padding: 0 8px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 1.6666666667;
  font-weight: 500;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-transform: capitalize;
}
.label-detailed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: -16px -12px -5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 11px;
}
.label-detailed--two {
  margin: 0 -12px -5px;
}
.label-detailed--two .label-detailed__total {
  font-size: calc(16.2px + 0.15vw);
}
.label-detailed__single {
  padding: 5px 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.label-detailed__single span,
.label-detailed__single strong {
  padding: 0 5px;
}
.label-detailed__single span.label-detailed__status,
.label-detailed__single strong.label-detailed__status {
  padding-left: 0;
}
.label-detailed__type {
  font-size: 14px;
  font-weight: 400;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-gray);
}
.label-detailed__type::before {
  content: "";
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #eee;
  display: inline-block;
  margin-right: 5px;
}
.label-detailed__type--primary::before {
  background: var(--color-primary);
}
.label-detailed__type--info::before {
  background: var(--color-info);
}
.label-detailed__total {
  font-size: 22px;
  font-weight: 600;
  color: var(--color-dark);
}
.label-detailed__status {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: normal;
}
.label-detailed__status i {
  font-size: 11px;
}
.label-detailed__status img,
.label-detailed__status svg {
  width: 11px;
  height: 11px;
  margin-left: 5px;
}
.label-detailed__status img + strong,
.label-detailed__status svg + strong {
  margin-left: 5px;
}
.label-detailed__status strong {
  padding: 0;
  font-size: 14px;
  font-weight: 500;
}
.revenueSourceChart {
  padding: 25px 25px 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.revenueSourceChart .chart-content__details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.revenueSourceChart .chart-content__single {
  text-align: center;
}
.revenueSourceChart .chart-content__single span {
  display: block;
}
.revenueSourceChart .chart-content__single span.icon {
  width: 80px;
  height: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 22px 22px 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.revenueSourceChart .chart-content__single span.icon svg {
  width: 30px;
}
.revenueSourceChart .chart-content__single span.icon i,
.revenueSourceChart .chart-content__single span.icon span {
  font-size: 30px;
}
.revenueSourceChart .chart-content__single span.icon.color-facebook {
  background: rgba(var(--color-primary-rgba), 0.2);
  color: var(--color-primary);
}
.revenueSourceChart .chart-content__single span.icon.color-secondary {
  background: rgba(var(--color-secondary-rgba), 0.2);
  color: var(--color-secondary);
}
.revenueSourceChart .chart-content__single span.icon.color-twitter {
  background: rgba(var(--color-twitter-rgba), 0.2);
  color: var(--color-twitter);
}
.revenueSourceChart .chart-content__single span.label {
  font-weight: 500;
  font-size: 15px;
  margin-top: 7px;
  color: var(--color-dark);
  text-transform: capitalize;
}
.revenueSourceChart .chart-content__single span.data {
  font-weight: 500;
  font-size: 14px;
  margin-top: 2px;
  color: var(--color-gray);
}
.performance-o {
  height: 100%;
}
.performance-o .card-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.performance-chart-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.performance-chart {
  width: 285px;
  height: 285px;
  margin: -25px;
}
.performance-chart-labels {
  margin-left: 38px;
}
.performance-chart-labels ul li {
  font-size: 15px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--color-gray);
}
.performance-chart-labels ul li:not(:last-child) {
  margin-bottom: 12px;
}
.performance-chart-labels ul li::before {
  content: "";
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: #ccc;
  margin-right: 7px;
}
.performance-chart-labels ul li.label-target {
  color: var(--color-gray);
}
.performance-chart-labels ul li.label-target::before {
  background: var(--color-primary);
}
.performance-chart-labels ul li.label-completed::before {
  background: var(--color-info);
}
.performance-chart-labels ul li.label-in-progress::before {
  background: var(--color-warning);
}
.storage .apexcharts-datalabel-value {
  font-family: Jost, sans-serif !important;
}
.profile-sider .card {
  max-width: 570px;
  margin: 0 auto 1.5625rem;
}
.profile-sider .card .card-header {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.profile-sider .profile-overview {
  margin: -8px -2px;
}
.ap-nameAddress__title {
  line-height: 1.25;
  font-weight: 500;
}
.ap-nameAddress__subTitle {
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-light);
  padding-top: 2px;
}
.ap-nameAddress__subTitle img,
.ap-nameAddress__subTitle svg {
  width: 13px;
  height: 15px;
  margin-right: 8px;
}
.ap-button button {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-height: 40px;
  min-height: 40px;
}
.ap-button button img,
.ap-button button svg {
  width: 16px;
  margin-right: 8px;
}
.po-details {
  margin: 8px 2px;
}
.po-details__title {
  padding-bottom: 4px;
}
.po-details__sTitle {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.user-bio__content {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.user-bio .card-body {
  padding-top: 18px;
  padding-bottom: 21px;
}
.profile-header-title {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 400;
  color: var(--color-light);
  text-transform: uppercase;
}
.user-content-info__item {
  color: var(--color-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
}
.user-content-info__item img,
.user-content-info__item svg {
  width: 16px;
  height: 16px;
  margin-right: 11px;
  color: var(--color-light);
}
.user-skils .card-body {
  padding-top: 22px;
}
.user-skils-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -6px -6px 0;
}
.user-skils-parent__item {
  margin: 0 6px 6px 0;
}
.user-skils-parent__item a {
  padding: 5px 10px;
  border: 1px solid var(--border-color);
  font-size: 11px;
  line-height: 1.2727272727;
  font-weight: 500;
  color: var(--color-gray);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-transform: uppercase;
}
.db-social-parent {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -10px;
}
.db-social-parent__item {
  margin: 5px 10px;
}
.db-social-parent__item a {
  -webkit-border-radius: 50rem;
  border-radius: 50rem;
  -webkit-box-shadow: 0 5px 15px var(--shadow3);
  box-shadow: 0 5px 15px var(--shadow3);
  padding: 11px;
}
.ap-tab-header {
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.ap-tab-header__img img {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  background: rgba(var(--color-primary-rgba), 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -o-object-fit: cover;
  object-fit: cover;
  min-height: 200px;
}
.ap-tab-main .nav-item:not(:last-child) {
  margin-right: 30px;
}
.ap-tab-main .nav-link {
  position: relative;
  padding: 0;
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 400;
  padding: 19px 0;
  cursor: pointer;
  color: var(--color-light);
}
.ap-tab-main .nav-link:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 1.5px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  content: "";
  opacity: 0;
  visibility: hidden;
  background-color: var(--color-primary);
}
.ap-tab-main .nav-link.active {
  color: var(--color-primary);
  font-weight: 500;
}
.ap-tab-main .nav-link.active:after {
  opacity: 1;
  visibility: visible;
}
.projects-tab-content .nav-item .nav-link {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  padding: 23px 0;
}
.ap-po-details {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  padding: 24px 25px 20px;
  background-color: var(--color-white);
}
.ap-po-details.ap-po-details--overview-bg-none .overview-content {
  background-color: transparent;
}
.ap-po-details__icon-area .svg-icon {
  width: 58px;
  height: 58px;
  -webkit-border-radius: 14px;
  border-radius: 14px;
}
.ap-po-details__icon-area .svg-icon i {
  font-size: calc(17.6px + 1.2vw);
}
.ap-po-details .overview-content p {
  margin: 2px 0 16px;
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  text-transform: capitalize;
}
.ap-po-details--2 .ap-po-details-time {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  padding: 10px 15px;
  background: var(--bg-lighter);
}
.ap-po-details--3 {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 25px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ap-po-details--3 .ap-po-details {
  padding: 0;
}
.ap-po-details--3 .overview-content3 {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.ap-po-details--3 > div:not(.b-none) {
  padding: 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 auto;
  flex: 0 auto;
  margin: 10px 0;
}
.ap-po-details--3 > div:not(.b-none):not(:last-child) {
  border-right: 1px solid var(--border-color);
  padding-right: 83px;
}
.ap-po-details--3 .ap-po-details strong {
  font-weight: 500;
}
.ap-po-details--3 .revenue-chart-box__Icon {
  width: 70px;
  height: 70px;
}
.ap-po-details--4 {
  padding: 39px 25px;
}
.ap-po-details--4 .revenue-chart-box__Icon svg {
  width: 30px;
}
.ap-po-details--5 {
  background-color: var(--color-white);
}
.ap-po-details--6 {
  background-color: var(--color-white);
}
.ap-po-details--ticket {
  padding: 44px 25px;
}
.ap-po-details--ticket .overview-content p {
  margin-bottom: 0;
}
.ap-po-details--ticket .overview-content .ap-po-details-content {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ap-po-details--ticket .ap-po-details__icon-area .svg-icon {
  -webkit-border-radius: 14px;
  border-radius: 14px;
}
.ap-po-details--luodcy {
  padding: 0;
  overflow: hidden;
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.05);
  background-color: var(--color-white);
}
.ap-po-details--luodcy .ap-po-details__titlebar {
  padding: 20px 25px;
}
.ap-po-details--luodcy .ap-po-details__titlebar p {
  font-size: 15px;
  color: var(--color-gray);
  margin: 2px 0 8px;
}
.ap-po-details--luodcy .ap-po-details__titlebar .ap-po-details-time {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 5px;
  margin-top: 13px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ap-po-details--luodcy .ap-po-details__icon-area {
  position: relative;
  width: 88px;
  height: 100%;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ap-po-details--luodcy .ap-po-details__icon-area i {
  font-size: calc(18.4px + 1.8vw);
}
.ap-po-details--luodcy .ap-po-details__icon-area.color-primary::before {
  background: rgba(var(--color-secondary-rgba), 0.1);
}
.ap-po-details--luodcy .ap-po-details__icon-area.color-secondary::before {
  background: rgba(var(--color-secondary-rgba), 0.1);
}
.ap-po-details--luodcy .ap-po-details__icon-area.color-info::before {
  background: rgba(var(--color-info-rgba), 0.1);
}
.ap-po-details--luodcy .ap-po-details__icon-area.color-success::before {
  background: rgba(var(--color-success-rgba), 0.1);
}
.ap-po-details--luodcy .ap-po-details__icon-area:before {
  content: "";
  position: absolute;
  width: 230px;
  height: 168px;
  left: 0;
  height: 110%;
  left: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.ap-content-wrapper .ap-po-details .overview-content p {
  color: var(--color-gray);
}
.ap-content-wrapper .ap-po-details-time span {
  font-weight: 500;
}
.ap-content-wrapper .ap-po-details-time small {
  color: var(--color-light);
}
.ap-po-details-time i {
  font-size: 15px;
}
.ap-po-details-time strong {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
}
.ap-po-details-time small {
  color: var(--color-light);
  white-space: nowrap;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  margin-left: 5px;
}
.ap-product .table {
  margin-bottom: 25px;
}
.ap-product .table thead tr th {
  border-top: none;
  border-bottom: 1px solid var(--border-color);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 17px 25px;
  color: var(--color-dark);
}
.ap-product .table thead tr th:first-child {
  width: 60%;
}
.ap-product .table tbody tr {
  border-bottom: 1px solid var(--border-color);
}
.ap-product .table tbody tr:hover td {
  background-color: var(--bg-normal);
}
.ap-product .table tbody tr td {
  color: var(--color-gray);
  border: none;
  padding: 17px 25px;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.ap-content-wrapper .card-header img,
.ap-content-wrapper .card-header svg {
  width: 24px;
}
.ap-profile-image {
  width: 46px;
  height: 46px;
}
.ap-main-post__header {
  padding-top: 14px;
}
.ap-post-content textarea {
  resize: none;
}
.ap-post-content__title .dropdown button {
  height: unset;
  padding: 0;
}
.ap-post-content__title .dropdown button img,
.ap-post-content__title .dropdown button svg {
  width: 22px;
}
.ap-post-content__title h6 {
  font-size: 14px;
  line-height: 1.4285714286;
}
.ap-post-content__title h6 small {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
}
.ap-post-content__p {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
}
.ap-post-content__feedback {
  padding: 16px 0;
}
.ap-post-content__feedback a {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.ap-post-content__feedback a:not(:last-child) {
  margin-right: 20px;
}
.ap-post-content__feedback a img,
.ap-post-content__feedback a svg {
  width: 15px;
  margin-right: 5px;
  color: var(--color-lighten);
}
.ap-post-attach {
  min-height: 60px;
}
.ap-post-attach a {
  line-height: 16px;
  padding: 7px 15px;
  height: 30px;
  background-color: var(--bg-lighter);
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 500;
}
.ap-post-attach a.ap-post-attach__drop {
  padding: 0;
  width: 48px;
}
.ap-post-attach a.ap-post-attach__drop img,
.ap-post-attach a.ap-post-attach__drop svg {
  width: 22px;
}
.ap-post-attach__drop img,
.ap-post-attach__drop svg {
  margin-right: 0;
  width: 18px;
  height: auto;
  color: var(--color-lighten);
}
.ap-post-attach__btn {
  display: none;
  height: 35px;
  text-transform: capitalize;
  -webkit-box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
  box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
}
.ap-post-attach__headImg {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.ap-post-content-comment button {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
  box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
}
.ap-post-content-comment button img,
.ap-post-content-comment button svg {
  margin-right: 0;
  width: 16px;
}
.ap-post-content-comment button.wh-50 {
  padding: 0 !important;
}
.ap-post-content-comment__write {
  background-color: var(--bg-lighter);
  height: 50px;
  -webkit-border-radius: 25px;
  border-radius: 25px;
  color: var(--color-gray);
  padding: 0 22px;
  margin-left: 45px;
}
.ap-post-content-comment__write input {
  color: var(--color-gray);
}
.ap-post-content-comment__write input:focus {
  -webkit-box-shadow: 0 0 !important;
  box-shadow: 0 0 !important;
}
.ap-post-content-comment__write input::-webkit-input-placeholder {
  font-size: 14px;
  color: var(--color-light);
  font-size: 12px;
}
.ap-post-content-comment__write input::-moz-placeholder {
  font-size: 14px;
  color: var(--color-light);
  font-size: 12px;
}
.ap-post-content-comment__write input::-ms-input-placeholder {
  font-size: 14px;
  color: var(--color-light);
  font-size: 12px;
}
.ap-post-content-comment__write input::placeholder {
  font-size: 14px;
  color: var(--color-light);
  font-size: 12px;
}
.ap-post-content-comment__write a {
  color: var(--color-lighten);
}
.ap-post-content-comment__write a img,
.ap-post-content-comment__write a svg {
  width: 18px;
}
.ap-post-content-comment__write a:not(:last-child) {
  margin-right: 18px;
}
.wig {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -4px;
}
.ap-post-gallery-bottom,
.ap-post-gallery-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -4px;
}
.wig__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding: 3.5px;
}
.wig__item img {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 100%;
}
.ap-post-gallery__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 4px;
}
.ap-post-gallery__item img {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 100%;
}
.ap-post-gallery-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.ap-post-gallery-overlay__content {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(var(--color-dark-rgba), 0.5);
  left: 0;
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0;
  color: var(--color-white);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 400;
}
.ap-post-cc-reply p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  text-transform: capitalize;
}
.ap-post-cc-reply__reaction {
  margin-top: 3px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ap-post-cc-reply__reaction li {
  color: var(--color-lighten);
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
}
.ap-post-cc-reply__reaction li:not(:last-child) {
  margin-right: 19px;
}
.ap-post-cc-reply__reaction li:not(:last-child):after {
  content: "I";
  right: -11px;
  top: 0;
  position: absolute;
}
.ap-post-cc-reply__reaction li a {
  color: var(--color-lighten);
}
.ap-post-cc-reply__reaction li a:hover {
  color: var(--color-primary);
}
.ap-post-cc-reply__reply {
  padding-top: 19px;
}
.view-more-comment {
  display: block;
  margin-top: 18px;
}
.ffp {
  padding: 13px 25px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ffp__icon {
  width: 31px;
  height: 31px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 15px;
}
.ffp__icon img,
.ffp__icon svg {
  width: 15px;
}
.ffp__title h6 {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-gray);
}
.ffp__title h6 span {
  font-weight: 500;
}
.ffp__title p {
  font-size: 12px;
  line-height: 1.8333333333;
  font-weight: 400;
  color: var(--color-lighten);
  margin-bottom: 0;
  padding-top: 2px;
}
.ffp__button {
  display: none;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ffp__button img,
.ffp__button svg {
  color: var(--color-lighten);
  width: 22px;
}
.ffp.ffp--hover:hover {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 15px 50px var(--shadow4);
  box-shadow: 0 15px 50px var(--shadow4);
}
.ffp.ffp--hover:hover .ffp__button {
  display: block;
}
.card .friends-widget .card-header,
.card .photo-gallery-widget .card-header,
.card .video-gallery-widget .card-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ffw {
  padding: 20px 25px;
  width: 100%;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ffw:hover {
  -webkit-box-shadow: 0 15px 50px var(--shadow4);
  box-shadow: 0 15px 50px var(--shadow4);
}
.ffw:hover button {
  background-color: var(--color-secondary);
  color: var(--color-white) !important;
}
.ffw:hover button:hover {
  background-color: var(--color-secondary);
  color: var(--color-white) !important;
}
.ffw button {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  text-transform: capitalize;
  padding: 8px 14px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
}
.ffw button:focus {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.ffw button.friends-follow {
  border: 1px solid var(--border-color);
  color: var(--color-gray);
}
.ffw button.friends-follow img,
.ffw button.friends-follow svg {
  display: none;
}
.ffw button.friends-follow.following {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.ffw__title h6 {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 600;
}
.ffw__title span {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-light);
  padding-top: 6px;
}
.ffw__imgWrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ffw__title {
  margin: 5px 0;
}
.wig-overlay {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.wig-overlay__content {
  position: absolute;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: rgba(39, 43, 65, 0.2);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wig-overlay__iconWrapper {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(var(--color-dark-rgba), 0.6);
}
.wig-overlay__iconWrapper img,
.wig-overlay__iconWrapper svg {
  width: 9px;
  color: var(--color-white);
}
.ap-post-form .card {
  position: relative;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.ap-post-form .card .card-header {
  padding-top: 20px;
  padding-bottom: 20px;
}
.ap-post-form .card .form-control::-webkit-input-placeholder {
  color: var(--color-light);
}
.ap-post-form .card .form-control::-moz-placeholder {
  color: var(--color-light);
}
.ap-post-form .card .form-control::-ms-input-placeholder {
  color: var(--color-light);
}
.ap-post-form .card .form-control::placeholder {
  color: var(--color-light);
}
.ap-post-form.highlighted .ap-post-attach__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ap-post-form.highlighted .card {
  z-index: 9999;
  -webkit-transform: scale(1.004);
  -ms-transform: scale(1.004);
  transform: scale(1.004);
}
.ap-post-form textarea:focus {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.ps-tab .nav-link {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  text-transform: capitalize;
  padding: 15px 10px;
  color: var(--color-light);
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ps-tab .nav-link img,
.ps-tab .nav-link svg {
  width: 16px;
  margin-right: 14px;
}
.ps-tab .nav-link img {
  width: 16px;
  margin-right: 14px;
}
.ps-tab .nav-link.active,
.ps-tab .nav-link:hover {
  background: rgba(var(--color-primary-rgba), 0.05);
  color: var(--color-primary);
  padding: 15px 20px;
}
.account-profile-cards__button button {
  background: 0 0;
}
.account-profile-cards__button button.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.account-profile-cards__button button.active span {
  color: inherit;
}
.account-profile-cards__button img,
.account-profile-cards__button svg {
  width: 16px;
  color: var(--color-light);
  margin-right: 8px;
}
.account-profile-cards__button span {
  margin-right: 7px;
  font-size: calc(16.2px + 0.15vw);
  color: var(--color-light);
}
.account-profile .pro_img_wrapper {
  width: 120px;
  height: 120px;
  -webkit-border-radius: 300px;
  border-radius: 300px;
  position: relative;
}
.account-profile #remove_pro_pic {
  position: absolute;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 4px solid var(--color-white);
  bottom: 0;
  right: 0;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: 14px;
}
.account-profile #remove_pro_pic img,
.account-profile #remove_pro_pic svg {
  width: 16px;
}
.as-cover__imgWrapper {
  position: relative;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.as-cover__imgWrapper label {
  width: 100%;
}
.as-cover__imgWrapper img {
  min-height: 120px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.ap-cover__changeImgBtn {
  position: absolute;
  right: 20px;
  top: 20px;
}
.ap-cover__changeImgBtn .cover-btn {
  border: 1px solid rgba(var(--color-white-rgba), 0.8);
  padding: 10px 20px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: var(--color-white);
  font-size: 13px;
  line-height: 1.2307692308;
  font-weight: 500;
}
.ap-cover__changeImgBtn .cover-btn img {
  width: 15px;
  height: 15px;
  min-height: 15px;
  margin-right: 8px;
}
.ap-cover__changeImgBtn button {
  border: 1px solid rgba(var(--color-white-rgba), 0.8);
  padding: 10px 20px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: var(--color-white);
  font-size: 13px;
  line-height: 1.2307692308;
  font-weight: 500;
}
.field-icon {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 10px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notification-content {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--bg-normal);
}
.notification-content__body h6 {
  color: var(--color-gray);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
}
.notification-content__body span {
  color: var(--color-light);
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
}
.notification-content .notification-content__body-wrapper {
  background-color: var(--color-white);
}
.profile-setting .card .card-header {
  padding-top: 24px;
  padding-bottom: 17px;
}
.profile-setting .card .card-header .edit-profile__title h6 {
  padding-bottom: 1px;
}
.chartLine-po-details .card-body {
  padding: 0 18px 19px;
}
.chartLine-po-details__overview-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chartLine-po-details__titlebar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chartLine-po-details__titlebar h1 {
  line-height: 1.2222222222;
  color: var(--color-dark);
}
.chartLine-po-details__titlebar p {
  margin-bottom: 0;
  margin-left: 8px;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 400;
  color: var(--color-gray);
}
.chartLine-po-details__time {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chartLine-po-details__time span {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chartLine-po-details__time span i {
  font-size: 10px;
}
.chartLine-po-details__time span strong {
  margin-left: 5px;
  font-weight: 500;
}
.table-social {
  min-height: 382px;
}
.table-social th {
  border-top: 0 !important;
  border-bottom: 0 !important;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  background-color: var(--bg-normal);
}
.table-social tr td:first-child,
.table-social tr th:first-child {
  border-left: 0;
}
.table-social tr td:last-child,
.table-social tr th:last-child {
  border-right: 0;
}
.table-social tr td:first-child {
  padding-left: 25px;
}
.table-social tr td:last-child {
  padding-right: 25px;
}
.table-social tbody tr:not(:first-child) {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-gray);
}
.table-social tbody tr:not(:first-child):hover {
  background: var(--bg-normal);
}
.table-social tbody tr:first-child td {
  color: var(--color-dark);
  font-weight: 500;
}
.table-social tbody tr td:not(:first-child) {
  text-align: right;
}
.table-social a {
  color: var(--color-info);
}
.table-social a:hover {
  color: var(--color-info);
}
.table-social td {
  font-size: 14px;
}
.table-social td,
.table-social th {
  padding: 12.8px 15px;
}
.top-menu .table-social {
  margin-bottom: 64px;
}
.landing-pages-table table tbody td a {
  white-space: nowrap;
}
.landing-pages-table table tbody td a:hover {
  color: var(--color-primary);
}
.table--default.body-px-25 td:first-child,
.table--default.body-px-25 th:first-child {
  padding-left: 25px;
}
.table--default.body-px-25 td:last-child,
.table--default.body-px-25 th:last-child {
  padding-right: 25px;
}
.table--default th {
  border-top: 0 !important;
  border-bottom: 0 !important;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
  color: var(--color-dark);
}
.table--default tr td:first-child,
.table--default tr th:first-child {
  border-left: 0;
}
.table--default tr td:last-child,
.table--default tr th:last-child {
  border-right: 0;
}
.table--default tbody tr {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-gray);
}
.table--default tbody tr:last-child {
  border-bottom: 1px solid var(--border-color);
}
.table--default tbody tr td:not(:first-child) {
  text-align: right;
}
.table--default thead tr th:not(:first-child) {
  text-align: right;
}
.table--default td {
  font-size: 14px;
  vertical-align: middle;
}
.table--default td,
.table--default th {
  padding: 16px 15px;
}
.table-borderless tr:last-child {
  border-bottom: 0 !important;
}
.traffic-table .progress {
  height: 5px;
}
.traffic-table.table--default thead tr th:not(:first-child) {
  text-align: left;
}
.traffic-table.table--default tbody tr td:not(:first-child) {
  text-align: left;
}
.top-menu .traffic-table {
  margin-bottom: 35px;
}
.table-top-regions {
  border: 1px solid var(--border-color);
  max-height: 280px;
  max-width: 466px;
  overflow-y: auto;
}
.table-top-regions::-webkit-scrollbar {
  width: 12px;
  background-color: var(--scrollbarBG);
}
.table-top-regions::-webkit-scrollbar-thumb {
  border: 4px solid var(--scrollbarBG);
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  -webkit-border-radius: 9999px;
  border-radius: 9999px;
  background-color: var(--thumbBG);
}
.table-top-regions table {
  margin-bottom: 0;
}
.table-top-regions table thead {
  background: var(--bg-normal);
}
.table-top-regions table th {
  padding: 11px 15px;
  color: var(--color-light);
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  text-transform: uppercase;
}
.table-top-regions table td {
  padding: 8px 15px;
  font-size: 14px;
  line-height: 2;
  font-weight: 400;
  color: var(--color-dark);
}
.table-top-regions table tbody tr:first-child td {
  padding-top: 20px;
}
.table-top-regions table tbody tr:last-child td {
  padding-bottom: 12px;
}
.table-top-regions table tbody tr td:first-child {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
}
.table-top-location {
  border-top: 1px solid var(--border-color);
  margin-top: 18px;
  padding-top: 10px;
  margin-bottom: -8px;
}
.table-top-location table thead th {
  font-size: 12px;
  font-weight: 400;
  text-transform: uppercase;
  color: var(--color-lighten);
}
.table-top-location table tbody tr:hover {
  background: var(--color-white);
}
.table-top-location table tbody tr:hover td {
  background-color: var(--color-white);
}
.table-top-location table td,
.table-top-location table th {
  padding: 7px 0 !important;
}
.top-menu .table-top-location {
  margin-bottom: 38px;
}
.table-revenue {
  min-height: 370px;
}
.table-revenue th {
  padding: 15px 18px;
}
.table-revenue td {
  padding: 10px 18px;
}
.top-menu .table-revenue {
  margin-bottom: 42px;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--border-color);
}
.table > tbody {
  vertical-align: middle;
}
.dm-pagination__item {
  margin: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.dm-pagination__item:not(:last-child) {
  margin-right: 20px;
}
.dm-pagination__item .paging-option {
  position: relative;
}
.dm-pagination__item .paging-option:after {
  content: "\f107";
  font-family: line awesome free;
  font-weight: 900;
  position: absolute;
  top: 50%;
  right: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: var(--color-light);
  font-size: 12px;
  pointer-events: none;
}
.dm-pagination__item .page-selection {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 13px;
  height: 32px;
  padding: 4.5px 13px;
  color: var(--color-gray);
  border: 1px solid var(--border-color);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 2px;
  min-width: 103px;
  background-color: var(--color-white);
}
.dm-pagination__link {
  min-height: 32px;
  font-size: 14px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 4.5px 11.75px;
  border-width: 1px;
  border-style: solid;
  background-color: var(--color-white);
  margin: 2.5px;
  border: 1px solid var(--border-color);
  color: var(--color-gray);
  line-height: normal;
}
.dm-pagination__link.pagination-control {
  width: 32px;
  height: 32px;
  color: var(--color-light);
}
.dm-pagination__link.pagination-control span {
  font-size: 12px;
}
.dm-pagination__link.active,
.dm-pagination__link:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.modal-header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.modal-header button.close {
  margin: 0;
  padding: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.modal-header button.close svg {
  width: 18px;
  color: var(--color-dark);
}
.modal-content p {
  font-size: 14px;
}
.modal-content p:last-child {
  margin-bottom: 0;
}
.modal-content .modal-footer {
  padding: 0.625rem;
}
.modal-content .modal-footer .btn.btn-sm {
  line-height: 2rem;
}
.modal-content.modal-colored .modal-title {
  color: var(--color-white);
}
.modal-content.modal-colored .close i,
.modal-content.modal-colored .close span,
.modal-content.modal-colored .close svg {
  color: var(--color-white);
}
.modal-content.modal-colored .modal-body p {
  color: var(--color-white);
}
.modal-content.modal-colored .modal-footer .btn {
  color: var(--color-white);
}
.modal-content.modal-colored .btn:hover {
  border-color: transparent;
}
.modal-info .modal-body {
  padding-bottom: 10px;
}
.modal-info .modal-footer {
  padding: 0.5rem 0.5rem 1rem;
  border-top: 0;
}
.modal-info-body .modal-info-icon {
  margin: -2px 14px 0 0;
}
.modal-info-body .modal-info-icon svg {
  width: 22px;
  color: var(--color-info);
}
.modal-info-body .modal-info-icon.primary svg {
  color: var(--color-primary);
}
.modal-info-body .modal-info-icon.danger svg {
  color: var(--color-danger);
}
.modal-info-body .modal-info-icon.success svg {
  color: var(--color-success);
}
.modal-info-body .modal-info-icon.warning svg {
  color: var(--color-warning);
}
.modal-info-body .modal-info-text h1,
.modal-info-body .modal-info-text h2,
.modal-info-body .modal-info-text h3,
.modal-info-body .modal-info-text h4,
.modal-info-body .modal-info-text h5,
.modal-info-body .modal-info-text h6 {
  margin-bottom: 10px;
}
.modal-content.modal-bg-primary {
  background-color: var(--color-primary);
}
.modal-content.modal-bg-success {
  background-color: var(--color-success);
}
.modal-content.modal-bg-danger {
  background-color: var(--color-danger);
}
.modal-content.modal-bg-warning {
  background-color: var(--color-warning);
}
.modal-btn-group {
  margin: -5px;
}
.modal-btn-group button {
  margin: 5px;
}
.noteModal .close {
  position: absolute;
  top: 15px;
  right: 20px;
  background: 0 0;
  border: none;
  font-size: 20px;
  color: var(--color-dark);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.noteModal .close:hover {
  opacity: 0.7;
}
.noteModal textarea {
  height: 110px;
}
.noteModal .form-control {
  min-height: 48px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.noteModal .form-control:focus,
.noteModal .form-control:hover {
  border-color: var(--color-primary);
}
.progress-wrap .progress {
  -webkit-box-flex: 28;
  -ms-flex: 28;
  flex: 28;
}
.progress-wrap .progress .progress-bar {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.progress-wrap .progress-icon,
.progress-wrap .progress-percentage {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.progress-wrap .progress-height {
  height: 5px;
  background-color: rgba(var(--color-white-rgba), 0.2);
}
.progress-wrap .progress-percentage {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
}
.progress-wrap .progress-icon {
  margin-left: 10px;
}
.progress-wrap .progress-icon i,
.progress-wrap .progress-icon img,
.progress-wrap .progress-icon span,
.progress-wrap .progress-icon svg {
  width: 14px;
}
.dm-notice__icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}
.dm-notice__icon i,
.dm-notice__icon img,
.dm-notice__icon svg {
  font-size: calc(17.4px + 1.05vw);
  width: 30px;
}
.dm-notice__icon i.fa,
.dm-notice__icon i.fas {
  font-size: calc(17px + 0.75vw);
}
.dm-notice__text {
  max-width: 550px;
  margin: 30px auto auto;
  margin-top: 30px;
}
.dm-notice__text h1,
.dm-notice__text h2,
.dm-notice__text h3,
.dm-notice__text h4,
.dm-notice__text h5,
.dm-notice__text h6 {
  font-weight: 500;
  margin-bottom: 20px;
}
.dm-notice__text p {
  font-size: 12px;
  margin-bottom: 0;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 18px;
}
.dm-notice__action {
  margin-top: 20px;
}
.dm-notice__action a + a {
  margin-left: 10px;
}
.dm-notice__action .btn.btn-sm {
  padding: 0 0.88rem;
  line-height: 2.65;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  min-width: 100px;
  font-weight: 400;
}
.dm-notice__content h1,
.dm-notice__content h2,
.dm-notice__content h3,
.dm-notice__content h4,
.dm-notice__content h5,
.dm-notice__content h6 {
  font-weight: 500;
  margin-bottom: 14px;
}
.dm-notice__content .notice-listBlock {
  padding: 24px;
}
.dm-notice__content .notice-list li:not(:last-child) {
  margin-bottom: 9px;
}
.dm-notice__content .notice-list li img,
.dm-notice__content .notice-list li svg {
  width: 16px;
}
.dm-notice__content .notice-list__text {
  font-size: 14px;
  margin-left: 6px;
  color: var(--color-dark);
}
.dm-notice__content .notice-list__text a {
  display: inline-block;
  margin-left: 5px;
}
.user-member .action-btn a {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  height: 40px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  text-transform: capitalize;
}
.user-member .action-btn a img.svg,
.user-member .action-btn a svg {
  width: 13px;
  height: 13px;
  margin-right: 7px;
}
.user-member__title {
  margin-right: 25px;
}
.user-member__title span {
  position: relative;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-gray);
}
.user-member__title span:before {
  content: "";
  position: absolute;
  left: 0;
  height: 100%;
  width: 1px;
  background-color: var(--border-light);
}
.user-member__form {
  padding: 15px 25px;
  background: var(--color-white);
  height: 46px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  -webkit-border-radius: 23px;
  border-radius: 23px;
}
.user-member__form .form-control {
  padding: 10px 13px;
  line-height: 25px;
  height: 46px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.user-member__form .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.user-member__form .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.user-member__form .form-control::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.user-member__form .form-control::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.user-member__form img,
.user-member__form svg {
  width: 20px;
  color: var(--color-lighten);
}
.tm-card-overlay {
  top: 17px;
  right: 30px;
}
.tm-card-overlay .dropdown button {
  height: unset;
}
.tm-card-overlay .dropdown img,
.tm-card-overlay .dropdown svg {
  color: var(--color-lighten);
  width: 24px;
}
.users-list__button button {
  font-size: 13px;
  line-height: 2.9230769231;
  font-weight: 500;
  height: 38px;
  min-width: 111px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.users-list__button button:hover {
  opacity: 0.7;
}
.users-list-body__title h6 {
  margin-bottom: 3px;
}
.users-list-body__title span {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
}
.users-list-body__title span span {
  font-weight: 600;
  color: var(--color-dark);
}
.users-list-body__title p {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.users-list-body__bottom span {
  padding-top: 6px;
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
}
.status-radio .radio-horizontal-list .custom-radio {
  padding-left: 0;
  margin-right: 35px;
}
.user-social-profile .edit-profile__body label {
  color: var(--color-dark);
  font-weight: 500;
}
.edit-social .input-group,
.user-social-profile .input-group {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.edit-social .input-group .input-group-prepend,
.user-social-profile .input-group .input-group-prepend {
  margin-right: 0;
}
.edit-social .input-group .input-group-text,
.user-social-profile .input-group .input-group-text {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.edit-social .input-group .form-control,
.user-social-profile .input-group .form-control {
  border-left: none;
  margin-left: -2px;
  background: 0 0;
}
.user-group .progress {
  height: 5px;
}
.user-group .user-group-progress-bar p {
  margin-top: 2px;
}
.user-group .user-group-progress-top {
  margin-top: 14px;
  margin-bottom: 20px;
  text-transform: capitalize;
}
.user-group .user-group-progress-top span {
  white-space: nowrap;
}
.user-group-media button img,
.user-group-media button svg {
  width: 24px;
  color: var(--color-lighten);
}
.user-group-people p {
  margin-bottom: 23px;
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.user-group-people__parent li:not(:last-child) {
  margin-right: 4px;
}
.user-group {
  background-color: var(--color-white);
}
.user-group .dropdown-default .dropdown-item,
.user-group .dropdown-menu .dropdown-item {
  padding: 1px 25px;
  font-size: 14px;
  line-height: 2.2857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.user-group .dropdown-default .dropdown-item:hover,
.user-group .dropdown-menu .dropdown-item:hover {
  background: rgba(var(--color-primary-rgba), 0.05);
}
.user-group .dropdown-default,
.user-group .dropdown-menu {
  padding: 18px 0;
}
.media-ui.user-group .user-group-progress-top {
  margin-top: 0;
  margin-bottom: 22px;
}
.media-ui .user-group-people > p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-gray);
  margin-bottom: 22px;
}
.media-ui .user-group-progress-top p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
  color: var(--color-dark);
}
.user-group:not(.media-ui) .media-body h6 {
  margin-bottom: 6px;
}
.userDatatable table {
  border-bottom: 1px solid var(--border-color);
  border-collapse: separate;
  border-spacing: 0;
}
.userDatatable table td {
  border-top: 0;
  border-bottom: none;
  padding: 10px 20px;
  vertical-align: middle;
  white-space: nowrap;
}
.userDatatable table thead tr:not(.footable-filtering) {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.userDatatable table thead tr:not(.footable-filtering) th {
  color: var(--color-gray);
  background: var(--bg-normal);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-bottom-color: var(--border-color) !important;
  white-space: nowrap;
}
.userDatatable table thead tr:not(.footable-filtering) th:first-child {
  border-left: 1px solid var(--border-color);
  -webkit-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
.userDatatable table thead tr:not(.footable-filtering) th:last-child {
  border-right: 1px solid var(--border-color);
  -webkit-border-radius: 0 10px 10px 0 !important;
  border-radius: 0 10px 10px 0 !important;
}
.userDatatable table tbody tr:first-child td {
  padding-top: 11px;
}
.userDatatable table tbody tr:last-child td {
  padding-bottom: 11px;
}
.userDatatable table > thead > tr:first-child th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.userDatatable .userDatatable__imgWrapper {
  margin-right: 12px;
}
.userDatatable tbody .custom-checkbox input[type="checkbox"] + label {
  margin-right: 18px;
}
.userDatatable-header th {
  border-bottom: none;
  padding: 13px 20px 15px;
  vertical-align: middle;
  background: var(--bg-normal);
}
.userDatatable-header th .checkbox-text {
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}
.userDatatable-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
  text-transform: capitalize;
}
.projectDatatable-title {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.userDatatable-inline-title p {
  font-size: 12px;
  line-height: 1.8333333333;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.project-table .userDatatable-inline-title h6 {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
}
.userDatatable-content,
.userDatatable-inline-title h6 {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: capitalize;
}
.userDatatable-content-status {
  padding: 5px 12px;
  height: 25px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
}
.checkbox-text.userDatatable-title {
  margin-left: 18px;
}
.userDatatable_actions li:not(:last-child) {
  margin-right: 20px;
}
.userDatatable_actions img,
.userDatatable_actions svg {
  width: 16px;
  color: var(--color-lighten);
}
.projectDatatable.userDatatable .table th {
  padding: 14px 20px;
  border-bottom: 1px solid var(--border-color);
}
.projectDatatable.userDatatable .table td {
  padding: 16px 20px;
}
.projects-tab-content .projectDatatable tbody tr {
  border-bottom: 1px solid var(--border-color);
}
.user-info-tab .ap-tab-main .nav-item .nav-link {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-gray);
  padding: 22px 0;
  border-bottom: 2px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}
.user-info-tab .ap-tab-main .nav-item .nav-link:after {
  position: absolute;
  left: 0;
  bottom: 1px;
  width: 100%;
  bottom: -3px;
  height: 1px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  content: "";
  opacity: 0;
  visibility: hidden;
  display: block;
}
.user-info-tab .ap-tab-main .nav-item .nav-link img,
.user-info-tab .ap-tab-main .nav-item .nav-link svg {
  color: var(--color-light);
  width: 18px;
  margin-right: 8px;
}
.user-info-tab .ap-tab-main .nav-item .nav-link.active {
  color: var(--color-primary);
}
.user-info-tab .ap-tab-main .nav-item .nav-link.active:after {
  opacity: 1;
  visibility: visible;
}
.user-info-tab .ap-tab-main .nav-item .nav-link.active img,
.user-info-tab .ap-tab-main .nav-item .nav-link.active svg {
  color: var(--color-primary);
}
.user-info-tab .form-check-label::before {
  border: 1px solid var(--body-color);
}
.user-info-tab .form-check-label::after {
  background: no-repeat 65%/77% 65%;
}
.user-info-tab .form-check-label::after,
.user-info-tab .form-check-label::before {
  width: 16px;
  height: 16px;
  left: -25px;
}
.user-info-tab .form-check-input:focus ~ .form-check-label::before {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.user-info-tab .form-check-input:checked ~ .form-check-label::before {
  border-color: var(--color-primary);
  background-color: var(--color-primary);
}
.user-info-tab .custom-radio {
  padding-left: 25px;
}
.form-group-calender a {
  content: "";
  position: absolute;
  right: 15px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  pointer-events: none;
}
.form-group-calender a img,
.form-group-calender a svg {
  width: 16px;
  color: var(--color-lighten);
}
.project-top-progress .project-tap {
  background: var(--color-white);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}
.listing-social-link.listing-social-link__products .icon-list-social__link {
  -webkit-box-shadow: 0 5px 10px transparent;
  box-shadow: 0 5px 10px transparent;
}
.listing-social-link.listing-social-link__products
  .icon-list-social__link.active {
  -webkit-box-shadow: 0 5px 10px var(--shadow3);
  box-shadow: 0 5px 10px var(--shadow3);
}
.project-tap.project-tab__product {
  background: #fff;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 40px;
}
.project-tap .nav-link {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
  padding: 0 15px;
  text-transform: capitalize;
}
.project-tap .nav-link.active {
  color: var(--color-primary);
}
.project-tap .nav-item {
  position: relative;
}
.project-tap .nav-item:not(:first-child):after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 1px;
  background-color: var(--border-color);
  top: 50%;
  left: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.project-search--height .form-control,
.project-search--height .user-member__form {
  height: 40px;
}
.project-search.shop-search .form-control {
  height: 46px;
}
.project-category {
  text-transform: capitalize;
}
.project-category .select2-container--default .select2-selection--multiple,
.project-category .select2-container--default .select2-selection--single {
  height: 40px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  min-width: 170px;
}
.project-category
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.project-category
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-primary);
}
.project-category
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0 100px 0 10px;
}
.project-category .project-tap .nav-link {
  padding: 10px 15px;
}
.icon-list-social__link {
  font-size: calc(16.2px + 0.15vw);
  line-height: 1.6666666667;
  font-weight: 500;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  color: var(--color-light);
  -webkit-box-shadow: 0 5px 10px var(--shadow3);
  box-shadow: 0 5px 10px var(--shadow3);
}
.icon-list-social__link:hover {
  color: var(--color-light);
}
.icon-list-social__link.active {
  background: var(--color-white);
  color: var(--color-primary);
}
.icon-list-social__link i {
  font-size: calc(16.4px + 0.3vw);
}
.icon-list-social__link img,
.icon-list-social__link svg {
  width: 16px;
}
.profile-card-3 {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  border: none;
}
.profile-card-3 .profile {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 8px solid var(--color-white);
}
.profile-card-3 .background-block {
  float: left;
  width: 100%;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.profile-card-3 .background-block .background {
  width: 100%;
  vertical-align: top;
  height: 150px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.profile-card-3 .card-dropdown {
  margin-top: 7px;
  position: absolute;
  top: 50%;
  right: -20px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 34px;
  height: 34px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.profile-card-3 .card-dropdown:hover {
  background: var(--bg-lighter);
}
.profile-card-3 .card-dropdown .dropdown img,
.profile-card-3 .card-dropdown .dropdown svg {
  color: var(--color-lighten);
  width: 24px;
}
.profile-card-3 .card-content {
  background: var(--color-white);
  -webkit-border-radius: 30px 30px 10px 10px;
  border-radius: 30px 30px 10px 10px;
}
.profile-card-3 .card-content .card-content__body {
  width: 100%;
  padding: 25px;
}
.user-group-progress-bar .progress-percentage {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
}
.media-ui--completed .progress-icon {
  -webkit-box-flex: unset;
  -ms-flex: unset;
  flex: unset;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--color-success);
}
.media-ui--completed .progress-icon img,
.media-ui--completed .progress-icon svg {
  width: 12px;
  color: var(--color-white);
}
.projectDatatable.project-table .table {
  border-bottom: none;
}
.projectDatatable.project-table .table td {
  border-bottom: 1px solid var(--border-color);
}
.projectDatatable.project-table .table td:first-child {
  padding-left: 0;
}
.projectDatatable.project-table .table td:last-child {
  padding-right: 0;
}
.project-progress .dropdown img,
.project-progress .dropdown svg {
  width: 22px;
  color: var(--color-lighten);
}
.project-progress .user-group-progress-bar .progress {
  height: 5px;
}
.new-member-modal .form-control {
  height: 48px;
}
.new-member-modal .form-control::-webkit-input-placeholder {
  color: var(--color-light);
}
.new-member-modal .form-control::-moz-placeholder {
  color: var(--color-light);
}
.new-member-modal .form-control::-ms-input-placeholder {
  color: var(--color-light);
}
.new-member-modal .form-control::placeholder {
  color: var(--color-light);
}
.new-member-modal .form-group label {
  text-transform: capitalize;
  color: var(--color-dark);
  font-size: 14px;
  line-height: 1.8571428571;
  font-weight: 500;
  margin-bottom: 8px;
}
.new-member-modal textarea.form-control {
  padding: 13px 20px;
  height: 120px;
  resize: none;
}
.new-member-calendar .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.new-member-calendar .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.new-member-calendar .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.new-member-calendar .form-control::placeholder {
  color: var(--color-lighten);
}
.select2-dropdown.category-member {
  z-index: 9999;
}
.select2-dropdown.category-member
  .select2-search--dropdown
  .select2-search__field {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border: 1px solid var(--border-color);
}
.input-list-wrap {
  margin-bottom: -10px;
}
.input-list__single {
  margin: 0 10px 10px 0;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
}
.input-container .input-icon {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  line-height: 0;
}
.input-container .input-icon.icon-left {
  left: 20px;
}
.input-container .input-icon.icon-right {
  right: 20px;
}
.input-container .input-icon img,
.input-container .input-icon svg {
  width: 14px;
  color: var(--color-lighten);
}
.input-container.icon-left input {
  padding-left: 40px;
}
.form-group input::-webkit-input-placeholder {
  color: var(--color-light);
}
.form-group input::-moz-placeholder {
  color: var(--color-light);
}
.form-group input::-ms-input-placeholder {
  color: var(--color-light);
}
.form-group input::placeholder {
  color: var(--color-light);
}
.basic-form-wrapper {
  max-width: 420px;
}
.form-basic label {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 10px;
}
.form-basic select {
  width: 100%;
  border: 1px solid var(--border-light);
}
.form-basic textarea {
  min-height: 100px;
}
.form-basic .form-inline-action {
  margin-top: 18px;
}
.form-basic .form-inline-action .custom-checkbox .checkbox-text {
  font-size: 13px;
  color: var(--color-light);
}
.form-basic .form-inline-action .forgot-pass {
  font-size: 13px;
}
.form-basic .btn-submit {
  min-width: 180px;
}
.form-basic .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.form-basic .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.form-basic .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.form-basic .form-control::placeholder {
  color: var(--color-lighten);
}
.form-control-md {
  height: 2.875rem;
}
.was-validated input:invalid ~ label,
.was-validated input:invalid ~ label .invalid-feedback,
input.is-invalid ~ label {
  color: var(--color-danger);
}
.was-validated input:invalid ~ label::after,
input.is-invalid ~ label::after {
  background-color: rgba(255, 77, 79, 0.3);
  border: 1px solid transparent;
}
.was-validated input:invalid ~ label .invalid-feedback {
  display: block;
}
.invalid-feedback,
.valid-feedback {
  margin-top: 8px;
}
.timeline-box {
  position: relative;
  z-index: 10;
}
.timeline-box.timeline-box--2 .timeline-single__content {
  width: 100%;
}
.timeline-box.timeline-box--2 .timeline-single__content p {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.timeline-box.timeline-box--2 .timeline-single__content p:hover {
  color: var(--color-primary);
}
.timeline-box.timeline-box--2 .timeline-single__buble {
  background-color: var(--body-color);
  border: none;
  border-style: none;
}
.timeline-box .timeline-single {
  position: relative;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: end;
}
.timeline-box .timeline-single:not(:last-child) {
  padding-bottom: 25px;
}
.timeline-box .timeline-single .timeline-line {
  position: absolute;
  left: 4px;
  top: 15px;
  height: 100%;
  width: 3px;
  z-index: -1;
  background-color: var(--border-color);
}
.timeline-box .timeline-single__buble {
  display: inline-table;
  width: 13px;
  height: 13px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border-width: 2px !important;
  border-style: solid;
  background-color: var(--color-white);
}
.timeline-box .timeline-single__buble.buble-primary {
  border: 1px solid var(--color-primary);
}
.timeline-box .timeline-single__buble.buble-success {
  border: 1px solid var(--color-success);
}
.timeline-box .timeline-single__buble.buble-danger {
  border: 1px solid var(--color-danger);
}
.timeline-box .timeline-single__buble.border-extra-light {
  border: 1px solid var(--color-lighten);
}
.timeline-box .timeline-single__content {
  padding-left: 32px;
  margin-top: -6px;
}
.timeline-box .timeline-single__content p {
  margin-bottom: 10px;
  color: var(--color-gray);
}
.timeline-box .timeline-single__badge {
  height: 22px;
  padding: 0 11px;
  font-size: 12px;
  line-height: 1.3333333333;
  font-weight: 500;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  text-transform: capitalize;
  margin-left: 7px;
}
.timeline-box .timeline-single__days {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-light);
}
.timeline-vertical .timeline {
  list-style: none;
  padding: 20px 0;
  position: relative;
}
.timeline-vertical .timeline:before {
  top: 0;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 3px;
  background-color: var(--border-light);
  left: 50%;
  margin-left: -1.5px;
}
.timeline-vertical .timeline li {
  position: relative;
}
.timeline-vertical .timeline li:after,
.timeline-vertical .timeline li:before {
  content: " ";
  display: table;
}
.timeline-vertical .timeline li:after {
  clear: both;
}
.timeline-vertical .timeline li .timeline-single {
  width: 45%;
  float: left;
  position: relative;
}
.timeline-vertical .timeline li .timeline-single:before {
  position: absolute;
  top: 65px;
  right: -11px;
  display: inline-block;
  border-top: 8px solid transparent;
  border-left: 11px solid #eff0f3;
  border-right: 0 solid #eff0f3;
  border-bottom: 7px solid transparent;
  content: " ";
}
.timeline-vertical .timeline li .timeline-single:after {
  position: absolute;
  top: 30%;
  right: -11px;
  display: inline-block;
  border-top: 8px solid #eff0f3;
  border-bottom: 7px solid #eff0f3;
  content: " ";
}
.timeline-vertical .timeline > li .timeline-single__buble {
  color: var(--color-white);
  width: 10px;
  height: 10px;
  border: 10px solid var(--bg-normal);
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 62px;
  left: 50%;
  margin-left: -10px;
  z-index: 100;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.timeline-vertical .timeline > li .timeline-single__buble span {
  width: 10px;
  height: 10px;
  position: absolute;
  font-size: 1.4em;
  text-align: center;
  margin-left: -10px;
  z-index: 100;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: -5px;
  left: 5px;
}
.timeline-vertical .timeline > li.timeline-inverted > .timeline-single {
  float: right;
}
.timeline-vertical .timeline > li.timeline-inverted > .timeline-single:before {
  border-left-width: 0;
  border-right-width: 12px;
  left: -12px;
  right: auto;
}
.timeline-vertical .timeline > li.timeline-inverted > .timeline-single:after {
  border-left-width: 0;
  border-right-width: 14px;
  left: -14px;
  right: auto;
}
.timeline-vertical.left-middle li {
  margin-bottom: 25px;
}
.timeline-vertical.left-middle .timeline:before {
  left: 0;
}
.timeline-vertical.left-middle .timeline > li .timeline-single__buble {
  left: 0;
}
.timeline-vertical.left-middle .timeline li .timeline-single {
  width: 94%;
}
.timeline-box--3.scroll-timeline {
  height: 100vh;
  overflow-x: auto;
  padding: 0 20px;
}
.timeline-box--3.basic-timeline .timeline {
  padding: 0;
}
.timeline-box--3.basic-timeline .timeline-single__content {
  padding: 0;
  background-color: transparent;
}
.timeline-box--3.basic-timeline .timeline li .timeline-single {
  margin-top: 8px;
  padding-right: 45%;
}
.timeline-box--3.basic-timeline .timeline li .timeline-single:before {
  display: none;
}
.timeline-box--3.basic-timeline
  .timeline
  li
  .timeline-single
  .timeline-single__days
  span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.timeline-box--3.basic-timeline
  .timeline
  li
  .timeline-single
  .timeline-single__content
  p {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray-x);
  margin-top: 24px;
  margin-bottom: 11px;
}
.timeline-box--3.basic-timeline
  .timeline
  li
  .timeline-single
  .timeline-single__content
  span {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-light);
}
.timeline-box--3.basic-timeline .timeline > li .timeline-single__buble {
  background-color: var(--color-white);
  width: 40px;
  height: 40px;
  border: none;
  margin-left: -18px;
  top: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.timeline-box--3.basic-timeline .timeline > li .timeline-single__buble--svg {
  width: 40px;
  height: 40px;
  border: none;
  margin-left: 0;
  top: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.timeline-box--3 .timeline-single__content {
  background: #eff0f3;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 20px;
}
.timeline-box--3 .timeline-single__content p {
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
  color: var(--color-gray-x);
  margin-bottom: 0;
}
.timeline-box--3 .timeline-single__days {
  text-align: end;
  margin-bottom: 7px;
}
.timeline-box--3 .timeline-single__days span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--body-color);
}
.timeline-box--3 .timeline-inverted .timeline-single__days {
  text-align: start;
}
.list-box__item {
  font-size: 14px;
  padding: 12px 20px;
  border-left: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  color: var(--color-gray);
}
.list-box__item:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.list-box__item:last-child {
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  border-bottom: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
}
.list-box__item:first-child {
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
  border-right: 1px solid var(--border-light);
}
.dm-menu.menu-horizontal {
  border-bottom: 1px solid var(--border-light);
}
.dm-menu.menu-horizontal .dm-menu__item {
  position: relative;
  display: inline-block;
}
.dm-menu.menu-horizontal .dm-menu__item:not(:last-child) {
  margin-right: 30px;
}
.dm-menu.menu-horizontal .dm-menu__item:hover > .dm-submenu {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.dm-menu.menu-horizontal .dm-menu__item.active > .dm-menu__link,
.dm-menu.menu-horizontal .dm-menu__item:hover > .dm-menu__link {
  color: var(--color-primary);
}
.dm-menu.menu-horizontal .dm-menu__item.active > .dm-menu__link:after,
.dm-menu.menu-horizontal .dm-menu__item:hover > .dm-menu__link:after {
  opacity: 1;
  visibility: visible;
}
.dm-menu.menu-horizontal .dm-menu__item.active > .dm-menu__link img,
.dm-menu.menu-horizontal .dm-menu__item.active > .dm-menu__link svg,
.dm-menu.menu-horizontal .dm-menu__item:hover > .dm-menu__link img,
.dm-menu.menu-horizontal .dm-menu__item:hover > .dm-menu__link svg {
  color: var(--color-primary);
}
.dm-menu.menu-horizontal .dm-menu__item.has-submenu > .dm-menu__link:before {
  font-family: line awesome free;
  font-weight: 700;
  content: "\f107";
  margin-left: 5px;
  margin-top: 1px;
  float: right;
}
.dm-menu.menu-horizontal .dm-menu__item.menu-item-disabled .dm-menu__link {
  border-color: transparent !important;
  cursor: not-allowed;
}
.dm-menu.menu-horizontal .dm-menu__item.menu-item-disabled .dm-menu__link img,
.dm-menu.menu-horizontal .dm-menu__item.menu-item-disabled .dm-menu__link svg {
  color: var(--color-lighten);
}
.dm-menu.menu-horizontal
  .dm-menu__item.menu-item-disabled
  .dm-menu__link:after {
  display: none;
}
.dm-menu.menu-horizontal .dm-menu__item.menu-item-disabled .dm-menu__text {
  color: var(--color-lighten);
}
.dm-menu.menu-horizontal .dm-menu__item .dm-menu__item {
  display: block;
}
.dm-menu.menu-horizontal .dm-menu__item .dm-menu__item > .dm-menu__link:after {
  display: none;
}
.dm-menu.menu-horizontal .dm-menu__item .dm-menu__item > .dm-menu__link:before {
  content: "\f105";
}
.dm-menu.menu-horizontal .dm-menu__link {
  padding-bottom: 8px;
}
.dm-menu__link {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 2.9;
  color: var(--color-gray);
}
.dm-menu__link:after {
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.35s;
  -o-transition: 0.35s;
  transition: 0.35s;
  content: "";
  background-color: var(--color-primary);
}
.dm-menu__link img,
.dm-menu__link svg {
  width: 16px;
  height: 16px;
  color: var(--color-lighten);
}
.dm-menu__link .dm-menu__text {
  font-weight: 500;
  margin-left: 10px;
}
.dm-submenu {
  position: absolute;
  left: 0;
  top: 48px;
  width: 260px;
  padding: 16px;
  -webkit-box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.15);
  -webkit-transform: scaleY(0);
  -ms-transform: scaleY(0);
  transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
  -ms-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.35s ease;
  -o-transition: 0.35s ease;
  transition: 0.35s ease;
  z-index: 999;
  background-color: var(--color-white);
}
.dm-submenu .submenu-title {
  display: block;
  font-size: 14px;
  padding: 8px 0;
  line-height: 1.67;
  color: var(--color-dark);
}
.dm-submenu li a {
  display: block;
  font-size: 14px;
  padding: 8px 0;
  line-height: 1.67;
  color: var(--color-dark);
}
.dm-submenu li > a:hover {
  color: var(--color-primary);
}
.dm-submenu ul {
  padding-left: 14px;
}
.dm-submenu .dm-submenu {
  left: 100%;
  top: 0;
}
.menu-wrapper .dm-menu.menu-vertical {
  max-width: 280px;
}
.dm-menu.menu-vertical {
  border-right: 1px solid var(--border-color);
}
.dm-menu.menu-vertical .dm-menu__item {
  padding-right: 15px;
  position: relative;
}
.dm-menu.menu-vertical .dm-menu__item:hover > .dm-submenu {
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}
.dm-menu.menu-vertical .dm-menu__item.has-submenu > .dm-menu__link:before {
  font-family: line awesome free;
  font-weight: 700;
  content: "\f105";
  margin-left: 5px;
  margin-top: 1px;
  float: right;
}
.dm-menu.menu-vertical
  .dm-menu__item.has-submenu
  > .dm-menu__link:hover
  .dm-menu__icon,
.dm-menu.menu-vertical
  .dm-menu__item.has-submenu
  > .dm-menu__link:hover
  .dm-menu__text,
.dm-menu.menu-vertical
  .dm-menu__item.has-submenu
  > .dm-menu__link:hover:before {
  color: var(--color-primary);
}
.dm-menu.menu-vertical .dm-menu__item .dm-menu__link:hover .dm-menu__icon svg,
.dm-menu.menu-vertical .dm-menu__item .dm-menu__link:hover .dm-menu__text,
.dm-menu.menu-vertical .dm-menu__item .dm-menu__link:hover:before {
  color: var(--color-primary);
}
.dm-menu.menu-vertical .dm-submenu {
  left: 100%;
  top: 0;
}
.dm-menu.menu-collapsable .dm-menu__item.has-submenu > .dm-menu__link:before {
  content: "\f107";
}
.dm-menu.menu-collapsable .dm-submenu {
  position: static;
  display: block;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  padding: 0 0 0 20px;
  background: 0 0;
}
.dm-menu.menu-collapsable .dm-submenu .dm-menu__link {
  min-width: auto;
}
.mobile-nav-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  width: 280px;
  height: 100%;
  -webkit-transform: translateX(280px);
  -ms-transform: translateX(280px);
  transform: translateX(280px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  padding-top: 130px;
  z-index: 22;
  -webkit-box-shadow: 0 0 30px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 0 30px rgba(var(--light-gray-rgba), 0.15);
  background-color: var(--bg-white);
}
.mobile-nav-wrapper.show {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.mobile-nav-wrapper .dm-menu {
  padding: 0 15px;
}
.mobile-nav-wrapper .dm-menu.menu-horizontal {
  border-bottom: 0;
}
.mobile-nav-wrapper
  .dm-menu.menu-horizontal
  .dm-menu__item
  .dm-menu__item
  > .dm-menu__link:before {
  content: "\f107";
}
.mobile-nav-wrapper .dm-menu__item {
  padding: 0;
  margin-right: 0 !important;
}
.mobile-nav-wrapper .dm-menu__item.has-submenu > .dm-menu__link:before {
  margin: 0 10px 0 0;
}
.mobile-nav-wrapper .dm-submenu {
  padding: 0 0 0 15px;
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleY(1);
  -ms-transform: scaleY(1);
  transform: scaleY(1);
  position: static;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.mobile-nav-wrapper .dm-submenu .dm-submenu li:first-child a {
  padding-top: 0;
}
.mobile-nav-wrapper .nav-close {
  position: absolute;
  top: 95px;
  left: 12px;
  color: var(--color-danger);
}
.menu-mob-trigger {
  max-width: 40px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.menu-mob-trigger span {
  display: block;
  width: 30px;
  height: 2px;
  background-color: var(--color-gray-x);
}
.menu-mob-trigger span:not(:last-child) {
  margin-bottom: 4px;
}
.message-wrapper {
  position: fixed;
  top: 90px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-pop-message {
  min-width: 200px;
  margin-bottom: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 16px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.2);
  background-color: var(--color-white);
}
.dm-pop-message.message-primary .dm-pop-message__icon i,
.dm-pop-message.message-primary .dm-pop-message__icon span,
.dm-pop-message.message-primary .dm-pop-message__icon svg {
  color: var(--color-primary);
}
.dm-pop-message.message-success .dm-pop-message__icon i,
.dm-pop-message.message-success .dm-pop-message__icon span,
.dm-pop-message.message-success .dm-pop-message__icon svg {
  color: var(--color-success);
}
.dm-pop-message.message-warning .dm-pop-message__icon i,
.dm-pop-message.message-warning .dm-pop-message__icon span,
.dm-pop-message.message-warning .dm-pop-message__icon svg {
  color: var(--color-warning);
}
.dm-pop-message.message-danger .dm-pop-message__icon i,
.dm-pop-message.message-danger .dm-pop-message__icon span,
.dm-pop-message.message-danger .dm-pop-message__icon svg {
  color: var(--color-danger);
}
.dm-pop-message__icon {
  margin-right: 8px;
}
.dm-pop-message__icon i,
.dm-pop-message__icon span,
.dm-pop-message__icon svg {
  color: var(--color-gray);
}
.dm-pop-message__text p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--color-dark);
}
.btn-message {
  height: 48px;
}
.dm-tag-wrap h6 {
  font-size: 14px;
  margin-bottom: 15px;
}
.dm-tag-wrap .tag-box {
  margin: -4px;
}
.dm-tag {
  font-size: 11px;
  font-weight: 500;
  display: inline-block;
  line-height: 1.4;
  padding: 4px 10px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: var(--color-white);
  margin: 4px;
}
.dm-tag.tag-primary {
  font-weight: 600;
  background-color: var(--color-primary);
}
.dm-tag.tag-primary.tag-transparented {
  background-color: rgba(var(--color-primary-rgba), 0.15);
  color: var(--color-primary);
}
.dm-tag.tag-secondary {
  font-weight: 600;
  background-color: var(--color-secondary);
}
.dm-tag.tag-secondary.tag-transparented {
  background-color: rgba(var(--color-secondary-rgba), 0.15);
  color: var(--color-secondary);
}
.dm-tag.tag-success {
  font-weight: 600;
  background-color: var(--color-success);
}
.dm-tag.tag-success.tag-transparented {
  background-color: rgba(1, 184, 26, 0.15);
  color: var(--color-success);
}
.dm-tag.tag-info {
  font-weight: 600;
  background-color: var(--color-info);
}
.dm-tag.tag-info.tag-transparented {
  background-color: rgba(var(--color-info-rgba), 0.15);
  color: var(--color-info);
}
.dm-tag.tag-warning {
  font-weight: 600;
  background-color: var(--color-warning);
}
.dm-tag.tag-warning.tag-transparented {
  background-color: rgba(var(--color-warning-rgba), 0.15);
  color: var(--color-warning);
}
.dm-tag.tag-danger {
  font-weight: 600;
  background-color: var(--color-danger);
}
.dm-tag.tag-danger.tag-transparented {
  background-color: rgba(var(--color-danger-rgba), 0.15);
  color: var(--color-danger);
}
.dm-tag.tag-light {
  font-weight: 500;
  color: var(--color-gray);
  background-color: var(--bg-deep);
}
.dm-tag.tag-light.tag-transparented {
  background-color: rgba(var(--light-gray-rgba), 0.15);
  color: var(--color-light);
}
.dm-tag i,
.dm-tag span {
  font-size: 10px;
  color: var(--color-gray);
}
.dm-tag a {
  color: var(--color-gray);
}
.dm-tag-mode .select2-search__field {
  text-align: left;
}
.ui-datepicker {
  display: none;
  background-color: var(--color-white);
  -webkit-box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  box-shadow: 0 0.125rem 0.3rem rgba(0, 0, 0, 0.2);
  margin-top: 0.25rem;
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.ui-datepicker-month {
  border: none;
  color: #666d92;
}
.ui-datepicker-year {
  border: none;
  color: #666d92;
}
.ui-datepicker-calendar table {
  border-collapse: collapse;
  border-spacing: 0;
}
.ui-datepicker-calendar thead th {
  padding: 0.25rem 0;
  text-align: center;
  font-size: 0.8rem;
  font-weight: 400;
  color: var(--color-dark);
}
.ui-datepicker-calendar tbody td {
  width: 2.5rem;
  text-align: center;
  padding: 0;
}
.ui-datepicker-calendar tbody td a {
  display: block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 2rem;
  -webkit-transition: 0.3s all;
  -o-transition: 0.3s all;
  transition: 0.3s all;
  color: var(--color-dark);
  font-size: 0.875rem;
  text-decoration: none;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border: 1px solid transparent;
  margin: 4px;
}
.ui-datepicker-calendar tbody td a:hover {
  border: 1px solid var(--color-primary);
}
.ui-datepicker-calendar tbody td a.ui-state-active {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.ui-datepicker-calendar tbody td a.ui-state-highlight {
  background: var(--color-primary);
  color: var(--color-white);
}
.ui-datepicker-header a span {
  display: none;
}
.ui-datepicker-header a.ui-corner-all {
  cursor: pointer;
  position: absolute;
  top: 0;
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  -webkit-border-radius: 0.5rem;
  border-radius: 0.5rem;
  color: var(--color-dark);
  font-size: calc(16.2px + 0.15vw);
}
.ui-datepicker-header a.ui-datepicker-prev {
  left: 0;
}
.ui-datepicker-header a.ui-datepicker-prev::after {
  font-family: line awesome free;
  font-weight: 900;
  content: "\f104";
}
.ui-datepicker-header a.ui-datepicker-next {
  right: 0;
}
.ui-datepicker-header a.ui-datepicker-next::after {
  font-family: line awesome free;
  font-weight: 900;
  content: "\f105";
}
.ui-datepicker-title {
  text-align: center;
  line-height: 2rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
  font-weight: 500;
  padding-bottom: 0.25rem;
}
.ui-datepicker-week-col {
  color: var(--color-dark);
  font-weight: 400;
  font-size: 0.75rem;
}
.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-calendar {
  width: 100%;
}
.date-picker__next,
.date-picker__prev {
  width: 45px;
  height: 45px;
}
.dm-date-picker input {
  color: var(--color-lighten);
}
.dm-date-picker .date-picker-disabled {
  cursor: not-allowed;
  background-color: var(--bg-lighter);
}
.dm-date-ranger {
  margin: 20px;
  border: 1px solid var(--border-light);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.dm-date-ranger.date-picker-disabled {
  background-color: var(--bg-lighter);
  cursor: not-allowed;
}
.dm-date-ranger.date-picker-disabled .divider {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  height: 42px;
  line-height: 2.5;
  background-color: transparent;
}
.dm-date-ranger.date-picker-disabled .form-control {
  -webkit-border-radius: 0;
  border-radius: 0;
  cursor: not-allowed;
}
.dm-date-ranger.date-picker-disabled .form-control:disabled {
  background-color: transparent;
}
.dm-date-ranger .form-group {
  width: 100%;
}
.dm-date-ranger .form-control {
  border: 0;
  text-align: center;
}
.dm-date-ranger .date-picker-icon {
  position: absolute;
  right: 12px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 50%;
  pointer-events: none;
}
.dm-date-ranger .date-picker-icon img,
.dm-date-ranger .date-picker-icon svg {
  width: 16px;
  color: var(--color-light);
}
.dm-nav-controller.date-picker-size .date-picker-list {
  margin-top: 35px;
}
.date-picker-list {
  margin: -20px;
}
.date-picker-list .dm-date-picker {
  margin: 20px;
}
.ui-datepicker-multi .ui-datepicker-group {
  float: left;
  width: 48%;
}
.ui-datepicker-multi .ui-datepicker-group.ui-datepicker-group-first {
  margin-right: 2%;
}
.dm-rating-wrap .rater {
  line-height: 1;
}
.dm-rating-wrap .rater-text {
  font-weight: 500;
  display: inline-block;
  font-size: 14px;
  margin-left: 6px;
  color: var(--color-gray);
}
.dm-rating-wrap .rater-text .rate-count {
  margin-right: 3px;
}
.rater,
.rating-basic,
.rating-half-star,
.rating-readOnly {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.jq-star {
  margin-right: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.jq-star .jq-star-svg {
  padding-left: 0;
}
.jq-star polygon {
  stroke: none !important;
}
.dm-steps__item {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  z-index: 10;
}
.dm-steps__item:last-child {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  padding-right: 0;
}
.dm-steps__item:first-child {
  padding-left: 0;
}
.dm-steps__item.finished .dm-steps__icon {
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.dm-steps__item.finished .dm-steps__line {
  background-color: var(--color-primary);
}
.dm-steps__item.active .dm-steps__icon {
  color: var(--color-white);
  border: 1px solid var(--color-primary);
  background-color: var(--color-primary);
}
.dm-steps__item.active .dm-steps__line {
  background-color: var(--color-primary);
}
.dm-steps__content {
  padding: 0 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  background-color: var(--color-white);
}
.dm-steps__line {
  position: absolute;
  width: 100%;
  height: 1px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  background-color: var(--border-light);
  z-index: -1;
}
.dm-steps__icon {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border-light);
  color: var(--color-lighten);
}
.dm-steps__text {
  font-weight: 500;
  display: inline-block;
  margin-left: 10px;
  color: var(--color-dark);
}
.statistics-item {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.statistics-item__title {
  font-size: 14px;
  color: var(--color-light);
}
.statistics-item__number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0 0;
  font-size: calc(16.4px + 0.3vw);
  color: var(--color-dark);
}
.statistics-item__number i,
.statistics-item__number span,
.statistics-item__number svg {
  font-size: 21px;
  margin-right: 4px;
}
.statistics-item__number i,
.statistics-item__number img,
.statistics-item__number svg {
  margin-left: -3px;
}
.statistics-item__number .number-fraction {
  margin: 0;
  font-size: 14px;
}
.statistics-item__action {
  margin-top: 16px;
}
.statistics-item__action a {
  display: inline-block;
}
.statistics-item.statistics-success .statistics-item__number {
  color: var(--color-success);
}
.statistics-item.statistics-danger .statistics-item__number {
  color: var(--color-danger);
}
.statistics-item .text-gray {
  letter-spacing: 1.5px;
  margin-left: 3px;
  font-size: 14px;
}
.statistics-countdown__title {
  font-size: 14px;
  color: var(--color-light);
}
.statistics-countdown ul {
  margin-top: 10px;
}
.statistics-countdown ul li {
  display: inline-block;
}
.statistics-countdown__time {
  font-size: calc(16.4px + 0.3vw);
  color: var(--color-dark);
}
.spin-list .dm-spin-dots:not(:last-child) {
  margin-right: 20px;
}
.dm-spin-dots {
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-animation: rotate 1.2s infinite linear;
  animation: rotate 1.2s infinite linear;
}
.dm-spin-dots .spin-dot {
  -webkit-transform: scale(0.75);
  -ms-transform: scale(0.75);
  transform: scale(0.75);
  position: absolute;
  opacity: 0.3;
  -webkit-animation: spinMove 1s infinite linear alternate;
  animation: spinMove 1s infinite linear alternate;
}
.dm-spin-dots .spin-dot:nth-child(1) {
  left: 0;
  top: 0;
}
.dm-spin-dots .spin-dot:nth-child(2) {
  right: 0;
  top: 0;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.dm-spin-dots .spin-dot:nth-child(3) {
  left: 0;
  bottom: 0;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}
.dm-spin-dots .spin-dot:nth-child(4) {
  right: 0;
  bottom: 0;
  -webkit-animation-delay: 0.7s;
  animation-delay: 0.7s;
}
.spin-embadded {
  position: relative;
}
.spin-embadded .alert {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: 0.35s ease;
  -o-transition: 0.35s ease;
  transition: 0.35s ease;
}
.spin-embadded .alert-content {
  padding: 10px 2px;
  -webkit-transition: 0.35s ease;
  -o-transition: 0.35s ease;
  transition: 0.35s ease;
}
.spin-embadded .alert-heading {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 15px;
  color: var(--color-gray);
}
.spin-embadded p {
  color: var(--color-light);
}
.spin-embadded .loaded-spin {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.spin-embadded.spin-active .alert-primary {
  background: rgba(var(--color-primary-rgba), 0.06);
  border-color: rgba(231, 232, 253, 0.0235294118);
}
.spin-embadded.spin-active .alert-content {
  opacity: 0.4;
}
.spin-embadded.spin-active .loaded-spin {
  display: inline-block;
}
.spin-embadded .embadden-spin-control {
  margin-top: 10px;
}
.spin-embadded .embadden-spin-control span {
  display: inline-block;
  margin-right: 6px;
  font-size: 14px;
  color: var(--color-gray);
}
.dm-spin-dots.spin-md {
  font-size: calc(16.8px + 0.6vw);
}
.dm-spin-dots.spin-md .spin-dot {
  width: 8px;
  height: 8px;
}
.dm-spin-dots.spin-lg {
  font-size: calc(17.4px + 1.05vw);
}
.dm-spin-dots.spin-lg .spin-dot {
  width: 10px;
  height: 10px;
}
.dm-upload .btn-upload {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.dm-upload .btn-upload img,
.dm-upload .btn-upload svg {
  width: 16px;
  height: 16px;
}
.dm-upload .upload-one {
  display: none;
}
.dm-upload__file {
  margin-top: 12px;
}
.dm-upload__file li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 14px;
  padding: 3px 6px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: 0.35s ease;
  -o-transition: 0.35s ease;
  transition: 0.35s ease;
}
.dm-upload__file li.danger .file-name i,
.dm-upload__file li.danger .file-name img,
.dm-upload__file li.danger .file-name span,
.dm-upload__file li.danger .file-name svg {
  color: var(--color-danger);
}
.dm-upload__file li:hover {
  background-color: var(--bg-lighter);
}
.dm-upload__file li:hover .btn-delete {
  opacity: 1;
  visibility: visible;
}
.dm-upload__file li:not(:last-child) {
  margin-bottom: 10px;
}
.dm-upload__file li .file-name {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-upload__file li .file-name i,
.dm-upload__file li .file-name img,
.dm-upload__file li .file-name span,
.dm-upload__file li .file-name svg {
  font-size: calc(16.2px + 0.15vw);
  margin-right: 7px;
  color: var(--color-gray);
}
.dm-upload__file li .file-name .name-text {
  margin-top: -4px;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-primary);
}
.dm-upload__file li .btn-delete {
  font-size: calc(16.2px + 0.15vw);
  opacity: 0;
  visibility: hidden;
  color: var(--color-danger);
}
.dm-upload-avatar .avatrSrc {
  max-width: 100px;
}
.dm-upload-avatar .upload-avatar-input {
  display: none;
}
.dm-upload .avatar-up .upload-avatar-input {
  display: none;
}
.page-title-wrap {
  padding: 20px 25px 17px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.page-title-wrap .dm-breadcrumb {
  margin-bottom: 18px;
}
.page-title-wrap .dm-breadcrumb__item:first-child a {
  color: var(--color-light);
}
.page-title-wrap .dm-breadcrumb__item:first-child a:hover {
  color: var(--color-primary);
}
.page-title-wrap .dm-breadcrumb__item:last-child span {
  color: var(--color-gray);
}
.page-title-wrap .dm-breadcrumb__item a {
  color: var(--color-light);
}
.page-title-wrap .dm-breadcrumb__item a:hover {
  color: var(--color-primary);
}
.page-title__right .page-top-action {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
}
.page-title__right .page-top-action a {
  margin: 5px;
  min-height: 34px;
}
.page-title__right .page-top-action .btn-xs {
  padding: 0 1.134rem;
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
}
.page-title-wrap.wrapper-bordered {
  border: 1px solid var(--border-light);
}
.page-title-block {
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.page-title-block.bg-container {
  padding: 25px;
}
.page-title-block .page-title-bg {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: var(--bg-white);
}
.page-title__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.page-title__left a i,
.page-title__left a img,
.page-title__left a span,
.page-title__left a svg {
  width: 16px;
  color: #000;
}
.page-title__left a i {
  font-size: calc(16.4px + 0.3vw);
  margin-top: 3px;
  margin-right: 9px;
}
.page-title__left .title-text {
  font-size: calc(16.6px + 0.45vw);
  font-weight: 600;
  color: var(--color-dark);
}
.page-title__left .title-text {
  margin-left: 8px;
}
.page-title__left .sub-title {
  font-size: 14px;
  margin-left: 10px;
  color: var(--color-lighten);
}
.page-info {
  padding: 0 30px 12px;
}
.page-info__item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 32%;
  flex: 0 0 32%;
}
.page-info__single {
  margin-bottom: 8px;
}
.page-info__single span {
  font-size: 14px;
  color: var(--color-dark);
}
.page-info__single span.info-text {
  color: var(--color-gray);
}
.page-info__single span.info-text.color-primary {
  color: var(--color-primary);
}
.page-info__single .info-list span {
  color: var(--color-light);
}
.dm-skeleton__title {
  width: 30%;
  height: 16px;
  background: var(--border-color);
  margin-bottom: 15px;
}
.dm-skeleton__list li {
  height: 16px;
  width: 100%;
  margin-bottom: 15px;
}
.dm-skeleton__list li:last-child {
  width: 40%;
  margin-bottom: 0;
}
.shimmer-effect {
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#f6f7f8),
    color-stop(20%, #edeef1),
    color-stop(40%, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-image: -o-linear-gradient(
    left,
    #f6f7f8 0,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-image: linear-gradient(
    to right,
    #f6f7f8 0,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  -webkit-background-size: 800px 104px;
  background-size: 800px 104px;
  display: inline-block;
  position: relative;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmerEffect;
  animation-name: shimmerEffect;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
.custom-radio input {
  display: none;
}
.custom-radio label {
  display: inline-block !important;
  font-size: 14px;
}
.custom-radio.disabled input[type="radio"] + label {
  color: var(--color-lighten);
}
.custom-radio.disabled input[type="radio"]:checked + label:after {
  background: var(--border-color);
  border: 1px solid var(--border-color);
}
.custom-radio.disabled label {
  cursor: not-allowed;
}
.custom-radio input[type="radio"] + label {
  min-width: 18px;
  min-height: 18px;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  line-height: 1.2;
  font-weight: 400;
  color: var(--color-gray);
}
.custom-radio input[type="radio"] + label:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
  border-width: 1px;
  border-style: solid;
  border: 1px solid var(--border-light);
  background-color: var(--color-white);
}
.custom-radio input[type="radio"] + label:before {
  position: absolute;
  width: 6px;
  min-width: 6px;
  min-height: 6px;
  max-height: 6px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  left: 6px;
  top: 50%;
  font-weight: 900;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  opacity: 0;
  -webkit-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  z-index: 2;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  background-color: var(--color-white);
}
.custom-radio input[type="radio"]:checked + label:after {
  background-color: var(--color-primary);
  border: 1px solid var(--color-primary);
}
.custom-radio input[type="radio"]:checked + label:before {
  opacity: 1;
  color: var(--color-white);
}
.radio-vertical-list .custom-radio:not(:last-child) {
  margin-bottom: 14px;
}
.radio-horizontal-list:not(:last-child) {
  margin-bottom: 14px;
}
.radio-horizontal-list .custom-radio {
  margin-right: 20px;
}
.radio-horizontal-list2
  .custom-radio
  input[type="radio"]:checked
  + label:after {
  background-color: var(--color-white);
}
.radio-horizontal-list2 .custom-radio input[type="radio"] + label:before {
  background-color: var(--color-primary);
}
.time-picker .input-container {
  width: 34%;
}
.time-picker .input-container .input-icon i,
.time-picker .input-container .input-icon img,
.time-picker .input-container .input-icon svg {
  width: 18px;
  height: 18px;
}
.time-picker .input-container .form-control-lg {
  height: 3rem;
}
.wickedpicker {
  z-index: 1;
  height: 145px;
}
.slider-wrapper {
  padding: 20px 0;
}
.slider-wrapper.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.slider-wrapper.disabled .dm-slider {
  background-color: var(--bg-normal);
}
.slider-wrapper.disabled .ui-slider-handle:after {
  border: 1px solid var(--border-color);
}
.dm-slider {
  height: 3px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: rgba(var(--color-primary-rgba), 0.2);
  position: relative;
}
.dm-slider .ui-slider-handle {
  position: absolute;
  top: -10px;
  width: 22px;
  height: 22px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: var(--color-white);
}
.dm-slider .ui-slider-handle:after {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  content: "";
  border-width: 2px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid var(--color-primary);
  color: var(--color-white);
}
.dm-slider .ui-slider-handle.ui-state-active .tooltip-text,
.dm-slider .ui-slider-handle.ui-state-hover .tooltip-text {
  display: inline-block;
}
.dm-slider .tooltip-text {
  position: absolute;
  top: -40px;
  left: -4px;
  font-size: 13px;
  padding: 0 5px;
  line-height: 2.2;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: none;
  background-color: var(--color-dark);
  color: var(--color-white);
}
.slider-controller {
  margin-top: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.slider-controller span {
  margin-right: 6px;
  font-size: 14px;
  color: var(--color-gray);
}
.slider-controller .form-switch {
  display: inline;
}
.drawer-basic-wrap.show .dm-drawer {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.drawer-basic-wrap.basic .drawer-basic {
  display: block !important;
}
.drawer-basic-wrap.account.bottom,
.drawer-basic-wrap.account.left,
.drawer-basic-wrap.account.top {
  right: 0;
  left: auto;
}
.drawer-basic-wrap.account.bottom .dm-drawer,
.drawer-basic-wrap.account.left .dm-drawer,
.drawer-basic-wrap.account.top .dm-drawer {
  width: 570px;
  height: 100%;
  -webkit-transform: translateX(570px);
  -ms-transform: translateX(570px);
  transform: translateX(570px);
}
.drawer-basic-wrap.account .drawer-account {
  display: block !important;
  width: 730px;
}
.drawer-basic-wrap.profile.bottom,
.drawer-basic-wrap.profile.left,
.drawer-basic-wrap.profile.top {
  right: 0;
  left: auto;
}
.drawer-basic-wrap.profile.bottom .dm-drawer,
.drawer-basic-wrap.profile.left .dm-drawer,
.drawer-basic-wrap.profile.top .dm-drawer {
  width: 570px;
  height: 100%;
  -webkit-transform: translateX(570px);
  -ms-transform: translateX(570px);
  transform: translateX(570px);
}
.drawer-basic-wrap.profile .drawer-profile {
  display: block !important;
  width: 730px;
}
.drawer-placement-wrap .radio-horizontal-list {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 0;
}
.drawer-basic-wrap.left.show .dm-drawer {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.drawer-basic-wrap.left .dm-drawer {
  right: auto;
  left: 0;
  -webkit-transform: translateX(-320px);
  -ms-transform: translateX(-320px);
  transform: translateX(-320px);
}
.drawer-basic-wrap.top.show .dm-drawer {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.drawer-basic-wrap.top .dm-drawer {
  height: 320px;
  width: 100%;
  -webkit-transform: translate(0, -320px);
  -ms-transform: translate(0, -320px);
  transform: translate(0, -320px);
}
.drawer-basic-wrap.bottom.show .dm-drawer {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.drawer-basic-wrap.bottom .dm-drawer {
  height: 320px;
  width: 100%;
  top: auto;
  bottom: 0;
  -webkit-transform: translate(0, 320px);
  -ms-transform: translate(0, 320px);
  transform: translate(0, 320px);
}
.dm-drawer {
  position: fixed;
  width: 320px;
  height: 100%;
  top: 0;
  right: 0;
  -webkit-transform: translateX(320px);
  -ms-transform: translateX(320px);
  transform: translateX(320px);
  opacity: 0;
  visibility: hidden;
  z-index: 9998;
  -webkit-transition: all 0.35s ease-in;
  -o-transition: all 0.35s ease-in;
  transition: all 0.35s ease-in;
  background-color: var(--color-white);
}
.dm-drawer__header {
  padding: 18px 25px;
  border-bottom: 1px solid var(--border-light);
}
.dm-drawer__header .drawer-title {
  color: var(--color-dark);
}
.dm-drawer__body {
  padding: 20px 24px 24px;
}
.dm-drawer__body p {
  font-size: 14px;
  color: var(--color-dark);
}
.area-drawer-wrapper {
  text-align: center;
}
.area-drawer-wrapper h1,
.area-drawer-wrapper h2,
.area-drawer-wrapper h3,
.area-drawer-wrapper h4,
.area-drawer-wrapper h5,
.area-drawer-wrapper h6 {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark);
}
.area-drawer-wrapper .drawer-btn {
  margin-top: 20px;
}
.area-drawer-wrapper .area-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(26, 27, 41, 0.5);
  left: 0;
  top: 0;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 9988;
  opacity: 0;
  visibility: hidden;
}
.area-drawer-wrapper .area-overlay.show {
  opacity: 1;
  visibility: visible;
}
.area-drawer-wrapper .area-drawer.show .dm-drawer {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.area-drawer-wrapper .dm-drawer {
  position: absolute;
}
.dm-drawer .drawer-content h6 {
  font-weight: 500;
  margin-bottom: 30px;
}
.dm-drawer .profile-info-box__single:not(:last-child) {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}
.dm-drawer .profile-info-box__single h6 {
  margin-bottom: 15px;
}
.dm-drawer .profile-info__single {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
}
.dm-drawer .profile-info__single:not(:last-child) {
  margin-bottom: 8px;
}
.dm-drawer .profile-info__label {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark);
}
.dm-drawer .profile-info__text {
  font-size: 14px;
  font-weight: 400;
}
.drawer-profile-box {
  border: 1px solid var(--border-light);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.drawer-profile-box__single {
  padding: 25px;
}
.drawer-profile-box__single:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.drawer-profile-box__single .profile-author img {
  max-width: 32px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.drawer-profile-box__single .profile-text {
  margin-left: 10px;
}
.drawer-profile-box__single .profile-text .author-name {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: var(--color-gray);
}
.drawer-profile-box__single .profile-text .author-title {
  font-size: 12px;
  margin-top: 2px;
  margin-bottom: 0;
  color: var(--color-lighten);
}
.drawer-profile-box__single .drawer-trigger {
  font-size: 14px;
}
.drawer-multiLevel.level-one.show {
  color: var(--color-white);
}
.drawer-multiLevel.level-one.show .drawer-level-one {
  display: block !important;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.drawer-multiLevel.level-two.show {
  color: var(--color-white);
}
.drawer-multiLevel.level-two.show .drawer-level-two {
  display: block !important;
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
  z-index: 9999;
}
.drawer-multiLevel .dm-drawer.drawer-level-one {
  width: 570px;
}
.dm-tab .nav-tabs {
  position: relative;
  margin-bottom: 18px;
}
.dm-tab .nav-tabs:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  content: "";
  background-color: var(--bg-lighter);
}
.dm-tab .nav-item:not(:last-child) {
  margin-right: 12px;
}
.dm-tab .nav-link {
  position: relative;
  font-size: 14px;
  color: var(--color-dark);
  z-index: 1;
}
.dm-tab .nav-link.active:after {
  opacity: 1;
  visibility: visible;
}
.dm-tab .nav-link:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  content: "";
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  background-color: var(--color-primary);
}
.dm-tab .tab-content p {
  font-size: 14px;
}
.dm-tab.tab-vertical {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 200px;
  overflow: hidden;
}
.dm-tab.tab-vertical .nav-tabs {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  margin: 0 20px 0 0;
  overflow-y: scroll;
  min-width: 80px;
}
.dm-tab.tab-vertical .nav-tabs:after {
  width: 2px;
  height: 100%;
  left: auto;
  right: 0;
}
.dm-tab.tab-vertical .nav-tabs .nav-item {
  margin-right: 0;
}
.dm-tab.tab-vertical .nav-tabs .nav-link:after {
  width: 3px;
  height: 100%;
  left: auto;
  right: 0;
}
.dm-tab.tab-vertical .tab-content {
  -webkit-box-flex: 19;
  -ms-flex: 19;
  flex: 19;
}
.dm-tab.tab-small .nav-tabs {
  margin-bottom: 12px;
}
.dm-tab.tab-small .nav-link {
  padding: 6px 12px;
}
.dm-tab.tab-large .nav-tabs {
  margin-bottom: 25px;
}
.dm-tab.tab-large .nav-link {
  padding: 15px 12px;
}
.nav-controller-slide .nav-controller-content {
  margin-top: 20px;
}
.dm-select-list .dm-select + .dm-select {
  margin-left: 10px;
}
.dm-select {
  min-width: 110px;
}
.dm-select .select2-selection {
  height: 42px;
}
.dm-select
  .select2-selection[aria-labelledby="select2-select-search-container"] {
  min-width: 200px;
}
.dm-select .select2-selection__arrow {
  display: none;
}
.dm-select.disabled .form-control {
  border: 1px solid var(--border-deep);
  background-color: var(--bg-lighter);
}
.dm-select.disabled select {
  cursor: not-allowed;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--color-lighten);
}
.dm-select {
  position: relative;
  font-weight: 900;
}
.dm-select:after {
  font-size: 10px;
  font-family: line awesome free;
  position: absolute;
  right: 15px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "\f107";
}
.dm-select select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.size-control-nav .nav-item {
  margin-bottom: 16px;
}
.size-control-nav .nav-item:first-child .nav-link {
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
.size-control-nav .nav-item:last-child .nav-link {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-right-width: 1px;
}
.size-control-nav .nav-item .nav-link {
  font-size: 14px;
  font-weight: 500;
  padding: 0.469rem 0.8rem;
  border-width: 1px;
  border-right-width: 0;
  border-style: solid;
  color: var(--color-light);
  border: 1px solid var(--border-deep);
}
.size-control-nav .nav-item .nav-link.active {
  border: 1px solid var(--color-primary);
}
.select-size-content {
  margin-top: 40px !important;
}
.select-size-content__single {
  margin: -20px 0;
}
.select-size-content__single .dm-select {
  margin: 20px 0;
}
.select2-dropdown {
  background: var(--color-white);
  -webkit-box-shadow: 0 5px 30px rgba(27, 30, 43, 0.15);
  box-shadow: 0 5px 30px rgba(27, 30, 43, 0.15);
  padding: 15px 0;
  z-index: 1;
  border: none;
  z-index: 33;
}
.select2-dropdown .select2-results__option {
  font-size: 14px;
  display: block;
  line-height: 1.1;
  font-weight: 400;
  padding: 10px 25px;
  color: var(--color-gray);
}
.select2-dropdown .select2-results__option {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background: var(--white-06);
  color: var(--color-primary);
}
.custom-select.is-invalid,
.was-validated .custom-select:invalid,
select.select-arrow-none {
  background: 0 0;
}
.custom-select.is-invalid::-ms-expand,
.was-validated .custom-select:invalid::-ms-expand,
select.select-arrow-none::-ms-expand {
  display: none;
}
.select-px-15
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.select-px-15
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 15px;
}
.select2 {
  width: 100% !important;
}
.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.select2-container--default .select2-selection--multiple:focus,
.select2-container--default .select2-selection--single:focus {
  outline: 0;
}
.select2-container--default
  .select2-search--inline
  .select2-search__field::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.select2-container--default
  .select2-search--inline
  .select2-search__field::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.select2-container--default
  .select2-search--inline
  .select2-search__field::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.select2-container--default
  .select2-search--inline
  .select2-search__field::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  top: 50%;
  right: 0;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow
  b {
  display: none;
}
.select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after {
  content: "\f107";
  font-family: line awesome free;
  font-weight: 900;
  color: var(--color-gray);
  font-size: 12px;
}
.select2-container--default.select2-container--open
  .select2-selection--single
  .select2-selection__arrow:after {
  content: "\f106";
  font-family: line awesome free;
  font-weight: 900;
  color: var(--color-gray);
  font-size: 12px;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 20px;
}
.select2-container .select2-selection--single .select2-selection__rendered,
.select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.select2-results__option {
  text-transform: capitalize;
}
.select2-container .select2-selection--single .select2-selection__rendered {
  width: 100%;
}
.select2-container .select2-selection--single .select2-selection__clear {
  right: 0;
  display: none;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  margin-top: 0;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 0;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background: #eff0f3;
  border: none;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 24px;
  font-size: 11px;
  line-height: 1.2727272727;
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__rendered {
  padding: 0 20px;
}
.select2-container--default
  .select2-selection--multiple
  .select2-selection__clear {
  margin-top: 0;
  padding: 0;
}
.select2-container--default.select2-container--focus
  .select2-selection--multiple,
.select2-container--default.select2-container--focus
  .select2-selection--single {
  border: 1px solid var(--color-primary);
}
.tooltip .arrow {
  position: relative;
  width: 12px;
  height: 8px;
  border-left: 1px solid transparent;
  border-bottom: 1px solid var(--border-light);
}
.tooltip .arrow:before {
  display: block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-right: 3px solid transparent;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-left: 1px solid var(--border-light);
  border-top: 1px solid var(--border-light);
  border-bottom: 3px solid transparent;
  -webkit-transform: rotate(45deg) skew(10deg, 10deg);
  -ms-transform: rotate(45deg) skew(10deg, 10deg);
  transform: rotate(45deg) skew(10deg, 10deg);
  left: 0;
  bottom: -6px;
  color: var(--color-white);
}
.tooltip .tooltip-inner {
  border: 1px solid var(--border-light);
  -webkit-box-shadow: 0 8px 15px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 8px 15px rgba(var(--light-gray-rgba), 0.15);
}
.drag-drop-wrap {
  margin: 20px 0;
}
.drag-drop-wrap .item {
  min-width: 120px; /*   chnages */
}
.drag-drop-wrap .item i,
.drag-drop-wrap .item img,
.drag-drop-wrap .item svg {
  color: var(--color-lighten);
}
.drag-drop-wrap .item-info {
  margin-left: 30px;
}
.drag-drop-wrap .item_title h6 {
  margin-left: 12px;
  font-size: 14px;
}
.drag-drop-wrap .item_title h6 a {
  color: var(--color-dark);
}
.drag-drop-wrap .drag-drop table tr {
  cursor: move;
}
.drag-drop-wrap .drag-drop table.table-basic tr td {
  background-color: transparent;
}
.drag-drop-wrap .date {
  min-width: 150px;
}
.application-task .application-task-icon {
  -webkit-border-radius: 12px;
  border-radius: 12px;
  margin-right: 20px;
}
.application-task .application-task-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.about-projects__details p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.about-projects ul {
  margin-top: 35px;
  margin-bottom: -10px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.about-projects ul li {
  margin-top: 10px;
  margin-bottom: 10px;
}
.about-projects ul li:not(:last-child) {
  margin-right: 80px;
}
.acButton {
  text-transform: capitalize;
  padding: 3px 15px;
  margin: 4px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.acButton img,
.acButton svg {
  width: 14px;
  margin-right: 7px;
}
.project-task.new .table {
  margin-bottom: 7px;
}
.project-task.new .table > :not(caption) > * > * {
  padding: 10.5px 16px;
}
.project-task.new .table tbody tr td:first-child {
  padding-left: 0;
}
.project-task-list--active-user td {
  padding-left: 0;
}
.project-task-list--active-user
  .custom-checkbox
  input[type="checkbox"]
  + label {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-gray);
}
.project-task-list--active-user .project-task-list__right ul li a img,
.project-task-list--active-user .project-task-list__right ul li a svg {
  color: var(--color-light);
  width: 14px;
}
.project-task-list--active-user .project-task-list__right ul li a i {
  font-size: 18px;
  color: var(--color-light);
}
.project-task-list--active-user .project-task-list__right ul li a.cross svg {
  width: 18px;
}
.project-task-list--active-user
  .project-task-list__right
  ul
  li
  a:hover.edit
  svg {
  color: var(--color-info);
}
.project-task-list--active-user
  .project-task-list__right
  ul
  li
  a:hover.cross
  svg {
  color: var(--color-danger);
}
.project-task-list__right ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-task-list__right ul li:not(:last-child) {
  margin-right: 20px;
}
.project-task-list__right ul li .time-completed {
  width: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-task-list__right ul li button {
  height: unset;
}
.project-task-list__right ul li button img,
.project-task-list__right ul li button svg {
  width: 22px;
  color: var(--color-lighten);
}
.project-task-list__right ul li a {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-task-list__right ul li a img,
.project-task-list__right ul li a svg {
  color: var(--color-light);
  width: 14px;
}
.project-task-list__right ul li a i {
  color: var(--color-light);
  font-size: 18px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}
.project-task-list__right ul li a i.uil-times {
  font-size: 22px;
}
.project-task-list__right ul li a:hover.edit i {
  color: var(--color-info);
}
.project-task-list__right ul li a:hover.cross i {
  color: var(--color-danger);
}
.project-task .ptl--hover,
.todo-task .ptl--hover {
  padding: 0;
  white-space: nowrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.project-task .ptl--hover td,
.todo-task .ptl--hover td {
  padding: 9px 0;
  vertical-align: middle;
}
.project-task .ptl--hover td:first-child,
.todo-task .ptl--hover td:first-child {
  padding-left: 25px;
}
.project-task .ptl--hover td:last-child,
.todo-task .ptl--hover td:last-child {
  padding-right: 25px;
  float: right;
}
.project-task .ptl--hover:hover,
.todo-task .ptl--hover:hover {
  -webkit-box-shadow: 0 15px 50px var(--shadow4);
  box-shadow: 0 15px 50px var(--shadow4);
}
.project-task
  .ptl--hover
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after,
.todo-task
  .ptl--hover
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
}
input[type="checkbox"]:checked + label.strikethrough {
  text-decoration: line-through;
}
.task-list-btn button {
  font-weight: 500;
  font-size: 12px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border: none;
  width: 100%;
  padding: 13px 20px;
  background-color: rgba(var(--color-primary-rgba), 0.08);
}
.task-list-btn button img,
.task-list-btn button svg {
  margin-right: 5px;
  width: 18px;
}
.project-task-list--todo td:first-child {
  padding-left: 0;
}
.project-task-list--todo td:last-child {
  padding-right: 0;
}
.project-task-list--todo td .custom-checkbox input[type="checkbox"] + label {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}
.project-task-list--todo
  td
  .custom-checkbox
  input[type="checkbox"]
  + label
  span {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-light);
  pointer-events: none;
  margin-top: 4px;
}
.project-task-list .event-Wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.project-task-list .event-Wrapper .event-wrapper-item {
  width: 60px;
  height: 60px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--color-white);
  margin-right: 15px;
  font-size: 15px;
  padding: 0 5px;
}
.project-task-list .event-Wrapper .event-Wrapper__right h6 {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-dark);
}
.project-task-list .event-Wrapper .event-Wrapper__right span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.project-task-list.new .dropdown img,
.project-task-list.new .dropdown svg {
  width: 18px;
  height: 18px;
  color: var(--color-light);
}
.project-task-list--event .dropdown svg {
  width: 20px;
  height: 20px;
  color: var(--color-gray);
}
.project-task-list--event tbody tr td {
  vertical-align: middle;
  padding: 10px 0;
}
.project-task-list--event .project-task-list__right ul li a svg {
  width: 12px;
  color: var(--color-light);
}
.project-task-list--event .project-task-list__right ul li a:hover.edit svg {
  color: var(--color-info);
}
.project-task-list--event .project-task-list__right ul li a:hover.cross svg {
  color: var(--color-danger);
}
.project-task-list--event.card .card-header .card-extra {
  margin-right: -15px;
}
.card .card-body .project-task--todo .dropdown i,
.card .card-body .project-task--todo .dropdown svg {
  color: var(--color-light);
}
.files-area__img {
  margin-right: 20px;
}
.files-area__right p {
  text-transform: capitalize;
}
.files-area__right .dropdown img,
.files-area__right .dropdown svg {
  width: 22px;
  color: var(--color-lighten);
}
.breadcrumb-edit,
.breadcrumb-remove {
  text-transform: capitalize;
  padding: 5px 15px;
}
.breadcrumb-edit img,
.breadcrumb-edit svg,
.breadcrumb-remove img,
.breadcrumb-remove svg {
  width: 14px;
  margin-right: 8px;
}
.application-ui .action-btn a {
  height: 35px;
  padding: 0 15px;
}
.application-ui .btn-group-toggle .btn {
  height: 35px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: 500;
  border: 1px solid #c6d0dc;
  color: var(--color-light);
  background: var(--color-white);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.application-ui .btn-group-toggle .btn-check:checked + .btn-outline-secondary {
  background-color: var(--color-primary);
  color: var(--color-white);
  border: 1px solid var(--color-primary);
}
.media-ui__title {
  margin-right: 10px;
}
.media-ui__start {
  margin-right: 30px;
}
.new-member .modal-header .close {
  width: 34px;
  height: 34px;
  padding: 0;
  margin: 0;
  background: var(--bg-lighters);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  color: var(--color-light);
  text-shadow: none;
  opacity: 1;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: calc(16.2px + 0.15vw);
  border: 1px solid var(--border-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.new-member .modal-header .close:hover {
  color: var(--color-white);
  background-color: var(--color-primary);
  opacity: 0.7;
}
.new-member .modal-header .close:hover svg {
  color: var(--color-white);
}
.new-member .modal-header .close img,
.new-member .modal-header .close svg {
  width: 18px;
}
.new-member .modal-body {
  padding: 25px 30px 30px;
}
.new-member .textarea-group {
  margin-bottom: 28px;
}
.new-member .modal-header {
  padding: 12px 30px;
}
.app-ui-tab.ap-tab-main .nav-link:after {
  bottom: -1px;
  height: 1px;
}
.chat-wrapper .ap-tab-main .nav-link:after {
  bottom: 0;
}
.sidebar-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 99;
  margin: 0 30px 0 15px;
  height: 100%;
}
.sidebar-group .left-sidebar-wrap {
  background: var(--bg-white);
  width: 420px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
}
.sidebar-group .sidebar:not(.active) {
  display: none;
}
.chat {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
}
.chat-area {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.chat-body .chat-header {
  padding: 25px 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: 0 10px 20px rgba(173, 180, 210, 0.1490196078);
  box-shadow: 0 10px 20px rgba(173, 180, 210, 0.1490196078);
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  height: 95px;
}
.chat-body .chat-header .dropdown .dropdown-item {
  color: var(--color-gray);
  padding: 4px 25px;
  font-weight: 400;
}
.chat-body .chat-header .dropdown .dropdown-item img,
.chat-body .chat-header .dropdown .dropdown-item svg {
  color: var(--color-lighten);
  width: 14px;
  margin-right: 13px;
}
.chat-body .chat-header .dropdown img,
.chat-body .chat-header .dropdown svg {
  color: var(--color-light);
  width: 22px;
}
.chat-body .chat-header .media h5 {
  text-transform: capitalize;
}
.chat-wrapper {
  height: 646px;
  overflow: hidden;
}
.chat-wrapper form {
  background-color: var(--bg-lighter);
  -webkit-border-radius: 23px;
  border-radius: 23px;
  height: 46px;
  padding: 0 25px;
}
.chat-wrapper form input {
  background-color: var(--bg-lighter);
  padding: 8px 13px;
}
.chat-wrapper form input:focus {
  background-color: var(--bg-lighter);
}
.chat-wrapper form input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: var(--color-lighten);
}
.chat-wrapper form input::-moz-placeholder {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: var(--color-lighten);
}
.chat-wrapper form input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: var(--color-lighten);
}
.chat-wrapper form input::placeholder {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: var(--color-lighten);
}
.chat-wrapper form img,
.chat-wrapper form svg {
  width: 16px;
  color: var(--color-light);
}
.chat-wrapper .search-header,
.chat-wrapper .search-tab {
  padding: 0 25px;
}
.search-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow-y: auto;
  height: 495px;
  overflow: hidden auto;
}
.search-body .user-list {
  height: 495px;
  overflow: hidden auto;
  padding: 9px 0;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.search-body .user-list::-webkit-scrollbar {
  width: 11px;
}
.search-body .user-list::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.search-body .user-list::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.user-list-item {
  padding: 19px 0;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.user-list-item::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.user-list-item:hover:hover {
  -webkit-box-shadow: 0 15px 50px var(--shadow4);
  box-shadow: 0 15px 50px var(--shadow4);
}
.user-list-item__wrapper {
  padding: 0 25px;
  padding-right: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  position: relative;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.user-list-item .avatar {
  margin-right: 15px;
  display: inline-block;
  margin-bottom: 0;
}
.user-list-item .users-list-body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  min-width: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.user-list-item .users-list-body .last-chat-time {
  padding-left: 10px;
  position: relative;
  display: inline-grid;
  text-transform: uppercase;
}
.user-list-item .users-list-body .last-chat-time small {
  color: var(--color-light);
  font-size: 12px;
  line-height: 1.8333333333;
  font-weight: 400;
}
.user-list-item .users-list-body .last-chat-time .badge {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 0 6px;
  margin: 0;
  z-index: auto;
  min-width: 20px;
  height: 20px;
  font-weight: 400;
  line-height: 20px;
}
.user-list-item .users-list-body-title {
  min-width: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.user-list-item .users-list-body-title h6 {
  font-size: 14px;
  line-height: 1.4285714286;
  margin-bottom: 6px;
}
.user-list-item .users-list-body-title p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-light);
}
.badge-direction-bottom {
  position: absolute;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background: var(--color-white);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  padding: 0;
  height: 12px;
  width: 12px;
  bottom: 0;
  right: 0;
}
.chat-badge-dot {
  display: block;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.avatar-online {
  background-color: var(--color-success);
  border: 2px solid var(--color-white);
}
.avatar-offline {
  background-color: var(--color-warning);
  border: 2px solid var(--color-white);
}
.search-tab .ap-tab-main {
  height: 66px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.search-tab .ap-tab-main .nav-link {
  font-size: 14px;
  line-height: 1.5;
  font-weight: 400;
  padding: 23px 0;
}
.search-tab .ap-tab-main .nav-link.group-notification {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.search-tab .ap-tab-main .nav-link.active {
  font-weight: 500;
}
.search-tab .ap-tab-main .nav-link.active .total-message {
  display: none;
}
.chat-box {
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.chat-box.chat-box--big {
  height: 450px;
  padding: 20px;
}
.chat-box.chat-box--small {
  height: 540px;
}
.chat-box.chat-box--small .chat-text-box {
  max-width: 90%;
}
.chat-box.chat-box--small .chat-text-box .media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.chat-box.chat-box--small .bg-lighters {
  background-color: var(--bg-lighters);
}
.chat-box::-webkit-scrollbar {
  width: 11px;
}
.chat-box::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.chat-box::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  border: 3px solid var(--scrollbarBG);
}
.incoming-chat .chat-text-box__subtitle {
  -webkit-border-radius: 0 15px 15px 15px;
  border-radius: 0 15px 15px 15px;
}
.outgoing-chat {
  margin: 5px 0;
}
.outgoing-chat .chat-text-box__subtitle {
  -webkit-border-radius: 15px 15px 0 15px;
  border-radius: 15px 15px 0 15px;
}
.chat-text-box {
  max-width: 80%;
}
.chat-text-box__subtitle.typing {
  padding: 5px 13px;
  min-height: 52px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.chat-text-box__subtitle p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  margin-bottom: 0;
}
.chat-text-box__title {
  margin-bottom: 15px;
}
.chat-text-box__other button img,
.chat-text-box__other button svg {
  width: 18px;
  color: var(--color-lighten);
}
.chat-text-box__photo img {
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.chat-body .social-connector {
  overflow: hidden;
  text-align: center;
}
.chat-body .social-connector:after,
.chat-body .social-connector:before {
  background-color: var(--border-light);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.chat-body .social-connector:before {
  right: 0.5em;
  margin-left: -50%;
}
.chat-body .social-connector:after {
  left: 0.5em;
  margin-right: -50%;
}
.chat-body .social-connector span {
  font-weight: 400;
}
.chat-type-text__write {
  background-color: var(--bg-lighters);
  height: 60px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  padding: 0 25px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  margin-right: 15px;
}
.chat-type-text__write:hover {
  background: var(--color-white);
  -webkit-box-shadow: 0 5px 30px var(--shadow5);
  box-shadow: 0 5px 30px var(--shadow5);
}
.chat-type-text__write input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
}
.chat-type-text__write input::-moz-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
}
.chat-type-text__write input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
}
.chat-type-text__write input::placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
}
.chat-type-text__write a img,
.chat-type-text__write a svg {
  color: #adb4d2;
  width: 24px;
}
.chat-type-text__write a input {
  padding: 8px 0;
}
.chat-type-text__btn img,
.chat-type-text__btn svg {
  width: 18px;
}
.chat-type-text__btn button:last-child {
  -webkit-box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
  box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
}
.chat-footer {
  padding: 20px;
}
.chat-text-box__other .dropdown-default .dropdown-item,
.dropdown-menu .dropdown-item {
  color: var(--color-gray);
  font-weight: 400;
  padding: 10px 25px;
  text-transform: capitalize;
}
.user-button-top {
  margin: 20px 14px 10px 25px;
}
.user-button-top button {
  padding: 10px 0;
  width: 100%;
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
  text-transform: capitalize;
}
.user-button-top button img,
.user-button-top button svg {
  color: var(--color-gray);
  margin-right: 11px;
  width: 14px;
}
.image-group ul li:not(:last-child) {
  margin-right: 4px;
}
.image-group ul li svg {
  width: 14px;
  color: var(--color-lighten);
}
.image-group ul li img.svg {
  width: 14px;
}
.seen-chat-group ul li:not(:last-child) {
  margin-right: 3px;
}
.emotions .dropdown-menu {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  padding: 15px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.emotions__parent li a img {
  width: 30px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.emotions__parent li a img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
}
.emotions__parent li:not(:last-child) {
  margin-right: 15px;
}
.dm-calendar-left .card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
}
.dm-calendar-left .btn-create-event {
  width: 100%;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  margin-bottom: 25px;
}
.date-picker__calendar-height {
  min-height: 474px;
}
.date-picker--demo5 .date-picker__calendar .ui-datepicker-calendar {
  width: 100%;
}
.date-picker--demo5 .date-picker__calendar .ui-datepicker-calendar thead th {
  padding: 21px 2px 23px 0;
  width: 40px;
  height: 40px;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-light);
}
.date-picker--demo5 .date-picker__calendar .ui-datepicker-calendar tbody td a {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  margin: 6px auto;
  color: var(--color-gray);
  font-size: 12px;
  font-weight: 400;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-calendar
  tbody
  td
  a.ui-state-highlight {
  color: var(--color-white);
}
.date-picker--demo5 .date-picker__calendar .ui-datepicker-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  a.ui-corner-all {
  color: var(--color-lighten);
  position: initial;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  a.ui-corner-all:hover {
  color: var(--color-primary);
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  a.ui-datepicker-prev {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  a.ui-datepicker-next {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  .ui-datepicker-title {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
  margin: 0 20px;
  padding: 0;
}
.date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-header
  .ui-datepicker-title
  span {
  font-size: 16px;
  font-weight: 500;
}
table.fc-scrollgrid thead .fc-scroller-harness .fc-scroller {
  overflow-y: hidden !important;
  background-color: var(--bg-normal);
}
.fc-theme-standard .fc-list {
  border: 1px solid var(--border-color);
}
.fc-theme-standard .fc-list .fc-list-empty {
  background-color: var(--color-white);
}
.date-picker__top {
  margin-bottom: 5px;
}
.date-picker__top a img,
.date-picker__top a svg {
  width: 16px;
  height: 16px;
  color: var(--color-light);
}
.date-picker__date {
  border: 0;
  font-size: 16px;
  font-weight: 500;
  background: 0 0;
}
.date-picker__calendar .ui-datepicker {
  padding: 0;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
  position: relative;
}
.date-picker__calendar .ui-datepicker-header .ui-datepicker-next,
.date-picker__calendar .ui-datepicker-header .ui-datepicker-prev {
  position: absolute;
  width: auto;
  height: auto;
  margin: 0;
}
.date-picker__calendar .ui-datepicker-header .ui-datepicker-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 16px;
}
.date-picker__calendar .ui-datepicker-header .ui-datepicker-title span {
  color: var(--color-dark);
}
.date-picker__calendar .ui-datepicker-header .ui-corner-all {
  top: 5px;
}
.date-picker__calendar .ui-datepicker-header .ui-corner-all:after {
  font-size: 12px;
}
.date-picker__calendar .ui-datepicker-header a.ui-datepicker-next {
  right: 25px;
}
.date-picker__calendar .ui-datepicker-header a.ui-datepicker-prev {
  left: 25px;
}
.date-picker__calendar .ui-datepicker-calendar {
  margin: 0 auto;
}
.date-picker__calendar .ui-datepicker-calendar thead tr th span {
  font-weight: 500;
  color: var(--color-light);
}
.date-picker__calendar .ui-datepicker-calendar tbody td a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 3px auto;
  width: 2.5rem;
  height: 2.5rem;
}
.draggable-events h1,
.draggable-events h2,
.draggable-events h3,
.draggable-events h4,
.draggable-events h5,
.draggable-events h6 {
  font-weight: 500;
}
.draggable-events a img,
.draggable-events a svg {
  width: 18px;
  height: 18px;
  color: var(--color-light);
}
.draggable-event-list {
  margin-top: 20px;
}
.draggable-event-list__single {
  cursor: pointer;
}
.draggable-event-list__single:not(:last-child) {
  margin-bottom: 15px;
}
.draggable-event-list__single .event-text {
  font-size: 14px;
  margin-left: 12px;
  color: var(--color-gray);
}
#full-calendar .fc-toolbar {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#full-calendar .fc-toolbar-chunk > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
#full-calendar .fc-toolbar-chunk .fc-today-button {
  padding: 6.5px 15px;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  border: 1px solid var(--border-color);
  margin-right: 30px;
  color: var(--color-gray);
  background-color: var(--color-white);
}
#full-calendar .fc-toolbar-chunk .fc-button.fc-next-button,
#full-calendar .fc-toolbar-chunk .fc-button.fc-prev-button {
  padding: 0.25em 0.563em;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--color-white);
}
#full-calendar .fc-toolbar-chunk .fc-button.fc-next-button span.fc-icon,
#full-calendar .fc-toolbar-chunk .fc-button.fc-prev-button span.fc-icon {
  font-size: 14px;
  margin-top: -4px;
  color: var(--color-light);
}
#full-calendar .fc-toolbar-chunk .fc-toolbar-title {
  margin: 0 20px;
  font-size: 16px;
  font-weight: 500;
  color: var(--color-dark);
}
#full-calendar .fc-toolbar-chunk .fc-button-group {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}
#full-calendar .fc-toolbar-chunk .fc-button-group .fc-button-primary {
  background-color: var(--color-white);
}
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button-primary.fc-button-active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
#full-calendar .fc-toolbar-chunk .fc-button-group .fc-button {
  font-size: 13px;
  font-weight: 500;
  padding: 6.5px 13.68px;
  text-transform: capitalize;
  border: 1px solid var(--border-color);
  color: var(--color-light);
}
#full-calendar .fc-toolbar-chunk .fc-button-group .fc-button:first-child {
  -webkit-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-dayGridMonth-button {
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-right: 1px solid var(--border-color);
}
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-listMonth-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 0;
  margin-left: 0;
  color: var(--color-light);
}
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-listMonth-button
  i,
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-listMonth-button
  img,
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-listMonth-button
  svg {
  font-size: 15px;
  min-width: 14px;
  margin-right: 6px;
  color: var(--color-primary);
}
#full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button.fc-listMonth-button.fc-button-active {
  background-color: var(--color-white);
  color: var(--color-primary);
}
#full-calendar .fc-toolbar-chunk .fc-button-group .fc-button:focus {
  outline: 0;
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.fc-view .fc-col-header {
  background-color: var(--bg-normal);
}
.fc-view .fc-col-header tr th {
  padding: 12.5px 0;
  border: 1px solid var(--border-color);
}
.fc-view .fc-col-header tr th .fc-scrollgrid-sync-inner {
  text-align: left;
}
.fc-view
  .fc-col-header
  tr
  th
  .fc-scrollgrid-sync-inner
  .fc-col-header-cell-cushion {
  padding-left: 22px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
}
.fc-theme-standard td,
.fc-theme-standard th {
  border: 1px solid var(--border-color);
}
.fc-theme-standard .fc-scrollgrid {
  border: 1px solid var(--border-color);
}
.fc-theme-standard .fc-daygrid-day.fc-day-today {
  background-color: var(--color-white);
}
.fc-timegrid-event .fc-event-resizer {
  display: block !important;
}
.fc-timegrid-event .fc-event-resizer.fc-event-resizer-end {
  position: relative;
}
.fc-timegrid-event .fc-event-resizer.fc-event-resizer-end:after,
.fc-timegrid-event .fc-event-resizer.fc-event-resizer-end:before {
  position: absolute;
  left: 50%;
  height: 1px;
  width: 10px;
  background: var(--color-white);
  content: "";
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  top: -8px;
}
.fc-timegrid-event .fc-event-resizer.fc-event-resizer-end:before {
  top: -5px;
}
.fc-timegrid-event.primary .fc-event-resizer.fc-event-resizer-end:after,
.fc-timegrid-event.primary .fc-event-resizer.fc-event-resizer-end:before {
  background-color: rgba(var(--color-primary-rgba), 0.5);
}
.fc-timegrid-event.secondary .fc-event-resizer.fc-event-resizer-end:after,
.fc-timegrid-event.secondary .fc-event-resizer.fc-event-resizer-end:before {
  background-color: rgba(var(--color-secondary-rgba), 0.5);
}
.fc-timegrid-event.success .fc-event-resizer.fc-event-resizer-end:after,
.fc-timegrid-event.success .fc-event-resizer.fc-event-resizer-end:before {
  background-color: rgba(1, 184, 26, 0.5);
}
.fc-timegrid-event.warning .fc-event-resizer.fc-event-resizer-end:after,
.fc-timegrid-event.warning .fc-event-resizer.fc-event-resizer-end:before {
  background-color: rgba(var(--color-warning-rgba), 0.5);
}
.fc-timegrid-slots tr:nth-child(2n) {
  border-bottom: 1px solid var(--border-color);
}
.fc-timegrid-slots .fc-timegrid-slot {
  height: 10px;
  line-height: 1.2;
  padding: 1px 12px;
  background-color: var(--color-white);
  border: 0;
}
.fc-timegrid-slots .fc-timegrid-slot:first-child {
  border-right: 1px solid var(--border-color);
}
.fc-timegrid-slots .fc-timegrid-slot .fc-timegrid-slot-label-frame {
  position: relative;
  top: 100%;
  margin-top: 7.5px;
}
.fc-timegrid-slots .fc-timegrid-slot-label-cushion {
  text-transform: uppercase;
  text-align: center;
  font-size: 12px;
}
.fc-media-screen .fc-timegrid-event {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  margin-left: 10px;
  padding: 4px 10px;
  -webkit-border-radius: 3px 6px 6px 3px;
  border-radius: 3px 6px 6px 3px;
  background-color: #efeffe;
  border-color: #efeffe;
}
.fc-media-screen .fc-timegrid-event .fc-event-main-frame .fc-event-time {
  font-size: 12px;
}
.fc-media-screen
  .fc-timegrid-event
  .fc-event-main-frame
  .fc-event-title-container {
  margin-bottom: 1px;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}
.fc-media-screen .fc-timegrid-event .fc-event-main-frame .fc-event-title {
  font-weight: 500;
  font-size: 13px;
}
.fc-timegrid-event {
  min-width: 140px;
}
.fc-timegrid-event.primary {
  background-color: #efeffe !important;
  border-left-color: var(--color-primary);
  border-left-width: 2px;
}
.fc-timegrid-event.primary .fc-event-main {
  color: var(--color-white);
}
.fc-timegrid-event.primary .fc-event-main .fc-event-time,
.fc-timegrid-event.primary .fc-event-main .fc-event-title {
  color: var(--color-primary);
}
.fc-timegrid-event.primary:hover {
  background-color: #efeffe !important;
}
.fc-timegrid-event.secondary {
  background-color: #fff0f6 !important;
  border-left-color: var(--color-secondary);
  border-left-width: 2px;
  color: var(--color-secondary);
}
.fc-timegrid-event.secondary .fc-event-main,
.fc-timegrid-event.secondary .fc-event-time,
.fc-timegrid-event.secondary .fc-event-title {
  color: var(--color-secondary);
}
.fc-timegrid-event.secondary:hover {
  background-color: #fff0f6 !important;
}
.fc-timegrid-event.success {
  background-color: #e8faf4 !important;
  border-left-color: #01b81a;
  border-left-width: 2px;
}
.fc-timegrid-event.success .fc-event-time,
.fc-timegrid-event.success .fc-event-title {
  color: #01b81a;
}
.fc-timegrid-event.success:hover {
  background-color: #e8faf4 !important;
}
.fc-timegrid-event.warning {
  background-color: #fff3e6 !important;
  border-left-color: var(--color-warning);
  border-left-width: 2px;
}
.fc-timegrid-event.warning .fc-event-time,
.fc-timegrid-event.warning .fc-event-title {
  color: var(--color-warning);
}
.fc-timegrid-event.warning.success {
  background-color: #fff3e6 !important;
  border-left-color: #01b81a;
  border-left-width: 2px;
}
.fc-timegrid-event.warning.success .fc-event-time,
.fc-timegrid-event.warning.success .fc-event-title {
  color: #01b81a;
}
.fc-timegrid-event.warning.success:hover {
  background-color: rgba(1, 184, 26, 0.1) !important;
}
.fc-timeGridWeek-view .fc-event-resizer {
  display: none !important;
}
.fc-daygrid-day.fc-day-today {
  background-color: rgba(var(--color-primary-rgba), 0.05) !important;
  border-top: 2px solid var(--color-primary);
}
.fc-daygrid-day.fc-day-today .fc-daygrid-day-number {
  color: var(--color-primary);
}
.fc-daygrid-day .fc-daygrid-event {
  color: #444;
}
.fc-daygrid-day .fc-daygrid-event.primary {
  background-color: var(--color-primary);
}
.fc-daygrid-day .fc-daygrid-event.primary.fc-h-event {
  border-color: var(--color-primary);
}
.fc-daygrid-day .fc-daygrid-event.secondary {
  background-color: var(--color-secondary);
}
.fc-daygrid-day .fc-daygrid-event.secondary.fc-h-event {
  border-color: var(--color-secondary);
}
.fc-daygrid-day .fc-daygrid-event.success {
  background-color: var(--color-success);
}
.fc-daygrid-day .fc-daygrid-event.success.fc-h-event {
  border-color: #01b81a;
}
.fc-daygrid-day .fc-daygrid-event.warning {
  background-color: var(--color-warning);
}
.fc-daygrid-day .fc-daygrid-event.warning.fc-h-event {
  border-color: var(--color-warning);
}
.fc-daygrid-day .fc-daygrid-event .fc-event-time {
  display: none;
}
.fc-daygrid-day .fc-daygrid-day-events .fc-daygrid-event-harness {
  margin: 0 6px;
}
.fc-daygrid-day
  .fc-daygrid-day-events
  .fc-daygrid-event-harness
  + .fc-daygrid-event-harness {
  margin-top: 6px;
}
.fc-daygrid-day .fc-daygrid-event {
  padding: 5.5px 12px;
  font-size: 13px;
  color: var(--color-white) !important;
}
.fc-daygrid-day .fc-daygrid-event .fc-daygrid-event-dot {
  display: none;
}
.fc-daygrid-day .fc-daygrid-event .fc-event-title {
  font-weight: 400;
}
.fc-daygrid-day-top {
  margin-bottom: 8px;
}
.fc-daygrid-day-top .fc-daygrid-day-number {
  font-size: 14px;
  margin: 6px 8px 0 0;
  color: var(--color-gray);
}
.fc-listMonth-view .fc-list-day {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
  border-bottom: 1px solid var(--border-color);
}
.fc-listMonth-view .fc-list-day:last-child {
  border-bottom: 1px solid var(--border-color);
}
.fc-listMonth-view .fc-list-day th {
  color: var(--color-dark);
  border: 0;
}
.fc-listMonth-view .fc-list-day .fc-list-day-cushion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  background-color: transparent;
  padding: 12px 14px;
}
.fc-listMonth-view .fc-list-day .fc-list-day-cushion .fc-list-day-side-text {
  font-weight: 500;
}
.fc-listMonth-view .fc-list-event {
  cursor: pointer;
}
.fc-listMonth-view .fc-list-event.primary,
.fc-listMonth-view .fc-list-event.secondary,
.fc-listMonth-view .fc-list-event.success,
.fc-listMonth-view .fc-list-event.warning {
  background-color: transparent !important;
}
.fc-listMonth-view .fc-list-event:hover {
  background-color: var(--color-white) !important;
}
.fc-listMonth-view .fc-list-event td {
  font-size: 14px;
  background-color: var(--color-white) !important;
  padding: 16px 14px;
}
.fc-listMonth-view .fc-list-event + .fc-list-event {
  margin-left: 20%;
  border: 0;
}
.c-event-dialog {
  max-width: 500px;
}
.c-event-dialog .modal-header {
  padding: 20px 25px;
}
.c-event-dialog .modal-header .modal-title {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
}
.c-event-dialog .modal-content {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.03);
}
.c-event-dialog .modal-body {
  padding: 20px 25px;
}
.c-event-dialog .modal-footer {
  border-top: 0;
  padding: 0 25px 25px;
}
.c-event-dialog .modal-footer .btn-white {
  color: var(--color-light);
}
.c-event-dialog .modal-footer .btn-sm {
  line-height: 2.15;
}
.c-event-form .form-control-md {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid var(--border-color);
}
.c-event-form .e-form-row:not(:last-child) {
  margin-bottom: 20px;
}
.c-event-form .e-form-row__left {
  min-width: 82px;
  margin-right: 40px;
}
.c-event-form .e-form-row__left label {
  font-size: 14px;
  color: var(--color-light);
}
.c-event-form .e-form-row__right {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.c-event-form .e-form-row__right textarea {
  min-height: 100px;
}
.e-info-modal .modal-header {
  padding: 0.75rem 1.45rem;
  -webkit-border-top-left-radius: 8px;
  border-top-left-radius: 8px;
  -webkit-border-top-right-radius: 8px;
  border-top-right-radius: 8px;
}
.e-info-modal .modal-content {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  background-color: transparent;
}
.e-info-modal .modal-body {
  padding: 0.95rem 0.95rem 1.15rem;
  -webkit-border-bottom-left-radius: 8px;
  border-bottom-left-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background-color: var(--color-white);
}
.e-info-modal .e-info-title {
  font-weight: 500;
  color: var(--color-white);
}
.e-info-modal .e-info-action {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.e-info-modal .e-info-action .btn-icon {
  background-color: transparent;
  border: 0;
  width: 36px;
  height: 36px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: transparent;
}
.e-info-modal .e-info-action .btn-icon:hover {
  background-color: rgba(var(--bg-lighter-rgba), 0.15);
}
.e-info-modal .e-info-action .btn-icon:hover i,
.e-info-modal .e-info-action .btn-icon:hover img,
.e-info-modal .e-info-action .btn-icon:hover svg {
  opacity: 1;
}
.e-info-modal .e-info-action .btn-icon img {
  max-width: 14px;
}
.e-info-modal .e-info-action .btn-icon i,
.e-info-modal .e-info-action .btn-icon svg {
  max-width: 14px;
  opacity: 0.6;
  color: var(--color-white);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.e-info-modal .e-info-action .btn-closed {
  padding: 0;
  background-color: transparent;
  width: auto;
  height: auto;
  margin-left: 6px;
}
.e-info-modal .e-info-action .btn-closed:hover {
  background-color: transparent;
}
.e-info-modal .e-info-action .btn-closed svg {
  color: var(--color-dark);
}
.e-info-modal .e-info-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.e-info-modal .e-info-list li:not(:last-child) {
  margin-bottom: 12px;
}
.e-info-modal .e-info-list li img,
.e-info-modal .e-info-list li svg {
  min-width: 14px;
  width: 14px;
  height: 18px;
  margin-right: 12px;
}
.e-info-modal .e-info-list li .list-line {
  font-size: 13px;
}
.e-info-modal .e-info-list li .list-line .list-label {
  color: var(--color-light);
}
.e-info-modal .e-info-list li .list-line .list-meta {
  font-weight: 500;
  color: var(--color-dark);
}
.e-info-modal .e-info-list li .list-text {
  font-size: 14px;
  color: var(--color-gray);
}
.todo-list td {
  padding: 12px 25px !important;
}
.todo-list__right li:not(:last-child) {
  margin-right: 20px;
}
.todo-list__right li a i,
.todo-list__right li a img,
.todo-list__right li a svg {
  width: 16px;
  color: var(--color-lighten);
}
.todo-list__right li a.active i,
.todo-list__right li a.active svg {
  color: var(--color-warning);
}
.todo-list .custom-checkbox input[type="checkbox"]:checked + label {
  color: var(--color-primary) !important;
}
.todo-list .custom-checkbox input[type="checkbox"] + label {
  color: var(--color-gray) !important;
}
.add-task-action {
  margin: 15px 0 0 25px;
}
.add-task-action button {
  font-size: 12px;
  width: auto;
  background-color: rgba(var(--color-primary-rgba), 0.08);
}
.add-todo-modal .modal-header .close img,
.add-todo-modal .modal-header .close svg {
  width: 18px;
}
.shop-breadcrumb .action-btn .btn {
  height: 36px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.shop-breadcrumb .action-btn .btn i {
  color: var(--color-primary);
}
.shop-breadcrumb .action-btn .btn.btn-primary,
.shop-breadcrumb .action-btn .btn.btn-primary i {
  color: var(--color-white);
}
.shop-search .user-member__form svg {
  color: var(--color-light);
}
.product_page--grid .project-category p,
.product_page--list .project-category p {
  margin-bottom: 0;
  margin-right: 20px;
}
.product_page--grid .project-top-wrapper,
.product_page--list .project-top-wrapper {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.product_page--grid .shop_products_top_filter,
.product_page--list .shop_products_top_filter {
  margin-bottom: 30px;
}
.product {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.product.card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.1);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.1);
}
.product .product-item__button button {
  height: 36px;
  margin: 5px;
  line-height: normal;
}
.product--grid .product-item__button {
  margin: -5px;
}
.product--grid:hover {
  -webkit-transform: scale(1.03);
  -ms-transform: scale(1.03);
  transform: scale(1.03);
}
.product--grid .product-item__body .card-title {
  font-size: calc(16.2px + 0.15vw);
}
.product-item .product-desc-price {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 600;
  color: var(--color-primary);
}
.product-item .product-price {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-lighten);
  text-decoration: line-through;
  margin-right: 10px;
  margin-left: 10px;
}
.product-item .product-discount {
  font-size: 12px;
  line-height: 1.5;
  font-weight: 500;
  color: var(--color-secondary);
}
.product-item__body .card-title {
  margin-bottom: 9px;
  text-transform: capitalize;
}
.product-item__button button {
  height: 34px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-gray);
  text-transform: capitalize;
  border: 1px solid var(--border-light);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.product-item__button button img,
.product-item__button button svg {
  width: 13.46px;
  height: 14.81px;
}
.product-item__button button.btn-primary {
  color: var(--color-white);
  -webkit-box-shadow: 0 3px 5px rgba(32, 201, 151, 0.0509803922);
  box-shadow: 0 3px 5px rgba(32, 201, 151, 0.0509803922);
}
.product--list .product-item .product-item__image {
  width: 300px;
  height: 200px;
}
.product--list .product-item .product-item__image img {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.product--list .like-icon {
  top: 0;
}
.product--list .product-item {
  padding: 20px;
}
.product--list .product-item .product-item__title p {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 17px;
}
.product--list .product-item__body .product-item__button {
  margin-top: 30px;
}
.product--list .product-item__body .card-title {
  font-size: calc(16.2px + 0.15vw);
  margin-bottom: 0;
  text-transform: capitalize;
}
.product--list .product-item__body .card-title + p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 13px;
}
.stars-rating {
  line-height: normal;
}
.stars-rating .star-icon {
  font-size: 13px;
}
.stars-rating .star-icon.active {
  color: var(--color-warning);
}
.stars-rating .star-icon.inactive {
  color: #c6d0dc;
}
.stars-rating span.active {
  color: var(--color-warning);
}
.stars-rating span.inactive {
  color: #c6d0dc;
}
.stars-rating__point {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
  margin-left: 6px;
  margin-right: 10px;
}
.stars-rating__review {
  font-size: 12px;
  line-height: 1.25;
  font-weight: 400;
  color: var(--color-lighten);
}
.product-ratings .checkbox-text {
  font-size: 13px;
  font-weight: 400;
  color: var(--color-light);
  text-transform: lowercase;
}
.product-ratings .stars-rating .active {
  font-size: 14px;
}
.like-icon {
  position: absolute;
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
  background-color: var(--color-white);
  -webkit-box-shadow: 0 5px 10px var(--shadow3);
  box-shadow: 0 5px 10px var(--shadow3);
  right: 15px;
  top: 15px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.like-icon button {
  color: var(--color-light);
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
}
.like-icon button .icon {
  font-size: 16px;
}
.like-icon button .icon.las {
  color: var(--color-danger);
}
.bookmark-page .columns-1,
.bookmark-page .columns-2,
.products_page .columns-1,
.products_page .columns-2 {
  position: relative;
  padding-right: 15px;
  padding-left: 15px;
}
.bookmark-page__list .cus-xl-3 {
  padding: 0 12.5px;
}
.products_page .widget {
  background-color: var(--color-white);
  -webkit-box-shadow: 0 5px 20px rgba(var(--box-shadow-color-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--box-shadow-color-rgba), 0.03);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  width: 100%;
}
.products_page .category_sidebar {
  padding: 25px;
}
.products_page .widget-header-title {
  border-bottom: 1px solid var(--border-color);
}
.products_page .widget-header-title img,
.products_page .widget-header-title svg {
  color: var(--color-light);
  width: 14px;
  height: 20px;
  margin-right: 12px;
}
.products_page .widget-header-title h6 {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
}
.product-sidebar-widget .widget_title h6 {
  font-size: 15px;
  line-height: 1.3333333333;
  position: relative;
  text-transform: capitalize;
}
.product-sidebar-widget .widget_title h6:before {
  content: "\f107";
  position: absolute;
  font-size: 12px;
  font-family: line awesome free;
  font-weight: 900;
  margin-left: auto;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: var(--color-primary);
}
.product-sidebar-widget .widget_title .widget_title__distence {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
  color: var(--color-dark);
  margin-top: 4px;
}
.product-sidebar-widget .widget_title .widget_title__distence strong {
  font-weight: 600;
}
.product-sidebar-widget .widget_title.nocollapse h6::before {
  display: none;
}
.product-sidebar-widget .widget_title.collapsed h6:before {
  content: "\f105";
  color: var(--color-gray);
}
.product-sidebar-widget .stars-rating {
  margin-top: -2px;
}
.product-brands ul li,
.product-category ul li,
.product-ratings ul li {
  text-transform: capitalize;
}
.product-brands ul li:not(:last-child),
.product-category ul li:not(:last-child),
.product-ratings ul li:not(:last-child) {
  margin-bottom: 10px;
}
.product-brands ul li label,
.product-category ul li label,
.product-ratings ul li label {
  width: 100%;
}
.product-brands ul li .item-numbers,
.product-category ul li .item-numbers,
.product-ratings ul li .item-numbers {
  float: right;
  color: var(--color-light);
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
}
.product-brands .checkbox-text {
  font-size: 14px;
  color: var(--color-gray);
}
.product-ratings .checkbox-text {
  width: 100%;
  margin-left: 10px;
}
.limit-list-item .more {
  color: var(--color-primary);
  text-transform: capitalize;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: 13px;
  font-weight: 500;
}
.limit-list-item .more:before {
  content: "\f105";
  font-size: 12px;
  font-family: line awesome free;
  font-weight: 900;
  margin-left: auto;
  position: absolute;
  right: -20px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.limit-list-item .more.less:before {
  content: "\f107";
}
#price-range {
  width: 100%;
  margin: 5% auto;
}
#price-range .price-value {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  margin-top: 20px;
  margin-bottom: 0;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  background-color: var(--color-white);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.ui-slider-horizontal {
  height: 3px;
  border: 0;
  background-color: rgba(var(--color-primary-rgba), 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -9px;
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
}
.ui-slider-horizontal .ui-slider-range {
  top: 0;
  height: 100%;
}
.ui-slider {
  position: relative;
  text-align: left;
}
.ui-slider .ui-slider-handle {
  position: absolute;
  z-index: 2;
  width: 22px;
  height: 22px;
  cursor: default;
  -ms-touch-action: none;
  touch-action: none;
  background: var(--color-white);
  border: none;
}
.ui-slider .ui-slider-handle:before {
  position: absolute;
  content: "";
  background: var(--color-primary);
  width: 16px;
  height: 16px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ui-slider .ui-slider-handle:after {
  position: absolute;
  content: "";
  background: var(--color-white);
  height: 13px;
  width: 13px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.ui-slider .ui-slider-range {
  position: absolute;
  z-index: 1;
  font-size: 0.7em;
  display: block;
  border: 0;
  background-position: 0 0;
  background-color: var(--color-primary);
}
.product-details.card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  margin-bottom: 140px;
}
.product-details__availability .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-details__availability .title:not(:last-child) {
  margin-bottom: 7px;
}
.product-details__availability .title p {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 0;
  text-transform: capitalize;
  -ms-flex-preferred-size: 65px;
  flex-basis: 65px;
  margin-bottom: 0;
}
.product-details__availability .title span {
  margin-left: 30px;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
}
.product-details__availability .title span.stock {
  color: var(--color-success);
}
.product-details__availability .title span.free {
  font-weight: 400;
  color: var(--color-light);
}
.product-details .product-item__body {
  -ms-flex-preferred-size: 43%;
  flex-basis: 43%;
}
.product-details .product-item__body .product-item__title .card-title {
  margin-bottom: 10px;
}
.product-details .product-item__body .stars-rating .star-icon {
  font-size: 15px;
}
.product-details .product-item__body .stars-rating .stars-rating__point {
  font-size: 15px;
  font-weight: 600;
}
.product-details .product-item__body .stars-rating .stars-rating__review {
  font-size: 15px;
}
.product-details .product-item__body .product-desc-price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: calc(16.6px + 0.45vw);
  line-height: 0.9090909091;
  font-weight: 500;
  margin-top: 30px;
  margin-bottom: 15px;
}
.product-details .product-item__body .product-desc-price sub {
  font-size: 14px;
  color: var(--color-lighten);
}
.product-details .product-item__body .product-price {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  margin-left: 0;
}
.product-details .product-details-brandName {
  font-size: 13px;
  line-height: 1.5384615385;
  font-weight: 400;
  color: var(--color-lighten);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.product-details .product-details-brandName span {
  color: var(--color-dark);
  font-weight: 500;
  margin-left: 6px;
}
.product-details .product-deatils-pera {
  font-size: 16px;
  line-height: 1.6875;
  font-weight: 400;
  color: var(--color-gray);
  margin: 24px 0 22px;
}
.product-details .product-item__button button {
  color: var(--color-white);
  height: 44px;
  padding: 14px 30px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
}
.product-details .product-item__button button img,
.product-details .product-item__button button svg {
  width: 15px;
}
.product-details .product-item__button .like-icon {
  position: unset;
  -webkit-box-shadow: 0 5px 15px var(--shadow5);
  box-shadow: 0 5px 15px var(--shadow5);
  width: 44px;
  height: 44px;
  margin-right: 10px;
}
.product-details .product-item__button .like-icon button {
  color: var(--color-light);
  padding: 0;
}
.product-deatils__social li a {
  color: var(--color-gray);
  font-size: 16px;
}
.product-deatils__social li a:hover {
  color: var(--color-primary);
}
.product-deatils__social li:not(:first-child) a {
  margin-left: 10px;
}
.product-item__image .carousel-inner {
  max-width: 580px;
}
.product-item__image .list-thumb-gallery {
  padding: 0;
  list-style-type: none;
  padding-top: 1rem;
  list-style-type: none;
}
.product-item__image .list-thumb-gallery > li {
  margin-bottom: 1rem;
  width: 80px;
  height: 80px;
}
.product-item__image .list-thumb-gallery > li:not(:last-child) {
  margin-right: 10px;
}
.product-item__image .list-thumb-gallery > li img {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -o-object-fit: cover;
  object-fit: cover;
  background-color: #fafafa;
}
.product-item__image .carousel-control-next,
.product-item__image .carousel-control-prev,
.product-item__image .carousel-indicators {
  display: none;
}
.product-item__image .carousel-item {
  width: 580px;
  height: 450px;
  max-width: 100%;
}
.product-item__image .carousel-item img {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 450px;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #fafafa;
}
.quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.quantity .input {
  width: 38px;
  padding: 0;
  margin: 0;
  text-align: center;
  border: none;
  background: 0 0;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.quantity .bttn {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  color: var(--color-gray);
  text-decoration: none;
  font-size: calc(16.4px + 0.3vw);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.quantity .bttn:hover {
  background: rgba(var(--color-primary-rgba), 0.1);
  color: var(--color-primary);
  border-color: rgba(var(--color-primary-rgba), 0.1);
}
.quantity input[type="number"]::-webkit-inner-spin-button,
.quantity input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.product-quantity {
  margin-top: 25px;
}
.product-quantity .bttn {
  width: 38px;
  height: 38px;
}
.product-quantity p {
  margin-bottom: 0;
  margin-right: 40px;
}
.product-quantity button + span {
  margin-left: 15px;
}
.product-item__content .product-item__button {
  margin-bottom: -5px;
}
.product-item__content .product-item__button .product-item__action {
  margin-top: 5px;
  margin-bottom: 5px;
}
.product-item__content .product-item__button .product-deatils__social {
  margin-top: 5px;
  margin-bottom: 5px;
}
.cartPage {
  background-color: var(--color-white);
}
.product-cart__header {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.product-cart__header th {
  padding: 15px 20px;
  vertical-align: middle;
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: capitalize;
}
.product-cart__cupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.product-cart__cupon input {
  width: 180px;
  border: 1px solid var(--border-color);
  margin-right: 10px;
  height: 44px;
  background-color: var(--bg-normal);
}
.product-cart__cupon input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
}
.product-cart__cupon input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
}
.product-cart__cupon input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
}
.product-cart__cupon input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
}
.product-cart .table td {
  padding: 20px;
  vertical-align: middle;
}
.product-cart .table th {
  padding: 18px 20px;
  vertical-align: middle;
}
.product-cart .table-hover tbody tr {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.product-cart .table-hover tbody tr:hover {
  --bs-table-accent-bg: var(--color-white);
}
.product-cart .table-hover tbody tr .actions button:hover {
  background: rgba(var(--color-danger-rgba), 0.2);
}
.product-cart .table-hover tbody tr .actions button:hover i {
  color: var(--color-danger);
}
.product-cart .price {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  color: var(--color-gray);
}
.product-cart .subtotal {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  color: var(--color-primary);
}
.product-cart .actions button {
  margin: 0;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.product-cart .actions button i {
  color: var(--color-lighten);
  font-size: calc(16.2px + 0.15vw);
  margin: 0;
  margin-left: -2px;
}
.Product-cart-title .media img {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: #fafafa;
}
.Product-cart-title .media h5 {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  margin-bottom: 5px;
}
.Product-cart-title .media p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 2.1428571429;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: capitalize;
}
.Product-cart-title .media p:not(:last-child) {
  margin-right: 20px;
}
.Product-cart-title .media p span {
  margin-left: 5px;
  font-weight: 400;
  color: var(--color-gray);
}
.product-cart__quantity .bttn {
  font-size: calc(16.1px + 0.075vw);
}
.product-cart table tr td {
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.product-cart__header th {
  background-color: var(--bg-normal);
}
.product-cart__header th:first-child {
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
}
.product-cart__header th:last-child {
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.order-summery {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: #f8f9fb;
}
.order-summery .card-header {
  background-color: transparent;
}
.order-summery .card-body {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  padding: 30px 25px;
  -webkit-box-shadow: 0 10px 30px var(--shadow3);
  box-shadow: 0 10px 30px var(--shadow3);
  background-color: var(--color-white);
}
.order-summery .total > div {
  display: block;
  padding-bottom: 10px;
  font-size: 0.8em;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
  text-transform: capitalize;
}
.order-summery .total > div span {
  color: var(--color-dark);
}
.order-summery .total > div:not(:last-child) {
  margin: 0 0 7px;
}
.order-summery .total span {
  float: right;
  text-align: right;
}
.order-summery .total-money {
  margin-top: 37px;
}
.order-summery .total-money h6 {
  font-weight: 500;
}
.order-summery .total-money h5 {
  color: var(--color-primary);
}
.order-summery .checkout {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  text-transform: capitalize;
  color: var(--color-white);
  font-size: 15px;
  font-weight: 500;
  padding: 15px 30px;
}
.order-summery .checkout i {
  margin-left: 8px;
  font-size: 16px;
}
td.order-summery .total > div {
  padding-bottom: 15px;
}
.promo-code a,
.promo-code input {
  height: 40px;
}
.promo-code {
  text-transform: capitalize;
}
.promo-code label {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-gray);
}
.promo-code input {
  margin-right: 6px;
  max-width: 212px;
}
.promo-code div a {
  border: 1px solid var(--color-success);
  color: #01b81a;
}
.promo-code div a:hover {
  color: #01b81a;
  opacity: 0.7;
}
.select-cupon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 12px;
  margin-bottom: 9px;
}
.select-cupon .percent {
  margin-right: 10px;
}
.select-cupon .percent img,
.select-cupon .percent svg {
  width: 20px;
  height: 23px;
}
.select-cupon
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--color-success);
  font-weight: 500;
}
.select-cupon .select2-container--default .select2-selection--multiple,
.select-cupon .select2-container--default .select2-selection--single {
  height: unset;
  border: none;
  background: 0 0;
}
.select-cupon
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0;
  color: var(--color-success);
  font-weight: 500;
}
.select-cupon
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow {
  width: unset;
  height: unset;
  right: 0;
  left: unset;
}
.select-cupon
  .select2-container
  .select2-selection--single
  .select2-selection__clear {
  right: 25px;
  color: var(--color-gray);
}
.checkout-progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  padding: 0 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.checkout-progress h3 {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
}
.checkout-progress div.step {
  position: relative;
  max-width: 50px;
  -webkit-box-shadow: 10px 0 20px var(--shadow5);
  box-shadow: 10px 0 20px var(--shadow5);
  color: var(--color-dark);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.checkout-progress div.step.current span:nth-of-type(1) {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.checkout-progress div.step.current span:nth-of-type(2) {
  color: var(--color-dark);
  font-weight: 500;
}
.checkout-progress div.step.completed span:nth-of-type(1) {
  background-color: var(--color-success);
  color: var(--color-white);
  font-weight: 900;
}
.checkout-progress div.step.completed span:nth-of-type(2) {
  color: var(--color-dark);
  font-weight: 500;
}
.checkout-progress div.step span {
  display: block;
  text-align: center;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 500;
}
.checkout-progress div.step span:nth-of-type(1) {
  position: relative;
  height: 50px;
  width: 50px;
  list-style-type: none;
  text-align: center;
  line-height: 50px;
  -webkit-transition: background-color 150ms ease;
  -o-transition: background-color 150ms ease;
  transition: background-color 150ms ease;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.checkout-progress div.step span:nth-of-type(2) {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin-top: 13px;
  white-space: nowrap;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-light);
}
.checkout-progress div.current img,
.checkout-progress div.current svg {
  width: 94%;
}
.checkout-progress div:not(.step) {
  border: none;
  -webkit-transition: background-color 150ms ease;
  -o-transition: background-color 150ms ease;
  transition: background-color 150ms ease;
  margin: 0 20px;
}
.checkout-progress div:first-of-type:before {
  content: none;
}
.wizard10 .checkout-shipping-form {
  margin-top: 0;
  padding-left: 50px;
  border: none;
  border-left: 1px solid var(--border-color);
  padding-right: 27px;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding-bottom: 100px;
}
.wizard10 .checkout-shipping-form .card-header {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.wizard10 .checkout-shipping-form .card-header h4 {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.1;
  font-weight: 500;
  white-space: normal;
  text-align: left;
}
.wizard10 .checkout-progress3 .step {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 15px 30px;
}
.wizard10 .checkout-progress3 .step:not(:last-child) {
  margin-bottom: 7px;
}
.wizard10 .checkout-progress3 .step span:first-child {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 8px;
}
.wizard10 .checkout-progress3 .step span:last-child {
  color: var(--color-gray-x);
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
}
.wizard10 .edit-profile__body .form-group .form-control {
  background-color: var(--bg-lighter);
  border: 1px solid var(--border-light);
  color: var(--body-dark);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.wizard10
  .edit-profile__body
  .form-group
  .form-control::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
}
.wizard10 .edit-profile__body .form-group .form-control::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
}
.wizard10 .edit-profile__body .form-group .form-control::-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
}
.wizard10 .edit-profile__body .form-group .form-control::placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.3333333333;
}
.wizard10 .create-passord-warn {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
}
.wizard10 .select2-container--default .select2-selection--multiple,
.wizard10 .select2-container--default .select2-selection--single {
  border: none;
  background-color: var(--bg-lighter);
}
.wizard10
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.wizard10
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 400;
  color: var(--color-dark);
}
.wizard10
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after {
  color: var(--color-gray-x);
}
.wizard10 .payment-method-card .card-body .form-control {
  background-color: var(--bg-lighter);
  border: 4px;
  border: 1px solid var(--border-light);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wizard10 .payment-method-card .card-body .form-control:hover {
  border-color: var(--color-primary);
}
.wizard10
  .payment-method-card
  .card-body
  .form-control::-webkit-input-placeholder {
  color: var(--color-dark);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.wizard10 .payment-method-card .card-body .form-control::-moz-placeholder {
  color: var(--color-dark);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.wizard10 .payment-method-card .card-body .form-control::-ms-input-placeholder {
  color: var(--color-dark);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.wizard10 .payment-method-card .card-body .form-control::placeholder {
  color: var(--color-dark);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.wizard10 .payment-method-card .card-body .form-control input {
  background-color: transparent;
}
.wizard10
  .payment-method-card
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.wizard10
  .payment-method-card
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--color-dark);
}
.wizard10 .quantity .bttn {
  background-color: var(--color-white);
  border: none;
}
.wizard10 .payment-success-modal {
  text-align: center;
  background-color: rgba(17, 18, 23, 0.6);
}
.wizard10 .payment-success-modal .modal-icon {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 70rem;
  border-radius: 70rem;
  border: 1px solid var(--color-success);
}
.wizard10 .payment-success-modal .modal-icon span {
  color: var(--color-success);
  font-size: calc(17.4px + 1.05vw);
}
.wizard10 .payment-success-modal .modal-dialog {
  width: 420px;
}
.wizard10 .payment-success-modal p {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-gray-x);
  margin: 12px 0 26px;
}
.wizard10 .payment-success-modal .button-group {
  margin: -5px;
}
.wizard10 .payment-success-modal .button-group a {
  margin: 5px;
}
.wizard8 .checkout-progress div.step {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: rgba(173, 180, 210, 0.5);
  color: var(--color-white);
  margin-bottom: 0;
}
.wizard8 .payment-method-area--top {
  margin-top: 46px;
}
.wizard8 .checkout-progress-indicator {
  padding-top: 50px;
  padding-right: 20px;
}
.wizard8 .checkout-progress-indicator .card-header {
  text-align: center;
  padding: 0;
}
.wizard8 .checkout-progress-indicator .card-header h4 {
  font-size: calc(16.6px + 0.45vw);
  line-height: 1.2272727273;
  font-weight: 600;
  padding: 27px 0;
}
.wizard8 .checkout-progress-indicator .card-header p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  margin-bottom: 50px;
}
.wizard8 .checkout-progress-indicator .card-header img,
.wizard8 .checkout-progress-indicator .card-header svg {
  max-width: 100%;
}
.wizard8 .checkout-shipping-form {
  margin-top: 0;
  padding-left: 50px;
  border: none;
  border-left: 1px solid var(--border-color);
  padding-right: 27px;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding-bottom: 100px;
}
.wizard8 .checkout-shipping-form .card-header {
  margin-bottom: 22px;
  margin-top: 28px;
}
.wizard8 .checkout-shipping-form .create-passord input {
  color: var(--color-light);
}
.wizard9 .checkout-progress2 {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.wizard9 .checkout-progress2 .step {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.wizard9 .checkout-progress2 .step:not(:last-child) {
  margin-bottom: 30px;
}
.wizard9 .checkout-progress2 .step.current span:first-child {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.wizard9 .checkout-progress2 .step.current span:last-child {
  color: var(--color-dark);
}
.wizard9 .checkout-progress2 .step.completed span:first-child {
  background-color: var(--color-success);
  color: var(--color-white);
}
.wizard9 .checkout-progress2 .step.completed span:last-child {
  color: var(--color-dark);
}
.wizard9 .checkout-progress2 .step span:first-child {
  width: 50px;
  height: 50px;
  background-color: rgba(173, 180, 210, 0.5);
  color: var(--color-white);
  -webkit-border-radius: 70rem;
  border-radius: 70rem;
  margin-right: 20px;
}
.wizard9 .checkout-progress2 .step span:last-child {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
}
.wizard7 .checkout-shipping-form {
  margin-top: 84px;
}
.wizard7
  .checkout-shipping-form
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after {
  border: 1px solid var(--color-success);
  background-color: var(--color-success);
}
.wizard7 .payment-status {
  margin-top: 88px;
}
.wizard7 .checkout-shipping-form .edit-profile__body .form-control-plaintext {
  padding-left: 6px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.wizard7
  .checkout-shipping-form
  .edit-profile__body
  .form-control-plaintext::-webkit-input-placeholder {
  font-size: 15px;
  line-height: 1.5333333333;
  font-weight: 400;
  color: var(--body-color);
  text-transform: capitalize;
  background-color: transparent;
}
.wizard7
  .checkout-shipping-form
  .edit-profile__body
  .form-control-plaintext::-moz-placeholder {
  font-size: 15px;
  line-height: 1.5333333333;
  font-weight: 400;
  color: var(--body-color);
  text-transform: capitalize;
  background-color: transparent;
}
.wizard7
  .checkout-shipping-form
  .edit-profile__body
  .form-control-plaintext::-ms-input-placeholder {
  font-size: 15px;
  line-height: 1.5333333333;
  font-weight: 400;
  color: var(--body-color);
  text-transform: capitalize;
  background-color: transparent;
}
.wizard7
  .checkout-shipping-form
  .edit-profile__body
  .form-control-plaintext::placeholder {
  font-size: 15px;
  line-height: 1.5333333333;
  font-weight: 400;
  color: var(--body-color);
  text-transform: capitalize;
  background-color: transparent;
}
.wizard7
  .checkout-shipping-form
  .edit-profile__body
  .form-control-plaintext:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
}
.wizard6.checkout-review .checkout-progress div:not(.step),
.wizard7.checkout-review .checkout-progress div:not(.step) {
  padding: 0 30px;
}
.checkbox-position
  .payment-method-area--top
  .custom-radio
  input[type="radio"]
  + label:before {
  top: 29px;
}
.checkbox-position
  .payment-method-area--top
  .custom-radio
  input[type="radio"]
  + label:after {
  top: 20px;
}
.wizard6 .checkout-review-title {
  margin-top: 83px;
  margin-bottom: 50px;
}
.wizard6 .checkout-shipping-form {
  margin-top: 64px;
}
.wizard6 .payment-method-area--top {
  margin-top: 83px;
}
.wizard6 .checkout-progress div.step {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wizard6 .checkout-progress div.step.current span:nth-of-type(1) {
  background-color: transparent;
}
.wizard6 .checkout-progress div.step.completed span:nth-of-type(1) {
  background-color: transparent;
}
.checkout-progress-indicator .button {
  margin: 0 auto;
  display: block;
}
.card.checkout-shipping-form .card-header h4 {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.5;
  font-weight: 500;
  white-space: normal;
}
.create-passord input::-webkit-input-placeholder {
  color: var(--color-light);
}
.create-passord input::-moz-placeholder {
  color: var(--color-light);
}
.create-passord input::-ms-input-placeholder {
  color: var(--color-light);
}
.create-passord input::placeholder {
  color: var(--color-light);
}
.create-passord .create-passord-warn {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
  padding-top: 4px;
}
.checkout-shipping-form {
  margin-top: 90px;
}
.checkout-shipping-form .edit-profile__body .form-group {
  margin-bottom: 25px;
}
.checkout-shipping-form .edit-profile__body .form-group label {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
}
.checkout-shipping-form
  .edit-profile__body
  .form-group
  .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.checkout-shipping-form
  .edit-profile__body
  .form-group
  .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.checkout-shipping-form
  .edit-profile__body
  .form-group
  .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.checkout-shipping-form
  .edit-profile__body
  .form-group
  .form-control::placeholder {
  color: var(--color-lighten);
}
.payment-method-card {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
}
.payment-method-card .card-header h5 {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.payment-method-card .card-body {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  padding: 30px 25px;
  -webkit-box-shadow: 0 10px 30px var(--shadow3);
  box-shadow: 0 10px 30px var(--shadow3);
}
.payment-method-card .card-body label {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  margin-bottom: 5px;
  color: var(--color-dark);
  text-transform: capitalize;
}
.payment-method-card .card-body .form-control {
  height: 48px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.payment-method-card .card-body .form-control input,
.payment-method-card .card-body .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
  text-transform: capitalize;
}
.payment-method-card .card-body .form-control input,
.payment-method-card .card-body .form-control::-moz-placeholder {
  color: var(--color-lighten);
  text-transform: capitalize;
}
.payment-method-card .card-body .form-control input,
.payment-method-card .card-body .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
  text-transform: capitalize;
}
.payment-method-card .card-body .form-control input,
.payment-method-card .card-body .form-control::placeholder {
  color: var(--color-lighten);
  text-transform: capitalize;
}
.payment-method-card .card-body .form-control:focus {
  background-color: transparent;
}
.payment-method-card
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.payment-method-card
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--color-lighten);
  text-transform: capitalize;
}
.payment-method-area--top {
  margin-top: 109px;
}
.payment-way img {
  width: 50px;
  height: 29px;
}
.payment-way img:not(:last-child) {
  margin-right: 8px;
}
.form-cc-img {
  width: 40px;
  height: 23px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.payment-method-csv input {
  width: 120px;
}
.payment-method-paypal .form-control {
  height: 60px;
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.payment-method-paypal .form-control img {
  width: 103px;
  height: 25px;
}
.checkout-review-title {
  margin-top: 109px;
  margin-bottom: 50px;
}
.crc__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.crc__title h5 {
  font-size: calc(16.2px + 0.15vw);
  line-height: 1.2222222222;
  font-weight: 400;
}
.crc__title-btn {
  text-transform: capitalize;
}
.crc__title-btn img,
.crc__title-btn svg {
  margin-right: 8px;
  width: 15px;
}
.crc .card-body {
  -webkit-box-shadow: 0 10px 30px var(--shadow3);
  box-shadow: 0 10px 30px var(--shadow3);
}
.checkout-review .checkout-progress {
  padding: 0 30px;
}
.check-review__contact {
  -ms-flex-preferred-size: 218px;
  flex-basis: 218px;
}
.check-review__contact p {
  font-size: 15px;
  line-height: 1.7333333333;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 5px;
}
.check-review__contact span {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.crc__method {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
}
.crc__method img {
  width: 40px;
  height: 23px;
  margin-right: 20px;
}
.crc-table tbody tr:first-child td,
.crc-table tfoot tr:first-child td {
  padding-top: 0;
}
.crc-table tbody tr:last-child td,
.crc-table tfoot tr:last-child td {
  padding-bottom: 30px;
}
.crc-table tfoot {
  border-top: 1px solid var(--border-color);
}
.crc-table tfoot .total {
  margin-top: 25px;
}
.crc-table .table td {
  padding: 8px 10px;
}
.crc-table .table td,
.crc-table .table th {
  vertical-align: middle;
}
.crc-table .table td:first-child,
.crc-table .table th:first-child {
  padding-left: 0;
}
.crc-table .table td:last-child,
.crc-table .table th:last-child {
  padding-right: 0;
  text-align: right;
}
.crc-table .table td.subtotal,
.crc-table .table th.subtotal {
  color: var(--color-gray);
}
.crc-table .table td .shipping span,
.crc-table .table td .subtotalTotal span,
.crc-table .table td .taxes span,
.crc-table .table th .shipping span,
.crc-table .table th .subtotalTotal span,
.crc-table .table th .taxes span {
  color: var(--color-gray);
  font-size: 15px;
}
.crc-table .order-summery .total > div:not(:last-child) {
  margin-bottom: 0;
}
.crc .check-review__contact p {
  margin-top: -5px;
}
.payment-status {
  margin-top: 112px;
}
.payment-invoice {
  background-color: var(--bg-white);
}
.payment-invoice-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.payment-invoice-logo a img,
.payment-invoice-logo a svg {
  max-width: 140px;
  width: 100%;
}
.payment-invoice-logo a img.light,
.payment-invoice-logo a svg.light {
  display: none;
}
.payment-invoice-logo span {
  font-size: calc(17.1px + 0.825vw);
  font-weight: 700;
  text-transform: capitalize;
  color: var(--color-dark);
}
.payment-invoice__btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-top: 77px;
}
.payment-invoice__btn button {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  height: 38px;
  print-color-adjust: [economy exact];
  print-color-adjust: exact;
}
.payment-invoice__btn button:not(:last-child) {
  margin-right: 10px;
}
.payment-invoice__btn button.download img,
.payment-invoice__btn button.download svg {
  color: var(--color-white);
}
.payment-invoice__btn button img,
.payment-invoice__btn button svg {
  width: 16px;
  color: var(--color-lighten);
}
.payment-invoice__body {
  padding: 80px 60px 40px;
}
.payment-invoice-address {
  margin-bottom: 57px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.payment-invoice-address__area address {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
  color: var(--color-dark);
  float: right;
  margin-bottom: 0;
  text-align: right;
}
.payment-invoice-qr {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  print-color-adjust: [economy exact];
  print-color-adjust: exact;
  background-color: var(--bg-normal);
}
.payment-invoice-qr__number .display-3 {
  line-height: 1.2777777778;
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 5px;
}
.payment-invoice-qr__number p {
  font-size: 15px;
  line-height: 1.7333333333;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 0;
}
.payment-invoice-qr__code {
  text-align: center;
  print-color-adjust: [economy exact];
  print-color-adjust: exact;
}
.payment-invoice-qr__code img {
  width: 270px;
  height: 60px;
}
.payment-invoice-qr__code p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.payment-invoice-qr__address {
  float: right;
}
.payment-invoice-qr__address p {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: uppercase;
  margin-bottom: 4px;
}
.payment-invoice-qr__address span {
  font-size: 15px;
  line-height: 1.6;
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.payment-invoice-table.userDatatable table thead tr th {
  color: var(--color-dark);
}
.payment-invoice-table table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--border-color);
}
.payment-invoice-table table thead tr {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.payment-invoice-table table thead tr th:first-child {
  border-left: 1px solid var(--border-color);
  -webkit-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
.payment-invoice-table table thead tr th {
  color: var(--color-gray);
  background: var(--bg-normal);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-bottom-color: var(--border-color);
  white-space: nowrap;
  print-color-adjust: [economy exact];
  print-color-adjust: exact;
}
.payment-invoice-table table > thead > tr:first-child th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.payment-invoice-table table thead tr th:last-child {
  border-right: 1px solid var(--border-color);
  -webkit-border-radius: 0 10px 10px 0 !important;
  border-radius: 0 10px 10px 0 !important;
  border-right: 1px solid var(--border-color);
  border-radius: 0 10px 10px 0 !important;
}
.payment-invoice-table .table {
  border-collapse: separate;
  border-spacing: 0;
}
.payment-invoice-table .table td,
.payment-invoice-table tbody tr th {
  padding: 20px;
  vertical-align: middle;
  white-space: nowrap;
}
.payment-invoice-table table thead .product-cart__header th:first-child {
  padding-left: 50px;
  width: 109px;
}
.payment-invoice-table tbody tr td {
  border-bottom: 1px solid var(--border-color);
}
.payment-invoice-table tbody tr th {
  border-bottom: 1px solid var(--border-color);
}
.payment-invoice-table tbody tr th:first-child {
  padding-left: 50px;
  vertical-align: top;
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
}
.payment-invoice-table .invoice-quantity,
.payment-invoice-table .order,
.payment-invoice-table .unit {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.order-search__form {
  padding: 15px 20px;
  background: var(--bg-lighter);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 20px;
  border-radius: 20px;
}
.order-search__form .form-control {
  padding: 8px 13px;
  background: 0 0;
}
.order-search__form .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.order-search__form .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.order-search__form .form-control::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.order-search__form .form-control::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.order-search__form img,
.order-search__form svg {
  color: var(--color-light);
  width: 18px;
}
.project-tap.order-project-tap {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.project-tap.order-project-tap:not(:first-child):after {
  background-color: var(--border-color);
}
.order-button-group button {
  height: 38px;
  margin: 4px;
}
.orderDatatable {
  background-color: var(--bg-white);
  border: 1px solid var(--border-light);
}
.orderDatatable .table thead tr th {
  padding: 15px 20px;
}
.orderDatatable .table tbody tr td {
  padding: 6px 20px;
}
.orderDatatable.sellerDatatable .table tbody tr td {
  padding: 8px 20px;
}
.orderDatatable.sellerDatatable .table tbody tr:first-child td {
  padding-top: 9px;
}
.orderDatatable.sellerDatatable .table tbody tr:last-child td {
  padding-bottom: 9px;
}
.orderDatatable .table-hover tbody tr {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.orderDatatable .table-hover tbody tr:hover {
  --bs-table-accent-bg: var(--bg-normal);
}
.orderDatatable .table-hover tbody tr td:first-child {
  -webkit-border-bottom-left-radius: 10px;
  border-bottom-left-radius: 10px;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
}
.orderDatatable .table-hover tbody tr td:last-child {
  -webkit-border-bottom-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.orderDatatable tbody .custom-checkbox input[type="checkbox"] + label {
  margin-right: 0;
}
.orderDatatable-title {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.orderDatatable-status span {
  height: 24px;
  padding: 0 10px;
  font-size: 12px;
  line-height: 1.25;
  font-weight: 500;
  text-transform: capitalize;
}
.orderDatatable_actions {
  min-width: 130px;
  max-width: 100%;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.orderDatatable_actions li a {
  width: 35px;
  height: 35px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.orderDatatable_actions li a.view:hover {
  background: rgba(var(--color-primary-rgba), 0.1);
}
.orderDatatable_actions li a.view:hover i,
.orderDatatable_actions li a.view:hover svg {
  color: var(--color-primary);
}
.orderDatatable_actions li a.edit:hover {
  background: rgba(var(--color-info-rgba), 0.1);
}
.orderDatatable_actions li a.edit:hover i,
.orderDatatable_actions li a.edit:hover svg {
  color: var(--color-info);
}
.orderDatatable_actions li a.remove:hover {
  background: rgba(var(--color-danger-rgba), 0.1);
}
.orderDatatable_actions li a.remove:hover i,
.orderDatatable_actions li a.remove:hover svg {
  color: var(--color-danger);
}
.orderDatatable_actions li a img,
.orderDatatable_actions li a svg {
  width: 16px;
  color: var(--color-lighten);
}
.orderDatatable_actions li a i {
  color: var(--color-lighten);
  font-size: calc(16.2px + 0.15vw);
}
.selling-table-wrap table tbody td {
  white-space: nowrap;
  padding: 10px 15px 10px 0;
  color: var(--color-dark);
}
.selling-table-wrap table tbody tr:first-child td {
  padding-top: 15px;
}
.selling-table-wrap table thead tr th {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: var(--color-light);
  text-transform: uppercase;
  padding: 11px 15px;
}
.selling-table-wrap table tr th:first-child {
  -webkit-border-radius: 6px 0 0 6px;
  border-radius: 6px 0 0 6px;
}
.selling-table-wrap table tr th:last-child {
  -webkit-border-radius: 0 6px 6px 0;
  border-radius: 0 6px 6px 0;
}
.selling-table-wrap--source.selling-table-wrap--transition.selling-table-wrap
  table
  tbody
  td {
  padding: 13px 15px 12px 0;
}
.selling-table-wrap--source.selling-table-wrap--transition.selling-table-wrap
  table
  tbody
  tr:first-child
  td {
  padding-top: 25px;
}
.selling-table-wrap--source.selling-table-wrap--transition
  .selling-product-img-wrapper {
  width: 44px;
  height: 44px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-right: 15px;
}
.selling-table-wrap--source.selling-table-wrap--transition
  .selling-product-img-wrapper
  i {
  font-size: 20px;
}
.selling-table-wrap--source.selling-table-wrap--transition
  .selling-product-img
  h6 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
}
.selling-table-wrap--source.selling-table-wrap--transition
  .selling-product-img
  span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.selling-table-wrap--source .selling-product-img-wrapper {
  margin-right: 12px;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.selling-table-wrap--source .selling-product-img-wrapper img {
  width: auto;
  height: auto;
  margin-right: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
}
.selling-table-wrap--source .selling-product-img-wrapper i {
  font-size: calc(16.2px + 0.15vw);
}
.selling-table-wrap--source .progress {
  min-width: 120px;
  height: 4px;
}
.selling-table-wrap--source.selling-table-wrap table tbody td {
  width: 20%;
}
.selling-table-wrap--source .table--default tr td:last-child {
  padding-right: 0;
}
.selling-table-wrap--source .table--default tbody tr:hover {
  background-color: transparent;
}
.selling-table-wrap.selling-table-wrap--2 table tbody tr:first-child td {
  padding-top: 3px;
}
.selling-table-wrap.selling-table-wrap--2 table tbody tr td:not(:first-child) {
  font-size: 14px;
}
.selling-table-wrap.selling-table-wrap--2 table tbody tr td:last-child {
  padding-right: 0;
}
.selling-table-wrap--traffic {
  margin-top: -13px;
  margin-bottom: 2px;
}
.selling-table-wrap--traffic table tbody td {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
}
.selling-table-wrap--traffic .selling-product-img span {
  text-transform: capitalize;
}
.selling-table-wrap--member table tbody td {
  color: var(--color-gray);
}
.selling-table-wrap--member table tbody td .status ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.selling-table-wrap--member table tbody td .status ul li {
  color: var(--color-light);
  white-space: nowrap;
}
.selling-table-wrap--member
  table
  tbody
  td
  .status
  ul
  li:not(:last-child):after {
  content: ", ";
  margin-right: 4px;
}
.selling-table-wrap--member table tbody td .ratio-percentage {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark);
}
.selling-table-wrap--member .table--default tbody tr td:not(:first-child) {
  text-align: left;
}
.selling-table-wrap__top-product table tbody tr td:not(:first-child) {
  padding-left: 15px;
}
.selling-product-img img {
  margin-right: 12px;
  width: 32px;
  height: 32px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.selling-product-img span {
  color: var(--color-dark);
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3333333333;
}
.selling-product-img--2 img {
  width: 25px;
  height: 25px;
  -webkit-border-radius: 0;
  border-radius: 0;
  -o-object-fit: contain;
  object-fit: contain;
}
.top-menu .demo2 .col-xxl-6 + .col-xxl-6 .card {
  height: 100%;
}
.top-menu .demo3 .col-xxl-4 + .col-xxl-8 .card {
  height: 100%;
}
.top-menu .demo3 .col-xxl-4 + .col-xxl-4 > div {
  height: 100%;
}
.top-menu .demo4 .banner-feature--5 {
  min-height: 100%;
}
.top-menu .demo4 .banner-feature--7 {
  min-height: 201px;
}
.top-menu .demo5 .date-picker__calendar-height {
  min-height: 543px;
}
table.table-basic tr:not(:last-child) td,
table.table-basic tr:not(:last-child) th {
  border-bottom: 1px solid var(--border-light);
}
table.table-basic tr td {
  border: 0;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 500;
  background-color: var(--bg-normal);
  color: var(--color-dark);
}
table.table-basic .table-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  /* justify-content: flex-end; */
  padding-right: 1rem;
}
table.table-basic .table-actions a:not(:last-child) {
  margin-right: 16px;
}
table.table-basic .table-actions a i,
table.table-basic .table-actions a img,
table.table-basic .table-actions a svg {
  width: 16px;
  color: var(--color-light);
}
table.table-rounded tr td,
table.table-rounded tr th {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
}
table.table-rounded thead tr th {
  padding: 16px 20px;
  text-transform: capitalize;
  background-color: var(--bg-normal);
  color: var(--color-gray);
}
table.table-rounded thead tr th:first-child {
  -webkit-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
table.table-rounded thead tr th:last-child {
  -webkit-border-radius: 0 10px 10px 0;
  border-radius: 0 10px 10px 0;
}
table.table-rounded tbody tr:hover td {
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
table.table-rounded tbody tr td {
  padding: 12px 20px;
  vertical-align: middle;
  background-color: var(--color-white);
}
table.table-rounded tbody tr td:first-child {
  -webkit-border-radius: 10px 0 0 10px;
  border-radius: 10px 0 0 10px;
}
table.table-rounded tbody tr td:last-child {
  -webkit-border-radius: 0 10px 10px 0;
  border-radius: 0 10px 10px 0;
}
.table4 {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--color-white);
}
.table4 .checkbox-group-wrapper {
  margin-right: 20px;
}
.table4 .table td {
  border-bottom: 1px solid var(--border-color);
  padding: 18px 20px;
}
.table4 .table thead th {
  border-top: none;
  border-bottom: 1px solid var(--border-color);
}
.table4 .table thead tr th:first-child {
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
}
.table4 .table thead tr th:last-child {
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
}
.table4 tbody tr {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.table4 tbody tr:hover {
  background-color: #fafafa;
}
.table4 .userDatatable-header th {
  background-color: #fafafa;
  padding: 15px 20px;
}
.table4 .userDatatable-title {
  color: rgba(0, 0, 0, 0.85);
}
.table4 .userDatatable-content {
  font-weight: 400;
  white-space: nowrap;
}
.table5.table4 .userDatatable-header th {
  background-color: unset;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  padding: 15px 20px;
}
.table5.table4 .userDatatable-header th:hover {
  background-color: #f2f2f2;
}
.table5 .userDatatable-header {
  background: #fafafa;
}
.table5 .userDatatable-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.table5 .userDatatable-title .userDatatable-sort {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 8px;
  margin-top: 4px;
}
.table5 .userDatatable-title .userDatatable-sort i {
  line-height: 5px;
  font-size: 13px;
  color: var(--color-light);
}
.table5 .userDatatable-title .userDatatable-sort i.down {
  color: var(--color-secondary);
}
.table5 .userDatatable-title .userDatatable-filter i {
  font-size: 10px;
  color: var(--body-color);
}
.table5 tbody .custom-checkbox input[type="checkbox"] + label {
  margin-right: 18px;
}
.table5 .pagination-total-text {
  display: inline-block;
  height: 32px;
  margin-right: 10px;
  line-height: 30px;
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-dark);
}
.browser-states {
  height: 100%;
}
.browser-states .selling-table-wrap table tbody td {
  padding: 15px 15px 15px 0;
}
.add-product {
  -webkit-border-radius: 20px;
  border-radius: 20px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-normal);
  padding: 30px;
}
.add-product .card-body {
  -webkit-box-shadow: 0 10px 30px var(--shadow3);
  box-shadow: 0 10px 30px var(--shadow3);
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: var(--color-white);
}
.add-product .card-body .card-header {
  background-color: var(--color-white);
  border-bottom: 1px solid var(--border-color);
  padding: 27px 40px;
}
.add-product__body {
  padding: 24px 40px;
}
.add-product__body .form-group {
  margin-bottom: 23px;
}
.add-product__body .form-group label {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: capitalize;
  margin-bottom: 7px;
}
.add-product__body .form-group .form-control {
  height: 48px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  resize: none;
}
.add-product__body .form-group .form-control:focus {
  border: 1px solid var(--color-primary);
}
.add-product__body .form-group .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.add-product__body .form-group .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.add-product__body .form-group .form-control::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.add-product__body .form-group .form-control::placeholder {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.add-product__body .form-group .form-control:-moz-placeholder {
  line-height: 35px;
}
.add-product__body .form-group .form-control::-moz-placeholder {
  line-height: 35px;
}
.add-product__body .form-group textarea {
  padding: 15px 20px;
  min-height: 150px;
}
.add-product__body .form-group .input-group-text {
  background-color: var(--bg-normal);
  border: 1px solid var(--border-color);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  color: var(--color-gray);
  height: 100%;
  border-right: 0;
  margin-right: -1px;
}
.add-product__body .form-group .input-group-text img,
.add-product__body .form-group .input-group-text svg {
  width: 16px;
}
.add-product__body .form-group .input-group input {
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -webkit-border-bottom-left-radius: 0;
  border-bottom-left-radius: 0;
}
.add-product__body .form-group .input-group input::-webkit-input-placeholder {
  color: var(--color-gray);
}
.add-product__body .form-group .input-group input::-moz-placeholder {
  color: var(--color-gray);
}
.add-product__body .form-group .input-group input::-ms-input-placeholder {
  color: var(--color-gray);
}
.add-product__body .form-group .input-group input::placeholder {
  color: var(--color-gray);
}
.add-product__body-img {
  padding: 30px 40px;
}
.add-product-btn button:last-child {
  -webkit-box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
  box-shadow: 0 8px 13px rgba(95, 99, 242, 0.2);
}
.upload-product-img {
  width: 100%;
  border: 2px dashed #c6d0dc;
  background: var(--bg-normal);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  text-align: center;
  padding: 80px 0;
  display: block;
}
.upload-product-img img,
.upload-product-img svg {
  color: var(--color-lighten);
  width: 60px;
  height: auto;
}
.upload-product-img .pera {
  font-weight: 500;
  margin-top: 25px;
  margin-bottom: 5px;
  display: block;
  font-size: calc(16.4px + 0.3vw);
}
.upload-product-img span {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-gray);
}
.upload-product-img span a {
  color: var(--color-secondary);
}
.file-upload {
  position: relative;
  display: inline-block;
}
.file-upload__label {
  display: block;
  color: var(--color-dark);
}
.file-upload__label:hover {
  cursor: pointer;
  color: var(--color-dark);
}
.file-upload__input {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  width: 0;
  height: 100%;
  opacity: 0;
}
.upload-media-area {
  width: 100%;
}
.upload-media-area > img {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  width: 100px;
  height: 80px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.upload-media-area__title {
  -webkit-box-flex: 8;
  -ms-flex: 8;
  flex: 8;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  word-break: break-all;
}
.upload-media-area__title p {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 0;
}
.upload-media-area__title span {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  color: var(--color-light);
}
.upload-media-area__btn button {
  padding: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.upload-media-area__btn button:hover {
  background: rgba(var(--light-gray-rgba), 0.2);
}
.upload-media-area__btn button img,
.upload-media-area__btn button svg {
  color: var(--color-danger);
  width: 14px;
  margin: 0;
}
.add-product-status-radio .custom-radio input[type="radio"] + label {
  color: var(--color-gray);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-weight: 500;
}
.add-product-status-radio .custom-radio input[type="radio"]:checked + label {
  color: var(--color-dark);
}
/* .quantity-appearance input[type="number"] {
  -moz-appearance: textfield;
} */
.quantity-appearance input[type="number"]::-webkit-inner-spin-button,
.quantity-appearance input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.quantity-appearance .input-group-prepend {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.quantity-appearance .pt_Quantity {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 12;
  -ms-flex: 12;
  flex: 12;
}
.quantity-appearance .pt_Quantity input {
  font-size: 15px;
}
.quantity-appearance .pt_Quantity input:focus {
  outline: 0;
}
.quantity-appearance .pt_Quantity:hover .pt_QuantityNav {
  opacity: 1;
}
.quantity-appearance .pt_QuantityNav {
  float: left;
  position: relative;
  height: 48px;
  opacity: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.quantity-appearance .pt_QuantityButton {
  position: relative;
  cursor: pointer;
  border-left: 1px solid var(--border-color);
  width: 25px;
  text-align: center;
  height: 24px;
  color: var(--color-gray);
  font-size: 9px;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.quantity-appearance .pt_QuantityButton:active {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
}
.quantity-appearance .pt_QuantityButton.pt_QuantityUp {
  position: absolute;
  height: 52%;
  top: 0;
  border-bottom: 1px solid var(--border-color);
}
.quantity-appearance .pt_QuantityButton.pt_QuantityDown {
  position: absolute;
  bottom: 0;
  height: 50%;
}
.ordertable-btns li:not(:last-child) a {
  margin-right: 25px;
}
.ordertable-btns li img,
.ordertable-btns li svg {
  color: var(--color-lighten);
  width: 18px;
}
.order-profile-icon img,
.order-profile-icon svg {
  width: 16px;
  color: var(--color-primary);
}
.order-dateTitle {
  margin-left: 94px;
}
.shipped-dataTable table tbody tr:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.dashboard-base {
  margin-top: 95px;
  margin-bottom: 90px;
}
.dashboard-base .table td,
.dashboard-base .table th {
  vertical-align: middle;
  padding: 0 15px;
  white-space: nowrap;
}
.dashboard-base .table tbody tr th {
  padding-left: 0;
}
.dashboard-base .type-info {
  font-size: 15px;
  line-height: 3.2;
  font-weight: 400;
  padding: 0;
}
.icon-list {
  margin: -10px 0;
}
.icon-list .col-sm-6 {
  margin: 10px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.icon-list .col-sm-6 .icon-text {
  font-size: 14px;
}
.icon-list i,
.icon-list svg {
  min-width: 25px;
  margin-right: 8px;
  color: var(--color-gray);
  font-size: calc(16.2px + 0.15vw);
}
.icon-list img,
.icon-list svg {
  width: 18px;
  height: 18px;
}
.search-result form {
  height: 48px;
}
.search-result form .form-control {
  padding: 8px 13px;
}
.search-result form .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.search-result form .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.search-result form .form-control::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.search-result form .form-control::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-lighten);
}
.search-result form img,
.search-result form svg {
  width: 18px;
  color: var(--color-light);
}
.search-result form button {
  font-size: 14px;
  line-height: 1.1428571429;
  font-weight: 500;
  color: var(--color-primary);
  text-transform: capitalize;
}
.search-keyword ul li {
  margin: 5px;
}
.search-keyword ul li a {
  padding: 6px 15px;
  color: var(--color-light);
  -webkit-box-shadow: 0 3px 6px rgba(146, 153, 184, 0.0509803922);
  box-shadow: 0 3px 6px rgba(146, 153, 184, 0.0509803922);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--color-white);
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 500;
  text-transform: capitalize;
}
.search-keyword ul li a.active {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.search-keyword ul li:not(:last-child) {
  margin-right: 4px;
}
.search-content .search-content__keyResult a h6 {
  text-transform: capitalize;
}
.search-content .search-content__keyResult a h6 span {
  font-weight: 600;
}
.search-content .search-content__keyResult p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 10px;
}
.search-content .keyword-searching {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: var(--color-dark);
  text-transform: capitalize;
  margin-bottom: 0;
}
.search-content .keyword-searching span {
  color: var(--color-light);
  text-transform: lowercase;
}
.gallery-filter .filtr-container {
  display: none;
}
.push-down--style {
  margin: 0 -25px;
  direction: ltr;
}
.fltr-controls.active {
  color: var(--color-primary);
}
.filtr-item--style {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  width: 25%;
}
.filtr-item--style img {
  width: 100%;
}
.filtr-item--style2 {
  width: 25%;
}
.filtr-item--style2 .card {
  width: 100%;
  overflow: hidden;
}
.filtr-item--style2 .card img {
  width: 100%;
}
.gc__title p {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 2px;
}
.gc__title span {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
}
.gc--gallery2 {
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.gc--gallery2:after {
  position: absolute;
  content: "";
  left: 0;
  top: 200px;
  width: 100%;
  height: 100%;
  background-color: rgba(95, 99, 242, 0.7);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}
.gc--gallery2:after {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.gc--gallery2:hover:after {
  visibility: visible;
  opacity: 1;
  top: 0;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.gc--gallery2:hover .card-body {
  visibility: visible;
  opacity: 1;
}
.gc--gallery2 .card-body {
  padding: 27px 25px;
  text-align: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.gc--gallery2 .gc__title p,
.gc--gallery2 .gc__title span {
  color: var(--color-white);
}
.gc--gallery2 .subtitle {
  color: var(--color-white);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 15px;
}
.gc--gallery2 .gc__link {
  margin-top: 19px;
}
.gc--gallery2 .gc__link li:not(:last-child) {
  margin-right: 10px;
}
.gc--gallery2 .gc__link li a {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50rem;
  border-radius: 50rem;
  color: var(--color-white);
  background: rgba(255, 255, 255, 0.25);
}
.gc--gallery2 .gc__link li a svg {
  width: 12px;
  height: 12px;
}
.gallery-filter .ap-tab-main .nav-link {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-light);
  padding: 20px 0;
}
.gallery-filter .ap-tab-main .nav-link.active {
  font-weight: 500;
  color: var(--color-primary);
}
.gallery-filter .ap-tab-main .nav-link:not(:last-child) {
  margin-right: 25px;
}
.gallery-filter .ap-tab-main .nav-link:after {
  height: 2px;
  bottom: 0;
}
.error-page {
  padding: 30px;
  padding-top: 0;
}
.error-page__title {
  font-size: calc(20.4px + 3.3vw);
  line-height: 0.6333333333;
  font-weight: 600;
  color: var(--color-lighten);
  margin-top: 107px;
  margin-bottom: 37px;
}
.error-page img,
.error-page svg {
  max-width: 100%;
  height: auto;
}
.application-faqs .panel-title {
  position: relative;
}
.application-faqs .panel-title a {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
  width: 90%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.application-faqs .panel-title > a:before {
  float: right !important;
  font-family: line awesome free;
  font-weight: 900;
  content: "\f068";
  padding-right: 5px;
  color: var(--color-light);
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}
.application-faqs .panel-title > a.collapsed:before {
  float: right !important;
  content: "\f067";
  font-family: line awesome free;
  font-weight: 900;
}
.application-faqs .panel-title > a:active,
.application-faqs .panel-title > a:focus,
.application-faqs .panel-title > a:hover {
  text-decoration: none;
}
.application-faqs .panel-heading {
  padding: 19px 25px;
  -webkit-border-radius: 5px 5px 0 0;
  border-radius: 5px 5px 0 0;
}
.application-faqs .panel-body {
  padding: 21px 25px 29px;
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  border-top: 1px solid var(--border-color);
}
.application-faqs .panel-body p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray);
}
.application-faqs .collapse.show .panel-body {
  -webkit-box-shadow: 0 15px 40px var(--shadow5);
  box-shadow: 0 15px 40px var(--shadow5);
}
.application-faqs .panel {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
  border: 1px solid var(--border-color);
}
.application-faqs .button-group button {
  height: 36px;
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 500;
}
.application-faqs .button-group button img,
.application-faqs .button-group button svg {
  width: 18px;
  height: auto;
}
.breadcrumb-main {
  line-height: 1.8571428571;
}
.breadcrumb-main.application-ui button {
  height: 35px;
}
.breadcrumb-main.application-ui .breadcrumb-action {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.breadcrumb-main.application-ui .breadcrumb-action .action-btn .btn {
  padding: 0 15px;
  height: 35px;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border-color: var(--color-primary);
}
.breadcrumb-main.application-ui .breadcrumb-action .action-btn .btn img,
.breadcrumb-main.application-ui .breadcrumb-action .action-btn .btn svg {
  color: var(--color-white);
  width: 16px;
  margin-right: 7px;
}
.task-activities .ffp {
  padding: 9px 0;
}
.task-activities .ffp span svg {
  width: 12px;
}
.faq-support img,
.faq-support svg {
  max-width: 100%;
}
.faq-support button {
  white-space: nowrap;
}
.faqs-wrapper-tab {
  padding-top: 28px;
}
.faqs-wrapper-tab .nav-link {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 13px;
  color: var(--color-gray);
  height: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-left: 2px solid transparent;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.faqs-wrapper-tab .nav-link:not(:last-child) {
  margin-bottom: 12px;
}
.faqs-wrapper-tab .nav-link .dot {
  width: 8px;
  height: 8px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  opacity: 0.5;
  margin-right: 12px;
}
.faqs-wrapper-tab .nav-link img,
.faqs-wrapper-tab .nav-link svg {
  width: 16px;
  margin-right: 14px;
}
.faqs-wrapper-tab .nav-link.active,
.faqs-wrapper-tab .nav-link:hover {
  border-left: 2px solid var(--color-primary);
  color: var(--color-primary);
  padding: 0 27px;
}
.faqs-wrapper-tab .nav-link.active .dot,
.faqs-wrapper-tab .nav-link:hover .dot {
  opacity: 1;
}
.pricing-action button {
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.pricing__tag {
  font-size: 13px;
  line-height: 1.5384615385;
  font-weight: 500;
  padding: 7px 22px;
  height: 32px;
}
.pricing__badge {
  font-size: 12px;
  line-height: 1.6666666667;
  font-weight: 500;
  color: var(--color-white);
  margin-left: 12px;
  height: 20px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 3px 6px;
}
.pricing__price .pricing_offer {
  font-size: 16px;
  line-height: 1.5625;
  font-weight: 400;
  color: var(--color-light);
  margin-right: 10px;
}
.pricing__price .pricing_user {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
  margin-left: 8px;
}
.pricing__price .pricing_value {
  margin-top: 21px;
}
.pricing__price .pricing_subtitle {
  font-size: 13px;
  line-height: 1.5384615385;
  font-weight: 500;
  color: var(--color-gray);
  text-transform: capitalize;
}
.pricing__price sup {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
  color: var(--color-lighten);
}
.pricing__features ul {
  padding-top: 30px;
  padding-bottom: 2px;
}
.pricing__features ul li {
  list-style-type: none;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  position: relative;
  padding-left: 1.33333rem;
  margin-left: 0;
  color: var(--color-gray);
  text-transform: capitalize;
}
.pricing__features ul li:not(:last-child) {
  margin-bottom: 15px;
}
.pricing__features ul li > span:first-child {
  position: absolute;
  left: 0;
  top: 4px;
  margin-right: 4px;
  width: 1.13333rem;
  font-size: 0.73333rem;
}
.pricing__features ul li span.fa-check {
  color: var(--color-success);
}
.pricing__features ul li span.fa-times {
  color: #e23636;
}
.ai-list {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ai-list-item {
  margin: 0 8px;
}
.ai-list-item span {
  color: var(--color-lighten);
  font-size: 14px;
  line-height: 1.2857142857;
  font-weight: 500;
}
.ai-list-item span.active {
  color: var(--color-dark);
}
.ail {
  display: none;
}
.ail,
.ail *,
.ail :after,
.ail :before,
.ail + .ail-btn,
.ail:after,
.ail:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ail ::-moz-selection,
.ail :after::-moz-selection,
.ail :before::-moz-selection,
.ail + .ail-btn::-moz-selection,
.ail::-moz-selection,
.ail:after::-moz-selection,
.ail:before::-moz-selection {
  background: 0 0;
}
.ail ::selection,
.ail :after::selection,
.ail :before::selection,
.ail + .ail-btn::selection,
.ail::selection,
.ail:after::selection,
.ail:before::selection {
  background: 0 0;
}
.ail + .ail-btn {
  outline: 0;
  display: block;
  width: 45px;
  height: 24px;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
}
.ail + .ail-btn:after,
.ail + .ail-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 18px;
  height: 18px;
}
.ail + .ail-btn:after {
  left: 1px;
  top: 1px;
}
.ail + .ail-btn:before {
  display: none;
}
.ail:checked + .ail-btn:after {
  left: 53%;
}
.ail-light + .ail-btn {
  background-color: var(--color-primary);
  -webkit-border-radius: 2em;
  border-radius: 2em;
  padding: 2px;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.ail-light + .ail-btn:after {
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-white);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ail-light:checked + .ail-btn {
  background-color: var(--color-primary);
}
.note-contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -12px;
}
.note-contents .note-sidebar-wrapper {
  width: 320px;
  margin: 0 12px;
}
.note-contents .note-grid-wrapper {
  margin: 0 12px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.note-contents .note-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -12px;
}
.note-contents .note-grid .note-single {
  width: 33.33%;
  padding: 12px;
}
.note-sidebar {
  height: 100%;
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.note-sidebar .note-types {
  margin: 20px 0 35px;
}
.note-sidebar ul li a {
  color: var(--color-gray-x);
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.note-sidebar ul li a svg {
  color: var(--color-lighten);
  width: 16px;
  margin-right: 13px;
}
.note-sidebar ul li a span {
  width: 10px;
  height: 10px;
  display: inline-block;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--light-gray-rgba);
  margin-right: 14px;
}
.note-sidebar ul li a.active {
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgba), 0.1);
}
.note-sidebar ul li a.active svg {
  color: var(--color-primary);
}
.note-sidebar ul li a:hover {
  color: var(--color-primary);
  background: rgba(var(--color-primary-rgba), 0.1);
}
.note-sidebar ul li a:hover svg {
  color: var(--color-primary);
}
.note-sidebar .note-labels p {
  padding: 0 20px;
  font-size: 12px;
  color: var(--color-light);
  margin-bottom: 7px;
}
.note-sidebar .note-labels p svg {
  width: 18px;
  color: var(--color-lighten);
  margin-right: 6px;
}
.note-sidebar .note-labels .label-personal span {
  background: var(--color-primary);
}
.note-sidebar .note-labels .label-work span {
  background: var(--color-success);
}
.note-sidebar .note-labels .label-social span {
  background: var(--color-warning);
}
.note-sidebar .note-labels .label-important span {
  background: var(--color-info);
}
.notes-wrapper {
  background: var(--color-white);
  padding: 30px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.notes-wrapper .row {
  margin-bottom: -30px;
}
.note-card {
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.note-card .card {
  background: 0 0;
}
.note-card .note-title {
  font-size: 16px;
  color: var(--color-dark);
  position: relative;
  display: inline-block;
  margin-bottom: 18px;
}
.note-card .note-title .note-status {
  width: 7px;
  height: 7px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-left: 10px;
  display: inline-block;
  background: 0 0;
  position: relative;
  top: -2px;
}
.note-card p {
  margin-bottom: 10px;
}
.note-default {
  background: rgba(222, 222, 222, 0.4);
}
.note-social {
  background: rgba(var(--color-warning-rgba), 0.4);
}
.note-social .note-title .note-status {
  background: var(--color-warning);
}
.note-work {
  background: rgba(1, 184, 26, 0.4);
}
.note-work .note-title .note-status {
  background: #01b81a;
}
.note-important {
  background: rgba(var(--color-info-rgba), 0.4);
}
.note-important .note-title .note-status {
  background: var(--color-info);
}
.note-personal {
  background: rgba(var(--color-primary-rgba), 0.4);
}
.note-personal .note-title .note-status {
  background: var(--color-primary);
}
.note-action {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.note-action__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 -5px;
}
.note-action__left a {
  padding: 0 5px;
}
.note-action__left a img {
  width: 16px;
}
.note-action__left a svg {
  width: 16px;
  color: var(--color-gray);
}
.note-action__left a.favorite svg {
  color: var(--color-warning);
}
.note-action__right a img {
  width: 16px;
}
.note-action__right a svg {
  width: 16px;
  color: var(--color-light) !important;
}
.note-action__right .label-dropdown .dropdown-default {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  right: 0 !important;
  left: auto !important;
}
.label-dropdown .dropdown-default {
  left: auto;
  right: 0;
}
.label-dropdown a.dropdown-item {
  position: relative;
  padding-left: 40px;
  padding-right: 40px;
}
.label-dropdown a.dropdown-item:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  left: 20px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: 0 0;
}
.label-dropdown a.dropdown-item.nl-personal:before {
  background: var(--color-primary);
}
.label-dropdown a.dropdown-item.nl-work:before {
  background: var(--color-success);
}
.label-dropdown a.dropdown-item.nl-social:before {
  background: var(--color-warning);
}
.label-dropdown a.dropdown-item.nl-important:before {
  background: var(--color-info);
}
.counting-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 32px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -25px;
  margin-bottom: 40px;
}
.counting-area .number {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  font-size: calc(18.6px + 1.95vw);
  line-height: 0.9047619048;
  font-weight: 600;
  color: var(--color-dark);
  min-width: 50px;
  margin: 25px;
}
.counting-area .number .text {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
  margin-top: 12px;
}
.coming-soon {
  padding: 120px 0;
}
.coming-soon img,
.coming-soon svg {
  width: 250px;
  height: 100px;
}
.coming-soon h1 {
  font-size: calc(20.2px + 3.15vw);
  line-height: 1.0689655172;
  font-weight: 600;
}
.coming-soon .light {
  display: none;
}
.coming-soon .subtitle {
  font-size: calc(16.1px + 0.075vw);
  line-height: 1.6470588235;
  font-weight: 400;
  color: var(--color-gray-x);
  max-width: 580px;
  margin: 30px auto 53px;
}
.coming-soon__body {
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 0 30px;
}
.coming-soon .form-inline .form-control {
  width: 320px;
  max-width: 100%;
  height: 48px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.coming-social ul li a {
  width: 44px;
  height: 44px;
  color: var(--color-white);
  font-size: 18px;
}
.coming-social ul li:not(:last-child) {
  margin-right: 15px;
}
.coming-social p {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-light);
  margin-bottom: 0;
  text-align: center;
  margin-top: 28px;
}
.subscribe-bar button {
  height: 48px;
}
.changelog__according {
  margin-top: 30px;
}
.changelog__according .card {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.changelog__according .card:not(:last-child) {
  margin-bottom: 20px;
}
.changelog__according .card .card-header {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background-color: var(--bg-normal);
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-bottom: 0;
}
.changelog__accordingCollapsed {
  height: 66px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}
.changelog__accordingCollapsed:not(.collapsed) .changelog__accordingArrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.changelog__accordingCollapsed .changelog__accordingArrow {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.changelog__accordingCollapsed .changelog__accordingArrow img,
.changelog__accordingCollapsed .changelog__accordingArrow svg {
  width: 18px;
  color: var(--color-light);
}
.changelog__accordingTitle .v-num {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.version-list__top .badge {
  font-size: 12px;
  line-height: 1.2;
  letter-spacing: 1.4px;
  font-weight: 500;
  display: inline-block;
  padding: 5px 8px;
  height: auto;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 14px;
  text-transform: capitalize;
}
.version-list__single:not(:last-child) {
  margin-bottom: 30px;
}
.version-list__single ul li {
  position: relative;
  padding-left: 20px;
  font-size: 16px;
  color: var(--color-gray);
  font-size: 16px;
}
.version-list__single ul li:not(:last-child) {
  margin-bottom: 12px;
}
.version-list__single ul li::after {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  content: "";
}
.version-list__single ul.version-success li::after {
  background-color: var(--color-success);
}
.version-list__single ul.version-info li::after {
  background-color: var(--color-info);
}
.version-list__single ul.version-primary li::after {
  background-color: var(--color-primary);
}
.v-num {
  font-size: calc(16.2px + 0.15vw);
  line-height: 1.2222222222;
  font-weight: 500;
  color: var(--color-dark);
}
.changelog-history__title {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.history-title {
  font-size: 11px;
  line-height: 1.3636363636;
  margin-bottom: 24px;
  color: var(--color-light);
}
.v-arrow {
  color: #5c637e;
  margin: 0 4px 0 14px;
}
.rl-date {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
  color: var(--color-gray-x);
}
.v-history-list li .version-name {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
  margin-right: 10px;
}
.v-history-list li .version-date {
  font-size: 14px;
  color: #868eae;
}
.v-history-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.v-history-list li:not(:last-child) {
  margin-bottom: 24px;
}
.banner-feature {
  z-index: 1;
  overflow: hidden;
}
.banner-feature img,
.banner-feature svg {
  max-width: 100%;
}
.banner-feature .card-body {
  padding: 43px 30px 40px;
}
.banner-feature__heading {
  line-height: 1.2666666667;
}
.banner-feature__para {
  font-size: 16px;
  line-height: 1.6875;
  font-weight: 400;
  margin: 19px 0 21px;
}
.banner-feature__btn {
  text-transform: capitalize;
}
.banner-feature--2 {
  background-color: var(--color-primary);
}
.banner-feature--2 .banner-feature__shape {
  position: absolute;
  bottom: 22px;
  right: 16px;
  z-index: -1;
}
.banner-feature--2 .card-body {
  padding: 55px 30px;
}
.banner-feature--2 .banner-feature__para {
  margin: 15px 0 36px;
}
.banner-feature--3 {
  background-color: var(--color-info);
}
.banner-feature--3 .banner-feature__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-feature--3 .card-body {
  padding: 47px 30px 30px;
}
.banner-feature--3 .banner-feature__para {
  margin: 15px 0 36px;
}
.banner-feature--4 {
  background-color: var(--color-dark);
}
.banner-feature--4 .banner-feature__shape {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: -1;
}
.banner-feature--4 .card-body {
  padding: 29px 30px 30px;
}
.banner-feature--4 .banner-feature__para {
  margin: 17px 0;
}
.banner-feature--danial {
  background-color: var(--color-dark);
}
.banner-feature--danial .banner-feature__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-feature--danial .card-body {
  padding: 55px 30px;
}
.banner-feature--danial .banner-feature__para {
  margin: 14px 0 22px;
  font-size: 16px;
  color: rgba(var(--color-white-rgba), 0.9);
}
.banner-feature--danial .banner-feature__btn {
  background-color: var(--bg-gray);
  height: 44px;
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.banner-feature--danial .banner-feature__btn:hover {
  opacity: 0.7;
}
.banner-feature--5 {
  background-image: -webkit-gradient(
    linear,
    left bottom,
    right top,
    from(#5840ff),
    color-stop(#0082ff),
    color-stop(#0af),
    color-stop(#00caff),
    to(#00e4ec)
  );
  background-image: -webkit-linear-gradient(
    left bottom,
    #5840ff,
    #0082ff,
    #0af,
    #00caff,
    #00e4ec
  );
  background-image: -o-linear-gradient(
    left bottom,
    #5840ff,
    #0082ff,
    #0af,
    #00caff,
    #00e4ec
  );
  background-image: linear-gradient(
    to right top,
    #5840ff,
    #0082ff,
    #0af,
    #00caff,
    #00e4ec
  );
}
.banner-feature--5 .banner-feature__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-feature--5 .card-body {
  padding: 55px 30px;
}
.banner-feature--5 .banner-feature__para {
  margin: 14px 0 22px;
  color: rgba(var(--color-white-rgba), 0.7);
}
.banner-feature--5 .banner-feature__btn:hover {
  color: var(--color-dark);
}
.banner-feature--6 {
  background-image: url(img/rectangle381.png);
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.banner-feature--6 .card-body {
  padding: 55px 30px;
}
.banner-feature--6 .banner-feature__para {
  margin: 14px 0 18px;
  color: rgba(var(--color-white-rgba), 0.7);
}
.banner-feature--6 .banner-feature__heading {
  margin: 19px 0 18px;
}
.banner-feature--7 {
  margin-bottom: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.banner-feature--7 .card-body {
  padding: 40px 30px 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.banner-feature--7 .banner-feature__para {
  margin: 5px 0 0;
  color: var(--color-gray);
}
.banner-feature--7 .banner-feature__heading {
  text-transform: capitalize;
}
.banner-feature--7 .banner-feature__shape.position-absolute {
  right: 0;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: -1;
}
.banner-feature--7 button {
  height: 38px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
}
.banner-feature--77 {
  margin-bottom: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.banner-feature--77 .card-body {
  padding: 40px 30px 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.banner-feature--77 .banner-feature__para {
  margin: 14px 0 18px;
  color: var(--color-gray);
}
.banner-feature--8 {
  background-color: var(--color-primary);
  text-align: center;
}
.banner-feature--8 .slick-slider {
  outline: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.banner-feature--8 .slick-slider:focus {
  border: none;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.banner-feature--8 .slick-slider .slick-track {
  outline: 0;
}
.banner-feature--8 .slick-slider .slick-slide {
  outline: 0;
}
.banner-feature--8 .card-body {
  padding: 60px 20px 64px;
}
.banner-feature--8 .banner-feature__shape {
  width: 100%;
}
.banner-feature--8 .banner-feature__para {
  margin: 19px 0 60px;
  color: rgba(var(--color-white-rgba), 0.7);
}
.banner-feature--8 .slick-slider .slick-dots button {
  width: 5px;
  height: 5px;
}
.banner-feature--8 .slick-slider .slick-dots li.slick-active button {
  width: 5px;
  background: var(--color-white);
}
.banner-feature--8 .slick-slider.slick-dots-bottom .slick-dots {
  margin: 0 0 70px;
}
.banner-feature--9 .card-body {
  text-align: center;
  padding: 37px 0 25px;
}
.banner-feature--10 {
  min-height: 450px;
  max-height: 100%;
  z-index: 1;
  background-color: var(--color-warning);
}
.banner-feature--10 .banner-feature__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  padding-left: 40px;
}
.banner-feature--10 .card-body {
  padding: 39px 25px 44px 40px;
}
.banner-feature--10 .banner-feature__btn {
  margin-top: 25px;
}
.banner-feature--11 {
  min-height: 450px;
  max-height: 100%;
  z-index: 1;
  overflow: hidden;
  background-color: var(--color-danger);
}
.banner-feature--11 .banner-feature__shape {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.banner-feature--11 .card-body {
  padding: 39px 25px 44px 40px;
}
.banner-feature--11 .banner-feature__para {
  color: rgba(var(--color-white-rgba), 0.7);
  margin: 17px 0 18px;
}
.banner-feature--12 {
  min-height: 223px;
  max-height: 100%;
  overflow: hidden;
  background-color: rgba(var(--color-warning-rgba), 0.3);
}
.banner-feature--12 .card-body {
  padding: 0 25px;
}
.banner-feature--13 {
  min-height: 223px;
  max-height: 100%;
  overflow: hidden;
  background-color: var(--color-info);
}
.banner-feature--13 .banner-feature__para {
  color: rgba(var(--color-dark-rgba), 0.7);
  margin: 15px 0 16px;
}
.banner-feature--13 .banner-feature__shape {
  max-width: unset;
}
.banner-feature--13 .card-body {
  padding: 30px 15px 30px 30px;
}
.banner-feature--14 {
  background: var(--bg-white);
}
.banner-feature--14 h1 {
  line-height: 1.2;
  margin-bottom: 17px;
}
.banner-feature--14 p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray-x);
  margin-bottom: 30px;
}
.banner-feature--14 button {
  height: 46px;
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  -webkit-box-shadow: 0 5px 10px rgba(var(--color-secondary-rgba), 0.15);
  box-shadow: 0 5px 10px rgba(var(--color-secondary-rgba), 0.15);
  padding: 10px 20px;
}
.banner-feature--14 .card-body {
  padding: 0;
}
.banner-feature--15 {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 30px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  border: 1px solid var(--border-color);
}
.banner-feature--15:hover {
  -webkit-box-shadow: 0 15px 25px rgba(var(--color-lighten-rgba), 0.15);
  box-shadow: 0 15px 25px rgba(var(--color-lighten-rgba), 0.15);
  border: 1px solid transparent;
}
.banner-feature--15 h4 {
  line-height: 1.2;
  font-weight: 500;
  margin-top: 27px;
}
.banner-feature--15 p {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray-x);
  margin-top: 20px;
  margin-bottom: 0;
}
.banner-feature--15 button {
  height: 40px;
  padding: 0 30px;
}
.banner-feature--16 {
  padding: 100px 0 0;
}
.banner-feature--17 {
  margin-top: 85px;
}
.banner-feature--17 .card-header h4 {
  white-space: normal;
}
.banner-feature--18 {
  background-color: #090e30;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 290px;
}
.banner-feature--18.new {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: auto;
}
.banner-feature--18.new .card-body {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
  padding: 40px;
}
.banner-feature--18.new .banner-feature__shape img {
  height: 100%;
  max-height: 100%;
}
.banner-feature--18.new .banner-feature__btn {
  min-height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
  padding: 0 25px;
}
.banner-feature--18 .banner-feature__shape img {
  height: 215px;
  max-height: 215px;
  -o-object-fit: cover;
  object-fit: cover;
}
.banner-feature--18 .banner-feature__btn {
  min-height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
}
.banner-feature__shape img,
.banner-feature__shape svg {
  max-width: 100%;
}
.demo4 .banner-feature--5 {
  min-height: 360px;
}
.products-awards .revenue-chart-box__Icon {
  margin-right: 0;
}
.products-awards .revenue-chart-box__Icon i {
  color: currentColor;
}
.products-awards p {
  font-size: 15px;
  color: var(--color-gray);
  margin-top: 11px;
}
.testimonial-section {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  margin-bottom: 50px;
}
.testimonial-section__padding {
  padding: 85px 0;
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.testimonial-slider-global .card {
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.testimonial-slider-global .author-thumb p {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  color: var(--color-dark);
  margin: 16px 0 0;
}
.testimonial-slider-global .author-thumb span {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-gray);
  color: rgba(var(--color-gray-rgba), 0.7);
  margin: 4px 0 0;
}
.testimonial-slider-global .author-comment {
  font-size: 16px;
  line-height: 1.75;
  font-weight: 400;
  color: var(--color-gray);
}
.testimonial-slider-global .slick-slide {
  margin: 40px 15px;
  outline: 0;
}
.testimonial-slider-global .slider-prev {
  left: -60px;
}
.testimonial-slider-global .slider-next {
  right: -60px;
}
.testimonial-slider-global .slider-arrow {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  color: var(--color-light);
  font-size: calc(16.2px + 0.15vw);
  cursor: pointer;
  z-index: 10;
  width: 44px;
  height: 44px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-box-shadow: 0 3px 10px rgba(var(--light-gray-rgba), 0.16);
  box-shadow: 0 3px 10px rgba(var(--light-gray-rgba), 0.16);
  background-color: #fff;
}
.testimonial-slider-global .slick-dots li button:before {
  color: var(--color-white);
  font-size: 10px;
}
.testimonial-slider-global .slick-dotted.slick-slider {
  margin-bottom: 30px;
}
.testimonial-slider-global.slick-slider .slick-dots button {
  width: 10px;
  height: 10px;
}
.testimonial-slider-global.slick-slider .slick-dots li button {
  background: rgba(var(--color-primary-rgba), 0.2);
}
.testimonial-slider-global.slick-slider .slick-dots li.slick-active button {
  width: 10px;
  height: 10px;
  background-color: var(--color-primary);
}
.testimonial-slider-global.testimonial-slider1 .card {
  -webkit-border-radius: 8px;
  border-radius: 8px;
  border: none;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.testimonial-slider-global.testimonial-slider1 .card-body {
  text-align: center;
  padding: 40px;
  -webkit-box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.1);
  -webkit-border-radius: 8px;
  border-radius: 8px;
}
.testimonial-slider-global.testimonial-slider1
  .card-body
  .slick-initialized
  .slick-slide {
  outline: 0;
}
.testimonial-slider-global.testimonial-slider1 .card-body .author-thumb span {
  margin-bottom: 24px;
}
.testimonial-slider-global.testimonial-slider1
  .slick-slide.slick-center
  .card-body {
  -webkit-box-shadow: 0 20px 30px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 20px 30px rgba(var(--light-gray-rgba), 0.2);
}
.testimonial-slider-global.testimonial-slider2 .author-comment {
  margin-top: 22px;
  margin-bottom: 0;
}
.testimonial-slider-global.testimonial-slider2 .card {
  -webkit-box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 10px 15px rgba(var(--light-gray-rgba), 0.1);
}
.testimonial-slider-global.testimonial-slider2 .card-body {
  padding: 40px 30px;
}
.testimonial-slider-global.testimonial-slider3-nav {
  margin-top: 50px;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide {
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
  position: relative;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide:after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: -webkit-transform 0.4s;
  transition: -webkit-transform 0.4s;
  -o-transition: transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide {
  -webkit-transform: scale(0.7) translate(640px);
  -ms-transform: scale(0.7) translate(640px);
  transform: scale(0.7) translate(640px);
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide.slick-active {
  -webkit-transform: scale(0.9) translate(225px);
  -ms-transform: scale(0.9) translate(225px);
  transform: scale(0.9) translate(225px);
}
.testimonial-slider-global.testimonial-slider3-nav
  .slick-slide.slick-center
  + .slick-slide {
  -webkit-transform: scale(0.9) translate(-225px);
  -ms-transform: scale(0.9) translate(-225px);
  transform: scale(0.9) translate(-225px);
  z-index: 10;
}
.testimonial-slider-global.testimonial-slider3-nav
  .slick-slide.slick-center
  + .slick-slide
  + .slick-slide {
  -webkit-transform: scale(0.7) translate(-640px);
  -ms-transform: scale(0.7) translate(-640px);
  transform: scale(0.7) translate(-640px);
  z-index: 5;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide.slick-center {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  z-index: 30;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-center:after {
  opacity: 0;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-list {
  height: 100px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-slide {
  margin: 15px;
  cursor: move;
}
.testimonial-slider-global.testimonial-slider3-nav img {
  min-width: 70px;
  min-height: 70px;
  opacity: 0.4;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-center img {
  min-width: 100px;
  min-height: 100px;
  opacity: 1;
}
.testimonial-slider-global.testimonial-slider3-nav .slick-track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.testimonial-slider-global.testimonial-slider3-nav
  .slick-track
  .slick-slide:not(.slick-active)
  img {
  opacity: 0;
}
.testimonial-slider-global.testimonial-slider3-for .slick-slide {
  margin: 20px 15px 0;
  cursor: move;
}
.testimonial-slider-global.testimonial-slider3-for .card-body {
  padding-bottom: 0;
}
.testimonial-slider-global.testimonial-slider4 .author-comment {
  margin-top: 25px;
}
.testimonial-slider-global.testimonial-slider4 .card-body {
  padding: 50px;
}
.fileM-contents .fileM-grid-wrapper {
  padding: 30px;
  background-color: var(--color-white);
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.fileM-sidebar {
  height: 100vh;
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.fileM-sidebar .fileM-types {
  margin: 0 0 63px;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav {
  margin: 21px 0 0;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.has-child
  > ul {
  visibility: hidden;
  opacity: 0;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.has-child.open
  > a {
  color: var(--color-primary);
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.has-child.open
  > ul {
  visibility: visible;
  opacity: 1;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li.menu-title
  span {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  padding: 0 14px;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav li > a {
  padding: 0 5px 0 14px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .toggle-icon {
  position: absolute;
  left: -2px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .nav-icon {
  margin-right: 10px;
}
.fileM-sidebar .fileM-types .sidebar__menu-group ul.sidebar_nav li ul {
  padding: 0 0 0 15px;
}
.fileM-sidebar
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  > a
  .nav-icon {
  width: 14px;
}
.fileM-sidebar ul li a {
  color: var(--color-gray-x);
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 38px;
  padding: 0 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.fileM-sidebar ul li a img,
.fileM-sidebar ul li a svg {
  color: var(--color-lighten);
  width: 18px;
  margin-right: 13px;
}
.fileM-sidebar ul li a.active {
  color: var(--color-primary);
}
.fileM-sidebar ul li a.active img,
.fileM-sidebar ul li a.active svg {
  color: var(--color-primary);
}
.fileM-sidebar ul li a:hover {
  color: var(--color-primary);
}
.fileM-progress {
  margin: 0 15px;
}
.fileM-progress .user-group-progress-bar .progress {
  height: 5px;
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.fileM-progress .user-group-progress-bar .progress span {
  color: var(--color-gray-x);
  font-size: 12px;
  line-height: 1.3333333333;
  font-weight: 500;
}
.fileM-progress .user-group-progress-bar .progress .progress-bar {
  -webkit-border-radius: 100px;
  border-radius: 100px;
}
.fileM-progress .user-group-progress-bar p {
  color: var(--color-gray-x);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  margin-bottom: 3px;
}
.fileM-progress .user-group-progress-bar span {
  color: var(--color-light);
  font-size: 12px;
  line-height: 1.6666666667;
  font-weight: 400;
}
.fileM-wrapper__title {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 20px;
}
.fileM-top-search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 28px;
}
.fileM-top-search form {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 0 20px;
}
.fileM-top-search form img,
.fileM-top-search form svg {
  color: var(--color-gray);
  width: 15px;
}
.fileM-top-search form input {
  padding: 8px 15px;
}
.fileM-top-search form input::-webkit-input-placeholder {
  color: var(--body-color);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.fileM-top-search form input::-moz-placeholder {
  color: var(--body-color);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.fileM-top-search form input::-ms-input-placeholder {
  color: var(--body-color);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.fileM-top-search form input::placeholder {
  color: var(--body-color);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.fileM-card {
  text-align: center;
}
.fileM-card .card {
  background-color: var(--bg-lighter);
}
.fileM-card .fileM-excerpt {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 0;
}
.fileM-card .fileM-img {
  margin-bottom: 18px;
}
.fileM-action .fileM-action__right .dropdown {
  top: 9px;
  right: 12px;
}
.fileM-action__right .dropdown .dropdown-default,
.fileM-action__right .dropdown .dropdown-menu {
  padding: 18px 0;
  margin-right: 0;
  -webkit-box-shadow: 0 5px 20px var(--shadow3);
  box-shadow: 0 5px 20px var(--shadow3);
}
.fileM-action__right .dropdown .dropdown-item {
  font-size: 14px;
  line-height: 2.2857142857;
  font-weight: 400;
  color: var(--color-gray-x);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 1px 25px;
}
.fileM-action__right .dropdown .dropdown-item:hover {
  background-color: rgba(var(--color-primary-rgba), 0.05);
  color: var(--color-primary);
}
.fileM-action__right .dropdown .dropdown-item:hover img,
.fileM-action__right .dropdown .dropdown-item:hover svg {
  color: var(--color-primary);
}
.fileM-action__right .dropdown .dropdown-item img,
.fileM-action__right .dropdown .dropdown-item svg {
  color: var(--color-gray);
  width: 16px;
  margin-right: 12px;
}
.fileM-action__right .dropdown img,
.fileM-action__right .dropdown svg {
  color: var(--color-light);
  width: 20px;
}
.fileM-sidebar .fileM-action__right .dropdown > a {
  width: 100%;
  font-size: 14px;
}
.fileM-sidebar .fileM-action__right .dropdown > a img,
.fileM-sidebar .fileM-action__right .dropdown > a svg {
  width: 15px;
  color: var(--color-white);
}
.fileM-sidebar .fileM-action__right .dropdown .dropdown-menu {
  width: 100%;
}
.filleM-table .projectDatatable-title {
  font-weight: 500;
  color: var(--color-gray-x);
}
.filleM-table .files-area__title span {
  line-height: 1.8333333333;
  margin-top: 2px;
  text-transform: uppercase;
}
.filleM-table .table thead tr th {
  border-top: 1px solid var(--border-color);
  padding: 14px 10px;
}
.filleM-table .table thead tr th:first-child {
  border-left: 1px solid var(--border-color);
}
.filleM-table .table thead tr th:last-child {
  border-right: 1px solid var(--border-color);
}
.filleM-table .table td,
.filleM-table .table th {
  white-space: nowrap;
  padding: 9px 10px;
  vertical-align: middle;
  border-top: none;
}
.filleM-table .table td:first-child,
.filleM-table .table th:first-child {
  padding-left: 38px;
}
.filleM-table tbody tr {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.filleM-table tbody tr:hover {
  -webkit-box-shadow: 0 15px 50px rgba(146, 153, 184, 0.2);
  box-shadow: 0 15px 50px rgba(146, 153, 184, 0.2);
}
.fileM-Modal-overlay .modal {
  background-color: rgba(17, 18, 23, 0.6);
}
.fileM-Modal-overlay .modal-header {
  border-bottom: none;
  padding-bottom: 30px;
}
.fileM-Modal-overlay .modal-footer {
  border-top: none;
  padding: 0 30px 30px;
}
.fileM-Modal-overlay .modal-footer button {
  height: 36px;
  border: none;
}
.fileM-Modal-overlay .modal-content {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.03);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.03);
}
.fileM-Modal-overlay .modal-body {
  padding: 0 30px;
}
.fileM-Modal-overlay .modal-body input {
  height: 36px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.fileM-Modal-overlay .modal-body input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.fileM-Modal-overlay .modal-body input::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.fileM-Modal-overlay .modal-body input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.fileM-Modal-overlay .modal-body input::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.fileM-Modal-overlay .modal-title button img,
.fileM-Modal-overlay .modal-title button svg {
  width: 20px;
  color: var(--color-gray);
}
.fileM-Modal-overlay .modal-footer > * {
  margin: 5px;
}
.media-import {
  margin: 10px auto 30px;
  position: relative;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 280px;
  border: 2px dashed #c6d0dc;
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.media-import svg {
  color: var(--body-color);
  width: 80px;
  height: auto;
}
.media-import p {
  line-height: 1.25;
  font-weight: 500;
  margin-top: 27px;
  margin-bottom: 0;
}
.exportDatatable .order-button-group button {
  height: 44px;
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 600;
}
.exportDatatable .order-search__form {
  background-color: transparent;
  border: 1px solid var(--border-color);
  padding: 12px 13px;
  width: 250px;
}
.exportDatatable.orderDatatable .table tbody tr td {
  padding: 20px;
}
.exportDatatable.orderDatatable .table-hover tbody tr {
  -webkit-transition: 0.3s ease;
  -o-transition: 0.3s ease;
  transition: 0.3s ease;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.exportDatatable.orderDatatable .table-hover tbody tr:hover {
  --bs-table-accent-bg: var(--bg-normal);
}
.exportDatatable .export-table .custom-checkbox input[type="checkbox"] + label {
  padding-left: 60px;
}
.exportDatatable .modal-dialog {
  max-width: 390px;
}
.exportDatatable .fileM-Modal-overlay .modal-body input {
  border: 1px solid var(--border-color);
}
.exportDatatable
  .fileM-Modal-overlay
  .modal-body
  .select2-container--default
  .select2-selection--multiple,
.exportDatatable
  .fileM-Modal-overlay
  .modal-body
  .select2-container--default
  .select2-selection--single {
  height: 36px;
  border: 1px solid var(--border-color);
}
.exportDatatable
  .fileM-Modal-overlay
  .modal-body
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.exportDatatable
  .fileM-Modal-overlay
  .modal-body
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--color-gray);
}
.select2-dropdown {
  z-index: 9999;
}
.dropzone-lg-s {
  min-height: 180px;
}
.dropzone-md-s {
  min-height: 100px;
}
.knowledgebase {
  background-color: var(--bg-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
  box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
  padding: 68px 0;
}
.knowledgebase__nav {
  border-bottom: 1px solid var(--border-color);
}
.knowledgebase__nav li .nav-link {
  padding: 0;
  padding-bottom: 21px;
  border-bottom: 1px solid transparent;
  color: var(--color-gray-x);
  font-size: 16px;
  line-height: 1.25;
  font-weight: 400;
}
.knowledgebase__nav li .nav-link.active {
  color: var(--color-dark);
  font-weight: 500;
  border-bottom: 1px solid var(--color-dark);
}
.knowledgebase__nav li:not(:last-child) {
  margin-right: 36px;
}
.knowledgebase__cta {
  text-align: center;
}
.knowledgebase__cta h3 {
  line-height: 1.2272727273;
}
.knowledgebase__cta p {
  margin-top: 17px;
  margin-bottom: 16px;
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray-x);
}
.knowledgebase__cta button {
  height: 46px;
}
.knowledgebase__list h3 {
  line-height: 1.2272727273;
  margin-top: 37px;
  margin-bottom: 22px;
}
.knowledgebase__list li {
  margin-bottom: 6px;
}
.knowledgebase__list li .knowledgebase__list-collapsed,
.knowledgebase__list li a {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray-x);
}
.knowledgebase__list li .knowledgebase__list-collapsed:hover,
.knowledgebase__list li a:hover {
  color: var(--color-primary);
}
.knowledgebase__list .knowledgebase__list-collapsed,
.knowledgebase__list a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.knowledgebase__list
  .knowledgebase__list-collapsed:not(.collapsed)
  .changelog__accordingArrow,
.knowledgebase__list a:not(.collapsed) .changelog__accordingArrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.knowledgebase__list .knowledgebase__list-collapsed .changelog__accordingArrow,
.knowledgebase__list a .changelog__accordingArrow {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
  position: absolute;
  left: -20px;
}
.knowledgebase__list
  .knowledgebase__list-collapsed
  .changelog__accordingArrow
  img,
.knowledgebase__list
  .knowledgebase__list-collapsed
  .changelog__accordingArrow
  svg,
.knowledgebase__list a .changelog__accordingArrow img,
.knowledgebase__list a .changelog__accordingArrow svg {
  width: 15px;
  height: auto;
  color: var(--color-dark);
}
.knowledgebase__list .collapse-area {
  margin-left: 22px;
  margin-bottom: 15px;
  margin-top: 6px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.knowledgebase__list-plugin h3 {
  line-height: 1.2272727273;
}
.knowledgebase__plugin-wrapper {
  margin-top: 46px;
}
.knowledgebase__more {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.knowledgebase__more a {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  color: var(--color-dark);
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  margin-top: 11px;
  padding-bottom: 6px;
  border-bottom: 1px solid transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.knowledgebase__more a i {
  font-size: 15px;
  margin-left: 13px;
  opacity: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.knowledgebase__more a:hover i {
  opacity: 1;
}
.knowledgebase__more a:hover {
  border-bottom: 1px solid var(--color-primary);
  color: var(--color-primary);
}
.knowledgebase__hr {
  background: var(--border-color);
  width: 100%;
  height: 1px;
  margin: 70px 0;
}
.knowledgebase__article span {
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
}
.knowledgebase__article h3 {
  line-height: 1.3636363636;
  color: rgba(255, 255, 255, 0.9);
  margin-top: 15px;
  margin-bottom: 25px;
}
.knowledgebase__article a {
  color: rgba(255, 255, 255, 0.9);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  position: relative;
}
.knowledgebase__article a i {
  visibility: hidden;
  opacity: 0;
}
.knowledgebase__article a:hover i {
  visibility: visible;
  opacity: 1;
}
.knowledgebase__article-title {
  margin-bottom: 30px;
}
.knowledgebase__article-title h2 {
  line-height: 1.25;
}
.knowledgebase-adv {
  margin-top: 62px;
}
.knowledgebase-adv__card .card-body {
  margin-left: 12px;
}
.knowledgebase-adv__card .knowledgebase__list li {
  margin-bottom: 17px;
}
.knowledgebase-adv__card .knowledgebase__list li .knowledgebase__list-collapsed,
.knowledgebase-adv__card .knowledgebase__list li a {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.5625;
  font-weight: 500;
}
.knowledgebase-adv__right h1 {
  line-height: 1.2666666667;
  margin-bottom: 27px;
}
.knowledgebase-adv__content article h3 {
  line-height: 1.2272727273;
}
.knowledgebase-adv__content article p {
  margin-top: 19px;
  margin-bottom: 40px;
  color: var(--color-dark);
  font-size: 15px;
  line-height: 1.7333333333;
  font-weight: 400;
}
.knowledgebase-adv__content article a {
  color: var(--color-dark);
  font-size: 12px;
  line-height: 1.3333333333;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: underline;
}
.knowledgebase-adv__content article a:hover {
  color: var(--color-primary);
}
.knowledgebase-adv__content article:not(:first-child) {
  margin-top: 50px;
}
.knowledgebase-adv__collapse:not(.collapsed) .changelog__accordingArrow {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
}
.knowledgebase-adv__collapse .changelog__accordingArrow {
  -webkit-transition: -webkit-transform 0.24s;
  transition: -webkit-transform 0.24s;
  -o-transition: transform 0.24s;
  transition: transform 0.24s;
  transition: transform 0.24s, -webkit-transform 0.24s;
  width: 28px;
  height: 28px;
  -webkit-box-shadow: 0 2px 2px rgba(146, 148, 159, 0.3);
  box-shadow: 0 2px 2px rgba(146, 148, 159, 0.3);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.knowledgebase-adv__collapse .changelog__accordingArrow img,
.knowledgebase-adv__collapse .changelog__accordingArrow svg {
  width: 18px;
  color: var(--color-dark);
}
.knowledgebase-adv .collapse-adv__area .nav-pills .nav-link {
  padding: 8px 18px;
  -webkit-border-radius: 0;
  border-radius: 0;
  background: 0 0;
  color: var(--color-gray-x);
  border-left: 1px solid #eaebef;
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
}
.knowledgebase-adv .collapse-adv__area .nav-pills .nav-link.active {
  color: var(--color-primary);
  border-left: 1px solid var(--color-primary);
}
.knowledgebase-updateDate {
  color: var(--color-light);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
  margin-top: 50px;
  margin-bottom: 0;
}
.knowledgebase-updateDate span {
  color: var(--color-dark);
  font-weight: 500;
}
.knowledgebase-help {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin: 25px 0;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
  box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
}
.knowledgebase-help .card-body {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 35px 30px;
  margin: 0 -20px;
}
.knowledgebase-help .card-body h6 {
  margin-right: 40px;
}
.knowledgebase-help .card-body button {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  height: 36px;
  text-transform: capitalize;
}
.knowledgebase-help .card-body button img,
.knowledgebase-help .card-body button svg {
  width: 20px;
  height: 20px;
}
.knowledgebase-help-request {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.knowledgebase-help-request > p {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 10px;
}
.knowledgebase-help-request > p a {
  margin-left: 10px;
}
.knowledgebase-help-request .knowledgebase__share {
  margin-bottom: 10px;
}
.knowledgebase-help-request .knowledgebase__share p {
  margin-bottom: 0;
  margin-right: 20px;
  color: var(--color-light);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.knowledgebase-help-request .knowledgebase__share ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.knowledgebase-help-request .knowledgebase__share ul li {
  margin-right: 10px;
}
.knowledgebase-help-request .knowledgebase__share ul li a {
  color: #9299bb;
  font-size: 15px;
}
.knowledgebase-help-request .knowledgebase__share ul li a:hover {
  color: var(--color-primary);
}
.knowledgebase-hr {
  width: 100%;
  height: 1px;
  background: #eaebef;
  margin-top: 40px;
  margin-bottom: 25px;
}
.knowledgebase-details {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
  box-shadow: 0 5px 10px rgba(146, 153, 184, 0.1);
  padding: 68px 0;
  margin-top: 50px;
  margin-bottom: 50px;
}
.post-pagination p {
  margin-bottom: 0;
  margin-top: 8px;
}
.post-pagination p a {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.post-pagination p a:hover {
  color: var(--color-primary);
}
.post-pagination .prev-post {
  float: left;
  text-align: left;
}
.post-pagination .prev-post span {
  color: var(--color-gray);
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
}
.post-pagination .prev-post span i {
  margin-right: 5px;
}
.post-pagination .next-post {
  float: right;
  text-align: end;
}
.post-pagination .next-post span {
  color: var(--color-gray);
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
}
.related-article {
  margin-top: 50px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.related-article .card-body {
  padding: 27px 30px 30px;
}
.related-article .card-body h4 {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 23px;
}
.related-article .card-body ul li {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  color: var(--color-gray-x);
}
.related-article .card-body ul li i {
  color: #ccd4de;
  margin-right: 9px;
}
.related-article .card-body ul li:not(:last-child) {
  margin-bottom: 14px;
}
.comment-respond {
  padding: 30px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-top: 30px;
}
.comment-respond h4 {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 23px;
}
.comment-respond label {
  font-size: 15px;
  line-height: 1.6666666667;
  font-weight: 400;
  margin-bottom: 10px;
}
.comment-respond textarea {
  min-height: 150px;
  resize: none;
}
.comment-respond .form-group {
  margin-bottom: 23px;
}
.comment-respond button {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  height: 46px;
  padding: 0 34px;
}
.cr-form-control {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  height: 50px;
  border: 1px solid var(--border-color);
}
.kb h1 {
  margin-bottom: 30px;
}
.kb__header-search {
  margin: 0 39px;
  -webkit-transition: background 0.4s ease;
  -o-transition: background 0.4s ease;
  transition: background 0.4s ease;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.1);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.1);
}
.kb__header-search-form {
  position: relative;
  z-index: 2;
}
.kb__header-search-form .input-group {
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-transition: all 0.15s ease;
  -o-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.kb__header-search-form .input-group input {
  border: none;
  padding: 16px 25px;
  -webkit-border-top-left-radius: 30px;
  border-top-left-radius: 30px;
  -webkit-border-bottom-left-radius: 30px;
  border-bottom-left-radius: 30px;
  height: 54px;
  -webkit-box-shadow: none;
  box-shadow: none;
  min-width: 160px;
}
.kb__header-search-form .input-group input::-webkit-input-placeholder {
  color: var(--color-light);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.kb__header-search-form .input-group input::-moz-placeholder {
  color: var(--color-light);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.kb__header-search-form .input-group input::-ms-input-placeholder {
  color: var(--color-light);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.kb__header-search-form .input-group input::placeholder {
  color: var(--color-light);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.kb__header-search-form .input-group button {
  padding: 0 33px;
  height: 54px;
  width: auto;
  border: none;
  -webkit-border-top-right-radius: 4px;
  border-top-right-radius: 4px;
  -webkit-border-bottom-right-radius: 4px;
  border-bottom-right-radius: 4px;
  color: var(--color-white);
  background-color: var(--color-primary);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  opacity: 1;
}
.kb__select {
  border: none;
  border-right: 1px solid var(--border-color);
  outline: 0;
  z-index: 9;
  padding: 0 0.75rem;
  width: 160px;
  height: 54px;
}
.kb__select-wrapper {
  max-width: 100%;
}
.kb__select-wrapper .select2-container--default .select2-selection--multiple,
.kb__select-wrapper .select2-container--default .select2-selection--single {
  height: unset;
}
.kb__select-wrapper
  .select2-container
  .select2-selection--single
  .select2-selection__rendered {
  width: 160px;
  height: 54px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.kb__select-wrapper .select2-container--default .select2-selection--multiple,
.kb__select-wrapper .select2-container--default .select2-selection--single {
  -webkit-border-radius: 0;
  border-radius: 0;
  -webkit-border-top-left-radius: 4px;
  border-top-left-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border: none;
  border-right: 1px solid var(--border-color);
}
.kb__select-wrapper
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.kb__select-wrapper
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  font-size: 15px;
  line-height: 1.8;
  font-weight: 400;
  color: var(--color-gray-x);
}
.kb__select-wrapper
  .select2-container--default
  .select2-selection--single
  .select2-selection__arrow:after {
  color: #a0a4b4;
}
.kb__select-wrapper
  .select2-container
  .select2-selection--single
  .select2-selection__clear {
  display: none;
}
.kb__categories {
  margin-top: 23px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 0 30px;
}
.kb__categories li p {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
  margin-right: 4px;
  margin-bottom: 0;
}
.kb__categories li:not(:last-child) {
  margin-right: 21px;
}
.kb__categories li a {
  color: var(--color-light);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 400;
}
.kb__categories li a:hover {
  text-decoration: underline;
  color: var(--color-gray-x);
}
.knowledgebase-breadcrumb .breadcrumb-item,
.knowledgebase-breadcrumb .breadcrumb-item a {
  color: var(--color-gray-x);
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
}
.knowledgebase-breadcrumb .breadcrumb-item a.active,
.knowledgebase-breadcrumb .breadcrumb-item.active {
  color: var(--color-light);
}
.kb__select-wrapper .select2,
.tagSelect-rtl .select2 {
  width: 300px;
  text-align: right;
  direction: ltr;
  position: relative;
}
.kb__select-wrapper
  .select2
  .select2-container--open
  + .select2-container--open,
.tagSelect-rtl .select2 .select2-container--open + .select2-container--open {
  left: auto;
  right: 0;
  width: 100%;
}
.kb__select-wrapper
  .select2
  .select2-selection--multiple
  .select2-selection__arrow,
.kb__select-wrapper
  .select2
  .select2-selection--single
  .select2-selection__arrow,
.tagSelect-rtl .select2 .select2-selection--multiple .select2-selection__arrow,
.tagSelect-rtl .select2 .select2-selection--single .select2-selection__arrow {
  left: unset;
}
.kb__select-wrapper
  .select2
  .select2-selection--multiple
  .select2-selection__rendered,
.tagSelect-rtl
  .select2
  .select2-selection--multiple
  .select2-selection__rendered {
  padding-right: 30px;
}
.knowledge-base {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.knowledge-base img {
  width: 80px;
  height: 70px;
  margin-top: 35px;
}
.knowledge-base h1 {
  margin-top: 22px;
  margin-bottom: 13px;
}
.knowledge-base p {
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray);
}
.knowledge-base button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  height: 38px;
}
.card-timeline .card-timeline-body ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card-timeline .card-timeline-body ul li .card-timeline-body__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 13px 0;
}
.card-timeline
  .card-timeline-body
  ul
  li
  .card-timeline-body__title
  .badge-dot--big {
  width: 10px;
  height: 10px;
  position: absolute;
}
.card-timeline .card-timeline-body ul li .card-timeline-body__title p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-gray);
  margin-left: 25px;
}
.card-timeline .card-timeline-body ul li .card-timeline-body__title p span {
  margin-left: 3px;
}
.card-timeline
  .card-timeline-body
  ul
  li:not(:last-child)
  .card-timeline-body__title
  .timeline-line {
  position: absolute;
  left: 4px;
  top: 18px;
  height: 100%;
  width: 1px;
  z-index: 0;
  background-color: var(--border-color);
}
.card-timeline .card-timeline-body .card-timeline-body__time span {
  font-size: 13px;
  font-weight: 400;
  color: var(--color-light);
}
.card-inbox .card-inbox-members ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.card-inbox .card-inbox-members ul li:not(:last-child) {
  margin-bottom: 15px;
}
.card-inbox .card-inbox-members ul li .card-inbox-members__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.card-inbox .card-inbox-members ul li .card-inbox-members__left img {
  margin-right: 12px;
  width: 34px;
  height: 34px;
}
.card-inbox
  .card-inbox-members
  ul
  li
  .card-inbox-members__left
  .card-inbox-members__title
  h6 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray);
}
.card-inbox
  .card-inbox-members
  ul
  li
  .card-inbox-members__left
  .card-inbox-members__title
  .card-inbox-members__title--online {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 2px;
}
.card-inbox .card-inbox-members ul li .card-inbox-member__time span {
  font-size: 13px;
  font-weight: 400;
  color: var(--color-light);
}
.kanban-board .card-header h4 {
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 500;
}
.kanban-board__card {
  padding: 30px;
  background: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.kanban-board__card .kanban-header {
  margin-bottom: 20px;
}
.kanban-board__body {
  padding: 14px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: var(--bg-lighter);
  margin-top: 16px;
  margin-bottom: 14px;
}
.kanban-board__body h6 {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 14px;
  padding-top: 6px;
}
.kanban-board__body li {
  padding: 16px 15px;
  background: var(--color-white);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 2px rgba(146, 153, 187, 0.15);
  box-shadow: 0 2px 2px rgba(146, 153, 187, 0.15);
  margin-bottom: 10px;
  cursor: pointer;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-board__body li:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.kanban-board__inline-body {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
}
.kanban-board__add-card {
  cursor: pointer;
}
.kanban-board__add-card p {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 6px 10px;
  margin-bottom: 0;
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-board__add-card p:hover {
  background: rgba(146, 153, 187, 0.2);
}
.kanban-board__add-card p img,
.kanban-board__add-card p svg {
  color: var(--color-gray);
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.kanban-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  overflow-x: auto;
  height: calc(100vh - 13.6rem);
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) rgba(146, 153, 184, 0.3);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-container::-webkit-scrollbar {
  height: 10px;
}
.kanban-container::-webkit-scrollbar-track {
  background: rgba(146, 153, 184, 0.3);
}
.kanban-container:hover::-webkit-scrollbar-thumb {
  background-color: var(--color-light);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 3px solid var(--light-gray-rgba);
}
.kanban-list {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 284px;
  flex: 0 0 284px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: var(--bg-lighter);
  max-height: calc(100vh - 15.8rem);
  height: 100%;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  margin-right: 20px;
}
.kanban-list:last-of-type {
  margin-right: 0;
}
.kanban-list .list-title {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
  padding: 9px 20px;
}
.kanban-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  padding: 0 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) transparent;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-items li {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 500;
  background-color: var(--color-white);
  padding: 16px 15px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  word-wrap: break-word;
  cursor: pointer;
  -webkit-box-shadow: 0 0 5px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 0 5px rgba(var(--light-gray-rgba), 0.1);
  position: relative;
}
.kanban-items li:not(:last-child) {
  margin-bottom: 10px;
}
.kanban-items li:last-of-type {
  margin-bottom: 0;
}
.kanban-items li:hover {
  background-color: #eee;
}
.kanban-items li button span {
  color: var(--color-gray);
  width: 10px;
  height: 10px;
}
.kanban-items::-webkit-scrollbar {
  width: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  margin-right: -10px;
}
.kanban-items::-webkit-scrollbar-track {
  background: 0 0;
}
.kanban-items::-webkit-scrollbar-thumb {
  background-color: rgba(var(--light-gray-rgba), 0.3);
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.kanban-items .lists-items-title {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.add-card-btn {
  color: var(--color-gray);
  font-size: 13px;
  line-height: 1.5384615385;
  font-weight: 500;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  margin: 10px 20px;
  background: var(--border-color);
  text-decoration: none;
}
.add-card-btn:hover {
  background-color: #cdd2d4;
  color: #4d4d4d;
}
.add-card-btn img,
.add-card-btn svg {
  color: var(--color-gray);
  width: 15px;
  height: 15px;
  margin-right: 5px;
}
.add-card-btn::after,
.add-list-btn::after {
  content: "...";
}
.kanban-tops img,
.kanban-tops svg {
  width: 24px;
  color: var(--color-lighten);
  margin-right: 18px;
}
.list__add-card {
  padding: 14px 20px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: var(--bg-lighter);
}
.kanban-board__add-card button {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 6px 10px;
  margin-bottom: 0;
  color: var(--color-gray);
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  width: 100%;
  background-color: var(--bg-lighter);
}
.kanban-board__add-card button:hover {
  background: rgba(146, 153, 187, 0.2);
}
.kanban-board__add-card button img,
.kanban-board__add-card button svg {
  color: var(--color-gray);
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.kanban-modal__list .dropdown-menu {
  padding: 10px 0;
}
.kanban-modal__list .dropdown-menu .dropdown-item {
  padding: 6px 15px;
}
.kanban-modal__header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 27px 30px 17px;
}
.kanban-modal__header-wrapper .close svg {
  color: var(--color-light);
}
.kanban-modal__header .modal-title {
  color: var(--color-dark);
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 6px;
}
.kanban-modal__header span {
  color: var(--color-lighten);
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
}
.kanban-modal__body {
  padding: 0 30px 30px;
}
.kanban-modal__card {
  background-color: rgba(17, 18, 23, 0.6);
}
.kanban-modal__card .close {
  width: 30px;
  height: 30px;
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 1px solid transparent;
  font-size: 16px;
}
.kanban-modal__card .close:hover {
  opacity: 0.7;
}
.kanban-modal__card .close svg {
  color: var(--color-dark);
}
.kanban-modal__form label {
  color: var(--color-dark);
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  margin-bottom: 10px;
}
.kanban-modal__form textarea {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--bg-lighter);
  resize: none;
  min-height: 88px;
}
.kanban-modal__form textarea::-webkit-input-placeholder {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  padding: 4px 0;
}
.kanban-modal__form textarea::-moz-placeholder {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  padding: 4px 0;
}
.kanban-modal__form textarea::-ms-input-placeholder {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  padding: 4px 0;
}
.kanban-modal__form textarea::placeholder {
  color: var(--color-gray);
  font-size: 15px;
  line-height: 1.3333333333;
  font-weight: 400;
  padding: 4px 0;
}
.kanban-modal__form button {
  padding: 0 25px 0 20px;
}
.kanban-modal__research {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 12px;
}
.kanban-modal__research h6 {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
}
.kanban-modal__research button {
  background-color: var(--bg-lighter);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: var(--color-gray);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  height: 38px;
  text-transform: capitalize;
}
.kanban-modal__progress .progress-wrap {
  margin-bottom: 11px;
}
.kanban-modal__progress .progress-wrap .progress-percentage {
  font-size: 12px;
  line-height: 1.3333333333;
  font-weight: 500;
  color: var(--color-gray);
}
.kanban-modal__list button {
  background-color: var(--bg-lighter);
  -webkit-border-radius: 5px;
  border-radius: 5px;
  color: var(--color-gray);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  height: 36px;
  padding: 0 37px;
  text-transform: capitalize;
  border: 1px solid transparent;
}
.kanban-modal__list ul {
  margin-bottom: 10px;
}
.kanban-modal__list .checkbox-group {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  padding: 4px 12px;
}
.kanban-modal__list .checkbox-group .dropdown {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-modal__list .checkbox-group .dropdown img,
.kanban-modal__list .checkbox-group .dropdown svg {
  color: var(--color-lighten);
  width: 24px;
  height: 24px;
}
.kanban-modal__list .checkbox-group:hover {
  background-color: var(--bg-lighter);
}
.kanban-modal__list .checkbox-group:hover .dropdown {
  visibility: visible;
  opacity: 1;
}
.kanban-modal__list
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
}
.kanban-modal__list .custom-checkbox input[type="checkbox"] + label {
  color: var(--color-gray);
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}
.kanban-modal__list .custom-checkbox input[type="checkbox"]:checked + label {
  color: var(--color-light);
}
.popup-overlay {
  visibility: hidden;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 9999;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  min-height: 116px;
  -webkit-box-shadow: 0 5px 5px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 5px 5px rgba(var(--light-gray-rgba), 0.1);
  display: none;
}
.popup-overlay.active {
  visibility: visible;
  text-align: center;
  display: block;
}
.popup-content {
  visibility: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  -webkit-transition: none;
  -o-transition: none;
  transition: none;
}
.popup-content.active {
  visibility: visible;
}
.popup-content textarea {
  resize: none;
}
.kanban-items li {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.kanban-items li .open-popup-modal {
  visibility: hidden;
  opacity: 0;
}
.kanban-items li.active .open-popup-modal {
  visibility: visible;
  opacity: 1;
}
.open-popup-modal {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: 0 0;
}
.open-popup-modal img,
.open-popup-modal svg {
  color: var(--color-gray);
  width: 14px;
  height: 14px;
}
.close-popup-modal {
  text-transform: capitalize;
}
.is-open {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  overflow: hidden;
}
.is-open:after {
  content: "";
  display: block;
  position: fixed;
  padding: 100px 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(var(--color-dark-rgba), 0.3);
  z-index: 9998;
}
.task-card .card-header {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: var(--color-dark);
}
.task-card__header {
  -webkit-box-flex: 2;
  -ms-flex: 2;
  flex: 2;
}
.task-card__header .custom-checkbox input[type="checkbox"] + label {
  font-size: 16px;
  line-height: 1.1875;
  font-weight: 400;
  color: var(--color-dark);
}
.task-card__header .checkbox-group {
  margin-bottom: 4px;
}
.task-card__header
  .checkbox-group
  .custom-checkbox
  input[type="checkbox"]:checked
  + label:after {
  background-color: var(--color-success);
  border: 1px solid var(--color-success);
}
.task-card__header
  .checkbox-group
  .custom-checkbox
  input[type="checkbox"]
  + label:after {
  width: 18px;
  height: 18px;
}
.task-card__header
  .checkbox-group
  .custom-checkbox
  input[type="checkbox"]
  + label:before {
  left: 4px;
  top: 4px;
}
.task-card__header span {
  color: var(--color-gray-x);
  font-size: 12px;
  line-height: 1.3333333333;
  font-weight: 400;
}
.task-card__body {
  padding: 19px 30px;
}
.task-card__body:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}
.task-card .table-actions a {
  margin-right: 10px;
}
.task-card .table-actions a img,
.task-card .table-actions a svg {
  width: 18px;
  color: var(--body-color);
}
.task-card .table-actions a.active svg {
  color: #fa8b0c;
}
.task-card__content .dropdown-item {
  font-size: 14px;
  line-height: 2.2857142857;
  font-weight: 400;
  color: var(--color-gray-x);
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.task-card__content .dropdown-item svg {
  color: var(--body-color);
  width: 14px;
  height: 14px;
  margin-right: 11px;
}
.task-card__content .dropdown-item:active {
  color: #fa8b0c;
}
.task-modal {
  background: rgba(17, 18, 23, 0.3);
}
.task-modal .modal-body {
  padding: 19px 30px;
}
.task-modal .modal-body form input {
  border: 1px solid var(--border-color);
  height: 36px;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.task-modal .modal-body form input:hover {
  border: 1px solid var(--color-primary);
}
.task-modal .modal-body form input::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form input::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form input::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form input::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form textarea {
  height: 123px;
  border: 1px solid var(--border-color);
  resize: none;
}
.task-modal .modal-body form textarea::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form textarea::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form textarea::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-body form textarea::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.task-modal .modal-header {
  border: none;
  padding: 0;
}
.task-modal .modal-header h5 {
  font-size: 16px;
  line-height: 1.25;
  font-weight: 500;
  color: var(--color-dark);
}
.task-modal .modal-header button {
  color: var(--color-gray);
}
.task-modal .modal-footer {
  border: none;
}
.task-modal .modal-footer button {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  height: 36px;
  padding: 0 22px;
}
.task-modal .modal-footer button:first-child {
  border: 1px solid var(--border-color);
  background: 0 0;
  color: var(--color-gray);
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 500;
}
.task-types ul li {
  width: 100%;
}
.bookmark--grid {
  border: none;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(146, 153, 184, 0.14);
  box-shadow: 0 10px 20px rgba(146, 153, 184, 0.14);
}
.bookmark__image img {
  -webkit-border-top-left-radius: 10px;
  border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  border-top-right-radius: 10px;
}
.bookmark__image .like-icon {
  width: 30px;
  height: 30px;
}
.bookmark__image .like-icon i {
  font-size: 16px;
}
.bookmark__body h6 {
  font-size: 15px;
  line-height: 1.4666666667;
  font-weight: 500;
  margin-bottom: 4px;
}
.bookmark__body a {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-gray);
}
.bookmark__body a:hover {
  color: var(--color-primary);
}
.bookmark__button {
  margin: -5px;
}
.bookmark__button .btn {
  margin: 5px;
  text-transform: capitalize;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  height: 32px;
  padding: 0 15px;
}
.bookmark-card .card-body {
  padding: 30px 22px;
}
.bookmark-card .card-header {
  padding: 0 30px;
}
.bookmark-sidebar {
  max-height: calc(100vh - 10.8rem);
  height: 100%;
  margin-bottom: 30px;
}
.with-icon {
  position: relative;
}
.with-icon .form-control {
  padding-left: 40px;
}
.with-icon span {
  font-size: calc(16.2px + 0.15vw);
  position: absolute;
  z-index: 2;
  display: block;
  width: 40px;
  height: 40px;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  top: 50%;
  left: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.form-control.ih-large {
  height: 60px;
}
.form-control.ih-medium {
  height: 48px;
}
.form-control.ih-small {
  height: 38px;
}
.form-group .ip-light::-webkit-input-placeholder,
.ip-light::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.form-group .ip-light::-moz-placeholder,
.ip-light::-moz-placeholder {
  color: var(--color-lighten);
}
.form-group .ip-light::-ms-input-placeholder,
.ip-light::-ms-input-placeholder {
  color: var(--color-lighten);
}
.form-group .ip-light::placeholder,
.ip-light::placeholder {
  color: var(--color-lighten);
}
.form-group .ip-gray::-webkit-input-placeholder,
.ip-gray::-webkit-input-placeholder {
  color: var(--color-gray-x);
}
.form-group .ip-gray::-moz-placeholder,
.ip-gray::-moz-placeholder {
  color: var(--color-gray-x);
}
.form-group .ip-gray::-ms-input-placeholder,
.ip-gray::-ms-input-placeholder {
  color: var(--color-gray-x);
}
.form-group .ip-gray::placeholder,
.ip-gray::placeholder {
  color: var(--color-gray-x);
}
.il-gray {
  color: var(--color-gray-x);
}
.il-dark {
  color: var(--color-dark);
}
.layout-button {
  margin: -7px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.layout-button button {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 500;
  height: 44px;
  text-transform: capitalize;
  margin: 7px;
}
.layout-button button.btn-primary {
  -webkit-box-shadow: 0 3px 5px rgba(32, 201, 151, 0.05);
  box-shadow: 0 3px 5px rgba(32, 201, 151, 0.05);
}
.form-element-textarea textarea {
  height: 80px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  resize: none;
}
.form-element .dm-select .select2-selection {
  min-height: 48px;
}
.form-element .dm-select.disabled .form-control {
  border: 1px solid var(--border-color);
  background: #eff0f3;
  color: var(--color-lighten);
}
.form-element .custom-file-label {
  overflow: hidden;
  height: 48px;
  padding: 0;
  line-height: 33px;
  font-size: 14px;
  color: var(--color-lighten);
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-element .custom-file-label::after {
  height: 48px;
  padding: 0 23px;
  line-height: 1.5;
  color: var(--color-lighten);
  content: "Browse";
  background-color: transparent;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-left: inherit;
}
.form-element .custom-file {
  position: relative;
}
.form-element .custom-file input[type="file"] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: default;
}
.form-element-radioChecks
  .custom-checkbox.disabled
  input[type="checkbox"]:checked
  + label:after {
  background-color: var(--color-primary);
}
.form-element-radioChecks
  .custom-checkbox.disabled
  input[type="checkbox"]:checked
  + label:before {
  color: var(--color-white);
}
.form-element-radioChecks .custom-radio.disabled input[type="radio"] + label {
  color: var(--color-white);
}
.select-style2
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.select-style2
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--color-lighten);
}
.alert2,
.option2 {
  border: 1px solid var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 16px 15px;
}
.alert2 .select2-results__option,
.option2 .select2-results__option {
  padding: 0;
  color: var(--color-gray-x);
  font-size: 14px;
  line-height: 2.5;
  font-weight: 400;
}
.alert2 .select2-search--dropdown,
.option2 .select2-search--dropdown {
  padding: 0;
}
.alert2 .select2-search--dropdown .select2-search__field,
.option2 .select2-search--dropdown .select2-search__field {
  height: 48px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: 0 3px 6px rgba(146, 153, 184, 0.15);
  box-shadow: 0 3px 6px rgba(146, 153, 184, 0.15);
}
.alert2 .select2-results__option--highlighted[aria-selected],
.alert2 .select2-results__option[aria-selected="true"],
.option2 .select2-results__option--highlighted[aria-selected],
.option2 .select2-results__option[aria-selected="true"] {
  background-color: transparent;
}
.alert2 .select2-results > .select2-results__options,
.option2 .select2-results > .select2-results__options {
  padding: 13px 0;
}
.ui-datepicker-multi {
  z-index: 11 !important;
}
.ui-datepicker-multi .ui-datepicker-calendar tbody td a {
  width: 32px;
  height: 32px;
  margin: 2px;
}
.formElement-editor .trumbowyg-editor,
.formElement-editor .trumbowyg-textarea {
  min-height: 160px;
}
.formElement-editor .trumbowyg-button-pane {
  background-color: transparent;
  border-bottom: 1px solid var(--border-color);
  min-height: 56px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.formElement-editor .trumbowyg-box {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.formElement-editor .trumbowyg-button-pane::after {
  background: var(--border-color);
  display: none;
}
.formElement-editor .trumbowyg-button-pane .trumbowyg-button-group::after {
  background: var(--border-color);
  display: none;
}
.formElement-editor .trumbowyg-box {
  margin: 0;
  min-height: 160px;
}
.formElement-editor .trumbowyg-editor {
  font-size: 15px;
}
.color-picker input {
  padding: 9px 0;
}
.bd-example-row .row > .col,
.bd-example-row .row > [class^="col-"] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(95, 99, 242, 0.584);
  color: var(--color-white);
}
.bd-example-row .row > div:nth-last-child(odd) {
  background-color: var(--color-primary);
}
.bd-example-row .row + .row {
  margin-top: 1rem;
}
.bd-example-row .flex-items-bottom,
.bd-example-row .flex-items-middle,
.bd-example-row .flex-items-top {
  min-height: 6rem;
  background-color: rgba(95, 99, 242, 0.1);
}
.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(95, 99, 242, 0.1);
}
.bd-example-row-flex-cols .row {
  min-height: 10rem;
  background-color: rgba(95, 99, 242, 0.1);
}
#overlayer {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  background: var(--color-white);
  top: 0;
  left: 0;
  max-height: 100vh;
  visibility: visible;
  opacity: 1;
}
.loader-overlay {
  display: inline-block;
  position: absolute;
  z-index: 99999;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
body.loaded:after,
body[cz-shortcut-listen="true"]:after {
  visibility: hidden;
  opacity: 0;
}
body.loaded #overlayer,
body[cz-shortcut-listen="true"] #overlayer {
  visibility: hidden;
  opacity: 0;
}
/* body:after {
  content: "";
  background: var(--color-white);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99998;
  position: absolute;
  visibility: visible;
  opacity: 1;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
} */
.overlayScroll {
  overflow: hidden;
}
.adv-search-area form {
  padding: 0 20px;
  background: var(--color-white);
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  box-shadow: 0 5px 20px rgba(146, 153, 184, 0.031372549);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  width: 280px;
}
.adv-search-area form img,
.adv-search-area form svg {
  width: 20px;
  color: var(--color-gray);
}
.adv-search-area form .form-control {
  padding: 10px 13px;
  line-height: 25px;
  height: 36px;
}
.adv-search-area form .form-control::-webkit-input-placeholder {
  color: var(--body-color);
}
.adv-search-area form .form-control::-moz-placeholder {
  color: var(--body-color);
}
.adv-search-area form .form-control::-ms-input-placeholder {
  color: var(--body-color);
}
.adv-search-area form .form-control::placeholder {
  color: var(--body-color);
}
.adv-table-table__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.adv-table-table__button a {
  font-size: 12px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-width: 93px;
  padding-right: 35px;
  height: 40px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.adv-table-table__button a:after {
  font-weight: 900;
  font-size: 12px;
}
.adv-table-table__button .table-responsive {
  overflow-y: hidden;
}
.footable-filtering + tr {
  background-color: var(--bg-normal);
}
#filter-form-container .form-inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 5px;
}
#filter-form-container label {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray-x);
  text-transform: capitalize;
}
#filter-form-container .footable-filtering-search {
  margin: 25px 0;
}
#filter-form-container .footable-filtering-search .input-group {
  font-weight: 900;
}
#filter-form-container .footable-filtering-search .input-group:after {
  font-size: calc(16.2px + 0.15vw);
  font-family: line awesome free;
  position: absolute;
  left: 20px;
  top: 8px;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  content: "\f002";
  color: var(--color-gray);
  z-index: 3;
}
#filter-form-container .footable-filtering-search .input-group-btn {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 3;
}
#filter-form-container
  .footable-filtering-search
  .input-group-btn
  button:first-child {
  background-color: transparent !important;
  border: none;
  color: var(--color-gray);
}
#filter-form-container
  .footable-filtering-search
  .input-group-btn
  .fooicon-search {
  visibility: hidden;
}
#filter-form-container .footable-filtering-search .form-control {
  width: 280px;
  padding-left: 46px;
}
#filter-form-container .form-control {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  height: 40px;
  border: 1px solid var(--border-color);
  color: var(--color-dark);
  text-transform: capitalize;
  width: 200px;
}
#filter-form-container input {
  border: 1px solid var(--border-color);
}
.footable.adv-table .pagination > li {
  margin: 3px;
}
.footable.adv-table .pagination > li > a {
  border: 1px solid var(--border-color);
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  color: var(--color-gray-x);
  font-size: 14px;
  line-height: 25px;
}
.footable.adv-table .pagination > li.active a {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.adv-table .footable-filtering-search {
  height: 40px;
  margin-left: auto !important;
}
.adv-table .fooicon {
  color: var(--color-gray);
}
.adv-table .fooicon:hover {
  color: var(--color-primary);
}
.userDatatable table.adv-table {
  border-bottom: none;
}
.userDatatable table.adv-table .pagination {
  border-top: 1px solid var(--border-color);
  margin: 0;
  margin-top: 2px;
  padding-top: 27px;
  padding-bottom: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.userDatatable.userDatatable--ticket
  table
  thead
  tr:not(.footable-filtering)
  th {
  border: none;
  background-color: var(--color-lighter);
}
.userDatatable.userDatatable--ticket
  table
  thead
  tr:not(.footable-filtering)
  th:first-child {
  border: none;
}
.userDatatable.userDatatable--ticket
  table
  thead
  tr:not(.footable-filtering)
  th.actions {
  text-align: right;
}
.userDatatable.userDatatable--ticket
  table
  thead
  tr:not(.footable-filtering)
  th.actions
  span {
  padding-right: 15px;
}
.userDatatable.userDatatable--ticket
  table
  thead
  tr:not(.footable-filtering)
  th
  span {
  color: var(--color-gray);
}
.userDatatable.userDatatable--ticket table tbody tr td {
  padding: 10px 25px;
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-inline-title
  a
  h6 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-inline-title
  a:hover
  h6 {
  color: var(--color-primary);
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-content--date,
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-content--priority {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-content--subject {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable-content-status {
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.userDatatable.userDatatable--ticket
  table
  tbody
  tr
  td
  .userDatatable__imgWrapper
  .profile-image {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
}
.userDatatable.userDatatable--ticket table tbody tr:first-child td {
  padding-top: 17px;
}
.userDatatable.userDatatable--ticket table tbody tr:last-child td {
  padding-bottom: 28px;
}
.userDatatable.userDatatable--ticket .userDatatable-header th {
  padding: 15px 25px;
}
.userDatatable.userDatatable--ticket .userDatatable-inline-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.userDatatable.userDatatable--ticket .orderDatatable_actions li a {
  color: var(--color-lighten);
}
.userDatatable.userDatatable--ticket--2 table {
  padding-bottom: 12px;
}
.userDatatable.userDatatable--ticket--2
  table
  tbody
  tr
  td
  .userDatatable-content-status {
  -webkit-border-radius: 15px;
  border-radius: 15px;
}
.userDatatable.userDatatable--ticket--2 table tbody tr:first-child td {
  padding-top: 13px;
}
.adv-table tfoot {
  padding-top: 27px;
}
.adv-table tfoot td {
  padding: 0;
}
.footable-pagination-wrapper .label-default {
  display: none;
}
.fooicon {
  font-weight: 900;
  font-family: "font awesome 5 free" !important;
}
.fooicon.fooicon-sort {
  font-family: line awesome free !important;
}
.fooicon.fooicon-sort:before {
  content: "\f338";
}
.product-pie-wrapper {
  height: 25px;
  width: 25px;
  float: left;
  position: relative;
}
.product-pie-wrapper:nth-child(3n + 1) {
  clear: both;
}
.product-pie-wrapper.product-pie-wrapper--style-3 .product-pie-wrapper__pie {
  clip: rect(0, 32px, 12px, 12px);
}
.product-pie-wrapper.product-pie-wrapper--style-4 .product-pie-wrapper__pie {
  clip: rect(0, 25px, 25px, 12px);
}
.product-pie-wrapper.product-pie-wrapper--style-4
  .product-pie-wrapper__pie
  .product-pie-wrapper__half-circle {
  clip: rect(0, 14px, 25px, 0);
}
.product-pie-wrapper .product-pie-wrapper__pie {
  height: 100%;
  width: 100%;
  clip: rect(0, 25px, 25px, 10px);
  left: 0;
  position: absolute;
  top: 0;
}
.product-pie-wrapper
  .product-pie-wrapper__pie
  .product-pie-wrapper__half-circle {
  height: 100%;
  width: 100%;
  border: 3px solid var(--color-primary);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  clip: rect(0, 10px, 25px, 0);
  left: 0;
  position: absolute;
  top: 0;
}
.product-pie-wrapper .product-pie-wrapper__shadow {
  height: 100%;
  width: 100%;
  border: 3px solid rgba(var(--color-primary-rgba), 0.2);
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.product-pie-wrapper.product-pie-wrapper--45
  .product-pie-wrapper__pie
  .product-pie-wrapper__half-circle {
  border-color: var(--color-primary);
}
.product-pie-wrapper.product-pie-wrapper--45
  .product-pie-wrapper__pie
  .product-pie-wrapper__left-side {
  -webkit-transform: rotate(162deg);
  -ms-transform: rotate(162deg);
  transform: rotate(162deg);
}
.team-members ul {
  padding: 0;
  margin: 0;
}
.team-members ul li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.team-members ul li:not(:last-child) {
  margin-bottom: 20px;
}
.team-members ul li .team-members__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 8;
  -ms-flex: 8;
  flex: 8;
}
.team-members ul li .team-members__left img {
  width: 34px;
  height: 34px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.team-members ul li .team-members__left img + .team-members__title {
  margin-left: 12px;
}
.team-members ul li .team-members__left .team-members__title h6 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray);
}
.team-members
  ul
  li
  .team-members__left
  .team-members__title
  .team-members__title--online {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray);
}
.team-members
  ul
  li
  .team-members__left
  .team-members__title
  .team-members__title--online
  span {
  margin-right: 5px;
}
.team-members ul li .team-members__middle {
  -webkit-box-flex: 5;
  -ms-flex: 5;
  flex: 5;
}
.team-members ul li .team-members__middle .team-members__title--online {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.team-members
  ul
  li
  .team-members__middle
  .team-members__title--online
  .badge-dot {
  margin-right: 6px;
}
.team-members ul li .team-member__right .team-member__add button {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  -webkit-border-radius: 3px;
  border-radius: 3px;
  color: var(--color-primary);
  font-size: 13px;
  font-weight: 600;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 0 12px;
  text-transform: capitalize;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}
.team-members ul li .team-member__right .team-member__add button:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.feature-profile {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--color-white);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
}
.feature-profile .feature-profile__bg {
  max-height: 175px;
  height: 175px;
  overflow: hidden;
}
.feature-profile .feature-profile__bg img {
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.feature-profile .feature-profile_content {
  text-align: center;
  padding-bottom: 36px;
}
.feature-profile .feature-profile_content img {
  width: 110px;
  height: 110px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  border: 7px solid var(--color-white);
  -o-object-fit: cover;
  object-fit: cover;
  margin-top: -73px;
}
.feature-profile .feature-profile_content h6 {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  color: var(--color-dark);
  margin-top: 10px;
  margin-bottom: 5px;
}
.feature-profile .feature-profile_content p {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-light);
  margin-bottom: 0;
}
.feature-profile .feature-profile_content .profile-feature__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 19px;
}
.feature-profile
  .feature-profile_content
  .profile-feature__social
  li:not(:last-child) {
  margin-right: 10px;
}
.feature-profile .feature-profile_content .profile-feature__social li a {
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  color: var(--color-white);
  font-size: 18px;
}
.feature-profile .feature-profile_content .profile-feature__social li a svg {
  width: 16px;
}
.feature-profile .feature-profile_content .profile-feature__social li a:hover {
  opacity: 0.8;
}
.breadcrumb-main--ticket {
  margin: 0;
}
.breadcrumb-main--ticket .action-btn a {
  height: 40px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: 500;
  outline: 0;
}
.breadcrumb-main--ticket .action-btn a:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.breadcrumb-main--ticket .action-btn a i {
  font-size: 10px;
}
.breadcrumb-main--table .action-btn a {
  font-size: 14px;
  font-weight: 500;
  height: 40px;
  min-height: 40px;
}
.breadcrumb-main--table .action-btn a i {
  margin-left: 7.5px;
  margin-right: 0;
  font-size: 14px;
}
.breadcrumb-main--table .breadcrumb-title {
  font-size: calc(16.2px + 0.15vw);
}
.ticket_modal .modal-content {
  -webkit-box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
}
.ticket_modal .modal-content .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 50px;
  border: none;
  padding: 0;
}
.ticket_modal .modal-content .modal-header h1 {
  font-size: calc(17.4px + 1.05vw);
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 0;
}
.ticket_modal .modal-content .modal-header .close:hover {
  opacity: 0.7;
}
.ticket_modal .modal-content .ticket_modal-modal form label {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray);
  margin-bottom: 6px;
}
.ticket_modal .modal-content .ticket_modal-modal form .form-control {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  height: 50px;
}
.ticket_modal .modal-content .ticket_modal-modal form .form-control:focus {
  border-color: var(--color-primary);
}
.ticket_modal .modal-content .ticket_modal-modal form textarea.form-control {
  min-height: 130px;
  resize: none;
}
.ticket_modal
  .modal-content
  .ticket_modal-modal
  form
  textarea.form-control:focus {
  border-color: var(--color-primary);
}
.ticket_modal .modal-content .ticket_modal-modal form .button-group .btn {
  text-transform: capitalize;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 0 30px;
}
.new-ticket .ticket_modal {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  padding: 50px 60px;
}
.new-ticket .ticket_modal .ticket_modal-modal h1 {
  font-size: calc(17.4px + 1.05vw);
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 50px;
}
.new-ticket .ticket_modal .ticket_modal-modal form label {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-gray);
  margin-bottom: 6px;
}
.new-ticket .ticket_modal .ticket_modal-modal form .form-control {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  height: 50px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.new-ticket .ticket_modal .ticket_modal-modal form .form-control:focus {
  border-color: var(--color-primary);
}
.new-ticket .ticket_modal .ticket_modal-modal form textarea.form-control {
  min-height: 130px;
  resize: none;
}
.new-ticket .ticket_modal .ticket_modal-modal form textarea.form-control:focus {
  border-color: var(--color-primary);
}
.new-ticket .ticket_modal .ticket_modal-modal form .button-group .btn {
  text-transform: capitalize;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 3px 30px;
}
.support-ticket-system {
  padding: 25px;
  border: none;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background-color: var(--color-white);
  margin-bottom: 30px;
}
.support-form .support-form__input {
  margin: 20px 0;
}
.support-form .support-form__input .dm-select .select2 {
  min-width: 200px;
}
.support-form .support-form__input .dm-select .select2 .select2-selection {
  height: 40px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), 0.03);
  box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), 0.03);
}
.support-form
  .support-form__input
  .dm-select
  .select2.select2-container
  .select2-selection--single
  .select2-selection__rendered {
  padding-left: 15px;
  padding-right: 15px;
}
.support-form .support-form__input-button {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  height: 40px;
  min-height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: var(--color-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5714285714;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0 20px;
  text-transform: capitalize;
  margin-left: 20px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.support-form .support-form__input-button:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.support-form .support-form__search {
  margin: 20px 0 20px 15px;
}
.support-form .support-form__input-id,
.support-form .support-form__input-status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.support-form .support-form__input-id label,
.support-form .support-form__input-status label {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
  margin-right: 10px;
  text-transform: capitalize;
}
.support-form .support-form__input-id {
  margin: 0 30px 0 0;
}
.support-form .support-form__input-id label {
  text-transform: uppercase;
}
.support-form .support-order-search .support-order-search__form {
  padding: 15px 20px;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  min-width: 280px;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), 0.03);
  box-shadow: 0 5px 5px rgba(var(--box-shadow-color-rgba), 0.03);
}
.support-form .support-order-search .support-order-search__form input {
  padding: 0;
}
.support-form
  .support-order-search
  .support-order-search__form
  input::-webkit-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.support-form
  .support-order-search
  .support-order-search__form
  input::-moz-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.support-form
  .support-order-search
  .support-order-search__form
  input::-ms-input-placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.support-form
  .support-order-search
  .support-order-search__form
  input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-lighten);
}
.support-form .support-order-search .support-order-search__form img,
.support-form .support-order-search .support-order-search__form svg {
  color: var(--color-light);
  width: 16px;
  margin-right: 10px;
}
.back-page {
  margin-bottom: 15px;
}
.back-page a {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4285714286;
  color: var(--color-gray);
  text-transform: capitalize;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.back-page a img,
.back-page a svg {
  width: 16px;
  margin-right: 6px;
}
.back-page a:hover {
  color: var(--color-primary);
}
.ticket-details-contact {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 25px 25px 30px;
}
.ticket-details-contact h4 {
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 30px;
}
.ticket-details-contact__ift {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ticket-details-contact__ift-wrapper {
  -webkit-box-flex: 180px;
  -ms-flex: 180px;
  flex: 180px;
}
.ticket-details-contact__ift-rule {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  color: var(--color-light);
  display: block;
  margin-bottom: 10px;
}
.ticket-details-contact__ift-namTitle {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ticket-details-contact__ift-namTitle img {
  margin-right: 15px;
  width: 30px;
  max-width: 30px;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
}
.ticket-details-contact__ift-namTitle h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--color-dark);
}
.ticket-details-contact__status {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 32px;
}
.ticket-details-contact__status-item {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 180px;
  flex: 0 0 180px;
  margin-right: 150px;
}
.ticket-details-contact__status-item label {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4285714286;
  color: var(--color-gray);
  margin-bottom: 15px;
}
.ticket-details-contact__status-item .dm-select {
  width: 130px;
}
.ticket-details-contact__status-item .dm-select select {
  width: 130px;
}
.ticket-details-contact__status-item .dm-select .select2 {
  width: 130px !important;
}
.ticket-details-contact__status-item
  .dm-select
  .select2-container--default
  .select2-selection--single {
  min-height: 36px;
  height: 36px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 1px solid var(--border-color);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ticket-details-contact__status-item
  .dm-select
  .select2-container--default
  .select2-selection--single:hover {
  border: 1px solid var(--color-primary);
}
.ticket-details-contact__status-item
  .dm-select
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-gray);
  padding-left: 15px;
  padding-right: 15px;
}
.ticket-details-contact__overview .ticket-details-contact__overview-title {
  color: var(--color-dark);
  font-size: calc(16.4px + 0.3vw);
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 10px;
}
.ticket-details-contact__overview p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
  margin-bottom: 0;
}
.ticket-file-attach {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 20px 25px 30px;
}
.ticket-file-attach h4 {
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 22px;
}
.ticket-file-attach__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.ticket-file-attach__items:not(:last-child) {
  margin-bottom: 20px;
}
.ticket-file-attach__items_detail {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ticket-file-attach__items_detail img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.ticket-file-attach__items_detail h6 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3333333333;
  color: var(--color-gray);
}
.ticket-file-attach__items_detail span {
  color: var(--color-light);
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
}
.ticket-file-attach__items_download a {
  color: var(--color-light);
}
.ticket-file-attach__items_download a img,
.ticket-file-attach__items_download a svg {
  width: 16px;
}
.ticket-file-attach__items_download a:hover {
  color: var(--color-primary);
}
.ticket-chat-wrapper {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 25px 25px 30px;
}
.ticket-chat-wrapper h4 {
  font-weight: 500;
  color: var(--color-dark);
  margin-bottom: 22px;
}
.ticket-chat-wrapper .ticket-search-body {
  max-height: 220px;
  overflow: hidden auto;
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.ticket-chat-wrapper .ticket-search-body::-webkit-scrollbar {
  width: 11px;
}
.ticket-chat-wrapper .ticket-search-body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.ticket-chat-wrapper .ticket-search-body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  -webkit-border-radius: 6px;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
.ticket-chat-wrapper .ticket-search-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.ticket-chat-wrapper .ticket-search-header form {
  background-color: var(--bg-lighters);
  height: 70px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  padding: 0 25px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  margin-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.ticket-chat-wrapper .ticket-search-header form input {
  background-color: var(--bg-lighters);
}
.ticket-chat-wrapper
  .ticket-search-header
  form
  input::-webkit-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-light);
}
.ticket-chat-wrapper .ticket-search-header form input::-moz-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-light);
}
.ticket-chat-wrapper .ticket-search-header form input::-ms-input-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-light);
}
.ticket-chat-wrapper .ticket-search-header form input::placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-light);
}
.ticket-chat-wrapper .ticket-search-header form svg {
  width: 24px;
  color: var(--color-lighten);
}
.ticket-chat-wrapper .ticket-search-header button {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.ticket-chat-wrapper .ticket-search-header button svg {
  width: 20px;
}
.ticket-user-list .ticket-user-list-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.ticket-user-list .ticket-user-list-item .ticket-user-list-item__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 100%;
}
.ticket-user-list
  .ticket-user-list-item
  .ticket-user-list-item__wrapper
  .avatar
  img {
  width: 30px;
  height: 30px;
}
.ticket-user-list
  .ticket-user-list-item
  .ticket-user-list-item__wrapper
  .ticket-users-list-body-title
  h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4285714286;
  color: var(--color-dark);
}
.ticket-user-list
  .ticket-user-list-item
  .ticket-user-list-item__wrapper
  .ticket-users-list-body-title
  p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
  margin-bottom: 0;
}
.ticket-user-list .ticket-user-list-item .ticket-users-list-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
.search-style-2 form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.search-style-2 form .job-search,
.search-style-2 form .location-search {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--color-white);
  height: 46px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  padding: 2px 25px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 10px;
}
.search-style-2 form .job-search input,
.search-style-2 form .location-search input {
  padding: 0 12px;
  margin-right: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-lighten);
}
.search-style-2 form .job-search input::-webkit-input-placeholder,
.search-style-2 form .location-search input::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-lighten);
}
.search-style-2 form .job-search input::-moz-placeholder,
.search-style-2 form .location-search input::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-lighten);
}
.search-style-2 form .job-search input::-ms-input-placeholder,
.search-style-2 form .location-search input::-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-lighten);
}
.search-style-2 form .job-search input::placeholder,
.search-style-2 form .location-search input::placeholder {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-lighten);
}
.search-style-2 form .job-search img,
.search-style-2 form .location-search img {
  width: 15px;
}
.search-style-2 form .job-search svg,
.search-style-2 form .location-search svg {
  color: var(--color-light);
  width: 15px;
}
.search-style-2 form button {
  min-width: 150px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-self: center;
  min-height: 46px;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
}
.search-style-2 form button img {
  width: 15px;
  margin-right: 8px;
}
.search-style-2 form button svg {
  width: 15px;
}
.price-value-wrapper p {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray);
}
.job {
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  border: none;
}
.job--list .job-item__image {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  margin-right: 30px;
  margin: 10px;
}
.job--list .job-item__content {
  -webkit-box-flex: 6;
  -ms-flex: 6;
  flex: 6;
  margin: 10px;
}
.job--list .job-item__content > div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-right: 30px;
}
.job--list .job-item__title h6 {
  margin-bottom: 3px;
}
.job-item__title span {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray);
}
.job-item__image-img {
  width: 50px;
  height: 50px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 20px;
  min-width: 50px;
}
.job-item__body {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: -10px;
}
.job-item__content h6 {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.6666666667;
}
.job-item__content span {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray);
}
.job-item__content.flex-column {
  margin-top: 14px;
}
.job-item__content.flex-column > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.job-item__content.flex-column > div:not(:last-child) {
  margin-bottom: 8px;
}
.job-item__content.flex-column h6 {
  width: 95px;
}
.job-item__content.flex-column span {
  margin-left: 0;
}
.job-item__button button {
  color: var(--color-dark);
  height: 38px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4285714286;
  background-color: var(--bg-deep);
  text-decoration: none;
  text-transform: capitalize;
}
.job-item__button button:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.job-details-card {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 30px;
}
.job-details-card__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -10px 0;
}
.job-details-card__top .btn {
  margin: 10px 0;
}
.job-details-card__top .job-item__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 10px 0;
}
.job-details-card__top a {
  font-size: 15px;
  font-weight: 600;
  -webkit-border-radius: 4px;
  border-radius: 4px;
}
.job-details-card__top .job-item__title .card-title {
  margin-bottom: 3px;
}
.job-details-card h4 {
  margin-top: 35px;
  margin-bottom: 12px;
  font-weight: 600;
  line-height: 1.2;
}
.job-details-card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
}
.job-details-card ul {
  padding: 0;
}
.job-details-card ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
  position: relative;
  padding-left: 21px;
  word-break: break-all;
}
.job-details-card ul li:not(:last-child) {
  margin-bottom: 10px;
}
.job-details-card ul li:before {
  content: "";
  width: 4px;
  height: 4px;
  float: left;
  padding-right: 0;
  background-color: var(--color-lighten);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 3px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}
.job-details-widget {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 20px 25px 30px;
}
.job-details-widget h4 {
  font-weight: 600;
  line-height: 1.2;
  color: var(--color-dark);
  margin-top: 3px;
  margin-bottom: 4px;
}
.job-details-widget a.btn {
  margin-top: 25px;
  height: 44px;
  min-height: 44px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
}
.job-apply {
  margin-bottom: 110px;
}
.job-apply-wrapper {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  padding: 50px 60px;
}
.job-apply-wrapper .job-apply__content h1 {
  font-size: calc(17.4px + 1.05vw);
  font-weight: 600;
  color: var(--color-dark);
  margin-bottom: 50px;
}
.job-apply-wrapper .job-apply__content form label {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3333333333;
  color: var(--color-gray);
  margin-bottom: 11px;
}
.job-apply-wrapper .job-apply__content form .form-group {
  margin-bottom: 30px;
}
.job-apply-wrapper .job-apply__content form .form-group .dm-upload__button {
  width: 100%;
  border: 3px dashed var(--color-deep);
  padding: 14px 15px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.job-apply-wrapper
  .job-apply__content
  form
  .form-group
  .dm-upload__button
  .btn-upload {
  background-color: var(--color-secondary);
  color: var(--color-white);
  font-size: 13px;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 0 15px;
  height: 38px;
  min-height: 38px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.job-apply-wrapper
  .job-apply__content
  form
  .form-group
  .dm-upload__button
  .btn-upload:hover {
  border-color: var(--color-secondary);
  opacity: 0.7;
}
.job-apply-wrapper
  .job-apply__content
  form
  .form-group
  .dm-upload__button
  .btn-upload
  svg {
  width: 12.24px;
  height: 14px;
}
.job-apply-wrapper .job-apply__content form .form-control {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  height: 50px;
  min-height: 50px;
  padding: 4px 20px;
}
.job-apply-wrapper
  .job-apply__content
  form
  .form-control::-webkit-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-lighten);
}
.job-apply-wrapper .job-apply__content form .form-control::-moz-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-lighten);
}
.job-apply-wrapper
  .job-apply__content
  form
  .form-control::-ms-input-placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-lighten);
}
.job-apply-wrapper .job-apply__content form .form-control::placeholder {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-lighten);
}
.job-apply-wrapper .job-apply__content form .form-control:focus {
  border-color: var(--color-primary);
}
.job-apply-wrapper .job-apply__content form textarea.form-control {
  min-height: 130px;
  resize: none;
  padding: 14px 20px;
}
.job-apply-wrapper .job-apply__content form textarea.form-control:focus {
  border-color: var(--color-primary);
}
.job-apply-wrapper .job-apply__content form .button-group .btn {
  text-transform: capitalize;
  -webkit-transition: 0.3s ease-in-out;
  -o-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
  padding: 0 30px;
  height: 50px;
  min-height: 50px;
  font-size: 15px;
  font-weight: 600;
}
.course-card {
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  padding: 20px 20px 30px;
}
.course-card__img-area {
  max-height: 180px;
  min-height: 180px;
  position: relative;
  overflow: hidden;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.course-card__img-area::before {
  content: "";
  position: absolute;
  background-color: rgba(var(--color-dark-rgba), 0.1);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.course-card__img-area .big-img {
  max-width: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  box-shadow: 0 5px 20px rgba(var(--color-lighten-rgba), 0.05);
  width: 100%;
}
.course-card__title h4 {
  color: var(--color-dark);
  font-weight: 600;
  font-size: 18px;
  margin-top: 20px;
  text-transform: capitalize;
  word-break: break-all;
}
.course-card__about {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 19px;
}
.course-card__about img {
  width: 30px;
  height: 30px;
  -o-object-fit: cover;
  object-fit: cover;
  margin-right: 10px;
}
.course-card__about span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.course-card__footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px 0;
  margin-top: 15px;
}
.course-card__footer .no-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
}
.course-card__footer .no-btn img,
.course-card__footer .no-btn svg {
  margin-right: 5px;
}
.course-card__footer .total-money {
  font-size: 20px;
  font-weight: 600;
  color: var(--color-success);
}
.course-card__footer .total-lextures {
  padding: 0 15px;
  min-height: 32px;
  height: 32px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(var(--color-secondary-rgba), 0.15);
  color: var(--color-secondary);
  margin: 5px;
}
.course-card__footer .total-lextures img {
  width: 12px;
  margin-right: 5px;
}
.course-card__footer .total-lextures img,
.course-card__footer .total-lextures svg {
  width: 12px;
}
.course-card__footer .total-hours {
  padding: 0 15px;
  min-height: 32px;
  height: 32px;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  background-color: rgba(var(--color-primary-rgba), 0.15);
  color: var(--color-primary);
  margin: 5px;
}
.course-card__footer .total-hours img {
  width: 12px;
  margin-right: 5px;
}
.course-card__footer .total-hours img,
.course-card__footer .total-hours svg {
  width: 12px;
}
.course-card__footer_right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
}
.course-details-about__content h5 {
  margin-bottom: 18px;
  font-size: calc(16.6px + 0.45vw);
  font-weight: 600;
  line-height: 1.2272727273;
  color: var(--color-dark);
}
.course-details-about__content .main-title {
  margin-bottom: 18px;
}
.course-details-about__content .main-title-sub {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
}
.course-details-about__content .course-details-author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 32px;
}
.course-details-about__content .course-details-author img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 12px;
}
.course-details-about__content .course-details-author h6 {
  font-weight: 500;
  margin-bottom: 2px;
}
.course-details-about__content .course-details-author span {
  font-size: 15px;
  font-weight: 400;
  line-height: 0.1392405063;
  color: var(--color-gray);
}
.course-details-about__content .course-details__step-list {
  margin-bottom: 30px;
}
.course-details-about__content .course-details__step-list ul {
  padding: 0;
}
.course-details-about__content .course-details__step-list ul li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
  position: relative;
  padding-left: 21px;
  word-break: break-all;
}
.course-details-about__content
  .course-details__step-list
  ul
  li:not(:last-child) {
  margin-bottom: 10px;
}
.course-details-about__content .course-details__step-list ul li:before {
  content: "";
  width: 4px;
  height: 4px;
  float: left;
  padding-right: 0;
  background-color: var(--color-dark);
  -webkit-border-radius: 50%;
  border-radius: 50%;
  top: 50%;
  left: 3px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
}
.course-accordion .dm-collapse-item {
  margin-bottom: 6px;
  background-color: var(--color-white);
  -webkit-box-shadow: 0 15px 40px rgba(var(--color-lighten-rgba), 0.15);
  box-shadow: 0 15px 40px rgba(var(--color-lighten-rgba), 0.15);
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
  border: 1px solid var(--border-color);
}
.course-accordion .dm-collapse-item .dm-collapse-item__body {
  border-top: 1px solid var(--border-color);
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray-x);
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item:not(:last-child) {
  margin-bottom: 19px;
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  .course-icon {
  width: 12px;
  margin-right: 10px;
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  .course-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray-x);
  text-transform: capitalize;
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  .course-preview-lectures {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  margin-right: 80px;
  color: var(--color-primary);
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .course-collapse-body-item
  .course-duration {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5714285714;
  color: var(--color-gray);
}
.course-accordion
  .dm-collapse-item
  .dm-collapse-item__body
  .collapse-body-text {
  padding: 15px 25px;
}
.course-accordion .dm-collapse-item__header {
  background-color: var(--color-white);
  -webkit-border-radius: 5px !important;
  border-radius: 5px !important;
}
.course-accordion .dm-collapse-item__header h6 {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-dark);
}
.course-accordion .dm-collapse-item__header .item-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 15px 25px;
}
.course-accordion .dm-collapse-item__header .item-link > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 5px 0;
}
.course-accordion
  .dm-collapse-item__header
  .item-link
  > div.course-collapse-right
  .course-collapse-lecture {
  margin-left: 20px;
}
.course-accordion .dm-collapse-item__header .item-link i {
  margin-right: 8px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.course-accordion .course-collapse-right > div {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5714285714;
  color: var(--color-gray);
}
.course-accordion .course-collapse-right .course-collapse-lecture {
  margin-right: 80px;
}
.course-details-collapse
  .dm-collapse
  .dm-collapse-item__header
  .item-link
  .course-collapse-right
  i {
  margin-right: -2px;
}
.cvg {
  background-color: var(--color-white);
  padding: 35px;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.cvg__item {
  position: relative;
  width: 100%;
  height: 390px;
  min-height: 390px;
  left: 0;
  top: 0;
}
.cvg__item > img {
  width: 100%;
  height: 100%;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -o-object-fit: cover;
  object-fit: cover;
}
.cvg__item-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  margin: 0;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(var(--color-dark-rgba), 0.3);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.cvg__iconWrapper {
  width: 80px;
  height: auto;
}
.cvg__iconWrapper svg path {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.cvg__iconWrapper svg:hover path {
  fill: red;
}
.cvg__icon-area {
  margin: 10px 25px;
}
.cvg__icon-area .svg-icon {
  width: 70px;
  height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.cvg__icon-area .svg-icon img,
.cvg__icon-area .svg-icon svg {
  width: 30px;
}
.cvg__icon-area span {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5714285714;
  color: var(--color-dark);
}
.cvg__contents {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-top: 25px;
}
.cvg__contents h1 {
  color: var(--color-dark);
  line-height: 1.2666666667;
  margin-bottom: 17px;
}
.cvg__contents button {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  min-height: 44px;
  height: 44px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.4666666667;
  text-transform: capitalize;
  color: var(--color-white);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.terms {
  margin-top: 10px;
}
.terms-breadcrumb {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  min-height: 330px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.terms-breadcrumb .display-1 {
  margin-top: 88px;
}
.terms-content {
  background-color: var(--color-white);
  -webkit-box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  box-shadow: 0 10px 50px rgba(var(--color-lighten-rgba), 0.2);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 60px 50px;
  margin-top: -120px;
}
.terms-content__group:not(:last-child) {
  margin-bottom: 55px;
}
.terms-content__group h1 {
  margin-bottom: 30px;
}
.terms-content__group p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6875;
  color: var(--color-gray);
  margin-bottom: 0;
}
.terms-content__group p a {
  color: var(--color-secondary);
}
.terms-content__update {
  color: var(--color-light);
  font-size: 15px;
}
.blog-card {
  -webkit-box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 20px rgba(var(--light-gray-rgba), 0.03);
  background-color: var(--color-white);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  padding: 25px 25px 30px;
}
.blog-card__thumbnail {
  width: 100%;
  height: 220px;
  overflow: hidden;
  position: relative;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.blog-card__thumbnail:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(var(--color-dark-rgba), 0.1);
  left: 0;
  top: 0;
}
.blog-card__thumbnail img {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 100%;
  height: 220px;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-card__title--top {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
  margin-top: 11px;
}
.blog-card__title {
  margin-top: 13px;
  margin-bottom: 8px;
}
.blog-card__title a {
  font-weight: 600;
  color: var(--color-dark);
  font-size: calc(16.4px + 0.3vw);
}
.blog-card__title a:hover {
  color: var(--color-primary);
}
.blog-card__content p {
  font-size: 16px;
  font-weight: 400;
  color: var(--color-gray);
  margin-bottom: 15px;
}
.blog-card__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: -5px;
}
.blog-card__meta > div {
  margin: 5px;
}
.blog-card__meta-count ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-card__meta-count ul li:not(:last-child) {
  margin-right: 20px;
}
.blog-card__meta-reaction {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-card__meta-reaction img,
.blog-card__meta-reaction svg {
  width: 14px;
}
.blog-card__meta-reaction-like {
  font-size: 13px;
  font-weight: 400;
  color: var(--color-light);
  margin-left: 5px;
}
.blog-card__meta-doc-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-card__meta-doc-wrapper img,
.blog-card__meta-doc-wrapper svg {
  width: 14px;
}
.blog-card__meta-doc-wrapper .blog-card__meta-doc {
  font-size: 13px;
  font-weight: 400;
  color: var(--color-light);
  margin-left: 5px;
}
.blog-card__meta-profile img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.blog-card__meta-profile span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
  text-transform: capitalize;
}
.blog-card__header-top {
  margin-top: 11px;
}
.blog-card__header-top .category,
.blog-card__header-top .date,
.blog-card__header-top .read {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.blog-card__header-top ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -8px;
}
.blog-card__header-top ul li {
  position: relative;
  margin: 0 8px;
}
.blog-card__header-top ul li:not(:last-child):after {
  content: "";
  position: absolute;
  right: -10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 3px;
  width: 3px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: var(--color-light);
}
.blog-card--2 {
  padding: 0;
}
.blog-card--2 .blog-card__thumbnail {
  padding: 0;
  height: 280px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.blog-card--2 .blog-card__thumbnail img {
  height: 280px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.blog-card--2 .blog-card__details {
  padding: 15px 25px;
}
.blog-card--2 .blog-card__title {
  margin-top: 20px;
  margin-bottom: 16px;
}
.blog-card--2 .blog-card__content p {
  line-height: 1.6875;
  margin-bottom: 13px;
}
.blog-card--3 {
  padding: 0;
}
.blog-card--3 .blog-card__thumbnail {
  padding: 0;
  height: 280px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.blog-card--3 .blog-card__thumbnail img {
  height: 280px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.blog-card--3 .blog-card__details {
  padding: 15px 25px;
}
.blog-card--3 .blog-card__title {
  margin-top: 17px;
  margin-bottom: 16px;
}
.blog-card--3 .blog-card__content p {
  line-height: 1.6875;
  margin-bottom: 13px;
}
.blog-page2 .breadcrumb-main {
  margin-bottom: 34px;
  margin-top: 31px;
}
.blog-details-thumbnail img {
  width: 100%;
  max-width: 100%;
  min-height: 450px;
  max-height: 450px;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.blog-details {
  padding: 0 100px;
}
.blog-details-meta {
  list-style: none;
  margin: 0 -10px 48px;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details-meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 10px;
  position: relative;
}
.blog-details-meta li:not(:last-child):before {
  position: absolute;
  content: "";
  width: 4px;
  height: 4px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-lighten);
  right: -4px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.blog-details-meta li a {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.blog-details-meta li a:hover {
  color: var(--color-secondary);
}
.blog-details-meta li a img {
  width: 50px;
  height: 50px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  margin-right: 10px;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-details-meta li.blog-read-time {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
}
.blog-details-meta .blog-author a span {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.25;
  color: var(--color-dark);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.blog-details-meta .blog-author a:hover span {
  color: var(--color-secondary);
}
.blog-details-content {
  margin-top: 53px;
}
.blog-body p {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 400;
  line-height: 1.6666666667;
  color: var(--color-gray);
}
.blog-body ul {
  margin-left: 6px;
}
.blog-body ul li {
  padding-left: 30px;
  margin-bottom: 20px;
  position: relative;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.blog-body ul li strong {
  color: var(--color-dark);
  font-weight: 500;
}
.blog-body ul li:before {
  position: absolute;
  left: 10px;
  top: 11px;
  width: 6px;
  min-width: 6px;
  height: 6px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  background-color: var(--color-dark);
}
.blog-body ol {
  margin-left: 6px;
}
.blog-body ol li {
  margin-bottom: 20px;
  position: relative;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 400;
  color: var(--color-gray);
  text-transform: capitalize;
}
.blog-body ol li strong {
  color: var(--color-dark);
  font-weight: 500;
}
.blog-body ol li::marker {
  color: var(--color-dark);
}
.blog-body ol li:not(:last-child) {
  margin-bottom: 18px;
}
.blog-tags ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -6px;
}
.blog-tags ul li {
  margin: 6px;
}
.blog-tags ul li a {
  font-size: 16px;
  font-weight: 400;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 34px;
  padding: 0 10px;
  text-decoration: none;
  line-height: normal;
  color: var(--color-gray);
  background-color: var(--bg-lighters);
}
.blog-tags ul li a:hover {
  background-color: var(--color-primary);
  color: var(--color-white);
}
.blog-share {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 50px;
}
.blog-share span {
  font-size: 16px;
  font-weight: 500;
  color: var(--color-dark);
  white-space: nowrap;
  text-transform: capitalize;
  margin: 10px 18px 10px 0;
}
.blog-share ul {
  margin: -5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.blog-share ul li {
  margin: 5px;
}
.blog-share ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  min-height: 37px;
  padding: 0 16px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  text-decoration: none;
  border: 1px solid var(--border-color);
  background-color: var(--bg-white);
  color: var(--color-gray);
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.blog-share ul li a:hover {
  color: var(--color-white);
}
.blog-share ul li a i {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: 16px;
  margin-right: 3px;
}
.blog-share ul li .facebook i {
  color: var(--color-facebook);
}
.blog-share ul li .facebook:hover {
  background-color: var(--color-facebook);
}
.blog-share ul li .facebook:hover i {
  color: var(--color-white);
}
.blog-share ul li .twitter i {
  color: var(--color-twitter);
}
.blog-share ul li .twitter:hover {
  background-color: var(--color-twitter);
}
.blog-share ul li .twitter:hover i {
  color: var(--color-white);
}
.blog-share ul li .pinterest i {
  color: var(--color-pinterest);
}
.blog-share ul li .pinterest:hover {
  background-color: var(--color-pinterest);
}
.blog-share ul li .pinterest:hover i {
  color: var(--color-white);
}
.blog-share ul li .linkedin i {
  color: var(--color-linkedin);
}
.blog-share ul li .linkedin:hover {
  background-color: var(--color-linkedin);
}
.blog-share ul li .linkedin:hover i {
  color: var(--color-white);
}
.blog-share ul li .copy-to-clickboard i {
  color: var(--color-copy);
}
.blog-share ul li .copy-to-clickboard:hover {
  background-color: var(--color-secondary);
}
.blog-share ul li .copy-to-clickboard:hover i {
  color: var(--color-white);
}
.blog-block-quote {
  padding: 17px 22px 15px 45px;
  margin-left: 15px;
  background-color: transparent;
  border-left: 2px solid rgba(var(--color-primary-rgba), 0.5);
  margin-bottom: 50px;
}
.blog-block-quote p {
  line-height: 1.63;
  color: var(--color-dark);
  position: relative;
  margin-bottom: 0;
  font-size: calc(16.4px + 0.3vw);
}
.blog-block-quote cite {
  font-style: normal;
  position: relative;
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  padding-left: 32px;
  color: var(--color-dark);
  margin-top: 14px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-block-quote cite span {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 400;
  line-height: 1.2222222222;
  color: var(--color-gray);
  margin-left: 6px;
}
.blog-block-quote cite:before {
  position: absolute;
  left: 0;
  top: 50%;
  width: 20px;
  height: 2px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  content: "";
  z-index: 22;
  background-color: var(--border-deep);
}
.blog-block-quote__wrapper {
  position: relative;
}
.blog-block-quote__wrapper .top-icon {
  position: absolute;
  top: 0;
  left: -20px;
}
.blog-block-quote__wrapper .top-icon img,
.blog-block-quote__wrapper .top-icon svg {
  width: 15px;
  height: auto;
}
.blog-block-quote__wrapper p:after {
  display: inline-block;
  content: " ";
  background-image: url(img/svg/bottomSemicolon.svg);
  -webkit-background-size: 15px 28px;
  background-size: 15px 28px;
  height: 28px;
  width: 28px;
  z-index: 1;
  background-repeat: no-repeat;
  vertical-align: middle;
}
.blog-author-box {
  padding: 35px;
  background-color: var(--color-white);
  -webkit-border-radius: 8px;
  border-radius: 8px;
  -webkit-box-shadow: 0 10px 40px rgba(var(--color-lighten-rgba), 0.2);
  box-shadow: 0 10px 40px rgba(var(--color-lighten-rgba), 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 50px 0 100px;
}
.blog-author-box .auth-img {
  margin-right: 20px;
}
.blog-author-box .auth-img img {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  -o-object-fit: cover;
  object-fit: cover;
}
.blog-author-box .auth-details span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-light);
  margin-bottom: 3px;
  display: inline-block;
}
.blog-author-box .auth-details h2 {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  color: var(--color-dark);
}
.blog-author-box .auth-details p {
  font-size: 16px;
  line-height: 1.6875;
  font-weight: 400;
  color: var(--color-gray);
  margin-top: 20px;
  margin-bottom: 0;
}
.blog-details .blog-share-top {
  position: fixed;
  top: 0;
  right: 0;
  margin-right: 15%;
  -webkit-transform: translateY(160px);
  -ms-transform: translateY(160px);
  transform: translateY(160px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.blog-details .blog-share-top.show {
  -webkit-transform: translateY(140px);
  -ms-transform: translateY(140px);
  transform: translateY(140px);
  opacity: 1;
  visibility: visible;
}
.blog-details .blog-share-top .blog-share-fixed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.blog-details .blog-share-top .blog-share-fixed span {
  font-size: 16px;
  font-weight: 500;
  color: #5c637e;
  white-space: nowrap;
  text-transform: capitalize;
  margin: 12px;
}
.blog-details .blog-share-top .blog-share-fixed ul {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.blog-details .blog-share-top .blog-share-fixed ul li {
  margin-bottom: 10px;
}
.blog-details .blog-share-top .blog-share-fixed ul li a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  text-decoration: none;
  border: 1px solid var(--border-color);
  background-color: var(--bg-white);
  color: var(--color-gray);
  text-transform: capitalize;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  width: 50px;
  height: 50px;
}
.blog-details .blog-share-top .blog-share-fixed ul li a:hover {
  color: var(--color-white);
}
.blog-details .blog-share-top .blog-share-fixed ul li a i {
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  font-size: calc(16.2px + 0.15vw);
}
.blog-details .blog-share-top .blog-share-fixed ul li .facebook i {
  color: var(--color-facebook);
}
.blog-details .blog-share-top .blog-share-fixed ul li .facebook:hover {
  background-color: var(--color-facebook);
}
.blog-details .blog-share-top .blog-share-fixed ul li .facebook:hover i {
  color: var(--color-white);
}
.blog-details .blog-share-top .blog-share-fixed ul li .twitter i {
  color: var(--color-twitter);
}
.blog-details .blog-share-top .blog-share-fixed ul li .twitter:hover {
  background-color: var(--color-twitter);
}
.blog-details .blog-share-top .blog-share-fixed ul li .twitter:hover i {
  color: var(--color-white);
}
.blog-details .blog-share-top .blog-share-fixed ul li .pinterest i {
  color: var(--color-pinterest);
}
.blog-details .blog-share-top .blog-share-fixed ul li .pinterest:hover {
  background-color: var(--color-pinterest);
}
.blog-details .blog-share-top .blog-share-fixed ul li .pinterest:hover i {
  color: var(--color-white);
}
.blog-details .blog-share-top .blog-share-fixed ul li .linkedin i {
  color: var(--color-linkedin);
}
.blog-details .blog-share-top .blog-share-fixed ul li .linkedin:hover {
  background-color: var(--color-linkedin);
}
.blog-details .blog-share-top .blog-share-fixed ul li .linkedin:hover i {
  color: var(--color-white);
}
.blog-details .blog-share-top .blog-share-fixed ul li .copy-to-clickboard i {
  color: var(--color-copy);
}
.blog-details
  .blog-share-top
  .blog-share-fixed
  ul
  li
  .copy-to-clickboard:hover {
  background-color: var(--color-secondary);
}
.blog-details
  .blog-share-top
  .blog-share-fixed
  ul
  li
  .copy-to-clickboard:hover
  i {
  color: var(--color-white);
}
.admin {
  background-image: url(img/admin-bg-light.png);
  background-repeat: no-repeat;
  background-position: top;
  min-height: 630px;
}
.admin .edit-profile {
  margin-top: 153px;
  margin-bottom: 150px;
}
.admin .edit-profile .card {
  -webkit-border-radius: 6px;
  border-radius: 6px;
}
.admin .edit-profile .card .card-header {
  border-bottom: 1px solid var(--border-color);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 70px;
  padding: 20px 40px;
}
.admin .edit-profile .card .card-header h4 {
  font-size: calc(16.4px + 0.3vw);
}
.admin .edit-profile .card .card-body {
  padding: 30px 40px 28px;
}
.admin
  .edit-profile
  .card
  .card-body
  .edit-profile__body
  .form-group
  .form-control {
  color: var(--color-dark);
  -webkit-box-shadow: 0 5px 20px transparent;
  box-shadow: 0 5px 20px transparent;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.admin
  .edit-profile
  .card
  .card-body
  .edit-profile__body
  .form-group
  .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.admin
  .edit-profile
  .card
  .card-body
  .edit-profile__body
  .form-group
  .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.admin
  .edit-profile
  .card
  .card-body
  .edit-profile__body
  .form-group
  .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.admin
  .edit-profile
  .card
  .card-body
  .edit-profile__body
  .form-group
  .form-control::placeholder {
  color: var(--color-lighten);
}
.admin .edit-profile__logos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 25px;
}
.admin .edit-profile__logos .light {
  display: none;
}
.admin .edit-profile__logos img {
  width: 195px;
  max-width: 100%;
}
.admin-element {
  background-color: var(--color-white);
}
.admin-topbar {
  background-color: #eff0f3;
  min-height: 70px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 20px;
  margin-top: 30px;
  -webkit-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.admin-topbar p {
  color: var(--color-gray);
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
}
.admin-element-right .card .card-header h6 {
  font-size: calc(16.8px + 0.6vw);
  line-height: 1.25;
  font-weight: 600;
  color: var(--color-dark);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.admin-element-right .card .card-header h6 span {
  color: var(--color-primary);
  font-size: calc(16.8px + 0.6vw);
  line-height: 1.25;
  font-weight: 600;
  margin: 0;
  margin-left: 6px;
}
.admin-element-right
  .edit-profile__body
  .form-group
  .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.admin-element-right
  .edit-profile__body
  .form-group
  .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.admin-element-right
  .edit-profile__body
  .form-group
  .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.admin-element-right
  .edit-profile__body
  .form-group
  .form-control::placeholder {
  color: var(--color-lighten);
}
.admin-header-top p {
  font-size: 14px;
  line-height: 1.5714285714;
  font-weight: 400;
  color: var(--color-gray);
}
.admin-condition {
  margin-bottom: 2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  text-transform: capitalize;
  margin-bottom: 19px;
  margin-top: 23px;
}
.admin-condition .custom-checkbox input[type="checkbox"] + label:after,
.admin-condition .custom-checkbox input[type="checkbox"] + label:before {
  margin-top: 0;
}
.admin-condition a {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-primary);
}
.admin-condition .custom-checkbox .checkbox-text {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 400;
  color: var(--color-light);
  cursor: pointer;
}
.admin-condition .custom-checkbox input[type="checkbox"] + label:before {
  top: 10px;
  left: 10px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.admin-condition .custom-checkbox input[type="checkbox"] + label:after {
  width: 20px;
  height: 20px;
}
.admin-socialBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.admin-socialBtn li button {
  -webkit-box-shadow: none;
  box-shadow: none;
  background: var(--bg-normal);
  border: none;
  white-space: nowrap;
  width: 48px;
  height: 48px;
  padding: 0;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.admin-socialBtn li button:hover {
  opacity: 0.7;
}
.admin-socialBtn li button svg {
  width: 20px;
}
.admin-socialBtn li button i {
  font-size: 22px;
}
.admin-socialBtn li button.google {
  background-color: #f06548;
}
.admin-socialBtn li button.facebook {
  background-color: rgba(58, 88, 155, 0.1);
}
.admin-socialBtn li button.facebook i {
  color: #3a589b;
}
.admin-socialBtn li button.twitter {
  background-color: rgba(3, 169, 244, 0.1);
}
.admin-socialBtn li button.twitter i {
  color: #03a9f4;
}
.admin-socialBtn li button.github {
  background-color: rgba(9, 14, 48, 0.1);
}
.admin-socialBtn li button.github i {
  color: #090e30;
}
.admin-socialBtn li img,
.admin-socialBtn li svg {
  margin-right: 0;
  width: 20px;
  height: 20px;
}
.admin-socialBtn li:not(:last-child) {
  margin-right: 10px;
}
.social-connector {
  overflow: hidden;
  text-align: center;
  position: relative;
}
.social-connector span {
  font-size: 13px;
  line-height: 1.6923076923;
  font-weight: 500;
  color: var(--color-light);
}
.social-connector.social-connector__admin {
  margin-bottom: 27px;
}
.social-connector:after,
.social-connector:before {
  background-color: var(--border-light);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 50%;
}
.social-connector:before {
  right: 0.5em;
  margin-left: -50%;
}
.social-connector:after {
  left: 0.5em;
  margin-right: -50%;
}
.admin-createBtn {
  width: 185px;
}
.signIn-createBtn {
  -webkit-border-radius: 6px;
  border-radius: 6px;
  white-space: nowrap;
  min-height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 500;
}
.adminTop {
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.adminBottom {
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(-1);
  -ms-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
.edit-profile__body label {
  text-transform: capitalize;
  margin-bottom: 8px;
}
.edit-profile__body .form-group label {
  font-size: 14px;
  line-height: 1.4285714286;
  font-weight: 500;
  color: var(--color-dark);
}
.edit-profile__body .form-group .form-control {
  border: 1px solid var(--border-color);
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 20px;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.edit-profile__body .form-group .form-control::-webkit-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.edit-profile__body .form-group .form-control::-moz-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.edit-profile__body .form-group .form-control::-ms-input-placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.edit-profile__body .form-group .form-control::placeholder {
  font-size: 14px;
  line-height: 1.7857142857;
  font-weight: 400;
  color: var(--color-gray);
}
.edit-profile__body .form-group .form-control:hover {
  border-color: var(--color-primary);
}
.edit-profile__body .form-group textarea.form-control {
  height: auto;
  resize: none;
}
.uil-eye-slash.uil-eye:before {
  content: "\ebaf" !important;
}
.edit-profile__body .form-control {
  height: 48px;
}
.edit-profile__body .form-control--social {
  height: 44px;
  padding: 0.375rem 0.9375rem;
}
.edit-profile__body .form-control--social::-webkit-input-placeholder {
  color: var(--color-light);
}
.edit-profile__body .form-control--social::-moz-placeholder {
  color: var(--color-light);
}
.edit-profile__body .form-control--social::-ms-input-placeholder {
  color: var(--color-light);
}
.edit-profile__body .form-control--social::placeholder {
  color: var(--color-light);
}
.dark-trigger {
  position: fixed;
  right: 30px;
  top: 30px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 999;
  margin: 0;
  color: #fff;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.dark-trigger ul li a {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  line-height: 63px;
  background: var(--color-warning);
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.dark-trigger ul li a i {
  font-size: 22px;
}
.dark-trigger ul li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background: var(--color-warning);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  z-index: -1;
}
.dark-trigger ul li a.active,
.dark-trigger ul li a:hover {
  color: #ffee10;
  text-shadow: 0 0 5px #ffee10;
}
.dark-trigger ul li a.active::before,
.dark-trigger ul li a:hover::before {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-box-shadow: 0 0 15px var(--color-warning);
  box-shadow: 0 0 15px var(--color-warning);
}
.layout-dark .navbar-left .navbar-brand .light {
  display: block;
}
.layout-dark .navbar-left .navbar-brand .dark {
  display: none;
}
.layout-dark .main-content {
  background-color: var(--contents-bg);
}
.layout-dark .card {
  -webkit-box-shadow: 0 5px 20px var(--shadow2);
  box-shadow: 0 5px 20px var(--shadow2);
}
.layout-dark a:hover {
  color: var(--color-white);
}
.layout-dark .bg-lighter,
.layout-dark .bg-white {
  background-color: var(--white-10) !important;
}
.layout-dark .color-dark,
.layout-dark .text-dark {
  color: var(--white-87) !important;
}
.layout-dark .bg-normal {
  background-color: var(--white-10);
}
.layout-dark .text-gray {
  color: var(--white-60) !important;
}
.layout-dark .btn-dark {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .btn-link {
  border-color: var(--border-color);
}
.layout-dark .btn-link:hover {
  color: var(--white-87);
}
.layout-dark .btn-transparent-dark {
  color: var(--white-87);
}
.layout-dark .color-dark.btn-outline-dark {
  border-color: var(--border-color);
}
.layout-dark .btn.btn-ghost:hover {
  color: var(--main-color);
}
.layout-dark .dm-select select option {
  color: var(--main-color);
}
.layout-dark .select2-dropdown {
  background-color: #323541;
}
.layout-dark
  .select2-dropdown.category-member
  .select2-search--dropdown
  .select2-search__field {
  border-color: var(--border-color);
  background-color: var(--white-10);
}
.layout-dark
  .select2-dropdown.category-member
  .select2-search--dropdown
  .select2-search__field:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark
  .select2-container--default
  .select2-results__option--highlighted[aria-selected],
.layout-dark
  .select2-container--default
  .select2-results__option[aria-selected="true"] {
  color: var(--white-87);
}
.layout-dark .select2-container--default .select2-selection--multiple,
.layout-dark .select2-container--default .select2-selection--single {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .dm-pagination .dm-pagination__link {
  background-color: var(--white-10);
}
.layout-dark .dm-pagination .dm-pagination__link.active,
.layout-dark .dm-pagination .dm-pagination__link:hover {
  background-color: var(--white-87);
  color: var(--main-color);
}
.layout-dark .dm-pagination .dm-pagination__link.active span,
.layout-dark .dm-pagination .dm-pagination__link:hover span {
  color: var(--main-color);
}
.layout-dark .dm-pagination .dm-pagination__link.pagination-control {
  color: var(--white-60);
}
.layout-dark .dm-pagination .dm-pagination__item select {
  background-color: var(--white-10);
}
.layout-dark .dm-pagination .dm-pagination__item select option {
  background: var(--white-10);
  color: var(--main-color);
}
.layout-dark .quantity-appearance .pt_QuantityButton {
  border-color: var(--border-color);
}
.layout-dark .custom-radio input[type="radio"] + label:after {
  border-color: var(--border-color);
  background-color: var(--white-10);
}
.layout-dark .custom-radio input[type="radio"]:checked + label:after {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.layout-dark .custom-checkbox input[type="checkbox"] + label:after {
  background-color: transparent;
  border-color: var(--white-60);
}
.layout-dark
  .custom-checkbox
  input[type="checkbox"]:indeterminate
  ~ label::after {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.layout-dark .custom-checkbox input[type="checkbox"]:checked + label:after {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.layout-dark
  .custom-checkbox.checkbox-theme-2
  input[type="checkbox"]
  + label:after {
  background-color: transparent;
}
.layout-dark
  .radio-horizontal-list2
  .custom-radio
  input[type="radio"]:checked
  + label:after {
  background-color: transparent;
}
.layout-dark .customizer-wrapper {
  background-color: #323541;
}
.layout-dark .customizer-wrapper .customizer__head {
  border-color: var(--border-color);
}
.layout-dark .customizer-trigger {
  background-color: var(--white-10);
  color: var(--white-60);
}
.layout-dark .customizer-trigger:hover {
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.layout-dark .dm-collapse .dm-collapse-item__header {
  background-color: var(--white-10);
}
.layout-dark .btn.btn-light,
.layout-dark .btn.btn-white {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .btn.btn-light:hover,
.layout-dark .btn.btn-white:hover {
  opacity: 0.7;
}
.layout-dark .modal-content {
  background-color: #1b1e2b;
}
.layout-dark .modal-header .close {
  background-color: var(--white-10);
  color: var(--white-87);
}
.layout-dark .modal-backdrop {
  background-color: #fff;
}
.layout-dark .form-check-input {
  background-color: var(--white-30);
}
.layout-dark .hasDatepicker,
.layout-dark .hasWickedpicker {
  background-color: transparent;
}
.layout-dark .form-element .dm-select.disabled .form-control {
  background-color: var(--white-30);
}
.layout-dark .timeline-box .timeline-single__buble {
  background-color: var(--main-color);
}
.layout-dark .timeline-box--3 .timeline-single__content {
  background-color: var(--white-10);
}
.layout-dark .timeline-box--3.basic-timeline .timeline-single__content {
  background-color: transparent;
}
.layout-dark .timeline-vertical .timeline li .timeline-single:before {
  border-right: 0 solid #323541;
  border-left: 11px solid #323541;
}
.layout-dark
  .timeline-vertical
  .timeline
  li.timeline-inverted
  > .timeline-single:before {
  border-left-width: 0;
  border-right-width: 12px;
  left: -12px;
  right: auto;
}
.layout-dark .timeline-vertical .timeline > li .timeline-single__buble {
  border-color: #1b1e2b;
}
.layout-dark .nav-tabs .nav-link.active {
  background-color: transparent;
}
.layout-dark .nav-tabs .nav-link.show .nav-link {
  background-color: transparent;
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li.active {
  background-color: rgba(255, 255, 255, 0.1);
}
.layout-dark .navbar-left .sidebar-toggle svg path {
  color: var(--white-60) !important;
  fill: var(--white-60) !important;
}
.layout-dark .navbar-left .sidebar-toggle:hover svg path {
  color: var(--color-primary) !important;
  fill: var(--color-primary) !important;
}
.layout-dark .mobile-author-actions,
.layout-dark .mobile-search {
  background-color: #1b1e2b;
}
.layout-dark .mobile-author-actions input,
.layout-dark .mobile-search input {
  background-color: transparent;
}
.layout-dark .navbar-right__menu .search-toggle i,
.layout-dark .navbar-right__menu .search-toggle svg {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-item-toggle {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-item-toggle.icon-active:before {
  border-color: var(--main-color);
}
.layout-dark .navbar-right__menu .nav-author .nav-item__title {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-author .nav-item__title .nav-item__arrow {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .search-form-topMenu {
  background-color: var(--white-06);
  border-color: var(--white-06);
}
.layout-dark .navbar-right__menu .search-form-topMenu .form-control {
  background-color: transparent;
  color: var(--white-87);
}
.layout-dark
  .navbar-right__menu
  .search-form-topMenu
  .form-control::-webkit-input-placeholder {
  color: var(--white-60);
}
.layout-dark
  .navbar-right__menu
  .search-form-topMenu
  .form-control::-moz-placeholder {
  color: var(--white-60);
}
.layout-dark
  .navbar-right__menu
  .search-form-topMenu
  .form-control::-ms-input-placeholder {
  color: var(--white-60);
}
.layout-dark
  .navbar-right__menu
  .search-form-topMenu
  .form-control::placeholder {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .search-form-topMenu .search-icon {
  color: var(--white-60);
}
.layout-dark .hexadash-top-menu .subMenu {
  background-color: var(--main-color);
}
.layout-dark .hexadash-top-menu > ul > li > ul li a:hover {
  background-color: var(--white-06);
  color: var(--white-87);
}
.layout-dark .hexadash-top-menu > ul > li .megaMenu-wrapper {
  background-color: var(--main-color);
}
.layout-dark
  .hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-small
  ul
  li
  > a:after {
  background-color: var(--white-87);
}
.layout-dark
  .hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-small
  ul
  li
  > a.active,
.layout-dark
  .hexadash-top-menu
  > ul
  > li
  .megaMenu-wrapper.megaMenu-small
  ul
  li
  > a:hover {
  color: var(--white-87);
  background-color: var(--white-06);
}
.layout-dark .navbar-right__menu .dropdown-custom .dropdown-wrapper {
  background-color: #323541;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark
  .navbar-right__menu
  .dropdown-custom
  .dropdown-wrapper
  .dropdown-wrapper__title {
  background-color: var(--white-10);
}
.layout-dark
  .navbar-right__menu
  .dropdown-custom
  .dropdown-wrapper
  .dropdown-wrapper__more {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--white-10);
  color: var(--white-60);
}
.layout-dark
  .navbar-right__menu
  .nav-message
  .dropdown-wrapper
  .user-avater:before {
  border-color: var(--main-color);
}
.layout-dark
  .navbar-right__menu
  .nav-message
  .dropdown-wrapper
  .user-message
  p {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-message .dropdown-wrapper ul li:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--white-06);
}
.layout-dark .navbar-right__menu .nav-notification__details p {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-notification__single:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: var(--white-06);
}
.layout-dark .navbar-right__menu .nav-notification__single:hover .subject {
  color: var(--white-87);
}
.layout-dark .navbar-right__menu .list-settings {
  margin: -3px;
}
.layout-dark .navbar-right__menu .list-settings li {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  margin: 3px;
  -webkit-box-flex: 48%;
  -ms-flex: 48%;
  flex: 48%;
}
.layout-dark .navbar-right__menu .list-settings li:hover {
  background-color: var(--white-06);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .navbar-right__menu .list-settings li p {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-flag-select .dropdown-wrapper a {
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-flag-select .dropdown-wrapper a:hover {
  background-color: var(--white-06);
  color: var(--white-87);
}
.layout-dark .navbar-right__menu .nav-author__info {
  background-color: var(--white-10);
}
.layout-dark .navbar-right__menu .nav-author__signout {
  background-color: var(--white-10);
  color: var(--white-60);
}
.layout-dark .navbar-right__menu .nav-author__info span {
  color: var(--white-60);
}
.layout-dark .sidebar {
  background-color: #1b1e2b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .sidebar.collapsed .sidebar__menu-group .has-child ul {
  background-color: var(--main-color);
}
.layout-dark .sidebar.collapsed .sidebar__menu-group .has-child ul li a:hover {
  color: var(--white-100);
}
.layout-dark .sidebar.collapsed .sidebar__menu-group li a.active,
.layout-dark .sidebar.collapsed .sidebar__menu-group li a:hover {
  background-color: var(--white-06);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li.menu-title span {
  color: var(--white-30);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a {
  color: var(--white-60);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a .nav-icon {
  color: var(--white-60);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a.active,
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a:hover {
  color: var(--white-100);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a.active .nav-icon,
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a.active .toggle-icon,
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a:hover .nav-icon,
.layout-dark .sidebar__menu-group ul.sidebar_nav li > a:hover .toggle-icon {
  color: var(--white-100);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li ul li.active,
.layout-dark .sidebar__menu-group ul.sidebar_nav li ul li:hover {
  color: var(--white-100);
}
.layout-dark .sidebar__menu-group ul.sidebar_nav li ul li.active a,
.layout-dark .sidebar__menu-group ul.sidebar_nav li ul li:hover a {
  color: var(--white-100);
}
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.has-child.active,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.has-child:hover,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.open.active,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.open:hover {
  background-color: transparent;
}
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.has-child.active
  a,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.has-child:hover
  a,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.open.active
  a,
.layout-dark
  .fileM-types
  .sidebar__menu-group
  ul.sidebar_nav
  li
  ul
  li.open:hover
  a {
  color: var(--white-87);
}
.layout-dark .fileM-types .sidebar__menu-group ul.sidebar_nav li ul li.active,
.layout-dark .fileM-types .sidebar__menu-group ul.sidebar_nav li ul li:hover {
  background-color: transparent;
}
.layout-dark .breadcrumb-item a {
  color: var(--white-60);
}
.layout-dark .breadcrumb-item a i,
.layout-dark .breadcrumb-item a span {
  color: var(--white-60);
}
.layout-dark .breadcrumb-item.active {
  color: var(--white-30);
}
.layout-dark .breadcrumb-item + .breadcrumb-item:before {
  background-color: var(--white-30);
}
.layout-dark .order-summery.card {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}
.layout-dark .card {
  background-color: var(--white-10);
  border-color: transparent;
}
.layout-dark .card .card-header {
  background-color: transparent;
  border-bottom: 1px solid var(--border-color);
}
.layout-dark .card .card-header .card-tab-links li a.active {
  background-color: var(--white-10);
  color: var(--white-87);
}
.layout-dark .card .sales-target__single {
  color: var(--white-60);
}
.layout-dark .card.bg-normal .card-body,
.layout-dark .card.bg-normal .card-header {
  background-color: transparent;
}
.layout-dark .ap-po-details {
  background-color: var(--white-10);
}
.layout-dark .ap-po-details--2 .ap-po-details-time {
  background-color: var(--white-06);
}
.layout-dark .ap-po-details--2 .overview-content {
  background-color: transparent;
}
.layout-dark .ap-po-details--5 {
  background-color: var(--white-10);
}
.layout-dark .ap-po-details--5 .overview-content {
  background-color: transparent;
}
.layout-dark .ap-po-details--6 .overview-content {
  background-color: transparent;
}
.layout-dark .feature-profile {
  background-color: var(--white-10);
}
.layout-dark .table > :not(:last-child) > :last-child > * {
  border-bottom-color: var(--border-color);
}
.layout-dark table.table-rounded tbody tr td {
  background-color: transparent;
}
.layout-dark .table-top-location {
  border-top: var(--white-10);
}
.layout-dark .table-top-location table tbody tr:hover {
  background-color: transparent;
}
.layout-dark .table-top-location table tbody tr:hover td {
  background-color: transparent;
}
.layout-dark .apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid #1b1d2a;
  background: #1b1d2a;
}
.layout-dark
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background: #1b1d2a;
  border-bottom: 1px solid var(--white-30);
}
.layout-dark .revenue-chart-box__label {
  color: var(--white-60);
}
.layout-dark .deals-table-wrap th,
.layout-dark .landing-pages-table th,
.layout-dark .leader-table-wrap th,
.layout-dark .selling-table-wrapB5 th,
.layout-dark .table-revenue th,
.layout-dark .table-social th,
.layout-dark .traffic-table th {
  background-color: var(--white-10);
}
.layout-dark .deals-table-wrap td,
.layout-dark .deals-table-wrap tr,
.layout-dark .landing-pages-table td,
.layout-dark .landing-pages-table tr,
.layout-dark .leader-table-wrap td,
.layout-dark .leader-table-wrap tr,
.layout-dark .selling-table-wrapB5 td,
.layout-dark .selling-table-wrapB5 tr,
.layout-dark .table-revenue td,
.layout-dark .table-revenue tr,
.layout-dark .table-social td,
.layout-dark .table-social tr,
.layout-dark .traffic-table td,
.layout-dark .traffic-table tr {
  border-color: var(--white-10);
}
.layout-dark .deals-table-wrap tr:hover,
.layout-dark .landing-pages-table tr:hover,
.layout-dark .leader-table-wrap tr:hover,
.layout-dark .selling-table-wrapB5 tr:hover,
.layout-dark .table-revenue tr:hover,
.layout-dark .table-social tr:hover,
.layout-dark .traffic-table tr:hover {
  background-color: var(--white-10);
}
.layout-dark .deals-table-wrap tbody tr:not(:first-child):hover,
.layout-dark .landing-pages-table tbody tr:not(:first-child):hover,
.layout-dark .leader-table-wrap tbody tr:not(:first-child):hover,
.layout-dark .selling-table-wrapB5 tbody tr:not(:first-child):hover,
.layout-dark .table-revenue tbody tr:not(:first-child):hover,
.layout-dark .table-social tbody tr:not(:first-child):hover,
.layout-dark .traffic-table tbody tr:not(:first-child):hover {
  background-color: var(--white-10);
}
.layout-dark .table-top-regions {
  border: 1px solid var(--white-10);
}
.layout-dark .table-top-regions table thead {
  background-color: var(--white-10);
}
.layout-dark .table-top-regions::-webkit-scrollbar-thumb {
  background-color: rgba(227, 230, 239, 0.3);
}
.layout-dark .t-thead-bg .selling-table-wrap table thead tr {
  background: var(--white-10);
}
.layout-dark .ap-po-details--3 > div:not(:last-child) > div {
  border-color: var(--white-10);
}
.layout-dark .forcast-cardbox {
  background-color: var(--white-10);
}
.layout-dark .performance-stats__up {
  background-color: transparent;
}
.layout-dark .performance-stats a.active .performance-stats__down,
.layout-dark .performance-stats a.active .performance-stats__up {
  background-color: var(--white-10);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .performance-stats div:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .card-overview-progress .card-progress {
  background-color: #1e212e;
  -webkit-box-shadow: 0 10px 30px var(--shadow2);
  box-shadow: 0 10px 30px var(--shadow2);
}
.layout-dark .crm .selling-table-wrap table thead tr {
  background-color: var(--white-10);
}
.layout-dark .jvectormap-container .jvectormap-zoomin,
.layout-dark .jvectormap-container .jvectormap-zoomout {
  color: var(--main-color);
}
.layout-dark .session-wrap.session-wrap--2 {
  background-color: var(--white-10);
}
.layout-dark .session-wrap .session-single .chart-label {
  color: var(--white-60);
}
.layout-dark .chartjs-tooltip {
  background-color: #323541;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #323541;
}
.layout-dark .chartjs-tooltip table tbody td {
  color: var(--white-60);
}
.layout-dark .chartjs-tooltip table tbody td .data-label {
  color: var(--white-60);
}
.layout-dark .overview-content {
  background-color: var(--white-10);
}
.layout-dark .add-lebel-from {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 10px 20px var(--shadow2);
  box-shadow: 0 10px 20px var(--shadow2);
}
.layout-dark .chat {
  background-color: var(--white-10);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
.layout-dark .chat.mini-chat {
  background-color: transparent;
}
.layout-dark .chat .chat-body {
  background-color: transparent !important;
}
.layout-dark .chat .chat-body .chat-header {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-bottom: 1px solid var(--border-color);
}
.layout-dark .chat .chat-body .chat-header .image-group ul li a {
  border-color: var(--border-color) !important;
}
.layout-dark .chat .chat-body .chat-header .dropdown .dropdown-item:hover {
  background-color: var(--white-10);
}
.layout-dark .chat .chat-body .chat-text-box__subtitle {
  background-color: var(--white-06) !important;
}
.layout-dark .chat .chat-body .chat-text-box__subtitle p {
  color: var(--white-60);
}
.layout-dark .chat .chat-body .chat-type-text__write {
  background-color: var(--white-10);
}
.layout-dark .chat .chat-body .chat-type-text__write a svg {
  color: var(--white-60);
}
.layout-dark .chat .chat-body .chat-type-text__btn .color-light {
  background-color: var(--white-10);
}
.layout-dark .chat .chat-body .chat-text-box__other button svg {
  color: var(--white-60);
}
.layout-dark .chat-area .sidebar-group .left-sidebar-wrap {
  background-color: var(--white-10);
}
.layout-dark .chat-area .chat-wrapper form {
  background-color: var(--white-06);
  border-color: var(--white-06);
}
.layout-dark .chat-area .chat-wrapper form input {
  background-color: transparent;
}
.layout-dark .chat-area .chat-wrapper form input::-webkit-input-placeholder {
  color: var(--white-60);
}
.layout-dark .chat-area .chat-wrapper form input::-moz-placeholder {
  color: var(--white-60);
}
.layout-dark .chat-area .chat-wrapper form input::-ms-input-placeholder {
  color: var(--white-60);
}
.layout-dark .chat-area .chat-wrapper form input::placeholder {
  color: var(--white-60);
}
.layout-dark .chat-area .search-tab .ap-tab-main {
  border-color: var(--white-10) !important;
}
.layout-dark .chat-area .search-tab .ap-tab-main .nav-link.active {
  color: var(--white-87);
}
.layout-dark .chat-area .search-tab .ap-tab-main .nav-link.active:after {
  background-color: var(--white-87);
}
.layout-dark .chat-area .search-body .user-button-top button {
  border-color: var(--border-color) !important;
  background-color: var(--white-10);
  color: var(--white-60);
}
.layout-dark .chat-area .search-body .chat-badge-dot {
  border-color: var(--main-color);
}
.layout-dark .chat-body .social-connector:after,
.layout-dark .chat-body .social-connector:before {
  background-color: var(--border-color);
}
.layout-dark .progress {
  background-color: var(--white-20);
}
.layout-dark .progress .progress-bar.bg-dark {
  background-color: #090e30 !important;
}
.layout-dark .feature-profile .feature-profile_content img {
  border-color: #1b1e2b;
}
.layout-dark .team-members ul li .team-member__right .team-member__add button {
  background-color: var(--white-10);
  color: var(--white-87);
}
.layout-dark
  .team-members
  ul
  li
  .team-member__right
  .team-member__add
  button:hover {
  background-color: #fff;
  color: #090e30;
}
.layout-dark .card.banner-feature--18 {
  background-color: var(--white-10) !important;
  border-color: transparent !important;
}
.layout-dark .calendar-grid .ui-datepicker {
  background-color: transparent;
}
.layout-dark .ui-datepicker {
  background-color: #323541;
}
.layout-dark .ui-datepicker .ui-datepicker-month {
  color: var(--white-60);
  background-color: transparent;
}
.layout-dark .ui-datepicker .ui-datepicker-year {
  color: var(--white-60);
  background-color: transparent;
}
.layout-dark .ui-state-default,
.layout-dark .ui-widget-content .ui-state-default,
.layout-dark .ui-widget-header .ui-state-default {
  background-color: #1b1e2b;
}
.layout-dark .ui-datepicker-calendar tbody td a.ui-state-highlight {
  background-color: var(--white-10);
  color: var(--white-87);
  border-color: transparent;
}
.layout-dark .ui-datepicker-calendar tbody td a:hover {
  border-color: transparent;
  background-color: var(--white-10);
  color: var(--white-87);
}
.layout-dark .ui-slider .ui-slider-handle:after {
  background-color: #1b1e2b;
}
.layout-dark .date-picker__calendar .ui-datepicker-header a.ui-corner-all {
  color: var(--white-60);
}
.layout-dark
  .date-picker__calendar
  .ui-datepicker-header
  a.ui-corner-all:hover {
  color: var(--white-87);
}
.layout-dark .date-picker--demo5 .ui-datepicker {
  background-color: transparent;
}
.layout-dark
  .date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-calendar
  tbody
  td
  a {
  background-color: transparent;
}
.layout-dark
  .date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-calendar
  tbody
  td
  a.ui-state-active,
.layout-dark
  .date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-calendar
  tbody
  td
  a.ui-state-highlight,
.layout-dark
  .date-picker--demo5
  .date-picker__calendar
  .ui-datepicker-calendar
  tbody
  td
  a:hover {
  background-color: var(--white-10);
}
.layout-dark
  .card-timeline
  .card-timeline-body
  ul
  li:not(:last-child)
  .card-timeline-body__title
  .timeline-line {
  background-color: var(--white-10);
}
.layout-dark
  .card-timeline
  .card-timeline-body
  ul
  li
  .card-timeline-body__title
  p {
  color: var(--white-87);
}
.layout-dark
  .card-inbox
  .card-inbox-members
  ul
  li
  .card-inbox-members__left
  .card-inbox-members__title
  h6 {
  color: var(--white-87);
}
.layout-dark .blog-card {
  background-color: var(--white-10);
}
.layout-dark .blog-card .blog-card__title--top {
  color: var(--white-30);
}
.layout-dark .blog-card .blog-card__meta-doc-wrapper svg,
.layout-dark .blog-card .blog-card__meta-reaction svg {
  color: var(--white-60);
}
.layout-dark .blog-author-box {
  background-color: var(--white-10);
  -webkit-box-shadow: 0 10px 40px var(--shadow3);
  box-shadow: 0 10px 40px var(--shadow3);
}
.layout-dark .blog-details .blog-share-top .blog-share-fixed span {
  color: var(--white-87);
}
.layout-dark .card-overview__left .overview-content,
.layout-dark .card-overview__right .overview-content {
  background-color: transparent;
}
.layout-dark .card-overview__left .overview-content strong,
.layout-dark .card-overview__right .overview-content strong {
  color: var(--white-87);
}
.layout-dark .mailbox-top__search .form-control-lg {
  background-color: var(--white-10);
}
.layout-dark .mailbox-list__single .mail-content__top .mail-title .badge {
  background-color: var(--white-10);
}
.layout-dark .mailbox-list__single .mail-content__bottom .mail-attatchment {
  background-color: var(--white-10);
}
.layout-dark
  .mailbox-list__single:hover
  .mail-authorBox
  .author-info
  .author-name {
  color: var(--white-60);
}
.layout-dark .mailbox-list__single:hover .mail-content__top .mail-title a {
  color: var(--white-60);
  text-shadow: none;
}
.layout-dark .mailbox-list__single:hover .time-meta {
  color: var(--white-60);
}
.layout-dark .mailbox-list__single:after {
  display: none;
}
.layout-dark .mailbox-top .mail-selection__dropdown {
  background-color: #323541;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .mailbox-container .dm-mailCompose__header {
  background-color: #323541;
}
.layout-dark .dm-mailCompose {
  -webkit-box-shadow: 0 10px 40px var(--shadow4);
  box-shadow: 0 10px 40px var(--shadow4);
  background-color: #323541;
}
.layout-dark .dm-mailCompose__header {
  background-color: var(--white-10);
  -webkit-box-shadow: 0 10px 40px var(--shadow2);
  box-shadow: 0 10px 40px var(--shadow2);
}
.layout-dark
  .mailCompose-form-content
  .select2-selection
  .select2-selection__rendered
  .select2-selection__choice {
  background-color: var(--main-color);
}
.layout-dark .mail-reply__action .btn-outline-lighten {
  border-color: var(--border-color);
}
.layout-dark .mail-reply__action .btn-outline-lighten:hover {
  border-color: var(--border-medium);
}
.layout-dark .mail-read-content .mdc__author .author-info .mail-info {
  background-color: #323541;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .mailCompose-form-content .trumbowyg-box .trumbowyg-button-pane {
  background-color: transparent;
}
.layout-dark
  .mailCompose-form-content
  .select2-container--default
  .select2-selection--multiple,
.layout-dark
  .mailCompose-form-content
  .select2-container--default
  .select2-selection--single {
  background-color: transparent;
}
.layout-dark .mailCompose-form-content .form-control-lg {
  background-color: transparent;
}
.layout-dark
  .mailCompose-form-content
  .form-control-lg::-webkit-input-placeholder {
  background-color: transparent;
  color: var(--white-60);
}
.layout-dark .mailCompose-form-content .form-control-lg::-moz-placeholder {
  background-color: transparent;
  color: var(--white-60);
}
.layout-dark .mailCompose-form-content .form-control-lg::-ms-input-placeholder {
  background-color: transparent;
  color: var(--white-60);
}
.layout-dark .mailCompose-form-content .form-control-lg::placeholder {
  background-color: transparent;
  color: var(--white-60);
}
.layout-dark .mailCompose-form-content .trumbowyg-button-pane button {
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.layout-dark .mailCompose-form-content .trumbowyg-box svg,
.layout-dark .mailCompose-form-content .trumbowyg-modal svg {
  fill: var(--white-60);
  color: var(--white-60);
}
.layout-dark
  .mailCompose-form-content
  .trumbowyg-button-pane
  button.trumbowyg-active,
.layout-dark
  .mailCompose-form-content
  .trumbowyg-button-pane
  button:not(.trumbowyg-disable):focus,
.layout-dark
  .mailCompose-form-content
  .trumbowyg-button-pane
  button:not(.trumbowyg-disable):hover {
  background-color: var(--white-10);
}
.layout-dark .mailCompose-form-content .trumbowyg-dropdown {
  background-color: #323541;
  border-color: var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-border-bottom-left-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-border-bottom-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
.layout-dark .mailCompose-form-content .trumbowyg-dropdown button {
  background-color: transparent;
  color: var(--white-60) !important;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.layout-dark .mailCompose-form-content .trumbowyg-dropdown button:hover {
  color: var(--white-87) !important;
  fill: var(--white-87) !important;
}
.layout-dark .trumbowyg-box.trumbowyg-fullscreen {
  background-color: #323541;
}
.layout-dark .pricing__tag.color-dark {
  background-color: var(--white-10) !important;
}
.layout-dark .search-style-2 form .job-search,
.layout-dark .search-style-2 form .location-search {
  background-color: var(--white-10);
}
.layout-dark .search-style-2 form .job-search input,
.layout-dark .search-style-2 form .location-search input {
  background-color: transparent;
}
.layout-dark .search-style-2 form .job-search input::-webkit-input-placeholder,
.layout-dark
  .search-style-2
  form
  .location-search
  input::-webkit-input-placeholder {
  color: var(--white-60);
}
.layout-dark .search-style-2 form .job-search input::-moz-placeholder,
.layout-dark .search-style-2 form .location-search input::-moz-placeholder {
  color: var(--white-60);
}
.layout-dark .search-style-2 form .job-search input::-ms-input-placeholder,
.layout-dark
  .search-style-2
  form
  .location-search
  input::-ms-input-placeholder {
  color: var(--white-60);
}
.layout-dark .search-style-2 form .job-search input::placeholder,
.layout-dark .search-style-2 form .location-search input::placeholder {
  color: var(--white-60);
}
.layout-dark
  .project-category
  .select2-container--default
  .select2-selection--multiple,
.layout-dark
  .project-category
  .select2-container--default
  .select2-selection--single {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark
  .project-category
  .select2-container
  .select2-selection--single
  .select2-selection__rendered,
.layout-dark
  .project-category
  .select2-container--default
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--white-87);
}
.layout-dark .project-icon-selected .icon-list-social__link.active {
  background-color: var(--white-10);
  color: var(--white-87);
}
.layout-dark .products_page .widget {
  background-color: var(--white-10);
}
.layout-dark .products_page .widget .card {
  background-color: transparent;
}
.layout-dark .products_page .widget-header-title {
  border-color: var(--border-color);
}
.layout-dark .product .product-item__button button {
  border-color: var(--border-color);
}
.layout-dark .product-details .product-item__button .like-icon {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .product--grid .like-icon {
  background-color: var(--main-color);
  border-color: var(--main-color);
}
.layout-dark .like-icon {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .product-quantity .bttn {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .product-add {
  background-color: var(--white-10) !important;
  border-color: var(--border-color) !important;
}
.layout-dark .product-add .add-product .card-body {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 10px 30px rgba(1, 4, 19, 0.4);
  box-shadow: 0 10px 30px rgba(1, 4, 19, 0.4);
  border-color: var(--border-color);
}
.layout-dark
  .product-add
  .add-product
  .card-body
  .add-product__body
  .form-group
  .form-control {
  background-color: var(--white-10);
  border-color: var(--border-color);
  -webkit-transition: 0s;
  -o-transition: 0s;
  transition: 0s;
}
.layout-dark
  .product-add
  .add-product
  .card-body
  .add-product__body
  .form-group
  .form-control:focus {
  -webkit-box-shadow: 0 5px 20px var(--shadow2);
  box-shadow: 0 5px 20px var(--shadow2);
}
.layout-dark
  .product-add
  .add-product
  .card-body
  .add-product__body
  .form-group
  .input-group-text {
  background-color: var(--white-10);
  border-color: var(--border-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  margin-right: 0;
}
.layout-dark .add-product__body-img .upload-product-img {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .add-product-btn .btn.btn-light {
  border-color: var(--border-color);
  background-color: var(--white-10);
}
.layout-dark .add-product-btn .btn.btn-light:hover {
  background-color: var(--white-30);
}
.layout-dark .cartPage {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .cartPage .product-cart__header th {
  background-color: var(--white-10);
}
.layout-dark .cartPage .product-cart .table-hover tbody tr:hover {
  --bs-table-accent-bg: transparent;
}
.layout-dark .cartPage .quantity .bttn {
  border-color: var(--border-color);
  background-color: var(--white-10);
}
.layout-dark .cartPage .quantity .bttn:hover {
  background-color: var(--white-30);
  color: var(--white-87);
}
.layout-dark .cartPage .quantity .bttn:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .cartPage .product-cart .actions button i {
  color: var(--white-60);
}
.layout-dark .cartPage .product-cart__cupon input {
  border-color: var(--border-color);
  background-color: var(--white-10);
}
.layout-dark .cartPage .product-cart__cupon input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .cartPage .order-summery .card-body {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 10px 30px #1b1e2b;
  box-shadow: 0 10px 30px #1b1e2b;
}
.layout-dark
  .cartPage
  .select-cupon
  .select2-container--default
  .select2-selection--multiple,
.layout-dark
  .cartPage
  .select-cupon
  .select2-container--default
  .select2-selection--single {
  background-color: transparent;
  border-color: transparent;
}
.layout-dark .cartPage .promo-code input {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .cartPage .promo-code input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .cartPage .promo-code div a:hover {
  opacity: 0.8;
  color: var(--color-success);
}
.layout-dark .orderDatatable {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .orderDatatable .table-hover tbody tr:hover {
  background-color: var(--white-10);
}
.layout-dark .order-search__form {
  background-color: var(--white-10);
}
.layout-dark .order-search__form input::-webkit-input-placeholder {
  color: var(--white-60);
}
.layout-dark .order-search__form input::-moz-placeholder {
  color: var(--white-60);
}
.layout-dark .order-search__form input::-ms-input-placeholder {
  color: var(--white-60);
}
.layout-dark .order-search__form input::placeholder {
  color: var(--white-60);
}
.layout-dark .project-tap.order-project-tap {
  border-color: var(--border-color);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.layout-dark .project-tap .nav-item:not(:first-child):after {
  background-color: var(--border-color);
}
.layout-dark .userDatatable table thead tr:not(.footable-filtering) th {
  background-color: var(--white-10);
}
.layout-dark .exportDatatable.orderDatatable .table-hover tbody tr:hover {
  -webkit-box-shadow: 0 15px 50px var(--shadow4);
  box-shadow: 0 15px 50px var(--shadow4);
}
.layout-dark .payment-invoice-logo a img.light,
.layout-dark .payment-invoice-logo a svg.light {
  display: block;
}
.layout-dark .payment-invoice-logo a img.dark,
.layout-dark .payment-invoice-logo a svg.dark {
  display: none;
}
.layout-dark .payment-invoice {
  background-color: var(--white-10);
}
.layout-dark .payment-invoice-qr {
  background-color: var(--white-10);
}
.layout-dark .payment-invoice-table table thead tr th {
  background-color: var(--white-10);
}
.layout-dark .payment-invoice__btn .bg-normal {
  background-color: var(--white-10);
  border: 1px solid var(--border-color);
  color: var(--white-60) !important;
}
.layout-dark .payment-invoice__btn .bg-normal svg {
  color: var(--white-60);
}
.layout-dark .ap-po-details--ticket {
  background-color: var(--white-10);
}
.layout-dark .ap-po-details--ticket .overview-content {
  background-color: transparent;
}
.layout-dark .support-ticket-system {
  background-color: var(--white-10);
}
.layout-dark
  .support-form
  .support-order-search
  .support-order-search__form
  input {
  background-color: transparent;
}
.layout-dark
  .support-form
  .support-order-search
  .support-order-search__form
  input::-webkit-input-placeholder {
  color: var(--white-60);
}
.layout-dark
  .support-form
  .support-order-search
  .support-order-search__form
  input::-moz-placeholder {
  color: var(--white-60);
}
.layout-dark
  .support-form
  .support-order-search
  .support-order-search__form
  input::-ms-input-placeholder {
  color: var(--white-60);
}
.layout-dark
  .support-form
  .support-order-search
  .support-order-search__form
  input::placeholder {
  color: var(--white-60);
}
.layout-dark .ticket_modal .modal-content {
  background-color: #1b1e2b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark
  .ticket_modal
  .modal-content
  .ticket_modal-modal
  form
  .form-control {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .ticket-details-contact {
  background-color: var(--white-10);
}
.layout-dark .ticket-file-attach {
  background-color: var(--white-10);
}
.layout-dark .ticket-chat-wrapper {
  background-color: var(--white-10);
}
.layout-dark .ticket-chat-wrapper .ticket-search-header form {
  background-color: var(--white-10);
}
.layout-dark .ticket-chat-wrapper .ticket-search-header form input {
  background-color: transparent;
}
.layout-dark .new-ticket .ticket_modal {
  background-color: var(--white-10);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .job-apply-wrapper,
.layout-dark .job-details-card,
.layout-dark .job-details-widget {
  background-color: var(--white-10);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark
  .job-apply-wrapper
  .job-apply__content
  form
  .form-group
  .dm-upload__button {
  border-color: var(--border-color);
}
.layout-dark .course-card,
.layout-dark .cvg.cvg {
  background-color: var(--white-10);
}
.layout-dark .course-accordion .dm-collapse-item {
  background-color: var(--white-10);
  -webkit-box-shadow: 0 15px 40px rgba(var(--main-color-rgba), 0.15);
  box-shadow: 0 15px 40px rgba(var(--main-color-rgba), 0.15);
}
.layout-dark .table4 {
  background-color: transparent;
}
.layout-dark .table4 .userDatatable-title {
  color: var(--white-87);
}
.layout-dark .table4 .userDatatable-header th {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .table4 tbody tr:hover {
  background-color: var(--white-10);
}
.layout-dark .table5 .userDatatable-header {
  background-color: var(--white-10);
}
.layout-dark .table5.table4 .userDatatable-header th:hover {
  background-color: var(--white-30);
}
.layout-dark .terms-content {
  background-color: #1b1e2b;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .terms-breadcrumb {
  background-color: var(--white-30);
}
.layout-dark .v-history-list li .version-date {
  color: var(--white-60);
}
.layout-dark .dm-mail-sidebar .card {
  background-color: #1b1e2b;
}
.layout-dark .mailbar-toggle {
  background-color: var(--white-10);
}
.layout-dark .project-top-progress .project-tap {
  background-color: var(--white-10);
}
.layout-dark .new-member .modal-header .close {
  background-color: var(--white-10);
}
.layout-dark .user-member__form {
  background-color: var(--white-10);
}
.layout-dark .db-social-parent__item a {
  -webkit-box-shadow: 0 5px 15px rgba(1, 4, 19, 0.4);
  box-shadow: 0 5px 15px rgba(1, 4, 19, 0.4);
}
.layout-dark form.user-member__form input {
  background-color: transparent;
}
.layout-dark .user-group {
  background-color: var(--white-10);
}
.layout-dark .application-ui .btn-group-toggle .btn {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .application-ui .btn-group-toggle .btn:hover {
  opacity: 0.7;
}
.layout-dark #full-calendar .fc-toolbar-chunk .fc-today-button {
  background-color: var(--white-06);
}
.layout-dark #full-calendar .fc-toolbar-chunk .fc-button.fc-next-button,
.layout-dark #full-calendar .fc-toolbar-chunk .fc-button.fc-prev-button {
  background-color: var(--white-10);
}
.layout-dark
  #full-calendar
  .fc-toolbar-chunk
  .fc-button.fc-listMonth-button.fc-button-primary {
  background-color: transparent;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.layout-dark
  #full-calendar
  .fc-toolbar-chunk
  .fc-button.fc-listMonth-button.fc-button-primary:hover {
  color: var(--white-87);
}
.layout-dark
  #full-calendar
  .fc-toolbar-chunk
  .fc-button-group
  .fc-button-primary {
  background-color: var(--white-10);
}
.layout-dark .fc-timegrid-slots .fc-timegrid-slot {
  background-color: transparent;
}
.layout-dark .fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
}
.layout-dark .fc .fc-list-sticky .fc-list-day > * {
  background: 0 0;
}
.layout-dark .fc .fc-list-day-cushion,
.layout-dark .fc .fc-list-table td {
  background-color: transparent !important;
}
.layout-dark .fc-listMonth-view .fc-list-event:hover {
  background-color: var(--white-06) !important;
}
.layout-dark .e-info-modal .modal-body {
  background-color: #323541;
}
.layout-dark .selling-table-wrap--source .table--default tbody tr:hover {
  background-color: transparent;
}
.layout-dark .profile-card-3 .card-content {
  background-color: #1b1e2b;
}
.layout-dark .profile-card-3 .profile {
  border-color: #1b1e2b;
}
.layout-dark .account-profile #remove_pro_pic {
  border-color: var(--main-color);
}
.layout-dark label[for="githubUrl"] + .input-group .bg-dark,
.layout-dark label[for="mediumUrl"] + .input-group .bg-dark {
  background-color: #090e30 !important;
}
.layout-dark .contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form {
  background-color: var(--white-10);
}
.layout-dark
  .contact-breadcrumb
  .breadcrumb-main__wrapper
  .add-contact__form
  input {
  background-color: transparent;
}
.layout-dark .contact-card .cp-info__designation {
  color: var(--white-60);
}
.layout-dark .contact-card .c-info-list__item p {
  color: var(--white-60);
}
.layout-dark .contact-list-wrap {
  background-color: var(--white-10);
}
.layout-dark .add-contact .close svg {
  color: var(--white-87);
}
.layout-dark .add-contact .close:hover svg {
  color: var(--white-60);
}
.layout-dark .note-sidebar,
.layout-dark .notes-wrapper {
  background-color: var(--white-10);
}
.layout-dark .note-sidebar .card,
.layout-dark .notes-wrapper .card {
  background-color: transparent;
}
.layout-dark #noteModal .close {
  background-color: var(--white-10);
}
.layout-dark .kanban-board__card {
  background-color: var(--white-10);
}
.layout-dark .kanban-board__card .kanban-list {
  background-color: var(--white-10);
}
.layout-dark .kanban-board__card .kanban-items li {
  background-color: #3e404c;
}
.layout-dark .kanban-board__card .kanban-items li .form-control {
  background-color: #1f2231;
}
.layout-dark .kanban-modal__card .close {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark.is-open:after {
  background-color: rgba(var(--color-white-rgba), 0.3);
}
.layout-dark .media-import {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .fileM-sidebar {
  background-color: var(--white-10);
}
.layout-dark .fileM-sidebar .card {
  background-color: transparent;
}
.layout-dark .fileM-top-search form input {
  background-color: transparent;
}
.layout-dark .fileM-contents .fileM-grid-wrapper {
  background-color: var(--white-10);
}
.layout-dark .bookmark__image .like-icon {
  background-color: rgba(30, 33, 46, 0.8);
}
.layout-dark .ap-tab-header {
  background-color: var(--white-10);
}
.layout-dark .profile-content .ap-po-details .overview-content {
  background-color: transparent;
}
.layout-dark .notification-content .notification-content__body-wrapper {
  background-color: #1b1e2b;
}
.layout-dark .feature-cards,
.layout-dark .feature-cards3,
.layout-dark .feature-cards4,
.layout-dark .feature-cards5,
.layout-dark .feature-cards6 {
  background-color: var(--white-10);
}
.layout-dark .feature-cards3 figcaption h2 {
  color: var(--white-87);
}
.layout-dark .feature-cards figcaption .feedbackSection .feedback span {
  color: var(--white-60);
}
.layout-dark .feature-cards7--2 .bg-dark {
  background-color: var(--white-10) !important;
}
.layout-dark .wizard8 .checkout-progress-indicator .card-header {
  background-color: transparent;
}
.layout-dark .wizard8 .checkout-progress-indicator .card-header svg ellipse {
  fill: var(--white-06);
}
.layout-dark .wizard8 .checkout-shipping-form {
  background-color: transparent;
}
.layout-dark .wizard10 .card {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.layout-dark .payment-method-card .card-body .form-control input {
  background-color: transparent;
}
.layout-dark .quantity .bttn {
  background-color: var(--white-10);
}
.layout-dark .quantity .bttn:hover {
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.layout-dark .banner-feature--5 .banner-feature__btn {
  background-color: #fff !important;
  color: #000 !important;
}
.layout-dark .banner-feature--5 .banner-feature__btn:hover {
  opacity: 0.7;
}
.layout-dark .banner-feature--2.card,
.layout-dark .banner-feature--8.card {
  background-color: var(--color-primary);
}
.layout-dark .banner-feature--2.card .banner-feature__btn,
.layout-dark .banner-feature--8.card .banner-feature__btn {
  background-color: #fff !important;
}
.layout-dark .banner-feature--13.card,
.layout-dark .banner-feature--3.card {
  background-color: var(--color-info);
}
.layout-dark .banner-feature--13.card .banner-feature__btn,
.layout-dark .banner-feature--3.card .banner-feature__btn {
  background-color: #fff !important;
}
.layout-dark .banner-feature--10.card {
  background-color: var(--color-warning);
}
.layout-dark .banner-feature--10.card .banner-feature__btn {
  background-color: #fff !important;
}
.layout-dark .banner-feature--11.card {
  background-color: var(--color-danger);
}
.layout-dark .banner-feature--11.card .banner-feature__btn {
  background-color: #fff !important;
}
.layout-dark .banner-feature--15:hover {
  -webkit-box-shadow: 0 15px 25px var(--shadow4);
  box-shadow: 0 15px 25px var(--shadow4);
}
.layout-dark .footable.adv-table .pagination > li > a {
  background-color: var(--white-10);
  border-color: var(--border-color);
}
.layout-dark .footable.adv-table .pagination > li > a:hover {
  color: var(--white-87);
}
.layout-dark .footable.adv-table .pagination > li.active > a {
  background-color: var(--color-primary);
}
.layout-dark .testimonial-slider-global.testimonial-slider1 .card-body {
  -webkit-box-shadow: 0 20px 30px var(--shadow2);
  box-shadow: 0 20px 30px var(--shadow2);
}
.layout-dark
  .testimonial-slider-global.testimonial-slider1
  .slick-slide.slick-center
  .card-body {
  -webkit-box-shadow: 0 20px 30px var(--shadow4);
  box-shadow: 0 20px 30px var(--shadow4);
}
.layout-dark .testimonial-slider-global.testimonial-slider2 .card {
  -webkit-box-shadow: 0 10px 30px var(--shadow3);
  box-shadow: 0 10px 30px var(--shadow3);
}
.layout-dark .testimonial-slider-global.testimonial-slider3-for .card {
  background-color: transparent;
}
.layout-dark .testimonial-slider-global .slider-arrow {
  background-color: var(--white-10);
  -webkit-box-shadow: 0 3px 10px var(--shadow2);
  box-shadow: 0 3px 10px var(--shadow2);
}
.layout-dark .search-keyword ul li a {
  background-color: var(--white-10);
}
.layout-dark .search-keyword ul li a:hover {
  color: var(--white-87);
}
.layout-dark .search-keyword ul li a.active {
  background-color: var(--color-primary);
}
.layout-dark .search-result form .form-control {
  background-color: transparent;
}
.layout-dark .search-content .card {
  background-color: transparent;
}
.layout-dark .knowledgebase,
.layout-dark .knowledgebase-details {
  -webkit-box-shadow: 0 5px 10px var(--shadow3);
  box-shadow: 0 5px 10px var(--shadow3);
}
.layout-dark .knowledgebase-details {
  background-color: var(--white-10);
}
.layout-dark .knowledgebase__nav li .nav-link.active {
  background-color: transparent;
}
.layout-dark .knowledgebase-adv__collapse .changelog__accordingArrow {
  -webkit-box-shadow: 0 2px 2px var(--shadow4);
  box-shadow: 0 2px 2px var(--shadow4);
}
.layout-dark .kb__header-search {
  -webkit-box-shadow: 0 5px 20px var(--shadow2);
  box-shadow: 0 5px 20px var(--shadow2);
}
.layout-dark .coming-social .bg-dark {
  background-color: var(--white-10) !important;
}
.layout-dark .coming-soon .light {
  display: inline-block;
}
.layout-dark .coming-soon .dark {
  display: none;
}
.layout-dark .admin {
  background-image: url(img/admin-bg-dark.png);
}
.layout-dark .admin-element {
  background-color: var(--white-10);
}
.layout-dark .admin-element-left {
  background-color: #525768;
}
.layout-dark .admin-socialBtn li svg {
  color: var(--white-87);
}
.layout-dark .admin-topbar {
  background-color: var(--white-06);
}
.layout-dark .admin-topbar p {
  color: var(--white-87);
}
.layout-dark .admin-overlay svg path {
  fill: var(--white-87);
}
.layout-dark .admin .edit-profile__logos .light {
  display: block;
}
.layout-dark .admin .edit-profile__logos .dark {
  display: none;
}
.layout-dark .admin .edit-profile .card {
  background-color: #1b1e2b;
}
.layout-dark .admin-socialBtn li button.github {
  background-color: var(--white-10);
}
.layout-dark .dark-trigger ul li a {
  background-color: var(--white-06);
}
.layout-dark .alert.alert-dismissible .btn-close span,
.layout-dark .alert.alert-dismissible .btn-close svg {
  color: var(--white-87);
}
.layout-dark .avatar .avatar-badge-wrap,
.layout-dark .avatar .badge-dot-wrap {
  background-color: var(--main-color);
}
.layout-dark .slick-slider__single p {
  color: var(--main-color);
}
.layout-dark .slick-slider .slick-dots button {
  background-color: rgba(var(--color-main-rgba), 0.7);
}
.layout-dark .dm-drawer {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 10px 15px var(--shadow4);
  box-shadow: 0 10px 15px var(--shadow4);
}
.layout-dark .dropdown-default,
.layout-dark .dropdown-menu {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 5px 30px var(--shadow3);
  box-shadow: 0 5px 30px var(--shadow3);
}
.layout-dark .dm-submenu {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 5px 30px var(--shadow3);
  box-shadow: 0 5px 30px var(--shadow3);
}
.layout-dark .dm-pop-message {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 5px 30px var(--shadow3);
  box-shadow: 0 5px 30px var(--shadow3);
}
.layout-dark .dm-notification-box {
  background-color: #1b1e2b;
  -webkit-box-shadow: 0 5px 30px var(--shadow3);
  box-shadow: 0 5px 30px var(--shadow3);
}
.layout-dark .notification-placement-wrap .dm-select select option {
  color: var(--main-color);
}
.layout-dark .dm-notification-box__close i,
.layout-dark .dm-notification-box__close svg {
  color: var(--white-87);
}
.layout-dark .mobile-nav-wrapper {
  background-color: #1b1e2b;
}
.layout-dark .page-title__left a i,
.layout-dark .page-title__left a span,
.layout-dark .page-title__left a svg {
  color: var(--white-87);
}
.layout-dark .dm-steps__content {
  background-color: #1b1e2b;
}
.layout-dark .wickedpicker {
  background-color: #1b1e2b;
}
.layout-dark .wickedpicker__title {
  background-color: var(--white-10);
  color: var(--white-60);
}
.layout-dark .wickedpicker__title .wickedpicker__close {
  color: var(--white-60);
}
.layout-dark .wickedpicker__controls__control-down,
.layout-dark .wickedpicker__controls__control-up {
  color: var(--white-60);
}
.layout-dark .wickedpicker__controls__control,
.layout-dark .wickedpicker__controls__control--separator {
  color: var(--white-87);
}
.layout-dark .course-card__footer .total-hours,
.layout-dark .course-card__footer .total-lextures {
  background-color: var(--white-06);
  color: var(--white-87);
}
.layout-dark .course-card__footer .total-hours:hover,
.layout-dark .course-card__footer .total-lextures:hover {
  opacity: 0.7;
}
.layout-dark .footer-wrapper {
  background-color: var(--white-10);
  -webkit-box-shadow: none;
  box-shadow: none;
}
.footer-wrapper {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 24px 0 24px 280px;
  width: 100%;
  background: var(--color-white);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  z-index: 1;
  -webkit-box-shadow: rgba(146, 153, 184, 0.05) 0 -5px 10px;
  box-shadow: rgba(146, 153, 184, 0.05) 0 -5px 10px;
}
.footer-wrapper p {
  margin: 0;
}
.footer-copyright {
  padding-left: 12px;
}
.footer-copyright p {
  font-size: 14px;
  color: var(--color-gray);
  font-weight: 500;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.footer-copyright p a {
  margin-left: 4px;
  color: var(--color-primary);
}
.footer-copyright p a:hover {
  color: var(--color-secondary);
}
.footer-menu {
  padding-right: 12px;
}
.footer-menu ul li {
  display: inline-block;
}
.footer-menu ul li:not(:last-child) {
  margin-right: 18px;
}
.footer-menu ul li a {
  font-size: 14px;
  color: var(--color-light);
}
.footer-menu ul li a:hover {
  color: var(--color-primary);
}
.top-menu .footer-wrapper {
  padding-left: 7px;
}
.social-overview-wrap .card .card-body {
  padding: 1.56rem 1rem;
}
.dm-button-list {
  margin: -5px -6px;
}
.dm-button-list button {
  margin: 5px 6px;
}
.dm-button-list .dropdown .btn {
  height: auto;
  line-height: 2.65rem;
}
.dm-button-list .dropdown .btn i,
.dm-button-list .dropdown .btn img,
.dm-button-list .dropdown .btn span,
.dm-button-list .dropdown .btn svg {
  margin: 0 0 0 6px;
}
.dm-button-list .btn:focus {
  -webkit-box-shadow: 0 0;
  box-shadow: 0 0;
}
.dm-button-block h1,
.dm-button-block h2,
.dm-button-block h3,
.dm-button-block h4,
.dm-button-block h5,
.dm-button-block h6 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 12px;
}
.dm-button-block .button-inline-list .btn-group:not(:last-child) {
  margin-right: 18px;
}
.dm-avatar-list {
  margin: 0 -10px;
}
.dm-avatar-list:not(:last-child) {
  margin-bottom: 20px;
}
.dm-badge-list {
  margin: -5px -6px;
}
.dm-badge-list .badge {
  margin: 5px 6px;
}
.dm-badge-list .badge-text {
  background: 0 0;
}
.badge-info-10 {
  background-color: rgba(var(--color-info-rgba), 0.1);
  color: var(--color-info);
}
.columnCard-wrapper {
  padding: 50px 30px 30px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: var(--bg-normal);
}
.columnCard-wrapper .card .card-header {
  border-bottom: 1px solid var(--border-color);
}
.columnGrid-wrapper .card .card-body {
  padding: 0;
}
.columnGrid-wrapper .card .card-body table {
  margin: 0;
}
.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mailbox-wrapper .col-lg-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}
.mailbox-wrapper .col-lg-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 80%;
  flex: 0 0 80%;
  max-width: 80%;
}
.dm-mail-sidebar {
  position: relative;
  -webkit-transform: translateX(-280px);
  -ms-transform: translateX(-280px);
  transform: translateX(-280px);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}
.dm-mail-sidebar.show {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}
.dm-mail-sidebar .mailbar-cross {
  position: absolute;
  right: 20px;
  top: 25px;
}
.dm-mail-sidebar .mailbar-cross i,
.dm-mail-sidebar .mailbar-cross svg {
  color: var(--color-danger);
}
.dm-mail-sidebar > .card {
  min-height: 636px;
}
.dm-mail-sidebar .card-body {
  padding: 30px 14px;
}
.dm-mail-sidebar .btn-compose {
  width: 100%;
  -webkit-border-radius: 22px;
  border-radius: 22px;
}
.dm-mail-sidebar .btn-compose.btn-shadow-primary {
  -webkit-box-shadow: 0 10px 15px rgba(95, 99, 242, 0.1);
  box-shadow: 0 10px 15px rgba(95, 99, 242, 0.1);
}
.dm-mail-sidebar .btn-compose img,
.dm-mail-sidebar .btn-compose svg {
  width: 18px;
  height: 18px;
}
.dm-mail-sidebar .mail-list-title {
  font-size: 12px;
  padding: 0 15px;
  margin-bottom: 12px;
  display: inline-block;
  color: var(--color-light);
}
.dm-mail-sidebar .mail-list {
  margin-top: 20px;
}
.dm-mail-sidebar .mail-list li a {
  -webkit-border-radius: 4px;
  border-radius: 4px;
  display: block;
  padding: 6.9px 15px;
  font-size: 14px;
  color: var(--color-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.dm-mail-sidebar .mail-list li a.active,
.dm-mail-sidebar .mail-list li a:hover {
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.dm-mail-sidebar .mail-list li a.active .badge,
.dm-mail-sidebar .mail-list li a.active i,
.dm-mail-sidebar .mail-list li a.active img,
.dm-mail-sidebar .mail-list li a.active span,
.dm-mail-sidebar .mail-list li a.active svg,
.dm-mail-sidebar .mail-list li a:hover .badge,
.dm-mail-sidebar .mail-list li a:hover i,
.dm-mail-sidebar .mail-list li a:hover img,
.dm-mail-sidebar .mail-list li a:hover span,
.dm-mail-sidebar .mail-list li a:hover svg {
  color: var(--color-primary);
}
.dm-mail-sidebar .mail-list li a i,
.dm-mail-sidebar .mail-list li a span {
  font-size: 12px;
  color: var(--color-lighten);
  margin-right: 20px;
}
.dm-mail-sidebar .mail-list li a img,
.dm-mail-sidebar .mail-list li a svg {
  margin-right: 20px;
  width: 16px;
  color: var(--color-lighten);
}
.dm-mail-sidebar .mail-list li a .badge {
  float: right;
  margin-left: auto;
  margin-right: 0;
  width: auto;
  margin-top: 2px;
  margin-bottom: 1px;
}
.dm-mail-sidebar .mail-list li a .badge.badge-primary {
  color: var(--color-primary);
  background-color: rgba(var(--color-primary-rgba), 0.1);
}
.dm-mail-sidebar .btn-add-label {
  font-size: 14px;
  padding: 7.5px 15px;
  color: var(--color-light);
}
.dm-mail-sidebar .btn-add-label img,
.dm-mail-sidebar .btn-add-label svg {
  width: 16px;
  margin-right: 20px;
}
.add-lebel-from {
  position: relative;
  padding: 25px 30px;
  width: calc(100% + 100px);
  left: -50px;
  -webkit-box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.2);
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: none;
  background-color: var(--color-white);
}
.add-lebel-from.show {
  display: block;
}
.add-lebel-from h1,
.add-lebel-from h2,
.add-lebel-from h3,
.add-lebel-from h4,
.add-lebel-from h5,
.add-lebel-from h6 {
  font-weight: 500;
  margin-bottom: 14px;
}
.add-lebel-from input {
  font-size: 14px;
  border-color: var(--border-color);
}
.add-lebel-from .label-action {
  margin-top: 16px;
}
.mailbox-top {
  padding: 16px 30px;
  border-bottom: 1px solid var(--border-color);
}
.mailbox-top__actions li:not(:last-child) {
  margin-right: 8px;
}
.mailbox-top__actions .custom-checkbox input[type="checkbox"] + label {
  padding-left: 20px;
  cursor: pointer;
}
.mailbox-top__actions .checkbox-theme-default img,
.mailbox-top__actions .checkbox-theme-default svg {
  width: 16px;
  height: 16px;
}
.mailbox-top .mail-selection {
  cursor: pointer;
  padding: 7.5px 0;
}
.mailbox-top .mail-selection label img,
.mailbox-top .mail-selection label svg {
  width: 12px;
  height: 12px;
  color: var(--color-gray);
}
.mailbox-top .mail-selection:hover .mail-selection__dropdown {
  top: 55px;
  opacity: 1;
  visibility: visible;
  z-index: 12;
}
.mailbox-top .mail-selection__dropdown {
  position: absolute;
  left: 30px;
  top: 70px;
  min-width: 200px;
  padding: 18px 0;
  -webkit-box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 5px 30px rgba(var(--light-gray-rgba), 0.2);
  -webkit-border-radius: 0 0 5px 5px;
  border-radius: 0 0 5px 5px;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  background-color: var(--color-white);
}
.mailbox-top .mail-selection__dropdown li {
  margin-right: 0;
}
.mailbox-top .mail-selection__dropdown li a {
  display: block;
  padding: 5px 25px;
  font-size: 14px;
  color: var(--color-gray);
}
.mailbox-top .mail-selection__dropdown li a:hover {
  background-color: rgba(var(--color-primary-rgba), 0.05);
}
.mailbox-top .icon-action {
  display: block;
  height: 38px;
  width: 38px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(var(--color-primary-rgba), 0);
}
.mailbox-top .icon-action.active,
.mailbox-top .icon-action:hover {
  background-color: rgba(var(--color-primary-rgba), 0.05);
}
.mailbox-top .icon-action.active i,
.mailbox-top .icon-action.active svg,
.mailbox-top .icon-action:hover i,
.mailbox-top .icon-action:hover svg {
  color: var(--color-primary);
}
.mailbox-top .icon-action img,
.mailbox-top .icon-action svg {
  width: 16px;
}
.mailbox-top .icon-action i,
.mailbox-top .icon-action svg {
  color: var(--color-light);
}
.mailbox-top__search {
  margin-right: 50px;
}
.mailbox-top__search .input-container {
  width: 350px;
}
.mailbox-top__search .input-container input {
  padding-left: 50px !important;
}
.mailbox-top__search .input-container input::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.mailbox-top__search .input-container input::-moz-placeholder {
  color: var(--color-lighten);
}
.mailbox-top__search .input-container input::-ms-input-placeholder {
  color: var(--color-lighten);
}
.mailbox-top__search .input-container input::placeholder {
  color: var(--color-lighten);
}
.mailbox-top__search .input-container .input-icon.icon-left img,
.mailbox-top__search .input-container .input-icon.icon-left svg {
  width: 16px;
  color: var(--color-light);
}
.mailbox-top__search .form-control-lg {
  height: 2.875rem;
  -webkit-border-radius: 23px;
  border-radius: 23px;
  border: 0;
  background-color: var(--bg-lighter);
}
.mailbox-top__extra .result-perpage {
  font-size: 14px;
  color: var(--color-light);
}
.mailbox-top__extra .icon-action {
  width: 30px;
  height: 30px;
}
.mailbox-top__extra .mail-sliding-control {
  margin: 0 15px 0 8px;
}
.mailbox-top__extra .mail-sliding-control .icon-action i {
  font-size: 12px;
}
.mailbox-top__extra .dropdown {
  margin-left: 20px;
}
.mailbox-top__extra .dropdown .dropdown-menu {
  z-index: 11;
}
.mailbox-container .card .card-body {
  padding: 0;
}
.mailbox-list__single {
  padding: 18px 30px;
  padding-top: 17px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid var(--border-color);
  position: relative;
  z-index: 10;
}
.mailbox-list__single:not(.attach-file) {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.mailbox-list__single:hover:after {
  opacity: 1;
  visibility: visible;
}
.mailbox-list__single:hover .mail-authorBox .author-info .author-name {
  color: rgba(var(--color-dark-rgba), 0.7);
  text-shadow: 0.1px 0 0 currentColor;
}
.mailbox-list__single:hover .mail-content__top .mail-title a {
  color: rgba(var(--color-dark-rgba), 0.7);
  text-shadow: 0.1px 0 0 currentColor;
}
.mailbox-list__single:hover .time-meta {
  color: rgba(var(--color-dark-rgba), 0.7);
  text-shadow: 0.05px 0 0 currentColor;
}
.mailbox-list__single:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  -webkit-box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.2);
  box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.2);
  z-index: -1;
}
.mailbox-list__single .star-toggle {
  margin: 0 20px 0 10px;
}
.mailbox-list__single .star-toggle.stared i.lar {
  font-weight: 900;
  color: var(--color-warning);
}
.mailbox-list__single .star-toggle img,
.mailbox-list__single .star-toggle svg {
  width: 16px;
}
.mailbox-list__single .star-toggle i,
.mailbox-list__single .star-toggle svg {
  color: var(--color-lighten);
}
.mailbox-list__single .mail-authorBox {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}
.mailbox-list__single .mail-authorBox .author-info {
  white-space: nowrap;
}
.mailbox-list__single .mail-authorBox .author-info .author-name {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;
  color: var(--color-gray);
}
.mailbox-list__single .mail-content {
  margin-right: 10px;
}
.mailbox-list__single .mail-content__top .mail-title {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
}
.mailbox-list__single .mail-content__top .mail-title a {
  color: var(--color-gray);
  font-weight: 500;
}
.mailbox-list__single .mail-content__top .mail-title .badge {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 0 7px;
  margin-left: 5px;
  background-color: #eff0f3;
  color: var(--color-light);
  height: 20px;
}
.mailbox-list__single .mail-content__top p {
  font-size: 14px;
  margin-bottom: 0;
  color: var(--color-gray);
}
.mailbox-list__single .mail-content__bottom {
  margin: 12px -3px -5px;
}
.mailbox-list__single .mail-content__bottom .mail-attatchment {
  display: inline-block;
  margin: 5px 3px;
  font-size: 13px;
  line-height: 2.35;
  -webkit-border-radius: 15px;
  border-radius: 15px;
  padding: 0 20px;
  color: var(--color-light);
  background-color: var(--bg-lighter);
}
.mailbox-list__single .mail-content__bottom .mail-attatchment i,
.mailbox-list__single .mail-content__bottom .mail-attatchment img,
.mailbox-list__single .mail-content__bottom .mail-attatchment svg {
  width: 16px;
  font-size: 16px;
}
.mailbox-list__single
  .mail-content__bottom
  .mail-attatchment
  + .mail-attatchment {
  margin-left: 6px;
}
.mailbox-list__single .checkbox-theme-default {
  margin-top: 5px;
  line-height: 1;
}
.mailbox-list .time-meta {
  white-space: nowrap;
  font-size: 13px;
  font-weight: 400;
  color: var(--color-gray);
}
.card .card-body .dropdown .ellipsis-more img,
.card .card-body .dropdown .ellipsis-more svg {
  width: 22px;
  height: 22px;
  color: var(--color-light);
}
.dm-mailCompose {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 50px rgba(var(--light-gray-rgba), 0.3);
  box-shadow: 0 10px 50px rgba(var(--light-gray-rgba), 0.3);
  background-color: var(--color-white);
}
.dm-mailCompose--position {
  position: fixed;
  right: 30px;
  bottom: 60px;
  width: 600px;
  display: none;
  z-index: 12;
}
.dm-mailCompose.show {
  display: block;
}
.dm-mailCompose__header {
  padding: 20px 30px;
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  -webkit-box-shadow: 0 10px 40px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 10px 40px rgba(var(--light-gray-rgba), 0.15);
  background-color: var(--color-dark);
}
.dm-mailCompose__header .mailCompose-title {
  font-size: 15px;
  font-weight: 500;
  color: var(--color-white);
}
.dm-mailCompose__action i,
.dm-mailCompose__action img,
.dm-mailCompose__action svg {
  width: 16px;
  height: 16px;
  opacity: 0.7;
  color: var(--color-white);
}
.dm-mailCompose__action a + a {
  margin-left: 6px;
}
.dm-mailCompose__body {
  padding: 0 20px 30px;
}
.dm-mailCompose__footer {
  border-top: 1px solid var(--border-color);
  margin: 0 30px;
  padding: 20px 0 30px;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.dm-mailCompose__footer .footer-left button:not(:last-child) {
  margin-right: 20px;
}
.dm-mailCompose__footer .footer-left a {
  color: var(--color-light);
}
.dm-mailCompose__footer .footer-left a img,
.dm-mailCompose__footer .footer-left a svg {
  width: 16px;
}
.dm-mailCompose__footer .footer-left a:not(:last-child) {
  margin-right: 20px;
}
.dm-mailCompose__footer .footer-right .btn-remove {
  color: var(--color-light);
}
.dm-mailCompose__footer .footer-right .btn-remove img,
.dm-mailCompose__footer .footer-right .btn-remove svg {
  width: 16px;
}
.mailCompose-form-content .form-control-lg {
  padding: 1rem 1rem 1.2em 0;
  border: 0;
  height: 3.44rem;
  width: 100%;
  border-bottom: 1px solid var(--border-color);
}
.mailCompose-form-content .form-control-lg:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mailCompose-form-content input:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.mailCompose-form-content .form-group {
  position: relative;
  margin-bottom: 0;
}
.mailCompose-form-content .form-group .input-label,
.mailCompose-form-content .form-group .mail-cc {
  font-size: 14px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1;
  color: var(--color-light);
}
.mailCompose-form-content .form-group .input-label {
  left: 0;
}
.mailCompose-form-content .form-group .mail-cc {
  right: 0;
}
.mailCompose-form-content .form-group input::-webkit-input-placeholder {
  color: var(--color-light);
}
.mailCompose-form-content .form-group input::-moz-placeholder {
  color: var(--color-light);
}
.mailCompose-form-content .form-group input::-ms-input-placeholder {
  color: var(--color-light);
}
.mailCompose-form-content .form-group input::placeholder {
  color: var(--color-light);
}
.mailCompose-form-content
  .select2-container--default
  .select2-selection--multiple {
  min-height: 55px;
  height: auto;
}
.mailCompose-form-content .select2-selection {
  border: 0 !important;
  border-bottom: 1px solid var(--border-color) !important;
}
.mailCompose-form-content .select2-selection .select2-selection__rendered {
  padding: 0 20px 0 25px !important;
}
.mailCompose-form-content
  .select2-selection
  .select2-selection__rendered
  .select2-selection__choice {
  font-size: 14px;
  font-weight: 500;
  margin: 5px;
}
.mailCompose-form-content
  .select2-selection
  .select2-selection__rendered
  .select2-selection__choice
  .select2-selection__choice__remove {
  margin-right: 6px;
}
.mailCompose-form-content
  .select2-selection
  .select2-selection__rendered
  .select2-search.select2-search--inline {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 2;
  margin-top: 4px;
}
.mailCompose-form-content .select2-selection__choice {
  height: 30px !important;
  padding: 0 14px !important;
  -webkit-border-radius: 16px !important;
  border-radius: 16px !important;
}
.mailCompose-form-content .trumbowyg-box {
  min-height: 260px;
  max-height: 320px;
  margin: 0;
  border: 0;
}
.mailCompose-form-content .trumbowyg-box p {
  font-size: 15px;
  color: var(--color-gray);
}
.mailCompose-form-content .trumbowyg-box .trumbowyg-button-pane {
  padding: 12px 0;
  border-bottom: 1px solid var(--border-color);
  background-color: var(--color-white);
}
.mailCompose-form-content .trumbowyg-box .trumbowyg-button-pane:after {
  display: none;
}
.mailCompose-form-content .trumbowyg-box .trumbowyg-editor {
  padding: 20px 0;
}
.mailCompose-form-content
  .trumbowyg-box
  .trumbowyg-editor[contenteditable="true"]:empty:not(:focus)::before {
  font-size: 14px;
  color: var(--color-light);
}
.mailCompose-form-content .trumbowyg-box .trumbowyg-button-group:after {
  display: none;
}
.mail-read-content {
  padding: 30px;
}
.mail-details__top {
  margin-bottom: 20px;
}
.mail-details__title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
  align-content: center;
  font-weight: 500;
}
.mail-details__title .badge {
  margin: 5px 0 0 20px;
  padding: 0 7.58px !important;
  -webkit-border-radius: 3px !important;
  border-radius: 3px !important;
}
.mail-details__content {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mail-details .icon-action {
  width: 38px;
  height: 38px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: rgba(var(--color-primary-rgba), 0);
}
.mail-details .icon-action.active,
.mail-details .icon-action:hover {
  background-color: rgba(var(--color-primary-rgba), 0.05);
}
.mail-details .icon-action.active i,
.mail-details .icon-action.active svg,
.mail-details .icon-action:hover i,
.mail-details .icon-action:hover svg {
  color: var(--color-primary);
}
.mail-details .icon-action img,
.mail-details .icon-action svg {
  width: 16px;
}
.mail-details .icon-action i,
.mail-details .icon-action svg {
  color: var(--color-light);
}
.mail-details .icon-action .double-icon img,
.mail-details .icon-action .double-icon svg {
  width: 12px;
  height: 12px;
}
.mail-details .mail-attatched {
  margin: -10px -5px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.mail-details .mail-attatched__item {
  margin: 10px 5px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  padding: 10px;
  border: 1px solid var(--border-color);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  -webkit-box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 10px 20px rgba(var(--light-gray-rgba), 0.1);
}
.mail-details .mail-attatched__item:hover .mail-attatched__image::after {
  height: 100%;
  opacity: 1;
  visibility: visible;
}
.mail-details .mail-attatched__item:hover .btn-mail {
  opacity: 1;
  visibility: visible;
}
.mail-details .mail-attatched__image {
  position: relative;
}
.mail-details .mail-attatched__image:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0%;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  content: "";
  opacity: 0;
  visibility: hidden;
  -webkit-transition: 0.3s ease-in;
  -o-transition: 0.3s ease-in;
  transition: 0.3s ease-in;
  background-color: rgba(var(--color-dark-rgba), 0.5);
}
.mail-details .mail-attatched__image img {
  width: 100%;
}
.mail-details .mail-attatched__hover {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  z-index: 1;
}
.mail-details .mail-attatched__hover a + a {
  margin-left: 10px;
}
.mail-details .mail-attatched__hover .btn-mail {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  background-color: rgba(var(--color-white-rgba), 0.2);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.mail-details .mail-attatched__hover .btn-mail img,
.mail-details .mail-attatched__hover .btn-mail svg {
  width: 16px;
  color: var(--color-white);
}
.mail-details .mail-attatched figcaption .file-name {
  font-size: 13px;
  font-weight: 500;
}
.mail-details .mail-attatched figcaption .file-size {
  font-size: 12px;
  color: var(--color-light);
}
.mdc__left img {
  max-width: 50px;
  margin-right: 20px;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
.mdc__head {
  margin-bottom: 30px;
}
.mdc__author .author-info {
  position: relative;
}
.mdc__author .author-info h6 {
  font-weight: 500;
}
.mdc__author .author-info .mail-info-btn {
  font-size: 13px;
  padding: 8px 0;
  cursor: pointer;
  color: var(--color-light);
}
.mdc__author .author-info .mail-info-btn:hover .mail-info {
  opacity: 1;
  visibility: visible;
}
.mdc__author .author-info .mail-info-btn img,
.mdc__author .author-info .mail-info-btn svg {
  width: 16px;
}
.mdc__author .author-info .mail-info {
  position: absolute;
  padding: 25px;
  left: 0;
  top: 50px;
  width: 300px;
  -webkit-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
  opacity: 0;
  visibility: hidden;
  -webkit-box-shadow: 0 5px 40px rgba(var(--light-gray-rgba), 0.25);
  box-shadow: 0 5px 40px rgba(var(--light-gray-rgba), 0.25);
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
  z-index: 12;
  background-color: var(--color-white);
}
.mdc__author .author-info .mail-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.mdc__author .author-info .mail-info li:not(:last-child) {
  margin-bottom: 10px;
}
.mdc__author .author-info .mail-info li span {
  font-size: 14px;
}
.mdc__author .author-info .mail-info li .mail-info__label {
  min-width: 60px;
  color: var(--color-light);
}
.mdc__author .author-info .mail-info li .mail-info__text {
  display: inline-block;
  margin-left: 32px;
  text-align: left;
  color: var(--color-gray);
}
.mdc__excerpt {
  font-size: 13px;
}
.mdc__excerpt .dropdownMenu img,
.mdc__excerpt .dropdownMenu svg {
  width: 22px;
  height: 22px;
}
.mdc__excerpt a {
  color: var(--color-light);
}
.mdc__excerpt a:not(:last-child) {
  margin-right: 26px;
}
.mdc__excerpt .feather-paperclip {
  width: 14px;
}
.mdc__excerpt img,
.mdc__excerpt svg {
  width: 16px;
}
.mdc__excerpt i,
.mdc__excerpt svg {
  color: var(--color-light);
}
.mdc__excerpt .date-meta {
  color: var(--color-light);
  display: inline-block;
  margin: 0 26px 0 20px;
  font-size: 13px;
}
.mdc__body h1,
.mdc__body h2,
.mdc__body h3,
.mdc__body h4,
.mdc__body h5,
.mdc__body h6,
.mdc__body p,
.mdc__body span {
  font-size: 15px;
  font-weight: 400;
  color: var(--color-gray);
}
.mdc__body h6 {
  margin-bottom: 35px;
}
.mdc__body p {
  line-height: 1.667;
}
.mdc__body p:last-child {
  margin-bottom: 0;
}
.mdc__body .mail-signature {
  margin: 42px 0 48px;
}
.mdc__body .mail-signature .author-name {
  margin-top: 10px;
}
.mail-reply__single {
  margin: 0 -30px;
  padding: 30px;
  -webkit-box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.1);
  box-shadow: 0 15px 40px rgba(var(--light-gray-rgba), 0.1);
}
.mail-reply__action {
  padding: 0 70px;
  margin: 50px 0 30px;
}
.mail-reply__action a:hover {
  color: var(--color-primary);
  background: 0 0;
}
.mail-reply__action a + a {
  margin-left: 10px;
}
.mail-reply__action .btn img,
.mail-reply__action .btn svg {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}
.mail-reply .mail-reply-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.mail-reply .mail-reply-box figure {
  margin-right: 20px;
}
.mail-reply .mail-details__content {
  margin: 0;
  padding: 0;
  border: 0;
}
.mail-reply .mdc__head {
  margin-bottom: 6px;
}
.reply-form {
  padding: 10px 30px 30px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  -webkit-box-shadow: 0 10px 40px rgba(var(--light-gray-rgba), 0.15);
  box-shadow: 0 10px 40px rgba(var(--light-gray-rgba), 0.15);
}
.reply-form__left .btn-send {
  margin-right: 20px;
}
.reply-form__left a {
  display: inline-block;
}
.reply-form__left a img,
.reply-form__left a svg {
  width: 16px;
  color: var(--color-light);
}
.reply-form__left a + a {
  margin-left: 20px;
}
.reply-form__header {
  padding: 20px;
  color: var(--color-white);
  -webkit-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--color-dark);
}
.reply-form__header p {
  margin-bottom: 0;
}
.reply-form__header .icon-right img,
.reply-form__header .icon-right svg {
  cursor: pointer;
  opacity: 0.7;
}
.reply-form__header .icon-right img:hover,
.reply-form__header .icon-right svg:hover {
  opacity: 1;
}
.reply-form__header .icon-right img:not(:last-child),
.reply-form__header .icon-right svg:not(:last-child) {
  margin-right: 20px;
}
.reply-form .mailCompose-form-content .trumbowyg-box {
  margin-bottom: 20px;
  border-bottom: 1px solid var(--border-color);
}
.reply-form .mailCompose-form-content .trumbowyg-box,
.reply-form .mailCompose-form-content .trumbowyg-editor {
  min-height: 180px;
}
.reply-form .select2-selection .select2-selection__rendered {
  padding: 0 20px 0 60px !important;
}
.reply-form__right a img,
.reply-form__right a svg {
  width: 16px;
  color: var(--color-light);
}
.mailbar-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 50px;
  margin: 0 auto 20px;
  cursor: pointer;
  margin-bottom: 25px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  background: var(--color-white);
  color: var(--color-primary);
  padding: 15px 8px;
  cursor: pointer;
}
.mailbar-toggle span {
  display: block;
  width: 22px;
  height: 2px;
  background-color: var(--color-primary);
}
.mailbar-toggle span + span {
  margin-top: 5px;
}
.sidebar.collapsed .sidebar__menu-group ul.sidebar_nav li.menu-title span {
  display: none;
  -webkit-transition: var(--transition);
  -o-transition: var(--transition);
  transition: var(--transition);
}
.mailbox-list__single:hover .mail-content__top .mail-title .badge {
  background-color: rgba(var(--color-primary-rgba), 0.1);
  color: var(--color-primary);
}
.maintenance-page__title {
  font-weight: 500;
  margin: 70px 0 14px;
}
.maintenance-page p {
  font-size: 14px;
  color: var(--color-gray);
}
.maintenance-page svg {
  max-width: 100%;
  height: auto;
}
.contact-breadcrumb .breadcrumb-main__wrapper .sub-title {
  display: none;
}
.contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form {
  width: 280px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 5px rgba(var(--light-gray-rgba), 0.03);
  box-shadow: 0 5px 5px rgba(var(--light-gray-rgba), 0.03);
  height: 40px;
  padding: 15px 18px;
  background-color: var(--color-white);
}
.contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form i,
.contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form img,
.contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form svg {
  color: var(--color-light);
  width: 16px;
}
.contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form .form-control {
  height: 40px;
}
.contact-breadcrumb
  .breadcrumb-main__wrapper
  .add-contact__form
  .form-control::-webkit-input-placeholder {
  color: var(--color-lighten);
}
.contact-breadcrumb
  .breadcrumb-main__wrapper
  .add-contact__form
  .form-control::-moz-placeholder {
  color: var(--color-lighten);
}
.contact-breadcrumb
  .breadcrumb-main__wrapper
  .add-contact__form
  .form-control::-ms-input-placeholder {
  color: var(--color-lighten);
}
.contact-breadcrumb
  .breadcrumb-main__wrapper
  .add-contact__form
  .form-control::placeholder {
  color: var(--color-lighten);
}
.contact-breadcrumb .action-btn .btn {
  -webkit-box-shadow: 0 5px 10px rgba(var(--color-primary-rgba), 0.1);
  box-shadow: 0 5px 10px rgba(var(--color-primary-rgba), 0.1);
}
.add-contact .close img,
.add-contact .close svg {
  width: 18px;
}
.add-contact .add-new-contact {
  margin-top: -8px;
}
.add-contact .add-new-contact label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
  color: var(--color-dark);
}
.add-contact .add-new-contact .form-control.form-control-lg {
  height: 3rem;
}
.add-contact .add-new-contact button {
  text-transform: capitalize;
}
.contact-list-wrap {
  padding: 25px 25px 30px;
  background-color: var(--color-white);
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
.contact-list table th.c-name {
  min-width: 120px;
}
.contact-list table th.c-company {
  min-width: 120px;
}
.contact-list table th.c-position {
  min-width: 120px;
}
.contact-list table th.c-phone {
  min-width: 170px;
}
.contact-list table th.c-action {
  min-width: 110px;
}
.contact-list .contact-item {
  min-width: 210px;
}
.contact-list .contact-item .contact-personal-info {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-left: 20px;
}
.contact-list .contact-item .contact-personal-info .profile-image {
  margin-right: 12px !important;
}
.contact-list .contact-item .contact_title h1,
.contact-list .contact-item .contact_title h2,
.contact-list .contact-item .contact_title h3,
.contact-list .contact-item .contact_title h4,
.contact-list .contact-item .contact_title h5,
.contact-list .contact-item .contact_title h6 {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
}
.contact-list .contact-item .contact_title a {
  color: var(--color-dark);
}
.contact-list .contact-item .contact_title .location {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-light);
}
.contact-list .table-actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  /* justify-content: flex-end; */
}
.contact-list .table-actions a:not(:last-child) {
  margin-right: 20px;
}
.contact-list .table-actions a.active i,
.contact-list .table-actions a.active img,
.contact-list .table-actions a.active svg {
  color: var(--color-warning);
}
.contact-list .table-actions a i,
.contact-list .table-actions a img,
.contact-list .table-actions a svg {
  width: 18px;
  color: var(--color-lighten);
}
.contact-list .table-actions .dropdown-menu {
  min-width: 7rem;
}
.contact-list .table-actions .btn-link img,
.contact-list .table-actions .btn-link svg {
  width: 18px;
  color: var(--color-lighten);
}
.contact-card {
  position: relative;
}
.contact-card .dropdown-menu {
  min-width: 7rem;
}
.contact-card .card__more-action {
  position: absolute;
  right: 30px;
  top: 15px;
}
.contact-card .cp-img {
  margin-bottom: 20px;
}
.contact-card .cp-img img {
  max-width: 120px;
}
.contact-card .cp-info__title {
  font-size: calc(16.2px + 0.15vw);
  font-weight: 600;
  margin-bottom: 4px;
  color: var(--color-dark);
}
.contact-card .cp-info__designation {
  font-size: 14px;
  color: #8288a4;
}
.contact-card .c-info-list {
  margin-bottom: 5px;
  margin-top: -2px;
}
.contact-card .c-info-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.contact-card .c-info-list__item:not(:last-child) {
  margin-bottom: 4px;
}
.contact-card .c-info-list__item .c-info-item-icon i,
.contact-card .c-info-list__item .c-info-item-icon img,
.contact-card .c-info-list__item .c-info-item-icon svg {
  width: 14px;
  margin-right: 12px;
  color: var(--color-light);
}
.contact-card .c-info-list__item p {
  font-size: 14px;
  color: #8288a4;
  margin-bottom: 0;
}
#world-map {
  height: 650px;
}
.jvectormap-container .jvectormap-zoomin,
.jvectormap-container .jvectormap-zoomout {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 20px;
  height: 18px;
  bottom: 25px;
  right: 0;
  top: auto;
  left: auto;
  border: 1px solid var(--border-color);
  background-color: var(--color-white);
  color: var(--color-gray);
}
.jvectormap-container .jvectormap-zoomin {
  -webkit-border-radius: 6px 6px 0 0;
  border-radius: 6px 6px 0 0;
}
.jvectormap-container .jvectormap-zoomout {
  bottom: 0;
  border-top: 0;
  -webkit-border-radius: 0 0 6px 6px;
  border-radius: 0 0 6px 6px;
}
.regions-svg #region-map,
.regions-svg #s_region-map_M,
.regions-svg #s_region-map_T,
.regions-svg #s_region-map_W,
.regions-svg #s_region-map_Y {
  height: 100%;
  width: 100%;
}
#leaflet-basic,
#leaflet-cluster,
#leaflet-customIcon,
#leaflet-multiIcon,
.google-map {
  height: 450px;
}
.google-map-iframe iframe {
  width: 100%;
  height: 257px;
}
:root {
  --body: #f4f5f7;
  --contents-bg: #f4f5f7;
  --main-color: white;
  --accent: #ffffff;
  --color-menu: #404040;
  --color-menu-hover: #5840ff;
  --color-menu-active: #5840ff;
  --color-menu-bg-opacity: rgb(var(--color-primary-rgba), 0.1);
  --header-bg: white;
  --sidebar-bg: white;
  --main-content: var(--contents-bg);
  --header-bg: white;
  --logo-wrapper-bg: white;
  --color-add-listing-button-text: var(--color-white);
  --color-add-listing-button-text-hover: var(--color-white);
  --bgcolor-add-listing-button: #8231d3;
  --bgcolor-add-listing-button-hover: #0a0a0a;
  --color-banner_title: var(--color-white);
  --color-breadcrumb-link: #8c90a4;
  --color-breadcrumb-link-hover: #5840ff;
  --color-breadcrumb_separator: #8c90a4;
  --bgcolor-footer: #353b58;
  --color-footer-divider: #353b58;
  --color-footer-title: var(--color-white);
  --color-footer-link-hover: var(--color-white);
  --color-primary: #8231d3;
  --color-secondary: #5840ff;
  --color-success: #01b81a;
  --color-info: #00aaff;
  --color-warning: #fa8b0c;
  --color-danger: #ff0f0f;
  --color-dark: #0a0a0a;
  --color-infos: #00e4ec;
  --color-warnings: #ffbb00;
  --color-dangers: #ff0f0f;
  --color-purple: #a722f6;
  --bg-primary: #8231d3;
  --bg-secondary: #5840ff;
  --bg-success: #01b81a;
  --bg-info: #00aaff;
  --bg-warning: #fa8b0c;
  --bg-danger: #ff0f0f;
  --bg-dark: rgb(10, 10, 10);
  --bg-primary-hover: #6726a8;
  --bg-secondary-hover: #3520c8;
  --bg-success-hover: #0d811d;
  --bg-info-hover: #0787c7;
  --bg-warning-hover: #d9790a;
  --bg-danger-hover: #cb0000;
  --bg-dark-hover: #272525;
  --bg-gray-hover: #585858;
  --color-primary-rgba: 130, 49, 211;
  --color-secondary-rgba: 88, 64, 255;
  --color-success-rgba: 1, 184, 26;
  --color-info-rgba: 0, 170, 255;
  --color-warning-rgba: 250, 139, 12;
  --color-danger-rgba: 255, 15, 15;
  --color-dark-rgba: 10, 10, 10;
  --color-primary-rgba-shadow: rgba(var(--color-primary-rgba), 0.2);
  --color-white: #fff;
  --color-light: #747474;
  --color-lighten: #a0a0a0;
  --color-lighter: #f8f9fb;
  --color-lighten-x: #5d658c;
  --footer-dark: #353b58;
  --color-deep: #eff0f3;
  --color-normal: #f8f9fb;
  --color-lighters: #f4f5f7;
  --color-lighten-rgba: 173, 181, 217;
  --color-gray: #404040;
  --color-gray-x: #5a5f7d;
  --body-color: #666d92;
  --text-color: #666d92;
  --light-gray: #8c90a4;
  --light-gray-rgba: 173, 181, 217;
  --border-color: #f1f2f6;
  --border-light: #e3e6ef;
  --border-deep: #c6d0dc;
  --bg-normal: #f8f9fb;
  --bg-lighters: #f4f5f7;
  --bg-lighter: #f4f5f7;
  --bg-deep: #eff0f3;
  --bg-gray: #404040;
  --bg-light: #747474;
  --bg-white: #ffffff;
  --bg-light-rgba: 116, 116, 116;
  --bg-normal-rgba: 248, 249, 251;
  --bg-lighter-rgba: 244, 245, 247;
  --bg-deep-rgba: 239, 240, 243;
  --color-gray-rgba: 173, 181, 217;
  --color-dark-rgba: 39, 43, 65;
  --color-white-rgba: 255, 255, 255;
  --box-shadow-color-rgba: 146, 153, 184;
  --border-radius-xl: 10px;
  --border-radius-md: 6px;
  --border-radius-xs: 4px;
  --color-facebook: #2366b8;
  --color-twitter: #00abe4;
  --color-pinterest: #ef1b25;
  --color-gplus: #dc4e41;
  --color-linkedin: #007cbc;
  --color-tumblr: #35465c;
  --color-vk: #4a76a8;
  --color-youtube: #e32212;
  --color-instagram: #9c27b0;
  --color-ruby: #c2185b;
  --color-facebook-rgba: 35, 102, 184;
  --color-twitter-rgba: 0, 171, 228;
  --color-pinterest-rgba: 239, 27, 37;
  --color-gplus-rgba: 220, 78, 65;
  --color-linkedin-rgba: 0, 124, 188;
  --color-tumblr-rgba: 53, 70, 92;
  --color-vk-rgba: 74, 118, 168;
  --color-youtube-rgba: 227, 34, 18;
  --color-instagram-rgba: 156, 39, 176;
  --color-ruby-rgba: 194, 24, 91;
  --color-infos-rgba: 0, 228, 236;
  --color-warnings-rgba: 255, 187, 0;
  --color-dangers-rgba: 255, 15, 15;
  --color-purple-rgba: 167, 34, 246;
  --color-primary-rgba-10: rgba(var(--color-primary-rgba), 0.1);
  --color-secondary-rgba-10: rgba(var(--color-secondary-rgba), 0.1);
  --color-primary-rgba-50: rgba(var(--color-primary-rgba), 0.5);
  --color-secondary-rgba-50: rgba(var(--color-secondary-rgba), 0.5);
  --transition: all 0.3s ease;
  --gridLines_color: #e3e6ef;
  --scrollbarBG: var(--color-white);
  --thumbBG: #e3e6ef;
  --input-bg: var(--color-white);
  --input-focus-bg: var(--color-white);
  --tooltip-bg: var(--color-white);
  --tooltip-arrow-color: var(--color-white);
  --shadow2: #9299b80d;
  --shadow3: #9299b81a;
  --shadow4: #9299b833;
  --shadow5: #9299b826;
}
.layout-dark {
  --body: var(--main-color);
  --contents-bg: var(--main-color);
  --white-100: white;
  --white-87: rgb(255, 255, 255, 0.87);
  --white-60: rgb(255, 255, 255, 0.6);
  --white-30: rgb(255, 255, 255, 0.3);
  --white-20: rgb(255, 255, 255, 0.2);
  --white-10: rgb(255, 255, 255, 0.1);
  --white-06: rgb(255, 255, 255, 0.06);
  --main-color: #010413;
  --color-menu: #404040;
  --color-menu-hover: #5840ff;
  --color-menu-active: #5840ff;
  --color-menu-bg-opacity: rgb(251, 53, 134, 0.1);
  --header-bg: white;
  --sidebar-bg: white;
  --main-content: var(--bg-normal);
  --logo-wrapper-bg: var(--white-10);
  --header-bg: var(--white-10);
  --color-add-listing-button-text: var(--color-white);
  --color-add-listing-button-text-hover: var(--color-white);
  --bgcolor-add-listing-button: #8231d3;
  --bgcolor-add-listing-button-hover: #0a0a0a;
  --color-banner_title: var(--color-white);
  --color-breadcrumb-link: #8c90a4;
  --color-breadcrumb-link-hover: #5840ff;
  --color-breadcrumb_separator: #8c90a4;
  --bgcolor-footer: #353b58;
  --color-footer-divider: #353b58;
  --color-footer-title: var(--color-white);
  --color-footer-link-hover: var(--color-white);
  --color-primary: #8231d3;
  --color-secondary: #5840ff;
  --color-success: #01b81a;
  --color-info: #00aaff;
  --color-warning: #fa8b0c;
  --color-danger: #ff0f0f;
  --color-dark: var(--white-87);
  --color-infos: #00e4ec;
  --color-warnings: #ffbb00;
  --color-dangers: #ff0f0f;
  --color-purple: #a722f6;
  --bg-primary: #8231d3;
  --bg-secondary: #5840ff;
  --bg-success: #01b81a;
  --bg-info: #00aaff;
  --bg-warning: #fa8b0c;
  --bg-danger: #ff0f0f;
  --bg-dark: var(--white-87);
  --bg-primary-hover: #6726a8;
  --bg-secondary-hover: #3520c8;
  --bg-success-hover: #0d811d;
  --bg-info-hover: #0787c7;
  --bg-warning-hover: #d9790a;
  --bg-danger-hover: #cb0000;
  --bg-dark-hover: #272525;
  --bg-gray-hover: #585858;
  --color-primary-rgba: 130, 49, 211;
  --color-secondary-rgba: 88, 64, 255;
  --color-success-rgba: 1, 184, 26;
  --color-info-rgba: 0, 170, 255;
  --color-warning-rgba: 250, 139, 12;
  --color-danger-rgba: 255, 15, 15;
  --color-dark-rgba: 9, 14, 48;
  --color-primary-rgba-shadow: rgba(var(--color-primary-rgba), 0.2);
  --color-white: var(--white-87);
  --color-gray: var(--white-60);
  --color-light: var(--white-60);
  --color-lighten: var(--white-60);
  --color-lighter: #f8f9fb;
  --color-lighten-x: #5d658c;
  --footer-dark: #353b58;
  --color-deep: #eff0f3;
  --color-normal: #f8f9fb;
  --color-lighters: #f4f5f7;
  --color-lighten-rgba: 173, 181, 217;
  --color-gray-x: var(--white-60);
  --body-color: var(--white-60);
  --text-color: var(--white-60);
  --light-gray: var(--white-60);
  --light-gray-rgba: 140, 144, 164;
  --border-color: var(--white-06);
  --border-deep: var(--white-06);
  --border-color: var(--white-06);
  --border-light: var(--white-06);
  --bg-normal: var(--white-10);
  --bg-lighters: var(--white-10);
  --bg-lighter: var(--white-10);
  --bg-deep: var(--white-10);
  --bg-white: var(--white-10);
  --bg-normal-rgba: 248, 249, 251;
  --bg-lighter-rgba: 244, 245, 247;
  --bg-deep-rgba: 239, 240, 243;
  --color-gray-rgba: 140, 144, 164;
  --color-dark-rgba: 39, 43, 65;
  --color-white-rgba: 255, 255, 255;
  --box-shadow-color-rgba: 146, 153, 184;
  --main-color-rgba: 1, 4, 19;
  --border-radius-xl: 10px;
  --border-radius-md: 6px;
  --border-radius-xs: 4px;
  --color-facebook: #2366b8;
  --color-twitter: #00abe4;
  --color-pinterest: #ef1b25;
  --color-gplus: #dc4e41;
  --color-linkedin: #007cbc;
  --color-tumblr: #35465c;
  --color-vk: #4a76a8;
  --color-youtube: #e32212;
  --color-instagram: #9c27b0;
  --color-ruby: #c2185b;
  --color-facebook-rgba: 35, 102, 184;
  --color-twitter-rgba: 0, 171, 228;
  --color-pinterest-rgba: 239, 27, 37;
  --color-gplus-rgba: 220, 78, 65;
  --color-linkedin-rgba: 0, 124, 188;
  --color-tumblr-rgba: 53, 70, 92;
  --color-vk-rgba: 74, 118, 168;
  --color-youtube-rgba: 227, 34, 18;
  --color-instagram-rgba: 156, 39, 176;
  --color-ruby-rgba: 194, 24, 91;
  --color-primary-rgba-10: rgba(var(--color-primary-rgba), 0.1);
  --color-secondary-rgba-10: rgba(var(--color-secondary-rgba), 0.1);
  --color-primary-rgba-50: rgba(var(--color-primary-rgba), 0.5);
  --color-secondary-rgba-50: rgba(var(--color-secondary-rgba), 0.5);
  --transition: all 0.3s ease;
  --gridLines_color: rgb(255, 255, 255, 0.3);
  --scrollbarBG: #1b1e2b;
  --thumbBG: rgb(255, 255, 255, 0.3);
  --input-bg: var(--white-10);
  --input-focus-bg: var(--white-10);
  --tooltip-bg: var(--main-color);
  --tooltip-arrow-color: var(--main-color);
  --shadow2: #01041333;
  --shadow3: #01041366;
  --shadow4: #01041399;
  --shadow5: #01041399;
}
@media only screen and (min-width: 0) and (max-width: 320px) {
  .user-member__title {
    margin: 0;
  }
  .shop-breadcrumb .breadcrumb-action .action-btn .btn {
    padding: 0 8px;
  }
  .project-category p {
    margin-right: 0;
    margin-bottom: 10px;
  }
  .footer-wrapper {
    padding: 20px 30px 18px;
  }
  .order-summery .checkout {
    padding: 15px 10px;
    font-size: 11px;
  }
  .payment-invoice-qr__code img {
    width: 170px;
  }
  .error-page__title {
    margin-top: 0;
    margin-bottom: 25px;
  }
  .chat-box {
    padding: 19px 0;
  }
}
@media only screen and (max-width: 575px) and (min-width: 1200px) {
  .device-pieChart-wrap .pie-chart-legend span {
    font-size: 20px;
  }
}
@media (min-width: 576px) {
  .product--list .product-item,
  .product--list .product-item__body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .fileM-sidebar .modal-dialog {
    max-width: 390px;
    margin: 1.75rem auto;
  }
  .task-modal .modal-dialog {
    max-width: 390px;
    margin: 1.75rem auto;
  }
}
@media (min-width: 700px) {
  .wizard8 .checkout-progress div.step:not(:last-child) {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 767px) {
  .revenueSourceChart.revenuePieChart--wrapper .revenuePieChart {
    margin-left: -21px;
  }
  .revenueSourceChart.revenuePieChart--wrapper .chart-content__details {
    margin-left: 30px;
  }
}
@media (min-width: 767px) {
  .ui-datepicker-multi {
    padding-right: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .w-md-50 {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .product--list .product-item__body .product-item__button button {
    width: 132px;
    height: 38px;
  }
  .changelog-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66666667%;
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }
  .changelog-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33333333%;
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }
  .banner-feature {
    min-height: 270px;
    max-height: 100%;
  }
  .banner-feature--7 {
    min-height: 160px;
    max-height: 100%;
  }
  .banner-feature--77 {
    max-height: 100%;
  }
}
@media only screen and (min-width: 991px) and (max-width: 992px) {
  .sidebar-group .left-sidebar-wrap {
    width: 360px;
  }
}
@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .chat-type-text__write {
    width: 100%;
    height: unset;
    padding: 6px 25px;
    margin-right: 0;
  }
  .chat-type-text__btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }
  .chat-type-text__btn button:not(:last-child) {
    margin-right: 8px;
  }
  .selling-table-wrap table tbody td {
    padding: 10px 15px 10px 25px;
  }
}
@media (min-width: 992px) {
  .ap-tab-header__img img {
    height: 220px;
  }
  .project-progress .user-group-progress-bar {
    width: 340px;
  }
  .order-search__form {
    width: 300px;
  }
  .search-content .card-body,
  .search-content .card-header {
    width: 1001px;
  }
}
@media only screen and (min-width: 1199px) {
  .user-member__form {
    min-width: 350px;
  }
}
@media (min-width: 1200px) {
  .fs-18 {
    font-size: 18px;
  }
  .fs-20 {
    font-size: 20px;
  }
  .fs-22 {
    font-size: 22px;
  }
  .fs-24 {
    font-size: 24px;
  }
  .chart-desc strong {
    font-size: 22px;
  }
  .tooltip-value {
    font-size: 22px;
  }
  .social-box {
    font-size: 22px;
  }
  .customizer-trigger i {
    font-size: 18px;
  }
  .customizer__title {
    font-size: 18px;
  }
  .navbar-left .sidebar-toggle i {
    font-size: 19px;
  }
  .sidebar__menu-group ul.sidebar_nav li > a .nav-icon {
    font-size: 18px;
  }
  .avatar.avatar-lg .avatar-letter {
    font-size: 24px;
  }
  .avatar.avatar-md .avatar-letter {
    font-size: 22px;
  }
  .avatar.avatar-sm .avatar-letter {
    font-size: 20px;
  }
  .avatar.avatar-xs .avatar-letter {
    font-size: 18px;
  }
  .breadcrumb-main .user-member__form {
    width: 350px;
  }
  .card .card-header h1,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header h5,
  .card .card-header h6 {
    font-size: 18px;
  }
  .card .card-header .dropdown i,
  .card .card-header .dropdown span,
  .card .card-header .dropdown svg {
    font-size: 18px;
  }
  .card-overview-progress .card-progress__summery strong {
    font-size: 24px;
  }
  .feature-cards2 figcaption h2 {
    font-size: 18px;
  }
  .feature-cards3 figcaption h2 {
    font-size: 18px;
  }
  .feature-cards4 figcaption h2 {
    font-size: 20px;
  }
  .feature-cards6 .card-short .card-short__title {
    font-size: 18px;
  }
  .slick-slider__single p {
    font-size: 18px;
  }
  .dm-notification-box__close i,
  .dm-notification-box__close img,
  .dm-notification-box__close svg {
    font-size: 18px;
  }
  .performance-stats div strong {
    font-size: 24px;
  }
  .device-pieChart-wrap .pie-chart-legend span {
    font-size: 30px;
  }
  .session-wrap .session-single strong {
    font-size: 18px;
  }
  .revenue-labels strong {
    font-size: 24px;
  }
  .revenue-chart-legend__data span {
    font-size: 18px;
  }
  .overview-content3 .revenue-chart-box__Icon i {
    font-size: 30px;
  }
  .revenue-chart-box__Icon i {
    font-size: 30px;
  }
  .revenue-chart-box__data {
    font-size: 20px;
  }
  .forcast-cardbox .forcast-details .forcast-value {
    font-size: 30px;
  }
  .ratio-box .ratio-info .ratio-point {
    font-size: 36px;
  }
  .label-detailed--two .label-detailed__total {
    font-size: 18px;
  }
  .ap-po-details__icon-area .svg-icon i {
    font-size: 32px;
  }
  .ap-po-details--luodcy .ap-po-details__icon-area i {
    font-size: 40px;
  }
  .ap-post-gallery-overlay__content {
    font-size: 20px;
  }
  .account-profile-cards__button span {
    font-size: 18px;
  }
  .dm-notice__icon i,
  .dm-notice__icon img,
  .dm-notice__icon svg {
    font-size: 30px;
  }
  .dm-notice__icon i.fa,
  .dm-notice__icon i.fas {
    font-size: 26px;
  }
  .users-list__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }
  .icon-list-social__link {
    font-size: 18px;
  }
  .icon-list-social__link i {
    font-size: 20px;
  }
  .ui-datepicker-header a.ui-corner-all {
    font-size: 18px;
  }
  .statistics-item__number {
    font-size: 20px;
  }
  .statistics-countdown__time {
    font-size: 20px;
  }
  .dm-spin-dots.spin-md {
    font-size: 24px;
  }
  .dm-spin-dots.spin-lg {
    font-size: 30px;
  }
  .dm-upload__file li .file-name i,
  .dm-upload__file li .file-name img,
  .dm-upload__file li .file-name span,
  .dm-upload__file li .file-name svg {
    font-size: 18px;
  }
  .dm-upload__file li .btn-delete {
    font-size: 18px;
  }
  .page-title__left a i {
    font-size: 20px;
  }
  .page-title__left .title-text {
    font-size: 22px;
  }
  .new-member .modal-header .close {
    font-size: 18px;
  }
  .user-list-item .users-list-body-title p {
    white-space: nowrap;
  }
  .product--grid .product-item__body .card-title {
    font-size: 18px;
  }
  .product--list .product-item .product-item__title {
    -webkit-box-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    padding: 0 30px;
  }
  .product--list .product-item .product-item__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 30px;
  }
  .product--list .product-item__body .card-title {
    font-size: 18px;
  }
  .product-details .product-item__body .product-desc-price {
    font-size: 22px;
  }
  .quantity .bttn {
    font-size: 20px;
  }
  .product-cart .actions button i {
    font-size: 18px;
  }
  .product-cart__quantity .bttn {
    font-size: 17px;
  }
  .checkout-progress div.step span {
    font-size: 18px;
  }
  .wizard10 .checkout-shipping-form .card-header h4 {
    font-size: 20px;
  }
  .wizard10 .payment-success-modal .modal-icon span {
    font-size: 30px;
  }
  .wizard8 .checkout-progress-indicator .card-header h4 {
    font-size: 22px;
  }
  .card.checkout-shipping-form .card-header h4 {
    font-size: 20px;
  }
  .crc__title h5 {
    font-size: 18px;
  }
  .payment-invoice-logo span {
    font-size: 27px;
  }
  .orderDatatable_actions li a i {
    font-size: 18px;
  }
  .selling-table-wrap--source .selling-product-img-wrapper i {
    font-size: 18px;
  }
  .upload-product-img .pera {
    font-size: 20px;
  }
  .icon-list i,
  .icon-list svg {
    font-size: 18px;
  }
  .error-page__title {
    font-size: 60px;
  }
  .counting-area .number {
    font-size: 42px;
  }
  .coming-soon h1 {
    font-size: 58px;
  }
  .coming-soon .subtitle {
    font-size: 17px;
  }
  .v-num {
    font-size: 18px;
  }
  .testimonial-slider-global .slider-arrow {
    font-size: 18px;
  }
  .related-article .card-body h4 {
    font-size: 20px;
  }
  .comment-respond h4 {
    font-size: 20px;
  }
  .kanban-board .card-header h4 {
    font-size: 20px;
  }
  .kanban-modal__header .modal-title {
    font-size: 20px;
  }
  .with-icon span {
    font-size: 18px;
  }
  #filter-form-container .footable-filtering-search .input-group:after {
    font-size: 18px;
  }
  .feature-profile .feature-profile_content h6 {
    font-size: 18px;
  }
  .breadcrumb-main--table .breadcrumb-title {
    font-size: 18px;
  }
  .ticket_modal .modal-content .modal-header h1 {
    font-size: 30px;
  }
  .new-ticket .ticket_modal .ticket_modal-modal h1 {
    font-size: 30px;
  }
  .ticket-details-contact__overview .ticket-details-contact__overview-title {
    font-size: 20px;
  }
  .job-apply-wrapper .job-apply__content h1 {
    font-size: 30px;
  }
  .course-details-about__content h5 {
    font-size: 22px;
  }
  .blog-card__title a {
    font-size: 20px;
  }
  .blog-body p {
    font-size: 18px;
  }
  .blog-body ul li {
    font-size: 18px;
  }
  .blog-body ol li {
    font-size: 18px;
  }
  .blog-block-quote p {
    font-size: 20px;
  }
  .blog-block-quote cite {
    font-size: 18px;
  }
  .blog-block-quote cite span {
    font-size: 18px;
  }
  .blog-author-box .auth-details h2 {
    font-size: 18px;
  }
  .blog-details .blog-share-top .blog-share-fixed ul li a i {
    font-size: 18px;
  }
  .admin .edit-profile .card .card-header h4 {
    font-size: 20px;
  }
  .admin-element-right .card .card-header h6 {
    font-size: 24px;
  }
  .admin-element-right .card .card-header h6 span {
    font-size: 24px;
  }
  .contact-card .cp-info__title {
    font-size: 18px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .bookmark-page .columns-1,
  .products_page .columns-1 {
    max-width: 30%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    width: 100%;
  }
  .bookmark-page .columns-2,
  .products_page .columns-2 {
    max-width: 70%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
  }
}
@media only screen and (max-width: 1399px) and (min-width: 1200px) {
  .card-overview-progress .card-progress__summery strong {
    font-size: 20px;
  }
  .performance-stats div strong {
    font-size: 20px;
  }
}
@media only screen and (min-width: 1399px) {
  .selling-table-wrap.selling-table-wrap--2 .table-responsive {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .selling-table-wrap.selling-table-wrap--2
    .table-responsive::-webkit-scrollbar {
    display: none;
  }
  .selling-table-wrap.selling-table-wrap--2 table tbody tr td:not(:last-child) {
    width: 40%;
  }
  .banner-feature--danial {
    min-height: 360px;
  }
}
@media (min-width: 1399px) {
  .fileM-contents .col-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.5%;
    flex: 0 0 20.5%;
    max-width: 20.5%;
  }
  .fileM-contents .col-79 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.5%;
    flex: 0 0 79.5%;
    max-width: 79.5%;
  }
  .kanban-board__card .cus-xl {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 4320px) {
  .cartPage .cus-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .cartPage .cus-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .order-summery--width {
    margin-left: auto;
  }
}
@media only screen and (min-width: 1499px) {
  .side-menu .contents.expanded .ap-po-details--2 {
    padding: 34px 25px !important;
  }
  .top-menu .ap-po-details--2 {
    padding: 37px 25px !important;
  }
}
@media (min-width: 1599px) {
  .knowledgebase-details .cus-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
}
@media (min-width: 1600px) {
  .profile-content .cos-lg-3 {
    max-width: 25%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .ap-statistics-charts canvas {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    width: 100% !important;
  }
  .cos-xl-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .bookmark-page .columns-1,
  .products_page .columns-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
    width: 100%;
  }
  .bookmark-page .columns-2,
  .products_page .columns-2 {
    -webkit-box-flex: 1;
    -ms-flex: 1 0 75%;
    flex: 1 0 75%;
    max-width: 75%;
  }
  .bookmark-page__list .cus-xl-3,
  .product-page-list .cus-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .wizard6.checkout-review .cus-8,
  .wizard7.checkout-review .cus-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .changelog-19 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 79.16666667%;
    flex: 0 0 79.16666667%;
    max-width: 79.16666667%;
  }
  .changelog-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20.83333333%;
    flex: 0 0 20.83333333%;
    max-width: 20.83333333%;
  }
  .admin-element-right .edit-profile {
    margin: 0 99px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 4320px) {
  .order-summery--width {
    max-width: 400px;
  }
}
@media only screen and (min-width: 1600px) and (max-width: 1650px) {
  .mailbox-top__search {
    margin-right: 25px;
  }
}
@media (min-width: 1699px) {
  .knowledgebase .cus-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .kanban-board__card .cus-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
}
@media only screen and (min-width: 1920px) {
  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
    -ms-flex-order: 0 !important;
    order: 0 !important;
  }
}
@media only screen and (max-width: 1599px) {
  .col-xxl.col-lg-4,
  .col-xxl.col-lg-6,
  .col-xxl.col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-4.col-xxl-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
  }
  .col-xl-6.col-xxl-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
  }
  .col-xl-3.col-xxl-6,
  .col-xl-4.col-xxl-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .sidebar.collapsed .sidebar__menu-group .has-child ul li a {
    padding: 6px 0;
  }
  .card-overview-progress {
    min-height: 510px;
  }
  .top-menu .card-overview-progress {
    min-height: auto;
  }
  .linechart-overview-wrap .d-flex {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .linechart-overview-wrap .d-flex:not(:last-child) .border-line-chart {
    margin-bottom: 10px !important;
  }
  .linechart-overview-wrap .border-line-chart {
    width: 100%;
    max-width: 100%;
  }
  .top-menu .linechart-overview-wrap .mb-3 {
    margin-bottom: 1rem !important;
  }
  .top-menu .ratio-box {
    min-height: 100%;
  }
  .inEx-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .inEx-wrap .inEx-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 25px 0 0;
    border-right: 0;
  }
  .inEx-wrap .inEx-chart {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding: 0 0 18px;
  }
  .inEx__single {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
  }
  .inEx__single .inEx__info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .inEx__info .inEx__percentage {
    display: inline-block;
    margin: 15px 8px 0 0;
  }
  .ap-po-details--3 > div:not(.b-none):not(:last-child) {
    border-right: none;
    padding-right: 0;
  }
  .ap-po-details--3 > div:not(.b-none) {
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
  }
  .ap-content-wrapper .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .top-menu .table-social {
    margin-bottom: 15px;
  }
  .landing-pages-table {
    padding-bottom: 58px;
  }
  .table-top-regions {
    max-width: 400px;
  }
  .top-menu .table-top-location {
    margin-bottom: 0;
  }
  .top-menu .table-revenue {
    margin-bottom: 0;
  }
  .projects-tab-content--progress .col-xl-4 {
    max-width: 50%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .calendar-grid .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  .calendar-grid .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
  }
  .date-picker__calendar .ui-datepicker-calendar tbody td a {
    width: 2.4rem;
    height: 2.4rem;
  }
  #full-calendar .fc-toolbar-chunk .fc-button-group .fc-button {
    padding: 6.5px 10px;
  }
  .project-top-left {
    margin-bottom: 20px;
  }
  .project-top-left,
  .project-top-right {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .product-item__image .carousel-item {
    width: 100%;
    height: 400px;
  }
  .checkout .col-xl-8 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .checkout .col-xl-4 {
    max-width: 60%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 60%;
    flex: 0 0 60%;
  }
  .exportDatatable .order-search__form {
    width: 100%;
  }
  .userDatatable table.adv-table .pagination {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .mailbox-wrapper .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mailbox-wrapper .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mailbox-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mailbox-top__left {
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 1399px) {
  .top-menu .navbar-left .logo-area {
    min-width: 210px;
  }
  .hexadash-top-menu ul li:hover > .subMenu {
    top: 40px;
  }
  .hexadash-top-menu ul li a {
    padding: 6px 0;
  }
  .sidebar.collapsed .sidebar__menu-group .has-child ul {
    max-height: 300px;
  }
  .card .card-header .card-tab-links li {
    margin: 0 6px;
  }
  .card-overview-progress .card-progress {
    padding: 15px;
  }
  .card-overview-progress .card-progress__summery strong {
    font-size: calc(16.4px + 0.3vw);
  }
  .card-overview-progress .card-progress__summery span {
    font-size: 13px;
  }
  .performance-stats div {
    padding: 10px;
  }
  .performance-stats div strong {
    font-size: calc(16.4px + 0.3vw);
  }
  .session-wrap .session-single {
    padding: 15px;
  }
  .performance-o .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .performance-o .performance-chart-labels {
    margin: 0 !important;
  }
  .performance-o .performance-chart-labels ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-top: 15px;
  }
  .performance-o .performance-chart-labels ul li {
    margin: 0 !important;
  }
  .ap-content-wrapper .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .wig__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .ffw {
    padding: 15px 25px;
  }
  .ffw > .d-flex {
    margin-bottom: 15px;
  }
  .landing-pages-table {
    padding-bottom: 40px;
  }
  .input-list__single {
    -webkit-box-flex: 33.33%;
    -ms-flex: 33.33%;
    flex: 33.33%;
  }
  .timeline-box.timeline-box--2 {
    overflow-x: auto;
  }
  .timeline-box--3.basic-timeline .timeline li .timeline-single {
    padding-left: 20px;
  }
  .chat-body .chat-header {
    padding: 25px 20px;
  }
  .chat-text-box {
    max-width: 100%;
  }
  .date-picker__calendar-height {
    height: auto;
    min-height: auto;
  }
  .date-picker__calendar .ui-datepicker-calendar tbody td a {
    width: 2rem;
    height: 2rem;
  }
  #full-calendar .fc-header-toolbar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #full-calendar .fc-toolbar-chunk:last-child {
    margin-top: 20px;
  }
  .product--grid:hover {
    -webkit-transform: unset;
    -ms-transform: unset;
    transform: unset;
  }
  .product--list .product-item .product-item__image {
    width: 300px;
    height: unset;
  }
  .product--list .product-item .product-item__image img {
    height: unset;
  }
  .product--list .like-icon {
    right: -10px;
    top: -5px;
  }
  .products_page .category_sidebar {
    padding: 15px;
  }
  .product-item__image .carousel-item img {
    height: 100%;
  }
  .order-summery--width {
    margin: 0 auto;
  }
  .wizard8 .checkout-shipping-form {
    height: unset;
    border-left: none;
    padding-left: 0;
  }
  .payment-invoice__btn {
    margin-top: 20px;
  }
  .payment-invoice__body {
    padding: 80px 30px 40px;
  }
  .note-contents .note-grid .note-single {
    width: 50%;
  }
  .coming-soon {
    padding: 60px 0;
  }
  .coming-soon :is(.light, .dark) {
    max-width: 250px;
  }
  .coming-soon__body {
    margin: 30px 0 50px;
  }
  .banner-feature .banner-feature__shape img {
    width: 100%;
  }
  .banner-feature--10 .banner-feature__shape img {
    width: 100%;
  }
  .banner-feature--11 .banner-feature__shape img {
    width: 100%;
  }
  .banner-feature--12 .banner-feature__shape img {
    width: 100%;
  }
  .banner-feature--13 .banner-feature__shape img {
    width: 100%;
  }
  .testimonial-slider-global.testimonial-slider3-nav .slick-slide.slick-active {
    -webkit-transform: scale(0.9) translate(150px);
    -ms-transform: scale(0.9) translate(150px);
    transform: scale(0.9) translate(150px);
  }
  .testimonial-slider-global.testimonial-slider3-nav
    .slick-slide.slick-center
    + .slick-slide {
    -webkit-transform: scale(0.9) translate(-150px);
    -ms-transform: scale(0.9) translate(-150px);
    transform: scale(0.9) translate(-150px);
  }
  .testimonial-slider-global.testimonial-slider3-nav .slick-slide.slick-center {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
  }
  .ticket-details-contact__status {
    margin-bottom: 15px;
  }
  .ticket-details-contact__status-item .dm-select {
    width: 100%;
  }
  .ticket-details-contact__status-item .dm-select select {
    width: 100%;
  }
  .ticket-details-contact__status-item .dm-select .select2 {
    width: 100% !important;
  }
  .course-card__img-area {
    max-height: 160px;
    min-height: 100%;
  }
  .course-card__title h4 {
    font-size: 16px;
  }
  .course-card__footer .total-money {
    font-size: 18px;
  }
  .course-card__footer .total-lextures {
    padding: 0 7px;
  }
  .cvg__item {
    min-height: 350px;
    height: 350px;
  }
  .cvg__item > img {
    height: 350px;
  }
  .admin .edit-profile {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .mailbox-wrapper .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30%;
    flex: 0 0 30%;
    max-width: 30%;
  }
  .mailbox-wrapper .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 70%;
    flex: 0 0 70%;
    max-width: 70%;
  }
  .mailbox-top__search {
    margin-right: 10px;
  }
  .mailbox-top__search .input-container {
    max-width: 250px;
  }
  .mailbox-list__single .mail-authorBox .author-info .author-name {
    margin-left: 5px;
  }
  .mailbox-container .dm-mailCompose--position {
    bottom: 30px;
    z-index: 9999;
    height: 500px;
    overflow-y: scroll;
  }
  .mail-details .mail-attatched__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 30.75%;
    flex: 0 0 30.75%;
  }
  .contact-card-group .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.3333%;
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
}
@media only screen and (max-width: 1399px) and (max-width: 575px) {
  .timeline-box--3.basic-timeline .timeline li .timeline-single {
    padding-right: 30%;
  }
}
@media only screen and (max-width: 1399px) and (max-width: 479px) {
  .timeline-box--3.basic-timeline .timeline li .timeline-single {
    padding-right: 0;
  }
}
@media only screen and (max-width: 1300px) {
  .hexadash-top-menu ul li:not(:last-child) {
    margin-right: 30px;
  }
  .hexadash-top-menu ul li > ul li:hover .subMenu {
    left: 180px;
  }
  .hexadash-top-menu .subMenu {
    width: 180px;
  }
  .hexadash-top-menu .subMenu .subMenu {
    width: 200px;
    left: 180px;
  }
  .hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-wide {
    width: 800px;
  }
  .hexadash-top-menu > ul > li .megaMenu-wrapper.megaMenu-dropdown {
    width: 340px;
  }
  .date-picker__calendar .ui-datepicker-calendar tbody td a {
    font-size: 12px;
    width: 1.5rem;
    height: 1.5rem;
  }
  .date-picker__calendar .ui-datepicker-calendar tbody td a {
    width: 2rem;
    height: 2rem;
  }
  #full-calendar .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 14px;
    margin: 0 14px;
  }
  .fc-view
    .fc-col-header
    tr
    th
    .fc-scrollgrid-sync-inner
    .fc-col-header-cell-cushion {
    padding-left: 5px;
    font-size: 11px;
  }
}
@media (max-width: 1300px) {
  .search-content .card-body,
  .search-content .card-header {
    width: 100%;
  }
  .note-contents .note-sidebar-wrapper {
    width: 240px;
  }
}
@media only screen and (max-width: 1199px) {
  .text-align-lg-center {
    text-align: center;
  }
  .text-align-lg-0 {
    text-align: left;
  }
  .col-xl-4.col-xxl-5.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-6.col-xxl-7.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-6.col-xxl-7.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-6.col-xxl-7.col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-3.col-lg-12,
  .col-xl-4.col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .col-xl-3.col-lg-6,
  .col-xl-4.col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-3.col-sm-12,
  .col-xl-4.col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .chartjs-tooltip {
    padding: 6px 8px !important;
  }
  .tooltip-value sup {
    font-size: 11px;
  }
  .chartjs-tooltip table tbody td {
    font-size: 12px;
  }
  .navbar-left .navbar-brand {
    margin-right: 26px;
  }
  .search-form-topMenu {
    top: 90px;
    right: 0;
  }
  .hexadash-top-menu ul li:not(:last-child) {
    margin-right: 26px;
  }
  .card .card-header h1,
  .card .card-header h2,
  .card .card-header h3,
  .card .card-header h4,
  .card .card-header h5,
  .card .card-header h6 {
    font-size: 15px;
  }
  .card-overview .br-1 {
    border: 0;
  }
  .card-overview__left .col-xl-6.br-1 {
    padding-right: 12.5px;
  }
  .card-overview__right .col-xl-6 {
    padding-left: 12.5px;
  }
  .inEx__single {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
  }
  .ap-po-details-time span {
    display: block;
  }
  .landing-pages-table {
    padding-bottom: 0;
  }
  .table-revenue {
    min-height: 100%;
  }
  .user-group-people__parent li:not(:last-child) {
    margin-bottom: 4px;
  }
  .drawer-placement-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .drawer-placement-wrap .radio-horizontal-list .custom-radio {
    margin-bottom: 15px;
  }
  .dm-drawer {
    width: 280px;
  }
  .product--list .product-item .product-item__title {
    -webkit-box-flex: 1.5;
    -ms-flex: 1.5;
    flex: 1.5;
    padding: 0 15px;
    padding-right: 0;
  }
  .product--list .product-item .product-item__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 15px;
  }
  .product--list .product-item .product-item__image {
    width: 307px;
    height: auto;
  }
  .products_page .category_sidebar {
    padding: 25px;
  }
  .project-top-left {
    margin-bottom: 20px;
  }
  .product-item__image .carousel-item {
    height: 350px;
  }
  .wizard10 .checkout-shipping-form {
    padding-left: 30px;
    padding-right: 30px;
  }
  .wizard8 .checkout-progress-indicator {
    height: unset;
    border-right: none;
    padding-right: 0;
  }
  .payment-invoice__body {
    padding: 30px;
  }
  .payment-invoice-table table thead .product-cart__header th:first-child {
    padding-left: 30px;
  }
  .payment-invoice-table table thead .product-cart__header th:last-child {
    padding-right: 50px;
  }
  .payment-invoice-table tbody tr th:first-child {
    padding-left: 30px;
  }
  .payment-invoice-table tbody tr td:last-child,
  .payment-invoice-table tfoot tr td:last-child {
    padding-right: 50px;
  }
  .orderDatatable.sellerDatatable .project-top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
  }
  .orderDatatable .project-top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .selling-table-wrap {
    padding-bottom: 0;
  }
  .search-content .card .card-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
  }
  .filtr-item--style {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33%;
    flex: 0 0 33%;
    width: 33%;
  }
  .breadcrumb-main.application-ui {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: unset;
    flex-flow: unset;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .breadcrumb-main.application-ui .breadcrumb-action {
    margin: 0;
  }
  .knowledgebase-help .card-body h6 {
    margin: 5px 20px;
    margin-right: 0;
  }
  .knowledgebase-help .card-body .button-group {
    margin: 0 20px;
  }
  .layout-dark .mobile-author-actions .nav-search {
    display: none;
  }
  .mailbox-wrapper .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333%;
    flex: 0 0 33.333%;
    max-width: 33.33%;
  }
  .mailbox-wrapper .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.6667%;
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }
  .add-lebel-from {
    padding: 20px;
  }
  .mailbox-top__right {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .mailbox-top__search {
    margin-bottom: 15px;
  }
  .mailbox-list__single {
    padding: 15px 20px;
  }
  .mailbox-list__single .mail-authorBox .author-info {
    white-space: unset;
  }
  .mailbox-list__single .mail-authorBox .author-info .author-name {
    margin: 5px 0 0;
  }
  .mailbox-list__single .mail-content__top .mail-title .badge {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 8px 0 0;
  }
  .mail-details__content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .mdc__left img {
    margin: 0 0 15px;
  }
  .mdc__author {
    margin: 0 0 8px;
  }
  .mail-reply__single .mdc__head .date-meta {
    margin-left: 0;
  }
  .mail-reply__action {
    padding-left: 0;
  }
  .mail-reply .mail-reply-box {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .mailbar-toggle {
    max-width: 40px;
    padding: 10px 6px;
  }
  .mailbar-toggle span {
    width: 18px;
  }
}
@media (max-width: 1150px) {
  .contents {
    padding: 73px 15px 72px 16px;
  }
  .contents.expanded {
    padding-left: 16px;
  }
  .contents.expanded + .footer-wrapper {
    padding-left: 0;
  }
  .sidebar.collapsed {
    margin-left: -76px;
  }
}
@media only screen and (max-width: 1024px) {
  .hexadash-top-menu ul li:not(:last-child) {
    margin-right: 16px;
  }
}
@media only screen and (max-width: 991px) {
  .text-align-md-center {
    text-align: center;
  }
  .text-align-md-0 {
    text-align: left;
  }
  .contents {
    padding: 73px 15px 64px 16px;
  }
  .contents.expanded + .footer-wrapper {
    padding-left: 8px;
  }
  .customizer-overlay.show,
  .overlay-dark-sidebar.show {
    z-index: 99;
  }
  .top-menu .sidebar-toggle {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .top-menu .sidebar {
    display: block;
  }
  .top-menu .contents {
    padding: 73px 15px 64px 16px;
  }
  .customizer-trigger i {
    margin-right: 0;
  }
  .customizer-trigger {
    padding: 0;
    width: 35px;
    height: 35px;
  }
  .customizer-trigger span {
    display: none;
  }
  .customizer-trigger svg {
    margin-right: 0;
  }
  .navbar-left .navbar-brand {
    margin-right: 25px;
    margin-left: 0;
  }
  .navbar-right__menu > li {
    padding: 20px 9px;
  }
  .hexadash-top-menu {
    display: none;
  }
  .sidebar {
    height: 100%;
  }
  .card .card-header .card-tab {
    margin: 5px 0;
  }
  .card .card-header .dropdown {
    margin: 0;
  }
  .card-overview-progress {
    min-height: 100%;
    max-width: 470px;
    margin: 0 auto;
  }
  .session-wrap {
    margin: 10px 0 -5px;
  }
  .session-wrap .session-single {
    padding: 10px;
  }
  .user-skils-parent {
    margin: 0;
  }
  .wig__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.33%;
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
  .ffw {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row;
    flex-flow: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .ffw__imgWrapper {
    margin: 0 1rem 0 0 !important;
  }
  .ffw > .d-flex {
    margin-bottom: 0;
  }
  .drawer-basic-wrap.account .drawer-account {
    width: 520px;
  }
  .drawer-basic-wrap.profile .drawer-profile {
    width: 520px;
  }
  .tab-slide-content .vertical-tabs {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .about-projects ul {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .sidebar-group {
    margin: 0;
  }
  .chat {
    padding-right: 0;
  }
  .chat-area {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .calendar-grid .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 390px;
    margin: 0 auto;
  }
  .calendar-grid .col-xl-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .project-search {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .project-search .user-member__form {
    min-width: 370px;
  }
  .product--list .product-item__body .product-item__button {
    margin-top: 20px;
  }
  .project-tap .nav-link {
    font-size: 13px;
    padding: 0 12px;
  }
  .shop_products_top_filter .project-top-left .project-result-showing,
  .shop_products_top_filter .project-top-left .project-search {
    width: 100%;
  }
  .shop_products_top_filter .project-top-right .project-category,
  .shop_products_top_filter .project-top-right .project-icon-selected {
    width: 100%;
  }
  .shop_products_top_filter .project-top-right .project-category {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .product-item__image .carousel-item {
    height: 100%;
  }
  .checkout-progress div.current img,
  .checkout-progress div.current svg {
    width: 100%;
  }
  .filtr-item--style {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    width: 50%;
  }
  .counting-area {
    margin-bottom: 12px;
  }
  .coming-soon .subtitle {
    margin: 37px auto 42px;
  }
  .knowledgebase-details {
    padding-left: 30px;
    padding-right: 30px;
  }
  #filter-form-container .form-inline {
    margin-bottom: 15px;
  }
  #filter-form-container .footable-filtering-search {
    margin: 15px 0;
  }
  .adv-table .footable-filtering-search {
    margin-left: 0 !important;
  }
  .ticket-details-contact__status-item {
    margin-right: 100px;
  }
  .ticket-chat-wrapper .ticket-search-header form {
    height: 50px;
  }
  .ticket-chat-wrapper .ticket-search-header button {
    width: 40px;
    height: 40px;
  }
  .course-card__footer > div {
    margin: 5px 0;
  }
  .blog-details {
    padding: 0 70px;
  }
  .footer-wrapper {
    padding: 14px 0 14px 8px;
  }
  .footer-wrapper {
    padding: 20px 0 !important;
  }
  .mdc__head {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .contact-card-group .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .project-category p {
    margin-right: 20px;
    margin-bottom: 0;
  }
  .payment-invoice-qr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .payment-invoice-qr .payment-invoice-qr__address,
  .payment-invoice-qr .payment-invoice-qr__number {
    text-align: center;
  }
}
@media only screen and (max-width: 991px) and (max-width: 767px) {
  .project-search .user-member__form {
    min-width: 350px;
  }
  .project-tap .nav-link {
    padding: 0 7.5px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 479px) {
  .project-search .user-member__form {
    min-width: 250px;
    max-width: 350px;
  }
}
@media only screen and (max-width: 991px) and (max-width: 375px) {
  .project-search .user-member__form {
    min-width: 250px !important;
  }
}
@media only screen and (max-width: 900px) {
  .navbar-right__menu .nav-settings .dropdown-custom .dropdown-wrapper--large {
    min-width: 100%;
    position: fixed;
  }
  .navbar-right__menu
    .nav-settings
    .dropdown-custom
    .dropdown-wrapper--large:before {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .text-align-sm-center {
    text-align: center;
  }
  .text-align-sm-0 {
    text-align: left;
  }
  .contents {
    padding: 56px 0 85px;
  }
  .contents.expanded {
    padding-left: 0;
  }
  .top-menu .contents {
    padding: 56px 0 85px;
  }
  .navbar {
    padding-right: 1rem;
  }
  .navbar-left .logo-area {
    padding-left: 1rem;
    height: 60px;
  }
  .navbar-right {
    margin-right: -8px;
  }
  .navbar-right__menu > li {
    padding: 0;
  }
  .navbar-right__menu > li .dropdown-custom {
    padding: 20px 12px;
  }
  .navbar-right__menu .dropdown-custom .dropdown-wrapper:before {
    display: none;
  }
  .navbar-right__menu .nav-search {
    display: none;
  }
  .navbar-right__menu .nav-author .dropdown-wrapper {
    min-width: 100%;
    position: fixed;
  }
  .navbar-right__mobileAction a img,
  .navbar-right__mobileAction a svg {
    width: 18px;
  }
  .sidebar {
    top: 56px;
  }
  .breadcrumb-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 28px;
  }
  .breadcrumb-main .breadcrumb-action {
    margin: 10px 4px 0 -4px;
  }
  .card .card-header h1 span,
  .card .card-header h2 span,
  .card .card-header h3 span,
  .card .card-header h4 span,
  .card .card-header h5 span,
  .card .card-header h6 span {
    display: block;
    margin: 8px 0 0;
  }
  .card .card-body {
    padding: 1rem;
  }
  .card-overview__left .col-xl-6 {
    padding-right: 12.5px;
  }
  .card-overview__right .col-xl-6 {
    padding-right: 12.5px;
  }
  .revenue-labels div {
    padding-bottom: 0;
  }
  .revenue-chart-box {
    text-align: center;
  }
  .label-detailed {
    margin-bottom: 0;
    gap: 0;
  }
  .revenueSourceChart {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .revenueSourceChart .chart-content__details {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .profile-content .card-body {
    padding: 1rem;
  }
  .ap-po-details--3 > div:not(.b-none) {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
  .table-top-regions {
    margin: 0 auto 25px;
  }
  .user-member .breadcrumb-main__wrapper {
    margin-bottom: 15px;
  }
  .user-pagination .justify-content-end {
    -webkit-box-pack: center !important;
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .project-search--height {
    width: 382px;
  }
  .ui-datepicker {
    width: auto !important;
  }
  .ui-datepicker-multi .ui-datepicker-group {
    width: 100%;
    float: none;
  }
  .ui-datepicker-multi .ui-datepicker-group.ui-datepicker-group-first {
    margin-right: 0;
  }
  .page-title {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .page-title__right {
    margin-top: 12px;
  }
  .page-info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .project-top-progress .project-top-right {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .project-top-progress .project-tap {
    padding: 0 10px;
  }
  .chat {
    padding-right: unset;
    padding: 0 15px;
  }
  .chat-area .chat-sidebar {
    width: calc(100% - 30px);
  }
  .chat-area .chat-sidebar .left-sidebar-wrap {
    width: 100%;
  }
  .outgoing-chat .dropdown-default.dropdown-bottomRight {
    right: auto !important;
    left: 0;
  }
  .emotions__parent li a img {
    width: 17px;
  }
  .product_page--grid .project-top-wrapper,
  .product_page--list .project-top-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .project-search.order-search {
    margin: 10px 50px;
  }
  .product-details.card {
    margin-bottom: 80px;
  }
  .product-details .product-item__button {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .project-top-left,
  .project-top-right {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .shop-breadcrumb .breadcrumb-main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 19px;
  }
  .shop-breadcrumb .breadcrumb-main h4 {
    margin-bottom: 6px;
  }
  .checkout .col-xl-4 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .wizard6 .checkout-shipping-form {
    margin-top: 8px;
  }
  .payment-invoice-address {
    margin-bottom: 30px;
  }
  .search-keyword {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .search-keyword ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .filtr-item--style {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .error-page__title {
    margin-top: 60px;
  }
  .breadcrumb-main.application-ui {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .breadcrumb-main.application-ui .breadcrumb-action {
    margin-bottom: 11px;
  }
  .note-contents .note-sidebar-wrapper {
    width: 200px;
  }
  .note-contents .note-grid .note-single {
    width: 100%;
  }
  .coming-soon {
    padding: 50px 0;
  }
  .banner-feature--14 .card-body {
    padding: 1rem;
  }
  .banner-feature--16 {
    padding: 50px 0 0;
  }
  .testimonial-slider-global .slider-arrow {
    top: 100%;
  }
  .testimonial-slider-global .slider-arrow.slider-prev {
    left: 30px;
  }
  .testimonial-slider-global .slider-arrow.slider-next {
    right: 30px;
  }
  .fileM-progress {
    margin: 0;
  }
  .fileM-top-search {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .fileM-top-search form {
    margin-bottom: 15px;
  }
  .knowledgebase-adv__right {
    margin-top: 50px;
  }
  .new-ticket .ticket_modal {
    padding: 20px 30px;
  }
  .support-ticket-system--search .support-form__input > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .support-ticket-system--search
    .support-form__input
    > div
    .support-form__input-id {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .support-ticket-system--search
    .support-form__input
    > div
    .support-form__input-button,
  .support-ticket-system--search
    .support-form__input
    > div
    .support-form__input-status {
    margin-left: 0;
    margin-bottom: 15px;
  }
  .support-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .support-form .support-form__search {
    margin: 0 0 20px;
  }
  .back-page {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: -10px;
  }
  .job-details-card__top .btn {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .job-details-card__top .job-item__image {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .job-apply-wrapper {
    padding: 20px 30px;
  }
  .cvg__item {
    min-height: 250px;
    height: 250px;
  }
  .cvg__item > img {
    height: 250px;
  }
  .cvg__item-content {
    height: 250px;
  }
  .blog-details-thumbnail img {
    max-height: 300px;
    min-height: 300px;
  }
  .blog-block-quote cite {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .blog-block-quote cite span {
    margin-left: 0;
    margin-top: 6px;
  }
  .blog-details .blog-share-top {
    display: none;
  }
  .admin-element-right .card .card-header {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .footer-wrapper {
    padding: 15px 0 !important;
  }
  .footer-copyright {
    text-align: center;
    padding-left: 0;
  }
  .footer-menu.text-end {
    margin-top: 10px;
    text-align: center !important;
    padding-right: 0;
  }
  .mailbox-wrapper .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .dm-mail-sidebar {
    position: fixed;
    top: 55px;
    left: 0;
    width: 280px;
    height: 100%;
    z-index: 99;
  }
  .dm-mail-sidebar > .card {
    -webkit-border-radius: 0;
    border-radius: 0;
    height: 100%;
    padding-top: 60px;
  }
  .mailbox-container .card .card-body {
    padding: 0;
  }
  .mail-read-content {
    padding: 15px;
  }
  .mail-details .mail-attatched__hover .btn-mail {
    width: 30px;
    height: 30px;
  }
  .contact-breadcrumb .breadcrumb-main__wrapper {
    margin-bottom: 15px;
  }
  .admin-header-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) and (max-width: 575px) {
  .project-search.order-search {
    margin: 0;
  }
}
@media (max-width: 700px) {
  #full-calendar .fc-toolbar-chunk .fc-button-group {
    margin-top: 10px;
  }
  .checkout-progress {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .checkout-progress .step {
    margin-bottom: 50px;
  }
  .checkout-progress .current img,
  .checkout-progress .current svg {
    display: none;
  }
  .checkout-progress .current span img,
  .checkout-progress .current span svg {
    display: block;
  }
  .wizard8 .checkout-progress div.step:not(:last-child) {
    margin-bottom: 20px;
  }
  .wizard7 .checkout-shipping-form {
    margin-top: 40px;
  }
  .payment-method-area--top {
    margin-top: 25px;
  }
  .payment-status {
    margin-top: 28px;
  }
}
@media (max-width: 650px) {
  .dm-mailCompose--position {
    right: 15px;
    width: calc(100% - 30px);
  }
}
@media only screen and (max-width: 575px) {
  .text-align-ssm-center {
    text-align: center;
  }
  .text-align-ssm-0 {
    text-align: left;
  }
  .col-xl-3.col-ssm-12,
  .col-xl-4.col-ssm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .customizer-trigger svg path {
    fill: var(--color-white);
  }
  .customizer-trigger i {
    color: var(--color-white);
  }
  .customizer-trigger:hover {
    background-color: var(--color-dark);
  }
  .customizer-trigger:hover svg path {
    fill: var(--color-white);
  }
  .customizer-trigger {
    width: 50px;
    height: 50px;
    -webkit-border-radius: 10px 0 0 10px;
    border-radius: 10px 0 0 10px;
    background-color: var(--color-primary);
    position: fixed;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0;
  }
  .customizer-trigger svg {
    margin: 0;
  }
  .customizer-trigger span {
    display: none;
  }
  .customizer-trigger img,
  .customizer-trigger svg {
    width: 25px;
  }
  .customizer-wrapper {
    width: 280px;
    -webkit-transform: translateX(280px);
    -ms-transform: translateX(280px);
    transform: translateX(280px);
  }
  .navbar-left .navbar-brand img,
  .navbar-left .navbar-brand svg {
    max-width: 130px;
    min-width: 130px;
  }
  .navbar-right__menu .dropdown-wrapper {
    min-width: 100% !important;
    position: fixed !important;
  }
  .navbar-right__menu .nav-author .nav-item__title {
    display: none;
  }
  .navbar-right__mobileAction a + a {
    margin-left: 10px;
  }
  .breadcrumb-main {
    margin-top: 28px;
    margin-bottom: 23px;
  }
  .breadcrumb-main .breadcrumb-action {
    margin: 5px 4px 0 -4px;
  }
  .card .card-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    text-align: center;
    padding: 20px 0;
    min-height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .card .card-header .card-tab {
    margin: 0 !important;
  }
  .card .card-header .card-extra {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    margin-top: 15px;
  }
  .card .card-header .dropdown {
    padding: 0;
    line-height: 1;
  }
  .card .card-header .card-tab-links {
    margin: 0;
  }
  .card .card-header .card-tab-links.me-3 {
    margin-right: 0 !important;
  }
  .card .card-header .card-tab-links li a {
    padding: 2px 7px;
  }
  .card .card-header .card-tab + .dropdown,
  .card .card-header .card-tab-links + .dropdown {
    margin: 10px 0 -6px;
  }
  .card-overview-progress .card-header {
    padding: 20px;
    min-height: 180px;
  }
  .device-pieChart-wrap .pie-chart-legend span {
    font-size: calc(16.4px + 0.3vw);
  }
  .session-wrap .session-single {
    padding: 5px;
  }
  .inEx-wrap .inEx-box {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px;
  }
  .inEx-wrap .inEx-chart .legend-static li {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .inEx-wrap .inEx-chart .legend-static li:not(:last-child) {
    margin: 0 0 15px;
  }
  .inEx__single {
    text-align: center !important;
  }
  .inEx__single {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .inEx__single .inEx__info {
    -webkit-box-align: center !important;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .cashflow-display {
    margin: -10px -10px 15px;
  }
  .cashflow-display {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .cashflow-display__single {
    text-align: center;
    margin: 10px;
  }
  .regions-svg .jvectormap-container svg {
    width: 100% !important;
  }
  .ap-tab-main .nav-link {
    font-size: 14px;
  }
  .ap-tab-main .nav-link.active:after {
    visibility: hidden;
    opacity: 0;
  }
  .chartLine-po-details .card-header {
    padding: 20px 0 0;
  }
  .chartLine-po-details__overview-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .user-info-tab .ap-tab-main .nav-item .nav-link.active {
    border-bottom: 2px solid transparent;
  }
  .project-category .project-tap .nav-link {
    padding: 10px;
  }
  .new-member-calendar {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .user-member-card .dropdown-default.dropdown-bottomRight {
    left: auto !important;
    right: 0 !important;
  }
  .input-list__single {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
  .timeline-box .timeline-single__content {
    padding-left: 16px;
  }
  .timeline-vertical .timeline:before {
    left: 0;
  }
  .timeline-vertical .timeline li {
    margin-bottom: 20px;
  }
  .timeline-vertical .timeline li .timeline-single {
    width: 92%;
    float: right;
  }
  .timeline-vertical .timeline li .timeline-single:before {
    border-left-width: 0;
    border-right-width: 12px;
    left: -12px;
    right: auto;
  }
  .timeline-vertical .timeline > li .timeline-single__buble {
    left: 0;
  }
  .timeline-vertical.left-middle .timeline li .timeline-single {
    width: 92%;
    float: right;
  }
  .timeline-box--3 .timeline-single__days {
    text-align: left;
  }
  .dm-submenu {
    position: static;
    opacity: 1;
    visibility: visible;
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    -webkit-box-shadow: 0 0;
    box-shadow: 0 0;
    padding: 0;
  }
  .dm-steps .nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .dm-steps__item:not(:last-child) {
    margin-bottom: 15px;
  }
  .dm-steps__line {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    top: 30px;
    left: 25px;
    height: 100%;
    width: 1px;
  }
  .time-picker .input-container {
    width: 100%;
  }
  .drawer-basic-wrap.account .drawer-account {
    width: 320px;
  }
  .drawer-basic-wrap.account .drawer-account {
    width: 280px;
  }
  .drawer-basic-wrap.profile .drawer-profile {
    width: 320px;
  }
  .drawer-multiLevel .dm-drawer.drawer-level-one {
    width: 320px;
  }
  .drawer-multiLevel .dm-drawer.drawer-level-one {
    width: 280px;
  }
  .acButton {
    margin-bottom: 0;
    margin-top: 15px;
  }
  .project-task-list__right ul li:not(:last-child) {
    margin-right: 10px;
  }
  .project-task-list__right ul li a i {
    font-size: 14px;
  }
  .project-task-list__right ul li a i.uil-times {
    font-size: 18px;
  }
  .task-list-btn button {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .task-activities__left {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    width: 100%;
  }
  .task-activities__left .task-activities__month {
    padding: 30px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .task-activities__right {
    padding-top: 17px;
  }
  .project-task-list .event-Wrapper {
    padding-right: 10px;
  }
  .project-task-list__left {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .project-task-list__left .checkbox-group {
    margin-bottom: 10px;
  }
  .projects-tab-content--progress .col-xl-4 {
    max-width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .chat-type-text__write {
    width: 100%;
    height: unset;
    padding: 6px 25px;
    margin-right: 0;
  }
  .chat-type-text__btn {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 20px;
  }
  .chat-type-text__btn button:not(:last-child) {
    margin-right: 8px;
  }
  .date-picker--demo5 .date-picker__calendar .ui-datepicker-calendar thead th {
    width: 28px;
    height: 28px;
  }
  .date-picker--demo5
    .date-picker__calendar
    .ui-datepicker-calendar
    tbody
    td
    a {
    width: 28px;
    height: 28px;
  }
  .fc-view
    .fc-col-header
    tr
    th
    .fc-scrollgrid-sync-inner
    .fc-col-header-cell-cushion {
    padding: 0;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    font-size: 11px;
  }
  .fc-daygrid-day .fc-daygrid-event {
    font-size: 10px;
    margin: 0;
    padding: 2px 5px;
  }
  .product--list .product-item .like-icon {
    top: 50%;
    right: -28px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .product--list .product-item .product-item__image {
    width: 100%;
    height: 100%;
  }
  .product--list .product-item .product-item__title {
    padding-left: 0;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .product--list .product-item .product-item__content {
    padding: 0;
  }
  .checkout-progress {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .checkout-progress div.step {
    margin-bottom: 55px;
  }
  .checkout-progress div.step span:nth-of-type(1) {
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 14px;
  }
  .checkout-progress div:not(.step) {
    opacity: 0;
  }
  .wizard10 .checkout-shipping-form {
    padding-bottom: 30px;
  }
  .wizard8 .checkout-progress-indicator .card-header h4 {
    padding: 15px 0;
  }
  .wizard8 .checkout-progress-indicator .card-header p {
    margin-bottom: 10px;
  }
  .wizard8 .checkout-shipping-form {
    padding-bottom: 30px;
  }
  .checkout-shipping-form {
    margin-top: 8px;
  }
  .payment-method-card .card-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .payment-method-card .card-header .payment-way {
    margin-top: 10px;
  }
  .payment-method-area--top {
    margin-top: 27px;
  }
  .checkout-review-title {
    margin-top: 36px;
    margin-bottom: 25px;
  }
  .orderDatatable.sellerDatatable .project-top-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .orderDatatable.sellerDatatable .order-button-group {
    margin-top: 15px;
  }
  .selling-product-img span {
    padding-right: 15px;
  }
  .dashboard-base {
    margin-bottom: 9px;
    margin-top: 40px;
  }
  .dashboard-base .table td,
  .dashboard-base .table th {
    padding: 0 10px;
  }
  .filtr-item--style {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    width: 100%;
  }
  .gallery-filter .ap-tab-main {
    padding: 15px 0;
  }
  .gallery-filter .ap-tab-main .nav-link {
    padding: 6px 0;
    margin-bottom: 0;
  }
  .error-page__title {
    margin-top: 50px;
    margin-bottom: 25px;
  }
  .breadcrumb-main.application-ui .breadcrumb-action {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    margin-bottom: 15px;
  }
  .breadcrumb-main.application-ui .breadcrumb-action .action-btn {
    margin-bottom: 15px;
  }
  .note-contents {
    display: block;
  }
  .note-contents .note-sidebar-wrapper {
    width: auto;
  }
  .coming-soon img,
  .coming-soon svg {
    width: 150px;
  }
  .coming-soon .subtitle {
    margin: 29px auto 42px;
  }
  .coming-soon .form-inline .form-control {
    width: 100%;
  }
  .subscribe-bar {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .banner-feature--3 .banner-feature__shape {
    position: static;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .banner-feature--3 .banner-feature__shape img {
    max-width: 180px;
  }
  .banner-feature--3 .card-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .banner-feature--4 .banner-feature__shape img {
    max-width: 120px;
  }
  .banner-feature--danial .banner-feature__shape img {
    max-width: 150px;
  }
  .banner-feature--5 .banner-feature__shape img {
    max-width: 150px;
  }
  .banner-feature--7 .banner-feature__shape.position-absolute {
    opacity: 0.3;
  }
  .banner-feature--10 {
    min-height: 280px;
  }
  .banner-feature--12 .banner-feature__shape {
    width: 100%;
  }
  .banner-feature--13 .banner-feature__shape {
    width: 100%;
  }
  .banner-feature--18.new .banner-feature__btn {
    min-height: 40px;
  }
  .banner-feature--18.new .card-body {
    text-align: center;
  }
  .banner-feature--18 .banner-feature__btn {
    min-height: 40px;
  }
  .banner-feature--18 .card-body {
    text-align: center;
  }
  .fileM-sidebar {
    height: auto;
  }
  .fileM-sidebar .fileM-types {
    margin: 0 0 30px;
  }
  .knowledgebase {
    padding: 30px 0;
  }
  .knowledgebase__list h3 {
    margin-top: 20px;
    margin-bottom: 15px;
  }
  .knowledgebase__hr {
    margin: 30px 0;
  }
  .knowledgebase-adv__content article h3 {
    width: 85%;
  }
  .knowledgebase-help .card-body {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .knowledgebase-help .card-body h6 {
    margin-right: 0;
    margin-bottom: 15px;
    text-align: center;
  }
  .knowledgebase-details {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .kb h1 {
    margin-bottom: 20px;
  }
  .kb__header-search {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .kb__header-search-form {
    outline: 0;
  }
  .kb__header-search-form .input-group {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .kb__header-search-form .input-group .kb__select-wrapper {
    width: 100%;
    margin-bottom: 15px;
  }
  .kb__header-search-form .input-group input {
    width: 100%;
    margin-bottom: 15px;
  }
  .kb__header-search-form
    .input-group
    .kb__select-wrapper
    .select2-container--default
    .select2-selection--multiple,
  .kb__header-search-form
    .input-group
    .kb__select-wrapper
    .select2-container--default
    .select2-selection--single {
    border-right: none;
  }
  .kb__header-search-form .input-group .select2-selection--single {
    -webkit-border-radius: 4px;
    border-radius: 4px;
  }
  .kb__header-search-form .input-group input {
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
    height: 30px;
  }
  .kb__header-search-form .input-group button {
    height: 40px;
    padding: 0 33px;
  }
  .kb__header-search-form .input-group button {
    -webkit-border-radius: 4px !important;
    border-radius: 4px !important;
  }
  .kb__select-wrapper
    .select2-container
    .select2-selection--single
    .select2-selection__rendered {
    height: 20px;
  }
  .card-timeline .card-timeline-body ul {
    overflow-x: auto;
    white-space: nowrap;
  }
  .card-timeline .card-timeline-body ul li .card-timeline-body__title p {
    padding-right: 10px;
  }
  .card-timeline .card-timeline-body ul li .card-timeline-body__title p span {
    font-size: 12px;
  }
  .card-timeline .card-timeline-body .card-timeline-body__time span {
    font-size: 12px;
  }
  .card-inbox .card-inbox-members ul li .card-inbox-member__time span {
    font-size: 12px;
  }
  .task-card .table-actions a {
    margin-right: 0;
  }
  #filter-form-container .form-group {
    width: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  #filter-form-container .form-group select {
    width: 100%;
  }
  #filter-form-container .form-group:after {
    top: auto;
    bottom: 7%;
  }
  .ticket_modal .modal-content .modal-header {
    margin-bottom: 30px;
  }
  .new-ticket .ticket_modal .ticket_modal-modal h1 {
    margin-bottom: 30px;
  }
  .support-form .support-form__input > div {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .support-form .support-form__input .dm-select .select2 {
    min-width: 160px;
  }
  .support-form .support-form__input-id {
    margin: 0 0 20px;
  }
  .support-form .support-order-search .support-order-search__form {
    min-width: 220px;
  }
  .ticket-details-contact__status-item {
    margin-right: 30px;
  }
  .job-item__body {
    margin: 0;
  }
  .job-details-card__top .job-item__image {
    margin-bottom: 15px;
  }
  .job-details-card h4 {
    margin-top: 15px;
  }
  .job-apply {
    margin-bottom: 50px;
  }
  .job-apply-wrapper .job-apply__content h1 {
    margin-bottom: 20px;
  }
  .course-card__footer {
    margin-top: 5px;
  }
  .course-card__footer .total-money {
    font-size: 16px;
  }
  .course-accordion
    .dm-collapse-item
    .dm-collapse-item__body
    .course-collapse-body-item
    .course-collapse-body-item__right {
    margin-top: 5px;
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .course-accordion .dm-collapse-item__header .item-link .dm-collapse-title {
    margin-bottom: 10px;
  }
  .course-accordion
    .dm-collapse-item__header
    .item-link
    .course-collapse-right {
    width: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .cvg {
    padding: 25px;
  }
  .terms-breadcrumb {
    min-height: 230px;
  }
  .terms-breadcrumb .display-1 {
    margin-top: 50px;
  }
  .terms-content {
    padding: 40px 30px;
    margin-top: -100px;
  }
  .terms-content__group:not(:last-child) {
    margin-bottom: 40px;
  }
  .terms-content__group h1 {
    margin-bottom: 25px;
  }
  .blog-card--2 .blog-card__thumbnail {
    height: 220px;
  }
  .blog-card--2 .blog-card__thumbnail img {
    height: 220px;
  }
  .blog-card--3 .blog-card__thumbnail {
    height: 220px;
  }
  .blog-card--3 .blog-card__thumbnail img {
    height: 220px;
  }
  .blog-details-thumbnail img {
    max-height: 250px;
    min-height: 250px;
  }
  .blog-details {
    padding: 0;
  }
  .blog-details-meta {
    margin: 0 -10px 28px;
  }
  .blog-details-meta li a img {
    width: 40px;
    height: 40px;
  }
  .blog-details-content {
    margin-top: 33px;
  }
  .blog-block-quote {
    margin-bottom: 30px;
    padding: 17px 22px 15px 35px;
  }
  .blog-author-box .auth-img img {
    width: 50px;
    height: 50px;
  }
  .admin .edit-profile .card .card-body {
    padding: 30px 30px 23px;
  }
  .admin-condition {
    margin-bottom: 14px;
    margin-top: 18px;
  }
  .social-connector.social-connector__admin {
    margin-bottom: 22px;
  }
  .layout-dark .customizer-trigger {
    background-color: var(--main-color);
    color: var(--white-60);
    -webkit-box-shadow: 0 5px 5px rgba(27, 30, 43, 0.3);
    box-shadow: 0 5px 5px rgba(27, 30, 43, 0.3);
  }
  .layout-dark .customizer-trigger:hover {
    background-color: var(--color-primary);
  }
  .layout-dark .dm-submenu {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  .columnCard-wrapper {
    padding: 40px 20px 30px;
  }
  .contact-card-group .col-xl-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .contact-breadcrumb .breadcrumb-main__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .contact-breadcrumb .breadcrumb-main__wrapper .add-contact__form {
    margin: 10px 0 2px;
  }
  .orderDatatable .project-category {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .project-top-progress .project-category {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 20px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .project-top-progress .project-top-right {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .payment-invoice-logo {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .payment-invoice-qr__code img {
    width: 180px;
  }
  .payment-invoice-address {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .payment-invoice-address__area address {
    margin-top: 15px;
    text-align: center;
  }
  .user-member__title {
    margin-right: 25px;
    margin-left: 25px;
    margin-bottom: 8px;
  }
  .user-member__title span {
    padding-left: 15px;
    margin-left: 15px;
  }
}
@media only screen and (max-width: 479px) {
  .text-align-xs-center {
    text-align: center;
  }
  .text-align-xs-0 {
    text-align: left;
  }
  .navbar-left .navbar-brand {
    margin-right: 25px;
    margin-left: 0;
  }
  .navbar-left .logo-area {
    min-width: 230px;
  }
  .navbar-right__menu .dropdown-wrapper {
    width: 100% !important;
  }
  .navbar-right__menu .nav-message ul li {
    -webkit-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .navbar-right__menu .nav-message .dropdown-wrapper ul li {
    padding: 10px 25px;
  }
  .navbar-right__menu .nav-message .dropdown-wrapper .user-avater {
    margin: 0 10px 10px 0;
  }
  .navbar-right__menu .nav-message .dropdown-wrapper .user-message {
    width: 100%;
  }
  .navbar-right__menu
    .nav-message
    .dropdown-wrapper
    .user-message
    p
    .msg-count {
    margin-left: 10px;
  }
  .list-settings {
    height: 300px;
    overflow-y: auto;
  }
  .list-settings li {
    padding: 10px;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .list-settings li .me-3 {
    margin-right: 0;
    margin-bottom: 15px;
  }
  .card.card-md .card-header .card-extra {
    margin-top: 8px;
  }
  .card.card-sm .card-header .card-extra {
    margin-top: 8px;
  }
  .notification-wrapper.top-left {
    left: 10px;
  }
  .notification-wrapper.top-right {
    right: 10px;
  }
  .notification-wrapper.bottom-left {
    left: 10px;
  }
  .notification-wrapper.bottom-right {
    right: 10px;
  }
  .notification-placement-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .revenueSourceChart .chart-content__single span.icon {
    width: 60px;
    height: 60px;
    margin: 15px 15px 0;
  }
  .performance-o .performance-chart-labels ul {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ap-po-details--ticket {
    padding: 25px;
  }
  .ap-po-details--luodcy .ap-po-details__titlebar {
    padding-right: 0;
  }
  .ap-po-details--luodcy .ap-po-details__icon-area {
    width: 70px;
  }
  .ap-po-details--luodcy .ap-po-details__icon-area:before {
    width: 150px;
    height: 100%;
    left: 15px;
  }
  .ap-post-content-comment button img,
  .ap-post-content-comment button svg {
    width: 12px;
  }
  .ap-post-content-comment button.wh-50 {
    width: 30px;
    height: 30px;
  }
  .ap-post-content-comment__write {
    height: 40px;
    padding: 0 10px;
  }
  .ap-post-content-comment__write a img,
  .ap-post-content-comment__write a svg {
    width: 14px;
  }
  .ap-post-content-comment__write a:not(:last-child) {
    margin-right: 8px;
  }
  .ffw > .d-flex {
    margin: -5px 0;
  }
  .as-cover__imgWrapper img {
    min-height: 150px;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .dm-pagination {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .dm-pagination__item:not(:last-child) {
    margin: 0 0 15px;
  }
  .timeline-vertical .timeline li .timeline-single {
    width: 88%;
  }
  .timeline-vertical.left-middle .timeline li .timeline-single {
    width: 88%;
  }
  .chat-body .chat-header .media-body__content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .chat-wrapper .search-header,
  .chat-wrapper .search-tab {
    padding: 0 15px;
  }
  .user-list-item__wrapper {
    padding: 0 15px;
  }
  .user-list-item .users-list-body {
    display: grid;
  }
  .user-list-item .users-list-body .last-chat-time {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .chat-text-box__subtitle p {
    font-size: 14px;
  }
  .chat-text-box__other .dropdown-default .dropdown-item,
  .dropdown-menu .dropdown-item {
    padding: 10px 15px;
  }
  .emotions .dropdown-menu {
    padding: 9px 20px;
  }
  .date-picker__calendar .ui-datepicker-calendar tbody td a {
    width: 1.5rem;
    height: 1.5rem;
  }
  #full-calendar .fc-toolbar-chunk .fc-today-button {
    margin-right: 15px;
    padding: 6px 10px;
  }
  #full-calendar .fc-toolbar-chunk .fc-button.fc-next-button,
  #full-calendar .fc-toolbar-chunk .fc-button.fc-prev-button {
    padding: 0.2rem 0.4rem;
  }
  #full-calendar .fc-toolbar-chunk .fc-toolbar-title {
    font-size: 12px;
    margin: 10px;
  }
  .fc-daygrid-day .fc-daygrid-event .fc-event-title {
    display: none;
  }
  .product_page--grid .project-category p,
  .product_page--list .project-category p {
    margin: 8px 20px;
  }
  .upload-product-img {
    padding: 40px 0;
  }
  .counting-area .number {
    margin: 20px;
  }
  .coming-soon :is(.light, .dark) {
    max-width: 180px;
  }
  .subscribe-bar {
    width: 100%;
  }
  .subscribe-bar .form-group,
  .subscribe-bar button {
    width: 100%;
  }
  .changelog__according .card .card-header {
    height: auto;
  }
  .changelog__accordingCollapsed {
    height: auto;
  }
  .rl-date {
    margin: 4px 0;
  }
  .banner-feature--17 {
    margin-top: 30px;
  }
  .banner-feature--17 .application-faqs .panel-title > a:before {
    right: -15px;
  }
  .banner-feature--18 .banner-feature__shape img {
    -o-object-fit: contain;
    object-fit: contain;
  }
  .testimonial-section__padding {
    padding: 40px 0 60px;
  }
  .knowledgebase__nav li .nav-link {
    font-size: 14px;
  }
  .knowledgebase__nav li:not(:last-child) {
    margin-right: 20px;
  }
  .card-timeline .card-header {
    padding-bottom: 0;
  }
  #filter-form-container .form-control {
    width: 150px;
    max-width: 100%;
  }
  .ticket-details-contact__status-item {
    margin-bottom: 20px;
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin-right: 0;
  }
  .search-style-2 form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .search-style-2 form .job-search,
  .search-style-2 form .location-search {
    margin: 0;
    width: 100%;
    margin-bottom: 10px;
    min-height: 46px;
  }
  .job--list .job-item__content {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .job--list .job-item__content > div {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
    margin-bottom: 10px;
  }
  .job-item__body {
    -webkit-box-flex: 100%;
    -ms-flex: 100%;
    flex: 100%;
  }
  .course-card__footer_left {
    margin-bottom: 5px;
  }
  .course-collapse__icon-right-fixed.course-accordion
    .dm-collapse-item__header
    .item-link {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .course-collapse__icon-right-fixed.course-accordion
    .dm-collapse-item__header
    .item-link
    .course-collapse-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: end;
    width: auto;
  }
  .mailbox-top__actions li {
    line-height: 1;
  }
  .mailbox-top .mail-selection {
    padding: 0;
  }
  .mailbox-top .icon-action {
    height: 25px;
    width: 25px;
  }
  .mailbox-list__single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .mailbox-list__single:not(.attach-file) {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .mailbox-list__single .mail-authorBox {
    margin: 0 0 12px;
  }
  .mailbox-list__single .mail-authorBox .author-info .author-name {
    margin: 0 0 0 5px;
  }
  .mailbox-list__single .mail-content__top .mail-title {
    margin-bottom: 10px;
  }
  .mailbox-list__single .mail-content__bottom {
    margin: 8px -3px -5px;
  }
  .mailbox-list .time-meta {
    margin-top: 10px;
  }
  .mail-details .mail-attatched__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 46%;
    flex: 0 0 46%;
  }
  .mail-reply__single {
    margin: 0;
    padding: 15px;
  }
  .reply-form {
    padding: 10px 15px 15px;
  }
  .project-top-progress .project-tap {
    height: auto;
  }
  .project-top-progress .project-tap .nav-item:not(:first-child):after {
    display: none;
  }
  .project-top-progress .project-tap .nav-link {
    padding: 6px 7.5px;
  }
  .project-top-progress .project-tap ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 5px 0;
  }
  .project-top-progress .project-category p {
    display: none;
  }
  .breadcrumb-main__wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .user-member {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .user-member__title {
    margin: 0 10px;
    padding-bottom: 10px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .user-member__title span {
    margin: 0;
    padding: 0;
    position: unset;
    margin-top: 15px;
  }
  .shop-breadcrumb .breadcrumb-action .action-btn {
    margin: 10px 4px;
  }
  .product-details .product-item__image .list-thumb-gallery > li {
    margin-bottom: 1rem;
    width: 70px;
    height: 70px;
  }
  .upload-include-area img {
    width: 60px;
    height: 50px;
  }
  .orderDatatable .button-group {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .orderDatatable .project-tap {
    height: auto;
  }
  .orderDatatable .project-tap ul {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .orderDatatable .project-tap .nav-link {
    padding: 5px 7.5px;
  }
  .orderDatatable .project-tap .nav-item:not(:first-child):after {
    background: 0 0;
  }
  .payment-invoice__btn {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .payment-invoice__btn button {
    margin-bottom: 15px;
  }
  .payment-invoice-address__area address,
  .payment-invoice-qr__address span {
    font-size: 13px;
  }
  .user-info-tab .ap-tab-main .nav-item .nav-link {
    padding: 10px 0;
  }
  .chat-body .chat-header {
    padding: 15px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    height: unset;
  }
  .chat-body .chat-header .chat-name {
    margin-bottom: 5px;
  }
  .chat-body .chat-header .chat-name .include-body__content {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .chat-wrapper {
    height: 750px;
  }
  .chat-box {
    padding: 20px 10px;
  }
  .search-tab .ap-tab-main {
    height: unset;
    -webkit-box-pack: unset;
    -ms-flex-pack: unset;
    justify-content: unset;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px 0;
  }
  .search-tab .ap-tab-main .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 0;
  }
  .search-tab .ap-tab-main .nav-link:after {
    display: none;
  }
  .sidebar-group {
    margin: 0 15px;
  }
  .sidebar-group .left-sidebar-wrap {
    width: 100%;
  }
  .order-summery .checkout {
    font-size: 12px;
    padding: 15px 10px;
  }
}
@media only screen and (max-width: 479px) and (max-width: 479px) {
  .sidebar-group.chat_sidebar {
    margin: 0;
  }
}
@media (max-width: 414px) {
  .product_page--grid .project-top-left,
  .product_page--list .project-top-left {
    margin-bottom: 15px;
  }
}
@media (max-width: 400px) {
  .daterangepicker {
    width: 240px;
  }
  .mailbox-top__extra .mail-sliding-control {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mdc__excerpt .dropdownMenu img,
  .mdc__excerpt .dropdownMenu svg {
    width: 17px;
    height: 17px;
  }
  .mdc__excerpt a:not(:last-child) {
    margin-right: 5px;
  }
  .mdc__excerpt .date-meta {
    margin-right: 10px;
    margin-left: 10px;
  }
}
@media only screen and (max-width: 375px) {
  .breadcrumb-main .breadcrumb-action .input-container .icon-left {
    left: 8px;
  }
  .breadcrumb-main .breadcrumb-action .input-container .icon-right {
    right: 4px;
  }
  .dm-comment-box__author {
    margin-right: 10px;
  }
  .performance-stats div {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .ap-tab-main {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .ap-tab-main .nav-link {
    padding: 10px 0;
  }
  .ap-tab-main .nav-link.active {
    border-bottom: 0;
  }
  .drawer-basic-wrap.profile .drawer-profile {
    width: 280px;
  }
  .dm-drawer {
    width: 260px;
  }
  .drawer-profile-box__single {
    padding: 15px;
  }
  .table5 .userDatatable-title .userDatatable-filter {
    padding-left: 60px;
  }
  .upload-media-area > img {
    width: 70px;
    height: 60px;
  }
  .gallery-filter .ap-tab-main {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .kanban-list {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 230px;
    flex: 0 0 230px;
  }
  .kanban-modal__list .checkbox-group__single {
    -webkit-box-flex: 3;
    -ms-flex: 3;
    flex: 3;
  }
  .admin-condition {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column;
    flex-flow: column;
  }
  .admin-condition a {
    margin-top: 10px;
  }
  .mail-details .mail-attatched__item {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 96%;
    flex: 0 0 96%;
  }
}
@-webkit-keyframes spinMove {
  100% {
    opacity: 1;
  }
}
@keyframes spinMove {
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(410deg);
    transform: rotate(410deg);
  }
}
@-webkit-keyframes shimmerEffect {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@keyframes shimmerEffect {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
@-webkit-keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes ring {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
}

/* Gym Tour Add Image css */

.drop-container {
  position: relative;
  display: flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 20px;
  border-radius: 10px;
  border: 2px dashed #555;
  color: #444;
  cursor: pointer;
  transition: background 0.2s ease-in-out, border 0.2s ease-in-out;
}

.drop-container:hover {
  background: #eee;
  border-color: #111;
}

.drop-container:hover .drop-title {
  color: #222;
}

.drop-title {
  color: #444;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  transition: color 0.2s ease-in-out;
}

.mr-4 {
  margin-right: 4px;
}
